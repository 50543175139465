import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
	ButtonProps,
} from '@components/button';
import Icon from '@components/icon';
import Paragraph from '@components/paragraph';

// STYLES
import styles from './ModalProductDemo.module.scss';

interface buttonsProps extends ButtonProps {
	validate?: {
		disabled?: boolean;
		label?: string;
		onClick?: (event: MouseEvent<HTMLElement>, itemName: string) => void;
	};
	more?: {
		href?: string;
		label?: string;
		disabled?: boolean;
	};
}

export interface ModalProductDemoProps {
	buttons?: buttonsProps;
	content?: ReactNode;
	hasAccess?: boolean;
	itemName?: string;
	icon?: string;
	permissionName?: string;
	title?: string;
}

function ModalProductDemo({
	buttons,
	content,
	itemName,
	icon,
	title,
}: ModalProductDemoProps) {
	const { t } = useTranslation();

	const handleOnClickValidate = (event: MouseEvent<HTMLElement>) => {
		if (buttons?.validate?.onClick) {
			buttons.validate.onClick(event, itemName);
		}
	};

	const modalIcon = (
		<div className={styles.modal_icon}>
			<Icon
				fontStyle={EnumFontStyle.LIGHT}
				name={icon}
			/>
		</div>
	);

	const validateLabel = buttons?.validate?.label || t('modals.product_demo.modal_button.request_demo');
	const moreInfoLabel = buttons?.more?.label || t('modals.product_demo.modal_button.more_info');

	return (
		<div className={`${styles.product_demo}`}>
			{modalIcon}
			<h2 className={styles.title}>{title}</h2>
			<Paragraph
				className={styles.modal_content}
			>
				{content}
			</Paragraph>
			<div className={styles.footer_buttons} >
				{buttons?.more?.href ? (
					<Button
						corners={EnumButtonCorners.Square}
						disabled={buttons?.more?.disabled || false}
						href={buttons.more.href}
						label={t('format.capitalize', {
							text: moreInfoLabel
						})}
						name={moreInfoLabel}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
					/>
				) : undefined}
				<Button
					corners={EnumButtonCorners.Square}
					disabled={buttons?.validate?.disabled || false}
					label={t('format.capitalize', {
						text: validateLabel
					})}
					name={validateLabel}
					size={EnumButtonSize.SMALL}
					onClick={handleOnClickValidate}
				/>
			</div>
		</div>
	);
}

export default withTranslation()(ModalProductDemo);
