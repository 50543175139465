import * as React from 'react';
import {
	ReactNode,
	MouseEvent,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// CONF
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

//TYPES
import {
	Image,
} from '@@types/Image';
import {
	Offer,
} from '@@types/Offer';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumMandateType,
} from '@enums/mandate.enum';

// MODULES
import getOfferPrice from '@modules/getOfferPrice';
import {
	updateImageUrls,
} from '@modules/images';

// LAYOUT
import {
	GetCardsConfiguration,
} from '@layouts/OfferCard/Private/config/actions';
import {
	BodyRequest,
	getDatesOffer,
} from '@layouts/Pages/PageDemand/config/demand.data';
import OfferRow from '@layouts/Pages/PageDemand/manage/table-view/offer-row';

// COMPONENTS
import {
	ButtonProps,
} from '@components/button';
import ButtonList, {
	ButtonListProps,
} from '@components/button-list';
import ButtonWithDropdown, {
	ButtonWithDropdownProps,
} from '@components/button-with-dropdown';
import Card, {
	CardProps,
} from '@components/card';
import {
	LabelStatusProps,
} from '@components/label-status';
import {
	SliderProps,
} from '@components/slider';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';

// STYLES
import styles from './private.module.scss';

interface PrivateCardProps {
	actionsList?: ReactNode;
	canSelect?: boolean;
	checkboxElement?: ReactNode;
	className?: string;
	configuration?: {
		actions?: JSX.Element;
		commercializationStep?: string;
		demandId?: number;
		type?: string;
	};
	isDesktopResolution: boolean;
	isSelected?: boolean;
	language: string;
	offer: Offer;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickBookmarked?: (event: MouseEvent<HTMLElement>) => void;
	onClickCommercializationStep?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void;
	onClickContact?: (offerId: number) => Promise<void>;
	onClickLot?: (offerId: number) => Promise<void>;
	onClickModal?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void;
	onClickSlider?: SliderProps['onClick'];
	onClickThumbtack?: (event: MouseEvent<HTMLElement>) => void;
	pinnedAction?: ReactNode;
	viewType: string;
}

function PrivateCard({
	actionsList,
	canSelect,
	checkboxElement,
	className,
	configuration,
	isDesktopResolution,
	isSelected,
	offer,
	onClick,
	onClickCommercializationStep,
	onClickContact,
	onClickLot,
	onClickModal,
	onClickSlider,
	onClickThumbtack,
	viewType,
	...otherprops
}: PrivateCardProps) {
	let salePriceFormatedCard = null;
	let salePriceFormatedTable = null;
	let rentalPriceFormatedCard = null;
	let rentalPriceFormatedTable = null;
	const { t } = useTranslation();

	const handleOnClickSlider = (event: MouseEvent<HTMLElement>) => {
		onClickSlider ? onClickSlider(event) : null;
	};

	const cssClasses = [
		styles.offer_private
	];
	if (className) cssClasses.push(className);

	// list of states for slider
	const sliderStatesTopLeft = [
		'bookmarked',
		'commercialized',
		'suspended',
		'under_option',
		'under_promise',
		'updated',
	];

	const sliderStatesTopRight = [
		'new',
		'viewed',
	];

	const hiddenLabels = [
		'pinned',
	];

	const sliderStates = [
		...sliderStatesTopLeft,
		...sliderStatesTopRight,
		...hiddenLabels,
	];

	const getSliderLabels = (definedLabels: string[], scopeLabels: string[]) => {
		const labelsFormated: LabelStatusProps[] = [
		];
		definedLabels?.filter(defineLabel => {
			if (scopeLabels.includes(defineLabel.toLowerCase())) {
				const labelStatus = {
					context: 'female',
					text: defineLabel.toLowerCase()
				};
				labelsFormated.push(labelStatus);
			}
		});
		return labelsFormated;
	};

	// filter states for card element
	const labels = offer.states?.filter(state => !sliderStates.includes(state.toLowerCase()));

	const availableCategory = [
		'photo'
	];
	const images = offer.images?.collection?.length ? offer.images.collection.filter(image => availableCategory.includes(image.category)) : null;
	const slider: SliderProps = {
		infinite: true,
		items: images ? updateImageUrls(images, isDesktopResolution ? EnumImageFormatQuery.CARD : EnumImageFormatQuery.CARD_MOBILE) as Image[] : null,
		labelsTopLeft: getSliderLabels(offer.states, sliderStatesTopLeft),
		labelsTopRight: getSliderLabels(offer.states, sliderStatesTopRight),
		size: 'thumbnail',
		onClick: handleOnClickSlider
	};

	const visibleDatesVisits: string[] = [
		'proposed',
		'applied'
	];
	const specificDateDisplayed = [
		'visit_expired',
		'visit_to_plan',
		'visit_planned',
		'visit_visited',
	];
	const dates: string[] = [
	];

	const datesPerCommercializationStep = getDatesOffer.find(date => date.name === configuration?.commercializationStep);

	const datesToDisplay = specificDateDisplayed.filter(specificDate => offer.states?.includes(specificDate));
	if (datesToDisplay.length && visibleDatesVisits.includes(configuration?.commercializationStep)) {
		datesToDisplay.forEach((date) => {
			const dateText = t('format.capitalize', {
				text: t(`page.demand.offer.${date}`, {
					'%date%': offer?.visit?.date,
					'%time%': offer?.visit?.time,
					context: 'female',
				})
			});
			dates.push(dateText);
		});
	} else if (datesPerCommercializationStep?.dates?.length) {
		datesPerCommercializationStep.dates.forEach((date) => {
			const dateObjectJson = offer[`${date as keyof object}`];
			const dateText = t('format.capitalize', {
				text: t(`general.${date}`, {
					context: 'female',
					'%date%': dateObjectJson ? dateObjectJson : null
				})
			});
			if (dateObjectJson) dates.push(dateText);
		});
	}

	const transactionTypesText = offer.transaction_types.map(transaction_type => `${t(`general.${transaction_type}`)}`).join(` ${t('general.and')} `);
	const transactionTypes = (
		t('format.capitalize', {
			'text': transactionTypesText
		})
	);

	let mandateTheme = null;
	switch (true) {
		case offer.mandate?.type === EnumMandateType.EXCLUSIVE && offer.mandate?.is_owner:
			mandateTheme = 'success';
			break;
		case offer.mandate?.type === EnumMandateType.EXCLUSIVE:
			mandateTheme = 'elevation';
			break;
		case offer.mandate?.type === EnumMandateType.SIMPLE:
			mandateTheme = 'warning';
			break;
		case offer.mandate?.type === EnumMandateType.REGULATION:
			mandateTheme = 'error';
			break;
	}

	const mandate = mandateTheme ? {
		type: t(`general.mandate.${offer.mandate?.type}`),
		theme: mandateTheme
	} : null;

	const firstLetterRent = t('format.capitalize', {
		text: t('general.rent')
	}).charAt(0);
	const firstLetterSale = t('format.capitalize', {
		text: t('general.sale')
	}).charAt(0);

	if (offer?.sale_price_value?.amount || offer?.sale_price_state) {
		const salePrice = getOfferPrice(offer?.sale_price_value, offer?.sale_price_state);
		salePriceFormatedCard = salePrice.amount ? (
			<>
				<span
					className={styles.price__amount}
				>
					{salePrice.amount}
				</span>
				{salePrice.unit}
				{salePrice.chargesText}
			</>
		) : salePrice.state;
		salePriceFormatedTable = salePrice.amount ? (
			<>
				{salePrice.amount}
				{salePrice.unit  + ' ' + `(${firstLetterSale})`}
			</>
		) : salePrice.state  + ' ' + `(${firstLetterSale})`;
	}
	if (offer?.rental_price_value?.amount || offer?.rental_price_state) {
		const rentalPrice = getOfferPrice(offer?.rental_price_value, offer?.rental_price_state);
		rentalPriceFormatedCard = rentalPrice.amount ? (
			<>
				<span
					className={styles.price__amount}
				>
					{rentalPrice.amount}
				</span>
				{rentalPrice.unit}
				{rentalPrice.chargesText}
			</>
		) : rentalPrice.state;
		rentalPriceFormatedTable = rentalPrice.amount ? (
			<>
				{rentalPrice.amount}
				{rentalPrice.unit + ' ' + `(${firstLetterRent})`}
			</>
		) : rentalPrice.state  + ' ' + `(${firstLetterRent})`;
	}

	const priceformatedCard = rentalPriceFormatedCard && salePriceFormatedCard
		? <>{rentalPriceFormatedCard} {`(${t('general.rent')})`} {' - '} {salePriceFormatedCard} {`(${t('general.sale')})`}</>
		: rentalPriceFormatedCard || salePriceFormatedCard;

	const priceformatedTable = rentalPriceFormatedTable && salePriceFormatedTable
		? <><span>{rentalPriceFormatedTable}</span> <span>{salePriceFormatedTable}</span></>
		: salePriceFormatedTable || rentalPriceFormatedTable;

	let areaLot = undefined;
	if (offer.lots_resume?.area_min && offer.lots_resume?.area_max) {
		if (offer.lots_resume?.area_min === offer.lots_resume?.area_max) {
			areaLot = t('page.demand.offer.detail.spread_lot_unique', {
				'%count%': offer.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': offer.lots_resume?.area_max,
				}),
			});
		} else {
			areaLot = t('page.demand.offer.detail.spread_lot', {
				'%count%': offer.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': offer.lots_resume?.area_max,
				}),
				'%min_area%': t('format.area', {
					'val': offer.lots_resume?.area_min,
				})
			});
		}
	}

	const areaBuilding = offer?.building?.area?.value ?
		t('format.area', {
			'val': offer.building.area.value,
		}) : null;

	const availabilityDateText = offer?.availability?.date_txt?.length ? offer?.availability?.date_txt : null;
	const availabilityDateFormated = offer?.availability?.date;
	const availabilityState = offer?.availability.state ? t(`status.${offer.availability.state}`) : null;

	const availabilityDateTranslation = t('format.capitalize', {
		text: t('general.availability_text', {
			'text': availabilityState || availabilityDateText || availabilityDateFormated
		})
	});

	const commercializedDateTranslation = offer?.commercialized_at ?
		t('format.capitalize', {
			text: t('general.commercialized_at', {
				'text': offer?.commercialized_at
			})
		}) : null;

	const suspendedDateTranslation = offer.suspended_at ?
		t('format.capitalize', {
			text: t('general.suspended_at', {
				context: 'female',
				'%date%': offer.suspended_at
			})
		}) : null;

	const availabilityTranslation = suspendedDateTranslation || commercializedDateTranslation || availabilityDateTranslation;
	const iconsList = [
	] as CardProps['iconsList'];

	if (offer.is_private) {
		iconsList.push({
			id: `iconPrivate-${offer.id}`,
			name: 'lock',
			style:  EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.private', {
					context: 'female'
				})
			}),
		});
	}

	if (offer.is_added) {
		iconsList.push({
			className: styles.icon_element,
			id: `iconManualAdd-${offer.id}`,
			name: 'hand-sparkles',
			style:  EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.added_manually', {
					context: 'female'
				})
			}),
		});
	} else if (!offer.is_search_criterias_matched) {
		iconsList.push({
			className: styles.icon_element,
			id: `iconOutOfCriteria-${offer.id}`,
			name: 'search-slash',
			style: EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.out_of_criteria')
			}),
		});
	}

	const getButtonCardsIcons = (itemList: ButtonProps[] | ButtonWithDropdownProps[]) => {
		const result = itemList?.map((item: ButtonProps | ButtonWithDropdownProps) => {
			const buttonCssClasses = [
				styles.button,
			];
			if (item.className) buttonCssClasses.push(item.className);
			return {
				...item,
				className: buttonCssClasses.join(' '),
				label: null,
				hasBoxShadow: false,
				tooltipText: item.label,
			};
		});
		return result;
	};

	const getButtonItemsOfferActions = (itemList: ButtonProps[] | ButtonWithDropdownProps[]) => {
		const result = itemList?.map((item: ButtonProps | ButtonWithDropdownProps) => {
			const buttonCssClasses = [
				styles.button
			];
			if (item.className) buttonCssClasses.push(item.className);
			const updatedState = {
				badgeTheme: null as EnumStatusTheme,
				iconLeft: (item as ButtonProps).iconLeft,
				label: (item as ButtonProps).label,
				tooltipText: (item as ButtonProps).label,
			};
			switch (true) {
				case offer?.states?.includes('visit_planned') && (item as DropdownItemProps).id === 'plan_my_visit':
					updatedState.iconLeft = 'calendar-rotate';
					updatedState.tooltipText = t('format.capitalize', {
						text: t('general.action.update_visit')
					});
					updatedState.label = t('format.capitalize', {
						text: t('general.action.update_visit')
					});
					break;
				case offer?.states?.includes('visit_expired') && (item as DropdownItemProps).id === 'plan_my_visit':
					updatedState.iconLeft = 'calendar-question';
					updatedState.tooltipText = t('format.capitalize', {
						text: t('general.action.confirm_visit')
					});
					updatedState.label = t('format.capitalize', {
						text: t('general.action.confirm_visit')
					});
					updatedState.badgeTheme = EnumStatusTheme.ERROR;
					break;
			}

			return {
				...item,
				...updatedState,
				className: buttonCssClasses.join(' '),
				label: isDesktopResolution ? null : updatedState.label,
				hasBoxShadow: false,
				tooltipText: isDesktopResolution ? updatedState.tooltipText : null,
				name: (item as DropdownItemProps).id,
				onClick: item.onClick ? (event: MouseEvent<HTMLElement>) => {
					item.onClick(event, offer);
				} : null,
			};
		});
		return result;
	};

	const buttonsList = offer ? GetCardsConfiguration(offer, isDesktopResolution, configuration?.demandId, configuration?.commercializationStep, onClickModal, onClickCommercializationStep) : undefined;

	let actionsCard = null;
	if (isDesktopResolution) {
		actionsCard = (
			<>
				<ButtonList
					className={styles.list_icons}
					items={getButtonCardsIcons(buttonsList as ButtonListProps['items'])}
					tooltipIsActive={true}
				/>
				{actionsList}
			</>
		);
	} else {
		actionsCard = (
			<>
				<ButtonWithDropdown
					classNameDropdown={styles.dropdown_mobile}
					hasBoxShadow={false}
					iconLeft='ellipsis-stroke'
					items={getButtonItemsOfferActions(buttonsList as ButtonProps[])}
					style={{
						top: '90%',
					}}
					theme={EnumTheme.NAKED}
				/>
			</>
		);
	}

	const reference = {
		label: t('format.capitalize', {
			text: t('general.ref', {
				'%ref%': offer.id
			})
		}),
		url: `${APP_CONF_VARS.sfLegacyPath}/offer/detail/id/${offer.id}`
	};

	return (
		viewType === 'card' ? (
			<Card
				{...otherprops}
				actions={actionsCard}
				address={offer.building.address.full.length ? offer.building.address.full : null}
				areaBuilding={areaBuilding}
				areaLot={areaLot}
				availability={availabilityTranslation}
				canSelect={canSelect}
				checkboxElement={checkboxElement}
				className={cssClasses.join(' ')}
				dates={dates}
				iconsList={iconsList}
				isPinned={offer?.states?.includes('pinned')}
				isSelected={isSelected}
				labels={labels}
				mandate={mandate}
				price={priceformatedCard}
				reference={reference}
				slider={slider}
				transactionTypes={transactionTypes}
				onClick={onClick}
			/>
		) : (
			<OfferRow
				{...otherprops}
				actions={actionsList}
				address={offer.building.address.full.length ? offer.building.address.full : null}
				areaBuilding={areaBuilding}
				areaLot={areaLot}
				availability={offer.suspended_at || offer?.commercialized_at || availabilityState || availabilityDateText || availabilityDateFormated}
				canSelect={canSelect}
				checkboxElement={checkboxElement}
				className={cssClasses.join(' ')}
				data={offer}
				dates={dates}
				iconsList={iconsList}
				image={offer.images?.collection[0].url}
				isDesktopResolution={isDesktopResolution}
				isPinned={offer?.states?.includes('pinned')}
				isSelected={isSelected}
				isViewed={offer?.states?.includes('viewed')}
				labels={offer?.states}
				mandate={mandate}
				price={priceformatedTable}
				reference={reference}
				transactionTypes={transactionTypes}
				onClick={onClick}
				onClickContact={onClickContact}
				onClickLot={onClickLot}
				onClickThumbtack={onClickThumbtack}
			/>
		)
	);
}

export {
	PrivateCard as default,
	PrivateCardProps,
};

