import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	OfferJson,
	CollectionJson,
} from '@@types/index';
import {
	EnumSteps,
} from '@enums/steps.enum';

export interface StoreOffers {
	instance: CollectionJson<OfferJson>;
}

// Define the initial state using that type
const initialState: StoreOffers = {
	// État initial de notre reducer qui stock les formulaires
	instance: {
		collection: [
		],
		count: 0,
	}
};

export const offersSlice = createSlice({
	name: 'offers',
	initialState,
	reducers: {
		clearOffers: (state) => {
			state.instance = {
				collection: [
				],
				count: 0,
			};
		},
		offerUpdate: (state, action) => {
			const findOffer = state.instance.collection.filter((offer: OfferJson) => {
				if (offer.id && action.payload.offer_id && offer.id === action.payload.offer_id) {
					return offer as OfferJson;
				}
			});
			if (findOffer.length) {
				const offers = state.instance.collection.map((offer: OfferJson) => {
					if (offer.id && action.payload.offer_id && offer.id === action.payload.offer_id) {
						return action.payload.offer as OfferJson;
					} else {
						return offer;
					}
				});
				state.instance.collection = offers;
			} else {
				state.instance.count = state.instance.count + 1;
				state.instance.collection = [
					action.payload.offer,
					...state.instance.collection,
				];
			}
		},

		offerUpdateCounter: (state, action) => {
			const currentCommercializationStep = action.payload.current_commercialization_step as EnumSteps;
			const updatedCommercializationStep = action.payload.new_commercialization_step as EnumSteps;
			const countDemandIdsUpdated = action.payload.demand_ids.length;

			const offers = state.instance.collection.map((offer: OfferJson) => {
				if (offer.id && action.payload.offer_id && offer.id === action.payload.offer_id) {
					return {
						...offer,
						counters: {
							...offer.counters,
							steps: {
								...offer.counters.steps,
								[`${updatedCommercializationStep}`]: (offer.counters.steps[`${updatedCommercializationStep}`] + countDemandIdsUpdated) as number,
								[`${currentCommercializationStep}`]: (offer.counters.steps[`${currentCommercializationStep}`] - countDemandIdsUpdated) as number,
							},
						},
					};
				} else {
					return offer;
				}
			});
			state.instance.collection = offers;
		},
	}
});

export const {
	clearOffers,
	offerUpdate,
	offerUpdateCounter,
} = offersSlice.actions;

export default offersSlice.reducer;
