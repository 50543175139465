// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HA2Y7pvO{display:flex;flex-direction:column;justify-content:space-between;overflow-y:scroll;padding:2.85714286rem 0 0}@media screen and (min-width: 768Px)and (orientation: landscape){.HA2Y7pvO{height:calc(100vh - 4.57142857rem)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageLogin": `HA2Y7pvO`
};
export default ___CSS_LOADER_EXPORT___;
