import React, {
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// CONFIG
import DeleteOfferLotsError from '@exceptions/DeleteOfferLotsError';
import {
	handleErrorResponse,
} from '@modules/utils';
import {
	deleteOfferLots,
} from '../config/fetch';
import columnTableConf from './config/columnTable.config';

// TYPES
import {
	Collection,
} from '@@types/Collection';

// ENUMS
import {
	EnumButtonCorners, EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// LAYOUTS
import {
	LotsStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import Header from '@layouts/Pages/PageOfferEntry/header';
import LotRow from '@layouts/Pages/PageOfferEntry/lots/lot-row';
import LotsInputsParking from '@layouts/Pages/PageOfferEntry/lots/lots-inputs-parking';
import LotsInputsTotal from '@layouts/Pages/PageOfferEntry/lots/lots-inputs-total';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Table from '@components/table';

// STYLES
import styles from './lots.module.scss';

const Lots = () => {
	const { t } = useTranslation();

	const {
		offerStateLots,
		offerEnumsState,
		setOfferStateLots,
		isDesktopResolution,
		formRef,
		offerState,
	}: PageOfferContextProps = useOutletContext();

	const [
		lotsIds,
		setLotsIds
	] = useState([
	] as number[]);

	const [
		reinitSelection,
		setReinitSelection
	] = useState(false);

	const [
		selectedRowId,
		setSelectedRowId
	] = useState(undefined as number);

	const [
		error,
		setError
	] = useState(false);

	const dataTable: LotsStateProps[] = offerStateLots?.lots?.collection?.length > 0
		? offerStateLots.lots.collection.filter(lot => !lot.commercialized_at)
		: [
			{
			} as LotsStateProps
		];

	const dataTableArchive: LotsStateProps[] = offerStateLots?.lots?.collection?.length > 0
		? offerStateLots.lots.collection.filter(lot => lot.commercialized_at)
		: [
		];

	// const dataTable: LotsStateProps[] = offerStateLots?.lots?.collection?.filter(lot => !lot.commercialized_at) || [
	// ];

	// if (dataTable.length === 0) {
	// 	dataTable.push({
	// 	} as LotsStateProps);
	// }

	// const dataTableArchive: LotsStateProps[] = offerStateLots?.lots?.collection?.filter(lot => lot.commercialized_at) || [
	// ];

	const buttonsActionBar = (
		<div className={styles.action_bar__buttons}>
			<Button
				corners={EnumButtonCorners.Square}
				hasBoxShadow={true}
				iconLeft={'trash-alt'}
				label={t('format.capitalize', {
					text: t('general.action.delete')
				})}
				name={'delete'}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				onClick={async () => {
					const lotSelectedIds = lotsIds.filter(lotId => !!lotId);
					if (lotSelectedIds.length) {
						const deleteOfferLotsResponse = await deleteOfferLots(offerState.id, lotSelectedIds);
						if (deleteOfferLotsResponse?.status) handleErrorResponse(deleteOfferLotsResponse, DeleteOfferLotsError);
						const offerLotsUpdated = {
							...offerStateLots,
							lots_total: {
								...offerStateLots.lots_total,
							},
							lots: {
								...offerStateLots.lots,
								collection: offerStateLots.lots.collection.filter(
									lot => !lotsIds.includes(lot.id)
								)
							}
						};
						setOfferStateLots({
							lots_total: {
								...offerStateLots.lots_total,
							},
							lots: {
								...offerLotsUpdated.lots,
								count: offerLotsUpdated.lots.collection.length
							},
							is_loaded: offerLotsUpdated.is_loaded
						});
					}
					setReinitSelection(true);
				}}
			/>
			<Button
				corners={EnumButtonCorners.Square}
				hasBoxShadow={true}
				iconLeft={'trash-alt'}
				label={t('format.capitalize', {
					text: t('general.action.archived')
				})}
				name={'archive'}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				onClick={async () => {
					const lotSelectedIds = lotsIds.filter(lotId => !!lotId);
					console.log(lotSelectedIds);
					setReinitSelection(true);
				}}
			/>
		</div>
	);

	const configurationActionBarTable = {
		type: t('page.offers_create_edit.lots.action_bar'),
		offerId: offerState.id,
		actions: buttonsActionBar,
		className: styles.action_bar
	};

	const renderTable = (data: LotsStateProps[], isArchive: boolean = false) => (
		<div className={styles.table__container}>
			<div className={styles.table_title_first}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.lots.title')
				})}
			</div>
			<div className={styles.table_title_second}>
				{t('page.offers_create_edit.lots.title_actif')} ({dataTable.length || '0'})
			</div>
			<Table
				actionBarClassName={styles.action_bar}
				className={isArchive ? styles.table_archive : styles.table}
				columns={columnTableConf}
				configurationActionBar={configurationActionBarTable}
				data={data}
				identifier={isArchive ? 'table_lots_archive' : 'table_lots'}
				options={{
					canSelect: true,
					hasHeader: isDesktopResolution,
					pagination: {
						nbItemsPerPage: 50,
					},
				}}
				reinitSelection={reinitSelection}
				renderSubComponent={(props) => {
					const handleRowClick = (rowId: number) => {
						setSelectedRowId(rowId);
					};

					const filteredLots = data.filter((lot) => {
						if (lot.id && props.original.id) {
							return lot.id === props.original.id;
						}
						return props.index === data.indexOf(lot);
					});

					const currentLot = filteredLots.length > 0 ? filteredLots[0] : null;

					return (
						<LotRow
							{...props}
							canSelect={true}
							currentLot={currentLot as LotsStateProps}
							currentOfferId={offerState.id}
							error={error}
							rowId={props.index}
							selectedRowId={selectedRowId}
							setError={setError}
							onClickRow={handleRowClick}
						/>
					);

				}}
				setComponentSelectedIds={setLotsIds}
				setReinitSelection={setReinitSelection}
				theme='column'
			/>
			{!isArchive && (
				<Button
					className={styles.add_button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft={'plus'}
					label={t('format.capitalize', {
						text: t('general.action.add_lot')
					})}
					name={'add'}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.NAKED}
					onClick={() => {
						const newEmptyLot = {
						} as LotsStateProps;
						setOfferStateLots({
							...offerStateLots,
							lots: {
								...offerStateLots?.lots,
								collection: [
									...(offerStateLots?.lots?.collection || [
									]),
									newEmptyLot
								],
							} as Collection<LotsStateProps, LotsStateProps>,
						});
					}}
				/>
			)}
		</div>
	);

	return (
		<>
			<Header
				isValid={offerStateLots?.lots?.collection?.some(lot => !!lot.id)}
			/>
			<div className={`${styles.section} ${styles.section__full}`}>
				<div className={styles.step_progress}>
					{t('format.capitalize', {
						text: `${t('page.offers_create_edit.step')} 4/8`
					})}
				</div>
				<div className={styles.legend}>{t('general.form.input.required')}</div>
				{renderTable(dataTable)}
				{dataTableArchive.length > 0 ? (
					<>
						<div className={styles.title}>
							{t('page.offers_create_edit.lots.title_archive')} ({dataTableArchive.length || '0'})
						</div>
						{renderTable(dataTableArchive, true)}
					</>
				) : undefined}

				<LotsInputsTotal
					offerEnumsState={offerEnumsState}
					offerState={offerState}
					offerStateLots={offerStateLots}
				/>
				<LotsInputsParking
					offerEnumsState={offerEnumsState}
					offerState={offerState}
					offerStateLots={offerStateLots}
				/>

				<Form
					innerRef={formRef}
					onError={() => {
						setError(true);
					}}
				>
					<InputText
						name='lot_id'
						required={true}
						type={EnumInputType.HIDDEN}
						value={offerStateLots?.lots?.collection[0]?.id?.toString()}
					/>
				</Form>

				<ValidationForm
					isValid={offerStateLots?.lots?.collection?.some(lot => !!lot.id)}
				/>
			</div>
		</>
	);
};

export default withTranslation()(Lots);
