// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ytWdWBUX{color:currentcolor}.cDwSfSpm{animation:AW40sVJb .75s infinite linear alternate,O9HdcUHb 1.5s infinite linear;aspect-ratio:1;border-radius:50%;color:var(--color-primary);margin-bottom:2.85714286rem;width:0.42857143rem}@keyframes AW40sVJb{0%,20%{box-shadow:2.14285714rem 0 0 0.21428571rem,0.71428571rem 0 0 0.21428571rem,-0.71428571rem 0 0 0.21428571rem,-2.14285714rem 0 0 0.21428571rem}60%,100%{box-shadow:0.85714286rem 0 0 0.21428571rem,1rem 0 0 0.42857143rem,-1rem 0 0 0.42857143rem,-0.85714286rem 0 0 0.21428571rem}}@keyframes O9HdcUHb{0%,25%{transform:rotate(0)}50%,100%{transform:rotate(0.5turn)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzonechild": `ytWdWBUX`,
	"progress_dots": `cDwSfSpm`,
	"animation1": `AW40sVJb`,
	"animation2": `O9HdcUHb`
};
export default ___CSS_LOADER_EXPORT___;
