// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FktvkLNq .jOxQH7Rp{border-top:0.07142857rem solid var(--color-border-default);margin-top:1.07142857rem;padding-top:1.07142857rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"company": `FktvkLNq`,
	"user_card_contact": `jOxQH7Rp`
};
export default ___CSS_LOADER_EXPORT___;
