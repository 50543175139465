import React, {
	Dispatch,
	MouseEvent,
	RefObject,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useNavigate,
} from 'react-router-dom';

// TYPES
import {
	User,
	UserJson,
} from '@@types/User';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// STORE
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	userLogout,
} from '@stores/_slices/user';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// EXCEPTIONS
import GetDemandsByBrokerError from '@exceptions/GetDemandsByBrokerError';

// MODULES
import {
	getURL,
} from '@modules/utils';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	optionsActivitiesIds,
	optionsNegociatorDesktop,
	optionsState,
} from '@layouts/Pages/PageDemands/config/filtersData.config';

// COMPONENTS
import Button from '@components/button';
import ButtonWithCounter from '@components/button-with-counter';
import InputSelect, {
	HTMLSelectElementCustom,
} from '@components/form/input-select';
import Sticky from '@components/sticky';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// LAYOUT
import {
	SelectedType,
} from '@layouts/Pages/PageDemands/index';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	currentUserId: number;
	contentDemandsRef: RefObject<HTMLDivElement>;
	isDesktopResolution: boolean;
	setHasUsedFilter: Dispatch<SetStateAction<boolean>>;
	setIsOpenFilterModal?: Dispatch<SetStateAction<boolean>>;
	setSelected?: Dispatch<SetStateAction<SelectedType>>;
	selected?: SelectedType;
}

const Header = ({
	currentUserId,
	contentDemandsRef,
	isDesktopResolution,
	setHasUsedFilter,
	setIsOpenFilterModal,
	setSelected,
	selected,
}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);

	const [
		brokers,
		setBrokers
	] = useState([
	]);

	const getBrokers = async () => {
		await fetch(getURL(FETCHES.private.demands.brokers), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			if (responseParsed.status === 200) {
				setBrokers(responseParsed.payload);
			} else if (responseParsed.error === 'access_denied') {
				dispatch(userLogout());
				dispatch(clearDemands());
				dispatch(addStatusMsg({
					message: t('status.error_session'),
					theme: EnumStatusTheme.ERROR,
				}));
			} else {
				navigate(PATHS.ERROR._ROOT.replace(':code', '404'));
				throw new GetDemandsByBrokerError(responseParsed.message);
			}
		}).catch(error => {
			throw new GetDemandsByBrokerError(error);
		});
	};

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			dispatch(clearToastMsgs());
			dispatch(clearStatusMsgs());
			getBrokers();
		}
	}, [
	]);

	const addDemandElement = (
		<Button
			corners={EnumButtonCorners.Square}
			href={PATHS.SEARCH.DEMAND_ADD.LEGACY}
			iconLeft='plus'
			label={isDesktopResolution ? t('format.capitalize', {
				text: t('page.demands.add_demand')
			}) : null}
			size={EnumButtonSize.SMALL}
		/>
	);

	const [
		resetSelectedIndexesState,
		setResetSelectedIndexesState
	] = useState(false);

	useEffect(() => {
		if (resetSelectedIndexesState) {
			setResetSelectedIndexesState(false);
		}
	}, [
		resetSelectedIndexesState
	]);
	const handleOnClickClearButton = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setResetSelectedIndexesState(true);
		setSelected({
			activities: null,
			broker: `${currentUserId}`,
			state: [
				'active'
			],
			counter: 2
		});
		setHasUsedFilter(false);
	};

	const optionsNegociator = [
	] as object[];

	brokers?.forEach((broker: UserJson) => {
		const user = new User(broker);
		const createdUserCardData = {
			title: user.full_name,
			descriptions: [
				{
					value: user?.email || null,
					type: 'subtitle',
				}
			],
		};

		const itemNegociator = {
			label: user.full_name,
			content: <UserCard
				data={createdUserCardData}
				theme={ThemeUserCard.ROUND}
			/>,
			value: `${user.id}`,
			selected: currentUserId === user.id ? true : false,
		};
		optionsNegociator.push(itemNegociator);

	});

	const [
		headerCssClasses,
		setHeaderCssClasses
	] = useState([
		styles.header
	]);

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter__sticky
	]);

	useEffect(() => {
		const baseFilterClasses = [
			styles.filter__sticky
		];
		const baseHeaderClasses = [
			styles.header
		];
		if (navIsOpen && isDesktopResolution) {
			baseHeaderClasses.push(styles.header__navisopen);
		}
		if (isSticky && navIsOpen && isDesktopResolution) {
			baseFilterClasses.push(styles.filter__sticky__navisopen);
		}
		setHeaderCssClasses(baseHeaderClasses);
		setStickyCssClasses(baseFilterClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	const headerStickyElement = (
		<>
			{isDesktopResolution ? (
				<>
					<div className={styles.filter__actions}>
						<InputSelect
							className={styles.input__state}
							defaultValue={[
								'active'
							]}
							id={'state'}
							isMultiSelect={true}
							name={'state'}
							options={optionsState}
							placeholder={t('format.capitalize', {
								text: t('page.demands.filter.labels.state')
							})}
							resetSelectedIndexes={resetSelectedIndexesState}
							selectedIndexes={[
								0
							]}
							onClickOption={() => {
								setHasUsedFilter(true);
								const stateIds = (document.querySelector(`.${styles.input__state} select`) as HTMLSelectElementCustom).values;
								setSelected({
									...selected,
									state: stateIds,
								});
							}}
						/>
						<InputSelect
							className={`${styles.input__nego}`}
							defaultValue={[
								`${currentUserId}`
							]}
							id={'negociator'}
							isMultiSelect={true}
							name={'negociator'}
							options={optionsNegociatorDesktop(brokers, currentUserId)}
							placeholder={t('format.capitalize', {
								text: t('page.demands.filter.labels.negociator')
							})}
							resetSelectedIndexes={resetSelectedIndexesState}
							onClickOption={() => {
								const brokerIds = (document.querySelector(`.${styles.input__nego} select`) as HTMLSelectElementCustom).values;
								const concatenatedIds = brokerIds.join(',');
								setHasUsedFilter(true);
								setSelected({
									...selected,
									broker: concatenatedIds,
								});
							}}
						/>
						<InputSelect
							className={styles.input__activities}
							id={'activities'}
							isMultiSelect={true}
							name={'activities'}
							options={optionsActivitiesIds}
							placeholder={t('format.capitalize', {
								text: t('page.demands.filter.labels.activities')
							})}
							resetSelectedIndexes={resetSelectedIndexesState}
							onClickOption={() => {
								setHasUsedFilter(true);
								const activitiesIds = (document.querySelector(`.${styles.input__activities} select`) as HTMLSelectElementCustom).values;
								const concatenatedIds = activitiesIds.join(',');
								setSelected({
									...selected,
									activities: concatenatedIds,
								});
							}}
						/>
						<Button
							hasBoxShadow={false}
							iconLeft='rotate'
							label={'Réinitialiser les filtres'}
							theme={EnumTheme.NAKED}
							onClick={handleOnClickClearButton}
						/>
					</div>
					{addDemandElement}
				</>
			) : (
				<div className={styles.filter__actions}>
					<ButtonWithCounter
						badgeCounter={selected.counter}
						corners={EnumButtonCorners.Square}
						hasBoxShadow={false}
						iconRight='filters'
						label='Filtrer'
						theme={EnumTheme.SECONDARY}
						onClick={() => { setIsOpenFilterModal(true); }}
					/>
					{addDemandElement}
				</div>
			)}
		</>
	);

	const stickyContainerRef = useRef(null);

	return (
		<div className={headerCssClasses.join(' ')}>
			<h1 className={styles.title}>
				{t('format.capitalize', {
					text: t('module.my_demand.name_other')
				})}
			</h1>
			{brokers.length ? (
				<>
					<div
						className={styles.filter}
						ref={stickyContainerRef}
					>
						{headerStickyElement}
					</div>
					<Sticky
						className={stickyCssClasses.join(' ')}
						isDesktopResolution={true}
						isSticky={isSticky}
						scrollContainerRef={isDesktopResolution ? contentDemandsRef : undefined}
						setIsSticky={setIsSticky}
						stickyContainerRef={stickyContainerRef}
					>
						{headerStickyElement}
					</Sticky>
				</>
			) : undefined}
		</div>
	);
};

export default withTranslation()(Header);
