import * as React from 'react';
import {
	SyntheticEvent,
	useState,
} from 'react';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// EXCEPTIONS
import GetTestApiError from '@exceptions/GetTestApiError';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import StatusBanner from '@components/status-banner';
import Loader from '@components/loader';

// STYLING
import styles from './api.module.scss';

function PageTestApi() {

	const [
		inputValueState,
		setInputValueState
	] = useState(null);

	const [
		isLoadingState,
		setIsLoadingState
	] = useState(false);

	const [
		responseState,
		setResponseState
	] = useState(null);

	const [
		responseStatusState,
		setStatusResponseState
	] = useState(null);

	interface TestApiError {
		status?: number;
		statusText?: string;
	}
	const errorInitialState: TestApiError = {
		status: null,
		statusText: null
	};
	const [
		errorState,
		setErrorState
	] = useState(errorInitialState);

	const sectionClassNames = [
		styles.section
	];
	const leftSectionClassName = [
		...sectionClassNames,
		styles.left
	];
	const rightSectionClassName = [
		...sectionClassNames,
		styles.right
	];

	const handleOnSubmit = (event: SyntheticEvent) => {
		event.preventDefault();
		setErrorState(errorInitialState);
		setResponseState('');
		if (inputValueState) {
			setIsLoadingState(true);
			fetch(`${APP_CONF_VARS.privatePath}${inputValueState}`, {
				...APP_CONF_VARS.request.default,
			})
				.then((resp) => {
					setStatusResponseState(resp.status);
					if (![
						200
					].includes(resp.status)) {
						setErrorState({
							status: resp.status,
							statusText: resp.statusText,
						});
						throw new GetTestApiError(resp.statusText);
					} else {
						return resp.json();
					}
				}).then((responseParsed) => {
					setResponseState(JSON.stringify(responseParsed, null, 2));
					setIsLoadingState(false);
				}).catch((err) => {
					const result = typeof err === 'object' ? JSON.stringify(err, Object.getOwnPropertyNames(err)) : err;
					setErrorState({
						...errorState,
						status: responseStatusState,
						statusText: result
					});
					setIsLoadingState(false);
				});
		} else {
			setErrorState({
				...errorState,
				status: 0,
				statusText: 'Please, fill the "Route" input before submitting !'
			});
		}
	};

	const resultContent = (
		<div className={styles.result}>
			<div className={styles.title}>Result{errorState.status || errorState.statusText ? ' error' : ''} :</div>
			<pre className={`${styles.value} ${styles.value__text}`}>
				{responseStatusState ? <div>{`Code : ${responseStatusState}`}</div> : null}
				{errorState?.statusText || responseState}
			</pre>
		</div>
	);
	return (
		<div className={styles.PageTestApi}>
			{errorState.status && errorState.status !== 0 ? (
				<StatusBanner
					message='ERROR - See the network tab in your browser console'
					theme={EnumStatusTheme.ERROR}
				/>
			) : null}
			<div className={styles.main}>
				<div className={leftSectionClassName.join(' ')}>
					<form onSubmit={handleOnSubmit}>
						<div className={styles.field}>
							<label htmlFor="route_text">Route :</label>
							<div>
								<span>{APP_CONF_VARS.privatePath}</span>
								<input
									id="route_text"
									type="text"
									onInput={(event) => {
										setInputValueState(event.currentTarget.value);
									}}
								/>
							</div>
						</div>
						<div className={styles.field}>
							<input
								id="route_text"
								type="submit"
								value="Test"
							/>

						</div>
					</form>
				</div>
				<div className={rightSectionClassName.join(' ')}>
					<div className={`${styles.result} ${styles.top}`}>
						<span className={styles.title}>Route :</span>
						&nbsp;
						<span className={styles.value}>
							{`${window.location.origin}/${APP_CONF_VARS.privatePath}${inputValueState}`}
						</span>
					</div>
					{isLoadingState ? <Loader /> : resultContent}
				</div>
			</div>
		</div>
	);
}

export default PageTestApi;
