import React, {
	useState,
} from 'react';

// LIB
import {
	useTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// COMPONENTS
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Textarea from '@components/form/textarea';
import Icon from '@components/icon';
import Tooltip from '@components/tooltip';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// LAYOUTS
import {
	OfferEnumsStateProps,
	OfferStateLotsProps,
	OfferStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';

// UTILS
import {
	findDefaultOption,
	generateOptions,
	handleOnChange,
	handleOptionChange,
} from '../config/utils';

// STYLES
import styles from './lots.module.scss';

interface LotsInputsTotalProps {
	offerState: OfferStateProps;
	offerStateLots: OfferStateLotsProps;
	offerEnumsState: OfferEnumsStateProps;
}

const LotsInputsTotal = ({
	offerState,
	offerStateLots,
	offerEnumsState,
}: LotsInputsTotalProps) => {
	const { t } = useTranslation();

	const {
		offerStateDefaultValue,
		setDirtySteps,
		setOfferState,
	}: PageOfferContextProps = useOutletContext();

	const tooltipContent = t('page.offers_create_edit.lots.total.total_area.tooltip');
	const tooltipChildren = (
		<Icon
			className={styles.icon}
			fontStyle={EnumFontStyle.SOLID}
			name="info-circle"
		/>
	);

	const [
		selectedUnit,
		setSelectedUnit
	] = useState(findDefaultOption(generateOptions(offerEnumsState?.ref_price_units), offerState?.rental_ref_price_unit_id ? offerState.rental_ref_price_unit_id : offerStateDefaultValue?.common?.ref_price_unit_id) as OptionType);

	const handleUnitChange = (selected: OptionType) => {
		setSelectedUnit(selected);
		handleOptionChange('rental_ref_price_unit_id', selected, 'lots', setDirtySteps, setOfferState);
	};

	return (
		<>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.lots.total.title')
				})}
			</div>
			<div className={styles.section__left_total}>
				<div className={styles.form_row_total}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.total_area.title')
						})}
						<Tooltip
							children={tooltipChildren}
							content={tooltipContent}
							id="lots_total_area"
						/>
					</div>
					<span>{offerStateLots?.lots_total?.total_area ? offerStateLots?.lots_total?.total_area : '0'} {t('page.offers_create_edit.lots.total.total_area.unit')}</span>
				</div>
				<div className={styles.form_row_total}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.total_area_main_natures.title')
						})}
					</div>
					<span>{offerStateLots?.lots_total?.total_area_main_natures ? offerStateLots?.lots_total?.total_area_main_natures : '0'} {t('page.offers_create_edit.lots.total.total_area.unit')}</span>
				</div>
				<div className={styles.form_row}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.rental_price.title')
						})}
					</div>
					<InputTextWithUnit
						className={styles.value}
						defaultValue={offerState?.rental_price?.toString()}
						label={t('page.offers_create_edit.lots.total.rental_price.label')}
						name="rental_price"
						placeholder={t('page.offers_create_edit.lots.total.rental_price.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.rental_price.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
					<InputMultiSelect
						className={styles.unit}
						defaultValue={selectedUnit}
						hasClearButton={false}
						hasShadowMenu={true}
						id="rental_ref_price_unit_id"
						isMulti={false}
						key={`rental_ref_price_unit_id-${selectedUnit?.value}`}
						label={t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.rental_price.unitValue.label')
						})}
						maxMenuHeight={150}
						name='rental_ref_price_unit_id'
						options={generateOptions(offerEnumsState?.ref_price_units)}
						onChange={handleUnitChange}
					/>
				</div>
				<div className={styles.form_row}>
					<div className={styles.subtitle_space}></div>
					<InputTextWithUnit
						className={styles.value}
						defaultValue={offerState?.rental_price_m2?.toString()}
						label={t('page.offers_create_edit.lots.total.rental_price_m2.label')}
						name="rental_price_m2"
						placeholder={t('page.offers_create_edit.lots.total.rental_price_m2.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.rental_price_m2.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
					<InputMultiSelect
						className={styles.unit}
						defaultValue={selectedUnit}
						hasClearButton={false}
						hasShadowMenu={true}
						id="rental_ref_price_unit_id_2"
						isMulti={false}
						key={`rental_ref_price_unit_id-${selectedUnit?.value}`}
						label={t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.rental_price.unitValue.label')
						})}
						maxMenuHeight={150}
						name='rental_ref_price_unit_id'
						options={generateOptions(offerEnumsState?.ref_price_units)}
						onChange={handleUnitChange}
					/>
				</div>
				{/* Waiting for backend */}
				{/* <div className={styles.form_row}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.workstation_rental.title')
						})}
					</div>
					<InputTextWithUnit
						className={styles.value}
						defaultValue={offerState?.workstation_rental?.toString()}
						label={t('page.offers_create_edit.lots.total.workstation_rental.label')}
						name="workstation_rental"
						placeholder={t('page.offers_create_edit.lots.total.workstation_rental.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.workstation_rental.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
					<InputMultiSelect
						className={styles.unit}
						defaultValue={selectedUnit}
						hasClearButton={false}
						hasShadowMenu={true}
						id="rental_ref_price_unit_id_3"
						isMulti={false}
						key={`rental_ref_price_unit_id-${selectedUnit?.value}`}
						label={t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.workstation_rental.unitValue.label')
						})}
						maxMenuHeight={150}
						name='rental_ref_price_unit_id'
						options={generateOptions(offerEnumsState?.ref_price_units)}
						onChange={handleUnitChange}
					/>
				</div> */}
				<div className={styles.form_row}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.sale_price.title')
						})}
					</div>
					<InputTextWithUnit
						className={styles.value_full}
						defaultValue={offerState?.sale_price?.toString()}
						label={t('page.offers_create_edit.lots.total.sale_price.label')}
						name="sale_price"
						placeholder={t('page.offers_create_edit.lots.total.sale_price.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.sale_price.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
				</div>
				<div className={styles.form_row}>
					<div className={styles.subtitle_space}></div>
					<InputTextWithUnit
						className={styles.value_full}
						defaultValue={offerState?.sale_price_m2?.toString()}
						label={t('page.offers_create_edit.lots.total.sale_price_m2.label')}
						name="sale_price_m2"
						placeholder={t('page.offers_create_edit.lots.total.sale_price_m2.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.sale_price_m2.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
				</div>
				{/* Waiting for backend */}
				{/*<div className={styles.form_row}>
					 <InputTextWithUnit
						className={styles.value_full}
						defaultValue={offerState?.sale_charge_provision_annual?.toString()}
						label={t('page.offers_create_edit.lots.total.sale_charge_provision_annual.label')}
						name="sale_charge_provision_annual"
						placeholder={t('page.offers_create_edit.lots.total.sale_charge_provision_annual.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.sale_charge_provision_annual.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
				</div>*/}
				<div className={styles.form_row}>
					<div className={styles.subtitle}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.sale_charge_provision_annual.title')
						})}
					</div>
					<InputTextWithUnit
						className={styles.value_full}
						defaultValue={offerState?.sale_charge_provision?.toString()}
						label={t('page.offers_create_edit.lots.total.sale_charge_provision.label')}
						name="sale_charge_provision"
						placeholder={t('page.offers_create_edit.lots.total.sale_charge_provision.placeholder')}
						type={EnumInputType.NUMBER}
						unit={t('page.offers_create_edit.lots.total.sale_charge_provision.unit')}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					/>
				</div>
				<div className={styles.form_row}>
					<div className={styles.subtitle_space}></div>
					<Textarea
						className={styles.value_full}
						defaultValue={offerState.sale_charge_comment ? offerState.sale_charge_comment : undefined}
						label={t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.sale_charge_comment.label')
						})}
						name="sale_charge_comment"
						placeholder={t('format.capitalize', {
							text: t('page.offers_create_edit.lots.total.sale_charge_comment.placeholder')
						})}
						rows={5}
						onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
					></Textarea>
				</div>
			</div>
		</>
	);
};

export default LotsInputsTotal;
