import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// EXCEPTIONS
import PutOfferLotsError from '@exceptions/PutOfferLotsError';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// STORES

// LAYOUTS
import {
	LotsStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	putOfferLots,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	handleOnClickFocus,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	LotRowProps,
} from '@layouts/Pages/PageOfferEntry/lots/lot-row';

// TYPES
import {
	Collection,
} from '@@types/Collection';

// ENUMS
import {
	EnumButtonCorners, EnumButtonType,
} from '@enums/button.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputCheckbox from '@components/form/input-checkbox';
import InputDatePicker from '@components/form/input-date-picker';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../lot-row.module.scss';

function AvailabilityElement({
	activeElement,
	currentOfferId,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
}: LotRowProps
) {
	const {t} = useTranslation();
	const {
		offerEnumsState,
		currentLanguage,
		isDesktopResolution,
		offerStateDefaultValue,
		offerStateLots,
		setOfferStateLots,
	}: PageOfferContextProps = useOutletContext();

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputAvailabilityElement = (
		<>
			<InputDatePicker
				defaultValue={currentLot?.availability?.date && !currentLot?.availability?.state ? new Date(currentLot.availability.date) : undefined}
				hasPlaceholder={true}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.lots.availability.label')
				})}
				name={'availability_date'}
				onChange={async (date: ChangeEvent<HTMLInputElement>) => {
					const formattedDate = new Date(date.target.value).toLocaleDateString('en-us');

					if (currentLot.id) {
						const putOfferLotResponse = await putOfferLots(currentOfferId, currentLot.id, {
							...currentLot,
							availability: {
								date: formattedDate,
							},
						});
						handleErrorResponse(putOfferLotResponse, PutOfferLotsError);
						if (putOfferLotResponse.status === 200) {
							setOfferStateLots({
								...offerStateLots,
								lots: {
									...offerStateLots.lots,
									collection: offerStateLots.lots.collection.map(lot =>
										lot.id === currentLot.id
											? {
												...lot,
												availability: {
													date: formattedDate,
												},
											} : lot
									),
								} as Collection<LotsStateProps, LotsStateProps>,
							});
						}
					} else {
						setOfferStateLots({
							...offerStateLots,
							lots: {
								...offerStateLots?.lots,
								collection: offerStateLots?.lots?.collection?.length
									? [
										...offerStateLots.lots.collection.slice(0, rowId),
										{
											...offerStateLots.lots.collection[rowId] || {
											},
											availability: {
												date: formattedDate
											}
										},
										...offerStateLots.lots.collection.slice(rowId + 1)
									]
									: [
										{
											availability: {
												date: formattedDate
											}
										}
									],
							} as Collection<LotsStateProps, LotsStateProps>,
						});
					}
				}}
			/>
			<div className={styles.or_element}>{t('format.capitalize', {
				text: t('page.offers_create_edit.lots.availability.or')
			})}</div>
			<div className={styles.checkbox}>
				{offerEnumsState?.ref_announcement_surface_availabilities.map((value: string) => {

					return (
						<InputCheckbox
							checked={currentLot.availability?.state === value || (offerStateDefaultValue?.common?.availability?.state === value && !currentLot.availability?.state && !currentLot.availability?.date)}
							id={`availability_state_${value}`}
							key={value}
							label={t('format.capitalize', {
								text: t(`status.${value}`)
							})}
							name="availability_state[]"
							value={value as string}
							onChange={async (event: ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								const value = target.value;
								const isChecked = target.checked;
								if (currentLot.id) {
									const putOfferLotResponse = await putOfferLots(currentOfferId, currentLot.id, {
										...currentLot,
										availability: {
											state: isChecked ? value : undefined,
										},
									});
									handleErrorResponse(putOfferLotResponse, PutOfferLotsError);
									if (putOfferLotResponse.status === 200) {
										setOfferStateLots({
											...offerStateLots,
											lots: {
												...offerStateLots.lots,
												collection: offerStateLots.lots.collection.map(lot =>
													lot.id === currentLot.id
														? {
															...lot,
															availability: {
																state: isChecked ? value : undefined,
															},
														} : lot
												),
											} as Collection<LotsStateProps, LotsStateProps>,
										});
									}
								} else {
									setOfferStateLots({
										...offerStateLots,
										lots: {
											...offerStateLots?.lots,
											collection: offerStateLots?.lots?.collection?.length
												? [
													...offerStateLots.lots.collection.slice(0, rowId),
													{
														...offerStateLots.lots.collection[rowId] || {
														},
														availability: {
															state: isChecked ? value : undefined
														}
													},
													...offerStateLots.lots.collection.slice(rowId + 1)
												] : [
													{
														availability: {
															state: isChecked ? value : undefined
														}
													}
												],
										} as Collection<LotsStateProps, LotsStateProps>,
									});
								}
							}}
						/>
					);
				})}
			</div>
		</>
	);

	const modalAvailabilityMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.header.availability')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
			>
				{inputAvailabilityElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_availability
	];

	if (activeElement === 'availability' && selectedRowId === currentLot?.id) cssClasses.push(styles.active);

	const availabilityRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`availability_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, currentLot, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_availability}
						clickable={true}
						htmlElement={inputAvailabilityElement}
						id={`tooltip_availability_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.TOP}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_availability_content}>
							{
								currentLot?.availability?.state || currentLot?.availability?.date ?
									currentLot?.availability?.state ? t('format.capitalize', {
										text: t(`status.${currentLot?.availability?.state}`)
									}) : new Date(currentLot?.availability?.date).toLocaleDateString(currentLanguage)
									: (
										<span className={styles.placeholder}>{t('format.capitalize', {
											text: t('page.offers_create_edit.lots.header.availability')
										})}</span>
									)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.lots.header.availability')
						})}</span>
						<span className={styles.mobile_value}>{currentLot?.availability?.state || currentLot?.availability?.date ?
							currentLot?.availability?.state ? t('format.capitalize', {
								text: t(`status.${currentLot?.availability?.state}`)
							}) : new Date(currentLot?.availability?.date).toLocaleDateString(currentLanguage)
							: undefined}</span>
					</>
				)}
			</div>
			{modalAvailabilityMobile}
		</>
	);

	return availabilityRow;
}

export default AvailabilityElement;
