import {
	AnyAction,
} from '@reduxjs/toolkit';
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	RefObject,
	SetStateAction,
} from 'react';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';

// EXCEPTIONS
import PostOfferLotError from '@exceptions/PostOfferLotsError';
import PutOfferContactError from '@exceptions/PutOfferContactError';
import PutOfferLotError from '@exceptions/PutOfferLotsError';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// MODULES
import i18n from '@modules/i18n';
import {
	handleErrorResponse,
} from '@modules/utils';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// FETCHES
import {
	postOfferLots,
	putOfferContact,
	putOfferLots,
} from './fetch';

// LAYOUTS
import {
	LotsStateProps,
	ObjectEnum,
	OfferStateContactsProps,
	OfferStateLotsProps,
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

// COMPONENTS
import {
	OptionType,
} from '@components/form/input-multi-select';

// Create option for input multi select
export const createOption = (keyOrString: string, value: number | string, trad: string) => {
	const key = value !== undefined ? keyOrString : keyOrString;
	const optionValue = value !== undefined ? value : keyOrString;

	return {
		label: trad ? i18n.t('format.capitalize', {
			text: i18n.t(`${trad}.${key}`)
		}) : key,
		value: optionValue.toString(),
		inputValue: {
			label: trad ? i18n.t('format.capitalize', {
				text: i18n.t(`${trad}.${key}`)
			}) : key,
		},
	};
};
export const generateOptions = (enumArray: ObjectEnum[] | string[], trad: string = 'references.announcement') => {
	return enumArray?.map((item) =>
		typeof item === 'string'
			? createOption(item, item, trad)
			: createOption(item.key, item.value, trad)
	);
};

// Find default option for input multi select
export const findDefaultOption = (
	options: OptionType[] = [
	],
	stateValue: string | number | (string | number)[] | undefined
) => {
	if (Array.isArray(stateValue)) {
		return options.filter(option => stateValue.includes(option.value as string | number));
	} else {
		return options.find(option => option.value === (stateValue as string | number)?.toString());
	}
};

// Handle option change for input multi select
export const handleOptionChange = (
	name: string,
	selected: OptionType | OptionType[],
	step: string,
	setDirtySteps: Dispatch<SetStateAction<Record<string, boolean>>>,
	setOfferState: Dispatch<SetStateAction<OfferStateProps>>
) => {
	setDirtySteps(prevState => ({
		...prevState,
		[step]: true,
	}));

	setOfferState((prevState: OfferStateProps) => ({
		...prevState,
		[name]: Array.isArray(selected)
			? selected.map((item) => item.value)
			: selected?.value,
	}));
};

// Handle option change for input multi select
export const handleOnChangeToggle = (
	name: string,
	selectedIds: string[],
	step: string,
	setDirtySteps: Dispatch<SetStateAction<Record<string, boolean>>>,
	setOfferState: Dispatch<SetStateAction<OfferStateProps>>
) => {
	setDirtySteps(prevState => ({
		...prevState,
		[step]: true,
	}));

	setOfferState((prevState) => ({
		...prevState,
		[name]: selectedIds[0] === 'true' ? true : selectedIds[0] === 'false' ? false : selectedIds[0],
	}));
};

// Handle input change
export const handleOnChange = (
	event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
	step: string,
	setDirtySteps: Dispatch<SetStateAction<Record<string, boolean>>>,
	setOfferState: Dispatch<SetStateAction<OfferStateProps>>
) => {
	const target = event.target as HTMLInputElement;
	let name: string;

	let value: string | boolean;
	if (target.type === 'checkbox') {
		value = target.checked;
		name = target.name;
	} else if (target.type === 'radio') {
		value = target.value === 'true' ? true : target.value === 'false' ? false : target.value;
		name = target.name.replace(/\[.*?\]$/, '');
	} else {
		value = target.value;
		name = target.name;
	}

	setDirtySteps(prevState => ({
		...prevState,
		[step]: true,
	}));

	setOfferState(prevState => ({
		...prevState,
		[name]: value as string | boolean,
	}));
};

// Update offer contact
export const updateOfferContact = async (
	currentOfferId: number,
	offerContact: ContactOfferJson,
	inputValue: string,
	inputType: string,
	offerStateContacts: OfferStateContactsProps,
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>,
	dispatch: Dispatch<AnyAction>
) => {
	if (offerContact?.id) {
		const putOfferContactResponse = await putOfferContact(currentOfferId, offerContact.id, {
			comment: inputType === 'comment' ? inputValue : offerContact.comment,
			contact_id: inputType === 'contact_id' ? inputValue : offerContact.contact_id,
			is_mandated: inputType === 'is_mandated' ? inputValue : offerContact.is_mandated,
			mandate_number: inputType === 'mandate_number' ? inputValue : offerContact.mandate_number,
			original_mail_date: inputType === 'original_mail_date' ? inputValue : offerContact.original_mail_date,
			reference: inputType === 'reference' ? inputValue : offerContact.reference,
			web_reference: inputType === 'web_reference' ? inputValue : offerContact.web_reference,
			web_title: inputType === 'web_title' ? inputValue : offerContact.web_title,
		});
		const handleOnErrorCallback = () => {
			dispatch(addStatusMsg({
				message: i18n.t('format.capitalize', {
					text: i18n.t('status.updated_element_error_message', {
						'%type%': 'contact',
					}),
				}),
				isClosable: true,
				buttons: [
					{
						label: i18n.t('format.capitalize', {
							text: i18n.t('general.action.contact'),
						}),
						href: 'serviceclient',
					},
				],
				theme: EnumStatusTheme.ERROR,
			}));
		};
		handleErrorResponse(putOfferContactResponse, PutOfferContactError, handleOnErrorCallback);
		if (putOfferContactResponse.status === 200) {
			setOfferStateContacts({
				...offerStateContacts,
				contacts: {
					...offerStateContacts?.contacts,
					collection: offerStateContacts?.contacts?.collection.map(contact =>
						contact.id === offerContact.id
							? {
								...contact,
								[inputType]: inputValue
							}
							: contact
					),
				} as Collection<ContactOfferJson, ContactOffer>,
			});
		}
	}
};

// Update offer lots
export const updateOfferLots = async (
	currentOfferId: number,
	currentLot: LotsStateProps,
	inputValue: string | number[],
	inputType: string,
	offerStateLots: OfferStateLotsProps,
	rowId: number,
	dispatch: Dispatch<AnyAction>,
	setOfferStateLots: Dispatch<SetStateAction<OfferStateLotsProps>>
) => {

	const updatedLot = {
		...currentLot,
		[inputType]: inputValue
	};

	if (updatedLot?.id) {
		const putOfferLotResponse = await putOfferLots(currentOfferId, updatedLot.id, updatedLot);
		const handleOnErrorCallback = () => {
			dispatch(addStatusMsg({
				message: i18n.t('format.capitalize', {
					text: i18n.t('status.updated_element_error_message', {
						'%type%': 'lot',
					}),
				}),
				isClosable: true,
				buttons: [
					{
						label: i18n.t('format.capitalize', {
							text: i18n.t('general.action.contact'),
						}),
						href: 'serviceclient',
					},
				],
				theme: EnumStatusTheme.ERROR,
			}));
		};
		handleErrorResponse(putOfferLotResponse, PutOfferLotError, handleOnErrorCallback);
		if (putOfferLotResponse.status === 200) {
			setOfferStateLots({
				...offerStateLots,
				lots: {
					...offerStateLots?.lots,
					collection: offerStateLots?.lots?.collection.map(lot =>
						lot.id === updatedLot.id
							? updatedLot
							: lot
					),
				} as Collection<LotsStateProps, LotsStateProps>,
			});
		}
	} else if (updatedLot?.surface_m2 && updatedLot?.ref_property_nature_ids?.length) {
		const postOfferLotResponse = await postOfferLots(currentOfferId, updatedLot);
		const handleOnErrorCallback = () => {
			dispatch(addStatusMsg({
				message: i18n.t('format.capitalize', {
					text: i18n.t('status.added_element_error_message', {
						'%type%': 'lot',
					}),
				}),
				isClosable: true,
				buttons: [
					{
						label: i18n.t('format.capitalize', {
							text: i18n.t('general.action.contact'),
						}),
						href: 'serviceclient',
					},
				],
				theme: EnumStatusTheme.ERROR,
			}));
		};
		handleErrorResponse(postOfferLotResponse, PostOfferLotError, handleOnErrorCallback);
		if (postOfferLotResponse.status === 200) {
			setOfferStateLots({
				...offerStateLots,
				lots: {
					...offerStateLots?.lots,
					collection: [
						...offerStateLots.lots.collection.slice(0, rowId),
						{
							...updatedLot,
							id: postOfferLotResponse.payload.id
						},
						...offerStateLots.lots.collection.slice(rowId + 1)
					]
				} as Collection<LotsStateProps, LotsStateProps>,
			});
		}
	} else {
		setOfferStateLots({
			...offerStateLots,
			lots: {
				...offerStateLots?.lots,
				collection: offerStateLots?.lots?.collection?.length
					? [
						...offerStateLots.lots.collection.slice(0, rowId),
						{
							...offerStateLots.lots.collection[rowId] || {
							},
							[inputType]: inputValue
						},
						...offerStateLots.lots.collection.slice(rowId + 1)
					]
					: [
						{
							[inputType]: inputValue
						}
					],
			} as Collection<LotsStateProps, LotsStateProps>,
		});
	}
};

// Handle click on contact with focus on input
export const handleOnClickFocus = (
	activeElement: string | null,
	event: MouseEvent<HTMLElement>,
	inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
	isDesktopResolution: boolean,
	offer: ContactOfferJson | LotsStateProps,
	onClickRow: (id: number) => void,
	setActiveElement: Dispatch<SetStateAction<string | null>>,
	setIsOpenModal: Dispatch<SetStateAction<boolean>>
) => {
	event.preventDefault();
	if (isDesktopResolution) {
		if (onClickRow && offer?.id) {
			onClickRow(offer.id);
		}
		const id = event.currentTarget.id;
		setActiveElement(prev => (prev === id ? null : id));
		if (activeElement !== id) {
			const findAndFocusInput = () => {
				if (inputRef.current) {
					inputRef.current.focus();
					const length = inputRef.current.value.length;

					if (inputRef.current.type === 'text' || inputRef.current.tagName === 'TEXTAREA') {
						inputRef.current.setSelectionRange(length, length);
					}
					return true;
				}
				return false;
			};

			let attempts = 0;
			const maxAttempts = 5;
			const tryFocus = () => {
				if (attempts >= maxAttempts) return;
				setTimeout(() => {
					if (!findAndFocusInput()) {
						attempts++;
						tryFocus();
					}
				}, 100 * (attempts + 1));
			};
			tryFocus();
		}
	} else {
		setIsOpenModal(true);
	}
};

