// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yD0SCmIU{margin-bottom:3.42857143rem;padding:0 1.14285714rem;text-align:center}@media screen and (min-width: 768Px)and (orientation: landscape){.yD0SCmIU{padding:0}}.HQ3luBES{width:100%}.HQ3luBES h1,.HQ3luBES h2,.HQ3luBES h3{padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.HQ3luBES h1,.HQ3luBES h2,.HQ3luBES h3{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `yD0SCmIU`,
	"page": `HQ3luBES`
};
export default ___CSS_LOADER_EXPORT___;
