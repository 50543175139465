import React from 'react';
import {
	Children,
	cloneElement,
	isValidElement,
	ReactElement,
	ReactNode,
} from 'react';

//STYLES
import styles from './tab.module.scss';

interface TabProps {
	isActive?: boolean;
	children?: ReactNode;
	className?: string;
	'data-testid'?: string;
	disabled?: boolean;
	isSuccessful?: boolean;
}

const Tab = ({
	isActive = false,
	children,
	className,
	'data-testid': dataTestid,
	disabled,
	isSuccessful = false,
}: TabProps): JSX.Element => {
	const classes = [
		styles.tab
	];

	if (isSuccessful) classes.push(styles.tab__successful);
	if (isActive) classes.push(styles.tab__active);
	if (disabled) classes.push(styles.tab__disabled);
	if (className) classes.push(className);

	const childClasses = [
		styles.child
	];
	if (isSuccessful) childClasses.push(styles.child__successful);
	if (isActive) childClasses.push(styles.child__active);
	if (disabled) childClasses.push(styles.child__disabled);

	const applyClassesToChildren = (children: ReactNode): ReactNode => {
		return Children.map(children, (child, index) => {
			if (isValidElement(child)) {
				const existingClasses = child.props.className ? child.props.className.split(' ') : [
				];
				const combinedClasses = [
					...existingClasses,
					...childClasses
				].join(' ');

				const updatedChild = cloneElement(child as ReactElement, {
					className: combinedClasses,
					'data-testid': `${dataTestid}-child-${index}`,
					disabled: disabled,
					onClick: (e: MouseEvent) => {
						if (disabled) {
							e.preventDefault();
							e.stopPropagation();
						}
						if (child.props.onClick) {
							child.props.onClick(e);
						}
					}
				});

				if (child.props.children) {
					return cloneElement(updatedChild, {
						children: applyClassesToChildren(child.props.children),
						disabled: disabled,
					});
				}
				return updatedChild;
			}
			return child;
		});
	};

	const updatedChildren = applyClassesToChildren(children);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{updatedChildren}
		</div>
	);
};

export {
	Tab as default,
	TabProps,
};
