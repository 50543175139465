import * as React from 'react';
import {
	lazy,
} from 'react';

import PATHS from '@routes/paths';

// LAZY LOADING
const TabReport = lazy(() => import('@layouts/Pages/PageDemand/report'));
const TabManage = lazy(() => import('@layouts/Pages/PageDemand/manage'));

const DEMAND_MANAGE_ROUTES = [
	{
		path: PATHS.DEMANDS.PRIVATE.MANAGE._ROOT,
		element: <TabManage />,
	},
	{
		path: PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT,
		element: <TabManage />,
	},
	{
		path: PATHS.DEMANDS.PRIVATE.MANAGE.STEP.OFFER._ROOT,
		element: <TabManage />,
	},
];
const DEMAND_ROUTES = [
	...DEMAND_MANAGE_ROUTES,
	{
		path: PATHS.DEMANDS.PRIVATE.REPORT,
		element: <TabReport />
	}
];

export {
	DEMAND_ROUTES as default,
	DEMAND_MANAGE_ROUTES,
};
