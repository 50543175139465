import React, {
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	findDefaultOption,
	generateOptions,
	handleOnClickFocus,
	updateOfferLots,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	LotRowProps,
} from '@layouts/Pages/PageOfferEntry/lots/lot-row/index';

// ENUMS
import {
	EnumButtonCorners, EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import Icon from '@components/icon';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../lot-row.module.scss';

function NatureElement({
	activeElement,
	currentOfferId,
	error,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
	setError,
}: LotRowProps) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const {
		isDesktopResolution,
		offerEnumsState,
		offerStateLots,
		setOfferStateLots,
	}: PageOfferContextProps = useOutletContext();

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputNatureElement = (
		<InputMultiSelect
			defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_property_nature_lots, 'references.natures'), currentLot?.ref_property_nature_ids)}
			isMulti={true}
			maxMenuHeight={isDesktopResolution ? 150 : undefined}
			menuIsOpen={true}
			name={'ref_property_nature_lots'}
			options={generateOptions(offerEnumsState?.ref_property_nature_lots, 'references.natures')}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.nature.placeholder')
			})}
			onChange={(selected: OptionType) => {
				updateOfferLots(currentOfferId, currentLot, Array.isArray(selected) ? selected.map((item: OptionType) => Number(item.value)) : [
				], 'ref_property_nature_ids', offerStateLots, rowId, dispatch, setOfferStateLots);
				selected ? setError(false) : undefined;
			}}
		/>
	);

	const modalNatureMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.header.nature')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
			>
				{inputNatureElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_nature
	];

	if (activeElement === 'nature' && selectedRowId === currentLot?.id) cssClasses.push(styles.active);

	const natures =  currentLot?.ref_property_nature_ids?.length > 0 ? (
		currentLot.ref_property_nature_ids
			.map((id) => {
				const match = offerEnumsState?.ref_property_nature_lots?.find((item) => item.value === id);
				return match
					? t('format.capitalize', {
						text: t(`references.natures.${match.key}`),
					})
					: null;
			})
			.filter(Boolean) // Delete undefined values
			.join(', ')
	) : undefined;

	const natureRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`nature_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, currentLot, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_nature}
						clickable={true}
						htmlElement={inputNatureElement}
						id={`tooltip_nature_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.TOP}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_nature_content}>
							{natures ? natures : error ?
								(
									<span className={styles.error_message}>
										<Icon
											className={styles.icon}
											fontStyle={EnumFontStyle.LIGHT}
											name="exclamation-triangle"
										/>
										{t('format.capitalize', {
											text: t('page.offers_create_edit.contacts.identity.error_message')
										})}
									</span>
								) : (
									<span className={styles.placeholder}>
										{t('format.capitalize', {
											text: t('page.offers_create_edit.lots.header.nature'),
										})}
									</span>
								)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.lots.header.nature')
						})}</span>
						<span className={styles.mobile_value}>{currentLot?.ref_property_nature_ids?.length > 0 ? natures : undefined}</span>
					</>
				)}
			</div>
			{modalNatureMobile}
		</>
	);

	return natureRow;
}

export default NatureElement;
