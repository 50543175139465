import * as React from 'react';
import {
	ReactNode,
} from 'react';

import styles from './ModalFull.module.scss';

import Paragraph from '@components/paragraph';

interface FullModalProps {
	children?: ReactNode;
	title?: string;
}

const ModalFull = ({
	children,
	title
}: FullModalProps) => {

	return (
		<div className={`${styles.full}`}>
			<Paragraph
				className={styles.modal_content}
				title={title}
				titleLevel={2}
			>
				{children}
			</Paragraph>
		</div>
	);
};

export default ModalFull;
