import * as React from 'react';

import {
	JSXElementConstructor,
	ReactElement,
} from 'react';

// ENUMS
import {
	EnumTheme,
} from '@enums/theme.enum';

//COMPONENTS
import Dropdown from '@components/dropdown';
import LinkNav, {
	LinkNavProps,
} from '@components/link-nav';

// HOC
import withDropdown, {
	withDropdownprops,
} from '@hoc/withDropdown';

// STYLES
import styles from './navigation-dropdown.module.scss';

interface NavigationDropdownProps {
	children?: ReactElement;
	className?: string;
	'data-testid'?: string;
	items: Array<object>;
	label?: string;
	selectedIndex?: number;
	titleDropdown?: string;
}

const NavigationDropdown = ({
	children,
	className,
	'data-testid': dataTestid,
	items,
	label,
	selectedIndex,
	titleDropdown = '',
	...otherProps
}: NavigationDropdownProps): JSX.Element => {

	const classes = [
		styles.navigation_dropdown
	];

	if (className) classes.push(className);

	const inlineStyles = {
		top: '0',
		left: '100%',
	};
	const dropdownElement = (
		<Dropdown
			data-testid={`${dataTestid}-list`}
			items={items}
			selectedIndex={selectedIndex}
			theme={EnumTheme.PRIMARY}
			title={{
				label: titleDropdown,
				titleLevel: 3
			}}
		/>
	);

	const LinkWithDropdown = withDropdown(LinkNav as JSXElementConstructor<withDropdownprops | LinkNavProps>, dropdownElement);

	return (
		<LinkWithDropdown
			{...otherProps}
			className={classes.join(' ')}
			data-testid={dataTestid}
			label={label}
			style={inlineStyles}
			theme={EnumTheme.NAKED}
		>{children}</LinkWithDropdown>
	);
};

export {
	NavigationDropdown as default,
	NavigationDropdownProps,
};

