import {
	ColumnDef,
	FilterFnOption,
} from '@tanstack/react-table';

export const columnTableConf: ColumnDef<unknown, unknown>[] = [
	{
		accessorKey: 'building_address_street_raw',
		enableColumnFilter: true,
		header: '',
	},
	{
		accessorKey: 'id',
		enableColumnFilter: true,
		header: '',
	},
	{
		accessorKey: 'states',
		enableColumnFilter: true,
		filterFn: 'customfilterFns' as FilterFnOption<unknown>,
		header: '',
	},
	{
		accessorKey: 'mandate_is_owner',
		enableColumnFilter: true,
		filterFn: 'customfilterFns' as FilterFnOption<unknown>,
		header: '',
	},
];

export default columnTableConf;
