import React, {
	BaseSyntheticEvent,
	Dispatch,
	SetStateAction,
} from 'react';
import {
	FieldValues,
} from 'react-hook-form';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';
import {
	matchRoutes,
	useLocation,
	useNavigate,
	useOutletContext,
} from 'react-router-dom';

// STORE
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';

// ROUTES
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
	OFFER_ENTRY_SUMMARY_ROUTES,
} from '@routes/lpdipro/private/offer-entry';
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
	EnumFontStyleButton,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
	EnumThemeButton,
} from '@enums/theme.enum';

// EXCEPTIONS
import GetOfferError from '@exceptions/GetOfferError';
import PostOfferError from '@exceptions/PostOfferError';
import PutOfferError from '@exceptions/PutOfferError';

// MODULES
import {
	getURL,
	handleErrorResponse,
} from '@modules/utils';

// CONFIG
import {
	getOffer,
	postOffer,
	putOffer,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';

// STYLES
import stylesOfferEntry from '../PageOfferEntry.module.scss';
import styles from './validation-form.module.scss';

interface ValidationFormProps {
	isHeaderValidationForm?: boolean;
	isValid: boolean;
	setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const ValidationForm = ({
	isHeaderValidationForm = false,
	isValid,
	setIsOpen,
}: ValidationFormProps): JSX.Element => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const {
		dirtySteps,
		formRef,
		isDesktopResolution,
		offerState,
		setDirtySteps,
		setOfferState,
	} : PageOfferContextProps = useOutletContext();

	const isPublishRoute = matchRoutes(OFFER_ENTRY_SUMMARY_ROUTES, location.pathname)?.length > 0;

	const onSubmitSuccess = async (data: FieldValues, event: BaseSyntheticEvent<SubmitEvent, HTMLFormElement, HTMLFormElement>) => {
		const submitter = (event?.nativeEvent?.submitter as HTMLButtonElement)?.name;

		setTimeout(() => {
			// Récupérer le conteneur scrollable
			const content = document.querySelector(`.${stylesOfferEntry.PageOfferEntry__content}`) as HTMLElement | null;

			// Récupérer l'élément avec data-error="true"
			const errorElement = document.querySelector('[data-error="true"]') as HTMLElement | null;

			if (content && errorElement) {
				const errorElementRect = errorElement.getBoundingClientRect();
				const contentRect = content.getBoundingClientRect();
				const scrollOffset = errorElementRect.top - contentRect.top - content.scrollTop;

				content.scrollTop = scrollOffset;

			} else if (content) {
				content.scrollTop = 0;
			}
		}, 100);

		if (!isValid) {
			if (setIsOpen) setIsOpen(true);
			setTimeout(() => {
				const submitEvent = new Event('submit', {
					bubbles: true,
					cancelable: true
				});
				formRef?.current?.dispatchEvent(submitEvent);
			}, 0);
			return;
		}
		let offerId = offerState.id;

		// If we are creating a new offer
		if (matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length) {
			const responseParsed = await postOffer(offerState);
			handleErrorResponse(responseParsed, PostOfferError);

			offerId = responseParsed.payload.id;

			// Navigate to the next step after creating the offer
			const goToOfferInfos = getURL(PATHS.OFFERS.EDIT.INFOS, {
				':offerid': `${offerId}`
			});
			const offerData = await getOffer(offerId);
			handleErrorResponse(offerData, GetOfferError);

			// Update the offer state with new data
			setOfferState({
				id: offerId,
				...offerData.payload,
			});

			setDirtySteps(prevState => (
				Object.fromEntries(
					Object.keys(prevState).map(key => [
						key,
						false
					])
				)
			));

			navigate(goToOfferInfos);
		} else {
			let currentStep = undefined;

			routesMapping.forEach(({ step, route }) => {
				if (matchRoutes(route, location.pathname)?.length) {
					currentStep = step;
				}
			});

			// Update the current steps based on the current path
			const updatedDirtySteps = {
				...dirtySteps,
				[currentStep as string]: true
			};

			const trueKeysOnly = Object.entries(updatedDirtySteps).reduce((acc: Record<string, boolean>, [
				key,
				value
			]) => {
				if (value) acc[key] = true;
				return acc;
			}, {
			});

			const updatedSteps = {
				...offerState.steps,
				...trueKeysOnly
			};

			const updatedOfferState = {
				...offerState,
				is_published: offerState?.is_published || (isPublishRoute && submitter === 'validate'),
				steps: updatedSteps
			};

			// If editing an existing offer
			const responseParsed = await putOffer(updatedOfferState);

			if (responseParsed.status === 200) {
				setOfferState(updatedOfferState);
				setDirtySteps(prevState => (
					Object.fromEntries(
						Object.keys(prevState).map(key => [
							key,
							false
						])
					)
				));
			}

			const handleOnErrorCallback = () => {
				dispatch(addStatusMsg({
					message: t('format.capitalize', {
						text: t('status.updated_offer_error_message')
					}),
					isClosable: true,
					buttons: [
						{
							label: t('format.capitalize', {
								text: t('general.action.contact'),
							}),
							href: 'serviceclient',
						},
					],
					theme: EnumStatusTheme.ERROR,
				}));
			};

			handleErrorResponse(responseParsed, PutOfferError, handleOnErrorCallback);

			// Find the next route and navigate if available
			const nextRoute = getNextRoute(updatedSteps, location.pathname, offerId);

			if (nextRoute) {
				navigate(nextRoute);
			}
		}
	};

	// Function to get the next route based on the current step
	const getNextRoute = (
		steps: Record<string, boolean>,
		currentPath: string,
		offerId: number
	): string | null => {

		// Find the index of the current route
		const currentRouteIndex = routesMapping.findIndex(({ route }) =>
			matchRoutes(route, currentPath)?.length
		);

		// Find the next route with a "false" step in offerState.steps
		if (currentRouteIndex !== -1 && !offerState?.is_published) {
			const nextRoute = routesMapping.slice(currentRouteIndex + 1).find(route => {
				const stepKey = route.step;
				return steps[stepKey] === false;
			});

			if (nextRoute) {
				return getURL(nextRoute.route[0].path, {
					':offerid': `${offerId}`
				});
			}
		}

		return undefined;
	};

	const commonButtonProps = {
		className: styles.button,
		corners: EnumButtonCorners.Square,
		size: isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL,
		iconStyle: EnumFontStyle.LIGHT as EnumFontStyleButton,
		theme: EnumTheme.SECONDARY as EnumThemeButton,
		type: EnumButtonType.BUTTON,
	};

	const leaveButton = (
		<Button
			{...commonButtonProps}
			label={t('format.capitalize', {
				text: t('general.action.leave'),
			})}
			onClick={() => navigate(PATHS.OFFERS.LIST)}
		/>
	);

	return (
		<Form
			className={styles.form}
			onSuccess={onSubmitSuccess}
		>
			<div className={styles.button_group}>
				{isDesktopResolution ? (
					<>
						{isHeaderValidationForm && (
							<>
								<Button
									{...commonButtonProps}
									iconLeft="trash-alt"
									iconStyle={EnumFontStyle.LIGHT}
									name="delete"
									tooltipText={t('format.capitalize', {
										text: t('general.action.delete'),
									})}
								/>
								{leaveButton}
								{offerState?.is_published || offerState?.id === undefined ? undefined : (
									<Button
										{...commonButtonProps}
										label={t('format.capitalize', {
											text: t('general.action.save'),
										})}
										name="save"
										type={EnumButtonType.SUBMIT}
									/>
								)}
							</>
						)}
					</>
				) : leaveButton}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: offerState?.is_published || isPublishRoute ? t('general.action.validate') : t('general.action.continue')
					})}
					name='validate'
					size={isDesktopResolution ? EnumButtonSize.DEFAULT : EnumButtonSize.SMALL}
					type={EnumButtonType.SUBMIT}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(ValidationForm);
