import React, {
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	useParams,
} from 'react-router-dom';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	Offer,
	OfferJson,
} from '@@types/Offer';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// ROUTES
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';
import PATHS from '@routes/paths';

// MODULES
import {
	updateImageUrls,
} from '@modules/images';
import * as utils from '@modules/utils';

// COMPONENTS
import Button from '@components/button';
import Link from '@components/link';
import Sticky from '@components/sticky';
import Text from '@components/text';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	actualLanguage: string;
	contentMatchingRef: RefObject<HTMLDivElement>;
	isDesktopResolution: boolean;
	offerGlobalState: OfferJson;
}

const Header = ({
	actualLanguage,
	contentMatchingRef,
	offerGlobalState,
	isDesktopResolution,
}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const { step } = useParams();
	const commercializationStepName = step || 'matched';
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);
	const offer = offerGlobalState ? new Offer(offerGlobalState, actualLanguage) : undefined;
	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: t('page.matching_offer_demands.title')
				})}
			</h1>
			<Link
				href={`${APP_CONF_VARS.sfLegacyPath}/offer/detail/id/${offer.id}`}
			>
				<Text
					className={styles.ref}
					theme={EnumStatusTheme.INFO}
				>
					{t('format.capitalize', {
						text: t('general.ref', {
							'%ref%': offer.id
						})
					})}
				</Text>
			</Link>
		</div>
	);

	const dataAddress = {
		title: offer.building.address.full,
		image_url: offer?.images?.collection?.length ? updateImageUrls(offer.images.collection[0].url, EnumImageFormatQuery.SQUARE) as string || undefined : undefined,
		descriptions: [
			{
				value: t('format.capitalize', {
					text: t('page.matching_offer_demands.to_commercilize')
				}),
				type: 'string'
			},
		]
	};

	const [
		headerCssClasses,
		setHeaderCssClasses
	] = useState([
		styles.header
	]);

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter__sticky
	]);

	useEffect(() => {
		const baseFilterClasses = [
			styles.filter__sticky
		];
		const baseHeaderClasses = [
			styles.header
		];
		if (navIsOpen && isDesktopResolution) {
			baseHeaderClasses.push(styles.header__navisopen);
		}
		if (isSticky && navIsOpen && isDesktopResolution) {
			baseFilterClasses.push(styles.filter__sticky__navisopen);
		}
		setHeaderCssClasses(baseHeaderClasses);
		setStickyCssClasses(baseFilterClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	const headerStickyElement = (
		<>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.MATCHED]}
				iconLeft={'bullseye-pointer'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.MATCHED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.matched.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.MATCHED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.PROPOSED]}
				iconLeft={'share-arrow'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.PROPOSED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.proposed.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.PROPOSED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.APPLIED]}
				iconLeft={'folder'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.APPLIED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.applied.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.APPLIED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.REFUSED]}
				iconLeft={'times'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.REFUSED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.refused.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.REFUSED
				})}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Default}
				counter={offerGlobalState?.counters?.steps[EnumSteps.IGNORED]}
				iconLeft={'trash-alt'}
				iconStyle={EnumFontStyle.REGULAR}
				isActive={commercializationStepName === EnumSteps.IGNORED}
				label={t('format.capitalize', {
					text: t('page.matching_offer_demands.steps.ignored.title'),
				})}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.SECONDARY}
				to={utils.getURL(PATHS.OFFERS.MATCHING_DEMANDS, {
					':offerid': `${offer.id}`,
					':step': EnumSteps.IGNORED
				})}
			/>
		</>
	);

	const stickyContainerRef = useRef(null);

	return (
		<div className={headerCssClasses.join(' ')}>
			{titleElement}
			<UserCard
				className={styles.user_card}
				data={dataAddress}
				hasEllipsis={isDesktopResolution ? false : true}
				theme={ThemeUserCard.SQUARE}
			/>
			<div
				className={styles.filter}
				ref={stickyContainerRef}
			>
				{headerStickyElement}
			</div>
			<Sticky
				className={stickyCssClasses.join(' ')}
				isDesktopResolution={true}
				isSticky={isSticky}
				scrollContainerRef={isDesktopResolution ? contentMatchingRef : undefined}
				setIsSticky={setIsSticky}
				stickyContainerRef={stickyContainerRef}
			>
				{headerStickyElement}
			</Sticky>
		</div>
	);
};
// };

export default withTranslation()(Header);
