import * as React from 'react';

import {
	ChangeEvent,
	useRef,
	useState,
} from 'react';

// COMPONENTS
import InputPassword from '@components/form/input-password';
import {
	InputTextProps,
} from '@components/form/input-text';
import Icon from '@components/icon';
import {
	useTranslation,
} from 'react-i18next';

// DATA
import validationRules from '@static_data/validation-rules.data';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// STYLES
import styles from './input-password-with-validation.module.scss';

interface InputPasswordWithValidationProps extends InputTextProps {
	itemList?: Array<JSX.Element>;
	onChange?: (event: ChangeEvent<HTMLInputElement>, newState: object) => void;
}

const InputPasswordWithValidation = ({
	autoComplete,
	className,
	customError,
	'data-testid': dataTestid,
	defaultValue,
	disabled = false,
	innerRef,
	methods,
	required = false,
	onChange,
	...otherProps
}: InputPasswordWithValidationProps): JSX.Element => {
	const { t } = useTranslation();
	const hasToBeFormated = autoComplete === 'new-password' && required;
	let customErrorMessage = null;

	customErrorMessage = hasToBeFormated ? t('general.form.input.error.password_format') : null;

	const hookRef = useRef();
	const localRef = innerRef || hookRef;

	const initialState: InputPasswordWithValidationProps = {
		...otherProps,
		disabled,
		dirty: false,
		itemList: [
		]
	};

	const [
		state,
		setState,
	] = useState(initialState);

	// PANEL
	const validatePanel = hasToBeFormated ? (
		<div
			className={styles.panel}
			data-testid={`${dataTestid}-rules`}
		>
			<ul
				className={styles.list}
				data-testid={`${dataTestid}-rules-list`}
			>
				{state.itemList}
			</ul>
		</div>
	) : null;

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, actualState: InputPasswordWithValidationProps) => {
		const inputItem = event.currentTarget;
		const isDirty = state.dirty === false || (state?.value !== state.initialValue) as boolean;
		const itemList = hasToBeFormated ? validationRules.map((validationRuleItem, key) => {
			const pattern = new RegExp(validationRuleItem.rule);
			const isValid = pattern.test(inputItem.value);
			return (
				<li
					className={`${styles.rule} ${isValid ? styles.rule__validate : styles.rule__error}`}
					data-testid={`${dataTestid}-rules-item`}
					key={key}
				>
					{isValid ? (
						<Icon
							className={styles.icon}
							data-testid={`${dataTestid}-icon-validate`}
							name='check'
						/>
					) : (
						<Icon
							className={styles.icon}
							data-testid={`${dataTestid}-icon-error`}
							name='times'
						/>
					)}
					<span className={styles.label}>{validationRuleItem.label}</span>
				</li>
			);
		}) : null;

		const newState: InputPasswordWithValidationProps = {
			...state,
			...actualState,
			dirty: isDirty,
			itemList,
		};
		setState(newState);
		if (onChange) onChange(event, newState);
	};

	const cssClasses = [
		styles.input_password_validation,
	];

	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			<InputPassword
				{...otherProps}
				autoComplete={autoComplete}
				customError={customErrorMessage ? customErrorMessage : customError}
				data-testid={`${dataTestid}-password`}
				defaultValue={defaultValue}
				disabled={disabled}
				innerRef={localRef}
				methods={methods}
				required={required || false}
				type={EnumInputType.PASSWORD}
				onChange={handleOnChange}
			/>

			{state.dirty ? validatePanel : null}
		</div>
	);
};

InputPasswordWithValidation.displayName = EnumComponentType.INPUT_PASSWORD_WITH_VALIDATION;

export {
	InputPasswordWithValidation as default,
	InputPasswordWithValidationProps,
};

