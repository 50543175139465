import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	EnumImageFormatQuery,
} from '@appConf/vars.conf';
import moment from 'moment';

// TYPES
import {
	Contact,
} from '@@types/index';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import AddToCalendarButton from '@components/add-to-calendar-button';
import Button, {
} from '@components/button';
import Textarea from '@components/form/textarea';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// LAYOUTS
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';
import {
	updateImageUrls,
} from '@modules/images';

// STYLES
import styles from './ModalVisitConfirmation.module.scss';

interface ModalVisitConfirmationProps {
	address?: string;
	contact?: Contact;
	comment?: string;
	date?: string;
	demandId?: number;
	hasAction?: boolean;
	image?: string;
	language: string;
	offerId?: number;
	setModalCanceled?: Dispatch<SetStateAction<{
		enabled: boolean;
		action?: string;
	}>>;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setDataModalConfirmation?: Dispatch<SetStateAction<BodyRequest>>;
	subject?: string;
	time?: string;
	time_end?: string;
}

function ModalVisitConfirmation({
	address,
	contact,
	comment,
	date,
	image,
	setModalCanceled,
	setDataModalConfirmation,
	hasAction,
	subject,
	time,
	time_end
}: ModalVisitConfirmationProps) {
	const { t } = useTranslation();

	const titleContactComment = t('format.capitalize', {
		text: t('modals.visit.confirmation.title_contact_comment')
	});

	const contactForComment = `[strong]${titleContactComment}[/strong] :[br]` +
		`${contact?.full_name ? contact.full_name + '[br]' : ''}` +
		`${contact?.company_name ? contact.company_name + '[br]' : ''}` +
		`${contact?.mobile || contact?.phone ? (contact.mobile || contact.phone) + '[br]' : ''}` +
		`${contact?.email ? contact.email : ''}`;

	const commentWithContact = comment ? `${comment}[br][br]${contactForComment}` : contactForComment;

	const cssClasses = [
		styles.visit_confirmation
	];
	if (!hasAction) cssClasses.push(styles.no_action);

	const dataAddress = {
		title: address,
		image_url: updateImageUrls(image, EnumImageFormatQuery.SQUARE) as string || undefined,
		descriptions: [
			{
				value: t('format.capitalize', {
					text: t('modals.visit.visit_date', {
						date: date,
						time: time
					})
				}),
				type: 'string',
				required: true,
			},
			{
				value: t('format.capitalize', {
					text: t('modals.visit.plan.with', {
						full_name: contact?.full_name || null,
					})
				}),
				type: 'string',
				required: true,
			},
			{
				value: contact?.company_name || null,
				type: 'subtitle',
				required: true,
			},
			{
				value: contact?.phone || contact?.mobile || null,
				type: 'tel',
				required: true,
			},
			{
				value: contact?.email || null,
				type: 'email',
				required: true,
			}
		],
	};

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setDataModalConfirmation(null);
		setModalCanceled({
			enabled: true,
		});
	});

	const handleOnClickConfirm = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setDataModalConfirmation(null);
	});

	return (
		<div className={cssClasses.join(' ')}>
			<UserCard
				className={styles.usercard}
				data={dataAddress}
				theme={ThemeUserCard.SQUARE}
			/>
			{comment ? (
				<>
					<div className={styles.title}>{t('format.capitalize', {
						text: t('general.useful_info')
					})}</div>
					<Textarea
						className={styles.textarea_info}
						defaultValue={comment}
						name='confirmation'
						readOnly={true}
						rows={4}
					></Textarea>
				</>
			) : null}
			{hasAction ? (
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.visit_canceled')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.visit_reschedule')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
						onClick={handleOnClickConfirm}
					/>
				</div>
			) : (
				<>
					<div className={styles.title}>{t('format.capitalize', {
						text: t('general.added_to_my_calendar')
					})}</div>
					<AddToCalendarButton
						description={commentWithContact}
						endTime={time_end}
						location={address}
						name={subject}
						startDate={moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
						startTime={time}
					/>
				</>
			)}
		</div>
	);
}

export default withTranslation()(ModalVisitConfirmation);
