import React, {
	Dispatch,
	RefObject,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';

// EXCEPTIONS
import GetOfferDefaultValuesError from '@exceptions/GetOfferDefaultValuesError';
import GetOfferEnumsError from '@exceptions/GetOfferEnumsError';
import GetOfferError from '@exceptions/GetOfferError';

// REDUCERS
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';
import {
	Media,
} from '@@types/Media';

// ROUTES
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import {
	getOffer,
	getOfferContacts,
	getOfferDefaultValues,
	getOfferEnums,
	getOfferLotsEdit,
	getOfferLotsTotal,
	getOfferMedia,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// COMPONENTS
import StatusBanner, {
	StatusBannerProps,
} from '@components/status-banner';

// STYLES
import styles from './PageOfferEntry.module.scss';

export interface AddressesProps {
	id: number;
	decoded: Record<string, unknown>;
	icon?: string;
	is_main: boolean;
	is_valid?: boolean;
	street_raw: string;
	pretty_street_raw?: string;
	type?: string;
}

export interface OfferStateProps {
	addresses?: AddressesProps[];
	allowed_activities?: string;
	annual_indexation?: string;
	building_description?: string;
	building_name?: string;
	building_services?: string;
	caz_name?: string;
	certification_comment?: string;
	commercialized_at?: string;
	construction_comment?: string;
	created_at?: string;
	deed_fees?: string;
	description_real_estate_website?: string;
	divisible_area_min?: number;
	dpe_energy_consumption?: number;
	dpe_greenhouse_gas_emission?: number;
	entrance_fee?: number;
	fees_comment?: string;
	fiscal_regime?: string;
	grade_level_door?: number;
	has_branch_line_ite?: boolean;
	has_description_real_estate_website?: boolean;
	has_outdoor_parking?: boolean;
	has_indoor_parking?: boolean;
	id?: number;
	infos?: Record<string, unknown>[] | Record<string, undefined>;
	is_divisible?: boolean;
	is_dpe_applicable?: boolean;
	is_independent_building?: boolean;
	is_private?: boolean;
	is_published?: boolean;
	is_rental_fees_charged_to_the_lessee?: boolean;
	is_sale_fees_charged_to_the_buyer?: boolean;
	is_single_storey_building?: boolean;
	leases?: {
			details?: {
					[key: string]: string | number;
			};
			rental_ref_lease_type_id?: number;
	}[];
	loading_dock?: number;
	logistic_hub_height?: number;
	lots_resume?: {
			[key: string]: number;
	};
	main_photo?: Media;
	management_fees_value?: string;
	management_fees_unit?: string;
	mandate?: {
			type?: string;
			is_owner?: boolean;
	};
	meter_linear_showcase?: number;
	nature?: string;
	office_tax?: string;
	observations?: string;
	parking_available?: boolean;
	parking_comment?: string;
	parking_indoor_place_nb?: number;
	parking_indoor_rental?: number;
	parking_indoor_sale_price?: number;
	parking_outdoor_place_nb?: number;
	parking_outdoor_rental_price?: number;
	parking_outdoor_sale?: number;
	private_offer_title?: string;
	profession_tax?: string;
	profession_tax_rate?: string;
	program_name?: string;
	prohibited_activities?: string;
	property_tax?: string;
	quote_part_rie?: string;
	ref_announcement_indexation_id?: string;
	ref_announcement_payment_type_id?: string;
	ref_building_commercial_zone_id?: string;
	ref_building_warehouse_classification_id?: string;
	ref_building_warehouse_typology_id?: string;
	ref_dpe_energy_consumption_id?: string;
	ref_dpe_greenhouse_gas_emission_id?: string;
	ref_icpe_code_ids?: string[];
	ref_offer_certification_classement_ids?: string[];
	ref_offer_certification_label_ids?: string[];
	ref_property_state_id?: number;
	ref_fiscal_regime_id?: string;
	ref_lease_activity_ids?: string;
	ref_lease_activity?: string[];
	ref_property_nature_main_id?: number;
	ref_property_nature_main?: string;
	ref_rental_fees_unit_id?: string;
	rental_caution?: string;
	rental_cession_price?: number;
	rental_fees?: number;
	rental_price_m2?: number;
	rental_price?: number;
	rental_ref_price_unit_id?: string;
	rental_ref_price_unit?: string;
	sale_charge_comment?: string;
	sale_charge_provision?: string;
	sale_charge_provision_annual?: string;
	sale_fees?: number;
	sale_price?: number;
	sale_price_m2?: number;
	sale_ref_sale_fees_unit_id?: string;
	security_deposit?: string;
	security_deposit_ref_price_unit_id?: string;
	showcase?: number;
	show_office_tax?: boolean;
	show_professional_tax?: boolean;
	show_property_tax?: boolean;
	show_teom?: boolean;
	state?: string;
	state_date?: string;
	steps?: {
			[key: string]: boolean;
	};
	suspended_at?: string;
	tax_comment?: string;
	teom?: string;
	total_area_main_natures?: number;
	transport_access?: string;
	types?: string[];
	under_option_at?: string;
	under_promise_at?: string;
	updated_at?: string;
	workstation_rental?: number;
}

export interface ObjectEnum {
	key: string;
	value: number | string;
}
export interface OfferEnumsStateProps {
	contact_roles?: ObjectEnum[];
	has_branch_line_ite?: boolean[];
	has_description_real_estate_website?: boolean[];
	has_indoor_parking?: boolean[];
	has_outdoor_parking?: boolean[];
	is_divisible?: boolean[];
	is_dpe_applicable?: boolean[];
	is_independent_building?: boolean[];
	is_single_storey_building?: boolean[];
	mandate_type?: string[];
	parking_available?: boolean[];
	ref_announcement_indexations?: ObjectEnum[];
	ref_announcement_payment_types?: ObjectEnum[];
	ref_announcement_surface_availabilities?: string[];
	ref_building_commercial_zones?: string[];
	ref_building_warehouse_classifications?: string[];
	ref_building_warehouse_typologies?: string[];
	ref_dpe_energy_consumption_ids?: string[];
	ref_dpe_greenhouse_gas_emission_ids?: string[];
	ref_fiscal_regimes?: ObjectEnum[];
	ref_icpe_codes?: ObjectEnum[];
	ref_lease_activities?: string[];
	ref_sale_fees_units?: ObjectEnum[];
	ref_offer_certification_classements?: string[];
	ref_offer_certification_labels?: string[];
	ref_price_units?: ObjectEnum[];
	ref_property_nature_announcements: ObjectEnum[];
	ref_property_nature_lots: ObjectEnum[];
	ref_property_states?: ObjectEnum[];
	rental_ref_lease_types?: ObjectEnum[];
	ref_rental_fees_units?: ObjectEnum[];
	ref_lot_states?: string[];
	types?: string[];
	visibilities: ObjectEnum[];
}

export interface OfferStateContactsProps {
	contacts: Collection<ContactOfferJson, ContactOffer>;
	is_loaded: boolean;
}

export interface LotsStateProps {
	[key: string]: string | number | number[] | Array<string> | {
		state?: string;
		date?: string;
	};
	availability?: {
		state?: string;
		date?: string;
	};
	building?: string;
	charges_m2?: number;
	charges_yearly?: number;
	commercialized_at?: string;
	id: number;
	level?: string;
	last_option_date?: string;
	last_promessed_date: string;
	lot_number?: string;
	natures: Array<string>;
	observations?: string;
	ref_property_nature_ids: number[];
	rental_price?: number;
	rental_price_m2?: number;
	sale_price?: number;
	sale_price_m2?: number;
	state?: string;
	surface_m2: number;
	suspended_at?: string;
	workstations_number?: number;
	workstation_rental?: number;
	workstation_rental_ref_price_unit_id?: number;
}

export interface OfferStateLotsProps {
	lots: Collection<LotsStateProps, LotsStateProps>;
	lots_total: {
		total_area: number;
		total_area_main_natures: number;
	};
	is_loaded: boolean;
}

export interface MediaOfferProps {
	is_loaded: boolean;
	media?: Media[];
}

export interface OfferStateDefaultValueProps {
	common: {
		ref_price_unit_id: number;
		availability: {
			state: string;
		};
	};
}

export interface PageOfferContextProps {
	contentEntryOfferRef: RefObject<HTMLDivElement>;
	currentLanguage: string;
	dirtySteps: Record<string, boolean>;
	formRef: RefObject<HTMLFormElement>;
	isDesktopResolution: boolean;
	language: string;
	mediaOfferState: MediaOfferProps;
	offerEnumsState: OfferEnumsStateProps;
	offerState?: OfferStateProps;
	offerStateContacts?: OfferStateContactsProps;
	offerStateDefaultValue?: OfferStateDefaultValueProps;
	offerStateLots?: OfferStateLotsProps;
	setDirtySteps: Dispatch<SetStateAction<Record<string, boolean>>>;
	setMediaOfferState: (Dispatch<SetStateAction<MediaOfferProps>>);
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
	setOfferState: (Dispatch<SetStateAction<OfferStateProps>>);
	setOfferStateLots: Dispatch<SetStateAction<OfferStateLotsProps>>;
}

const PageOfferEntry = () => {
	const {offerid} = useParams();
	const currentLanguage = useSelector((state: ReducerInstance) => state.app?.instance?.localization?.actualLanguage);
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const statusMsgs = useSelector((state: ReducerInstance) => state.status_msgs.instances);

	const navigate = useNavigate();

	const contentEntryOfferRef = useRef(null);
	const formRef = useRef(null);

	const location = useLocation();

	const [
		isLoaded,
		setIsLoaded,
	] = useState(false);

	const [
		offerState,
		setOfferState
	] = useState();

	const [
		dirtySteps,
		setDirtySteps
	] = useState({
		location: false,
		infos: false,
		contacts: false,
		lots: false,
		media: false,
		services: false,
		terms: false,
		summary: false,
	});

	const [
		offerStateContacts,
		setOfferStateContacts,
	] = useState({
		is_loaded: false,
		contacts: undefined,
	});

	const [
		offerStateLots,
		setOfferStateLots,
	] = useState({
		is_loaded: false,
		lots: undefined,
		lots_total: undefined,
	});

	const [
		offerStateDefaultValue,
		setOfferStateDefaultValue,
	] = useState<OfferStateDefaultValueProps>(undefined);

	const [
		mediaOfferState,
		setMediaOfferState
	] = useState({
		is_loaded: false,
		media: undefined
	});

	const [
		offerEnumsState,
		setOfferEnumsState,
	] = useState(undefined);

	const handleOnErrorCallback = () => {
		dispatch(clearToastMsgs());
		dispatch(addToastMsg({
			message: t('status.default'),
			theme: EnumStatusTheme.ERROR,
		}));
		navigate(PATHS.HOMEPAGE);
	};

	useEffect(() => {
		// Async function to handle data fetching
		const getOfferData = async () => {
			// Fetch enums for editing an existing offer
			const enumsResponse = await getOfferEnums();
			handleErrorResponse(enumsResponse, GetOfferEnumsError, handleOnErrorCallback);
			if (enumsResponse.status === 200) {
				setOfferEnumsState(enumsResponse.payload);
			}

			// Check if it's a new offer edit route
			if (routesMapping.some(({ route }) => matchRoutes(route, location.pathname)?.length)) {

				// Fetch offer default values
				const getOfferDefaultValuesResponse = await getOfferDefaultValues(currentOfferId);
				handleErrorResponse(getOfferDefaultValuesResponse, GetOfferDefaultValuesError);
				if (getOfferDefaultValuesResponse.status === 200) {
					setOfferStateDefaultValue(getOfferDefaultValuesResponse.payload);
				}

				// Fetch offer details
				const getOfferResponse = await getOffer(currentOfferId);
				handleErrorResponse(getOfferResponse, GetOfferError, handleOnErrorCallback);
				if (getOfferResponse.status === 200) {
					setOfferState({
						...getOfferResponse.payload,
						id: currentOfferId,
					});
					setIsLoaded(true);

					// Fetch offer contacts
					const contactsResponse = await getOfferContacts(currentOfferId);
					if (contactsResponse.status === 200) {
						setOfferStateContacts({
							contacts: contactsResponse.payload,
							is_loaded: true,
						});
					}

					// Fetch offer lots
					const lotsResponse = await getOfferLotsEdit(currentOfferId);
					if (lotsResponse.status === 200) {
						setOfferStateLots(prevState => ({
							...prevState,
							lots: lotsResponse.payload,
							is_loaded: true,
						}));
					}

					// Fetch offer lots total
					const lotsTotalResponse = await getOfferLotsTotal(currentOfferId);
					if (lotsTotalResponse.status === 200) {
						setOfferStateLots(prevState => ({
							...prevState,
							is_loaded: true,
							lots_total: lotsTotalResponse.payload,
						}));
					}

					// Fetch offer media
					const mediaResponse = await getOfferMedia(currentOfferId);
					if (mediaResponse.status === 200) {
						setMediaOfferState({
							...mediaOfferState,
							is_loaded: true,
							media: mediaResponse.payload,
						});
					}
				}
			} else {
				setIsLoaded(true);
			}
		};

		// Call the async function when the component mounts
		getOfferData();
	}, [
		currentOfferId,
	]);

	const statusMsgsElement = statusMsgs.length ? (
		<div
			className={styles.status_banner_container}
			data-status-msg-wrapper
		>
			{statusMsgs.map((statusBanner: StatusBannerProps, index: number) => {
				return (
					<StatusBanner
						buttons={statusBanner.buttons}
						className={styles.status_banner}
						isClosable={statusBanner.isClosable}
						key={index}
						message={statusBanner.message}
						theme={statusBanner.theme as StatusBannerProps['theme']}
					/>
				);
			})}
		</div>
	) : null;

	return isLoaded ? (
		<Page className={styles.PageOfferEntry}>
			<div
				className={styles.PageOfferEntry__content}
				ref={contentEntryOfferRef}
			>
				<Outlet
					context={{
						contentEntryOfferRef,
						currentLanguage,
						dirtySteps,
						formRef,
						isDesktopResolution,
						mediaOfferState,
						offerEnumsState,
						offerState,
						offerStateContacts,
						offerStateDefaultValue,
						setDirtySteps,
						offerStateLots,
						statusMsgsElement,
						setMediaOfferState,
						setOfferState,
						setOfferStateContacts,
						setOfferStateLots,
					}}
				/>
			</div>
		</Page>
	) : undefined;
};

export default withTranslation()(PageOfferEntry);
