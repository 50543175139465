import {
	ColumnFilter,
	Row,
} from '@tanstack/react-table';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const includesSome = (row: Row<{ [key: string]: any }>, columnFilters: ColumnFilter[]) => {
	const selectedFilters = columnFilters.map(filter => String(filter.id));
	const filterValues = columnFilters.map(filter => String(filter.value)).flat();
	const filterRowValue = Array.from(selectedFilters).flatMap(filter => {
		const filtersColumn = row.getValue(filter);
		return (Array.isArray(filtersColumn) ? filtersColumn : [
						filtersColumn as string
		]).filter(value => filterValues.includes(value));
	});

	return filterRowValue.length > 0;
};
