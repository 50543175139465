import React, {
	ChangeEvent,
	memo,
	useEffect,
	useRef,
	useState,
} from 'react';
import DatePicker from 'react-date-picker';
import {
	Controller,
} from 'react-hook-form';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import Icon, {
	IconProps,
} from '@components/icon';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumFormMode,
} from '@enums/form.enum';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// MODULES
import formUtils from '@modules/formUtils';

// COMPONENTS
import {
	InputProps,
	MessageProps,
	MethodsProps,
} from '@components/form/input';
import InputLabel from '@components/form/input-label';
import {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-date-picker.module.scss';

interface StateProps {
	error: MessageProps;
}

export interface InputDatePickerProps extends Omit<InputTextProps, 'defaultValue'> {
	'data-testid'?: string;
	calendarIcon?: IconProps;
	className?: string;
	customError?: string;
	defaultValue?: Date;
	disabled?: boolean;
	format?: string;
	hasBorder?: boolean;
	hasCalendarIcon?: boolean;
	hasPlaceholder?: boolean;
	hasShadow?: boolean;
	id?: string;
	isCalendarOpen?: boolean;
  isOpen?: boolean;
	label?: string;
	language?: string;
	maxDate?: Date;
	methods?: MethodsProps;
	name: string;
	rules?: object;
}

const InputDatePicker = memo(({
	'data-testid': dataTestid,
	className,
	customError,
	defaultValue,
	disabled,
	format = 'dd-MM-y',
	hasBorder = true,
	hasPlaceholder = false,
	hasShadow = false,
	id,
	innerRef,
	invalid,
	isOpen = false,
	label,
	language = APP_CONF_VARS.language_default.code,
	maxDate,
	methods,
	name,
	onChange,
	pattern,
	required,
	rules,
	size,
	validationType,
}: InputDatePickerProps): JSX.Element => {
	const { t } = useTranslation();
	let register = null;
	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];

	const hookRef = useRef<HTMLDivElement>(null);
	const localRef = innerRef || hookRef;

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: customError ? customError : t('general.form.input.error.date_picker')
			};
			registerRules.pattern = {
				value: pattern || formUtils.rulesByType['default'],
				message: customError ? customError : t('general.form.input.error.date_picker')
			};
		}

		register = methods.register(name, registerRules);
	}

	const [
		valueDate,
		setValueDate
	] = useState(defaultValue ? new Date(defaultValue) : hasPlaceholder ? undefined : new Date());
	const [
		open,
		setOpen
	] = useState(isOpen);

	const [
		state,
		setState
	] = useState({
		error: methods?.formState?.errors[name],
	} as StateProps);

	const calendarIconElement = (
		<Icon
			data-testid={`${dataTestid}-icon-calendar`}
			fontStyle={EnumFontStyle.LIGHT}
			name="calendar-day"
		/>
	);

	const clearIconElement = valueDate ? (
		<Icon
			data-testid={`${dataTestid}-icon-clear`}
			fontStyle={EnumFontStyle.LIGHT}
			name="times"
		/>
	) : null;

	const labelElement = label ? (
		<InputLabel
			data-testid={`${dataTestid}-label`}
			id={id}
			textEllipsis={true}
		>{decodeURI(t(label))}</InputLabel>
	) : null;

	const classes = [
		styles.date_picker,
	];

	function handleOnClick() {
		setOpen(!open);
	}

	useEffect(() => {
		// When the component mounts or value change, update the value in the form
		if (methods && methods?.setValue) {
			methods.setValue(name, valueDate);
		}
	}, [
		valueDate,
	]);

	useEffect(() => {
		setValueDate(defaultValue ? new Date(defaultValue) : hasPlaceholder ? undefined : new Date());
	}, [
		defaultValue,
	]);

	const placeholderElement = hasPlaceholder && !valueDate && !disabled  ? (
		<div
			className={styles.placeholder}
			data-testid={`${dataTestid}-placeholder`}
			onClick={handleOnClick}
		>
			{t('general.form.input.type.date_picker.placeholder')}
		</div>
	) : null;

	useEffect(() => {
		const errorMessage = invalid && customError ? {
			message: customError
		} : invalid;
		setState({
			error: errorMessage,
		});
	}, [
		invalid
	]);

	const errorMsgElement = state.error ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{state.error.message}
		</div>
	) : null;

	if (className) classes.push(className);
	if (hasShadow) classes.push(styles.shadow);
	if (hasBorder) classes.push(styles.border);
	if (size) classes.push(styles[`${'size__' + size}`]);
	if (state.error) classes.push(styles.invalid);

	// Function used into lib component props which can't be rendered in Jest JsDom env
	/* istanbul ignore next */
	const onDateChange = (newDate: Date) => {
		setValueDate(newDate);
		const isInputInvalid = validationType !== EnumFormMode.ON_SUBMIT && required && !newDate ? true : false;

		setState({
			error: isInputInvalid ? {
				message: t('general.form.input.error.date_picker')
			} : undefined,
		});

		if (onChange) {
			onChange({
				target: {
					value: newDate
				}
			} as unknown as ChangeEvent<HTMLInputElement>);
		}
	};

	const DatePickerComponent = (): JSX.Element => (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
			ref={localRef}
		>
			<div className={styles.date_picker__container}>
				{labelElement}
				{placeholderElement}
				<DatePicker
					calendarIcon={calendarIconElement}
					clearIcon={clearIconElement}
					data-testid={`${dataTestid}-picker`}
					defaultValue={defaultValue}
					disabled={disabled}
					format={format}
					id={id}
					isOpen={open}
					locale={language}
					maxDate={maxDate}
					name={name}
					value={valueDate}
					onChange={/* istanbul ignore next */(date) => {
						onDateChange(date as Date);
					}}
				/>
				{errorMsgElement}
			</div>
		</div>
	);

	return register ? (
		<Controller
			defaultValue={valueDate}
			name={name}
			render={() => DatePickerComponent()}
			rules={rules}
		/>
	) : DatePickerComponent();
});

InputDatePicker.displayName = EnumComponentType.INPUT_DATE_PICKER;

export default InputDatePicker;
