import React from 'react';
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';

const Loader = (props: {
	className?: string;
}) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const height = isDesktopResolution ? 228 : 167;
	const width = '100%';

	const cssClasses = [
	];

	if (props.className) cssClasses.push(props.className);

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			height={height}
			width={width}
		>
			<rect
				height={height}
				id="5"
				rx="4"
				ry="4"
				width={width}
				x={0}
				y={0}
			/>
		</ContentLoader>
	);
};

export default Loader;
