import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// EXCEPTIONS
import PutOfferLotsError from '@exceptions/PutOfferLotsError';

// UTILS
import {
	handleErrorResponse,
} from '@modules/utils';

// TYPES
import {
	Collection,
} from '@@types/Collection';

// ENUMS
import {
	EnumButtonCorners, EnumButtonType,
} from '@enums/button.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	LotsStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	putOfferLots,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	findDefaultOption,
	generateOptions,
	handleOnClickFocus,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	LotRowProps,
} from '@layouts/Pages/PageOfferEntry/lots/lot-row/index';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputDatePicker from '@components/form/input-date-picker';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import LabelStatus from '@components/label-status';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../lot-row.module.scss';

function StatusElement({
	activeElement,
	currentOfferId,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
}: LotRowProps
) {
	const {t} = useTranslation();

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const {
		isDesktopResolution,
		currentLanguage,
		offerEnumsState,
		offerStateLots,
		setOfferStateLots,
	}: PageOfferContextProps = useOutletContext();

	const [
		inputValueStatus,
		setInputValueStatus
	] = useState<string>(undefined);

	const getStatusName = (status: string): string => {
		switch (status) {
			case 'archived':
				return 'commercialized_at';
			case 'under_option':
				return 'last_option_date';
			case 'under_promise':
				return 'last_promessed_date';
			default:
				return 'suspended_at';
		}
	};

	const inputStatusElement = (
		<>
			<InputMultiSelect
				defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_lot_states, 'status'), inputValueStatus)}
				hasClearButton={false}
				hasShadowMenu={true}
				isMulti={false}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.lots.status.label')
				})}
				maxMenuHeight={150}
				name={'ref_lot_states'}
				options={generateOptions(offerEnumsState?.ref_lot_states, 'status')}
				placeholder={t('format.capitalize', {
					text: t('general.form.input.placeholder')
				})}
				onChange={(selected: OptionType) => {
					setInputValueStatus(selected.value as string);
				}}
			/>
			{ inputValueStatus ? (
				<>
					<div className={styles.status_tooltip}>
						<span>{t('page.offers_create_edit.lots.status.tooltip_status')}
							<LabelStatus
								className={styles.label}
								text={inputValueStatus}
							/>
						</span>
						{t('page.offers_create_edit.lots.status.tooltip_date')}
					</div>
					<InputDatePicker
						hasPlaceholder={true}
						label={t('format.capitalize', {
							text: t(`status.${inputValueStatus}`)
						})}
						name={inputValueStatus}
						required={inputValueStatus ? true : false}
						onChange={async (date: ChangeEvent<HTMLInputElement>) => {
							const formattedDate = new Date(date.target.value).toLocaleDateString('en-us');

							const statusName = getStatusName(inputValueStatus);
							if (currentLot.id) {
								const putOfferLotResponse = await putOfferLots(currentOfferId, currentLot.id, {
									...currentLot,
									[statusName]: formattedDate,
								});
								handleErrorResponse(putOfferLotResponse, PutOfferLotsError);
								if (putOfferLotResponse.status === 200) {
									setOfferStateLots({
										...offerStateLots,
										lots: {
											...offerStateLots.lots,
											collection: offerStateLots.lots.collection.map(lot =>
												lot.id === currentLot.id
													? {
														...lot,
														[statusName]: formattedDate,

													}
													: lot
											),
										} as Collection<LotsStateProps, LotsStateProps>,
									});
								}
							} else {
								setOfferStateLots({
									...offerStateLots,
									lots: {
										...offerStateLots?.lots,
										collection: offerStateLots?.lots?.collection?.length
											? [
												...offerStateLots.lots.collection.slice(0, rowId),
												{
													...offerStateLots.lots.collection[rowId] || {
													},
													[statusName]: formattedDate,
												},
												...offerStateLots.lots.collection.slice(rowId + 1)
											]
											: [
												{
													[statusName]: formattedDate,
												}
											],
									} as Collection<LotsStateProps, LotsStateProps>,
								});
							}
						}}
					/>
				</>
			) : undefined
			}
		</>
	);

	const modalStatusMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.header.charges_yearly')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
			>
				{inputStatusElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_status
	];

	if (activeElement === 'status' && selectedRowId === currentLot?.id) cssClasses.push(styles.active);

	const stateDate = inputValueStatus || currentLot.state;
	const statusName = getStatusName(stateDate);

	const statusRowElement = (
		<div className={styles.status_cell}>
			<LabelStatus
				text={stateDate}
			/>
			<div className={styles.small}>
				{t('page.offers_create_edit.lots.status.cell_message', {
					date: new Date(currentLot?.[statusName] + 'Z').toLocaleDateString(currentLanguage, {
						timeZone: 'UTC'
					})
				})}
			</div>
		</div>
	);

	const statusRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`availability_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, currentLot, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_status}
						clickable={true}
						htmlElement={inputStatusElement}
						id={`tooltip_status_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.TOP}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_status_content}>
							{
								currentLot.commercialized_at || currentLot.last_promessed_date || currentLot.last_option_date || currentLot.suspended_at ? statusRowElement : (
									<span className={styles.placeholder}>{t('format.capitalize', {
										text: t('page.offers_create_edit.lots.header.status')
									})}</span>
								)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.lots.header.availability')
						})}</span>
						<span className={styles.mobile_value}>{currentLot?.commercialized_at ? currentLot.commercialized_at : undefined}</span>
					</>
				)}
			</div>
			{modalStatusMobile}
		</>
	);

	return statusRow;
}

export default StatusElement;
