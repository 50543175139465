import * as utils from '@modules/utils';
import FETCHES from '@routes/fetches';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import AddViewedOnAnnouncementError from '@exceptions/AddViewedOnAnnouncementError';
import GetContactsByOfferError from '@exceptions/GetContactsByOfferError';
import GetEventHistoryError from '@exceptions/GetEventHistoryError';
import GetPrivateAnnouncementError from '@exceptions/GetPrivateAnnouncementError';
import GetPrivateDemandGeojsonDotsError from '@exceptions/GetPrivateDemandGeojsonDotsError';
import GetPrivateAnnouncementsError from '@exceptions/GetPrivateAnnouncementsError';
import GetLotsByOfferError from '@exceptions/GetContactsByOfferError';
import {
	BodyRequest,
} from '../../config/demand.data';
import SetApplicationAnnouncementError from '@exceptions/SetApplicationAnnouncementError';
import SetCommercializationStepError from '@exceptions/SetCommercializationStepError';

export const getContacts = async (demandId: number, offerId: number) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer.contacts, {
		':demandid': `${demandId}`,
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetContactsByOfferError(error);
	});
};

export const getLots = async (demandId: number, offerId?: number) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer.lots, {
		':demandid': `${demandId}`,
		':offerid': `${offerId || offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetLotsByOfferError(error);
	});
};

export const getPrivateAnnouncements = async (demandId: number, commercializationStep: string) => {
	return await fetch(utils.getURL(FETCHES.private.demands.step, {
		':demandid': `${demandId}`,
		':step': `${commercializationStep}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetPrivateAnnouncementsError(error);
	});
};

export const getPrivateDemandGeojsonDots = async (demandId: number, commercializationStep: string) => {
	return await fetch(utils.getURL(FETCHES.private.demands.geo_json, {
		':demandid': `${demandId}`,
		':step': `${commercializationStep}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetPrivateDemandGeojsonDotsError(error);
	});
};

export const addViewedOnAnnouncement = async (demandId: number, offerId: number) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer.viewed, {
		':demandid': `${demandId}`,
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: 'PATCH',
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new AddViewedOnAnnouncementError(error);
	});
};

export const getPrivateAnnouncement = async (demandId: number, offerId: number) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer._ROOT, {
		':demandid': `${demandId}`,
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetPrivateAnnouncementError(error);
	});
};

export const getEventHistory = async (demandId: number, offerId: number) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer.event_history, {
		':demandid': `${demandId}`,
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetEventHistoryError(error);
	});
};

export const setApplicationAnnouncement = async (demandId: number, args: BodyRequest) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offer.application, {
		':demandid': `${demandId}`,
		':offerid': `${args.ids[0]}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: 'POST',
		body: JSON.stringify(args),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new SetApplicationAnnouncementError(error);
	});
};

export const setCommercializationStep = async (demandId: number, args: BodyRequest) => {
	return await fetch(utils.getURL(FETCHES.private.demands.offers, {
		':demandid': `${demandId}`
	}), {
		...APP_CONF_VARS.request.default,
		method: 'PATCH',
		body: JSON.stringify(args),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new SetCommercializationStepError(error);
	});
};
