import * as React from 'react';
import {
	lazy,
} from 'react';
import PATHS from '@routes/paths';

// LOGIN STEPS
const StepAuthSMS = lazy(() => import('@layouts/Pages/PageLogin/step-auth-sms'));
const StepLoginRoot = lazy(() => import('@layouts/Pages/PageLogin/step-login'));
const StepPasswordForgot = lazy(() => import('@layouts/Pages/PageLogin/step-password-forgot'));
const StepPasswordEmailSent = lazy(() => import('@layouts/Pages/PageLogin/step-password-email-sent'));
const StepPasswordEdit = lazy(() => import('@layouts/Pages/PageLogin/step-password-edit'));
const StepPasswordSuccess = lazy(() => import('@layouts/Pages/PageLogin/step-password-success'));
const StepUnknownIpAddress = lazy(() => import('@layouts/Pages/PageLogin/step-unknown-ip-address'));

const LOGIN_ROUTES = [
	{
		element: <StepLoginRoot />,
		index: true,
	},
	{
		element: <StepAuthSMS />,
		path: PATHS.LOGIN.SUCCESS_STEP_2FA_SMS,
	},
	{
		element: <StepPasswordEdit />,
		path: PATHS.LOGIN.PASSWORD_EDIT,
	},
	{
		element: <StepPasswordSuccess />,
		path: PATHS.LOGIN.PASSWORD_SUCCESS,
	},
	{
		element: <StepPasswordEmailSent />,
		path: PATHS.LOGIN.PASSWORD_EMAIL_SENT,
	},
	{
		element: <StepPasswordForgot />,
		path: PATHS.LOGIN.PASSWORD_FORGOT,
	},
	{
		element: <StepUnknownIpAddress />,
		path: PATHS.LOGIN.ERROR_AUTH_IP,
	},
];

export default LOGIN_ROUTES;
