// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M0d9RD_O{height:calc(100vh - 4.57142857rem);padding-bottom:6.42857143rem}@media screen and (min-width: 1025Px){.M0d9RD_O{height:calc(100vh - 4.57142857rem)}}.aeb57kxL{height:100%}.RdjW3CJx{background-color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5);display:none;height:100%;left:0;opacity:0;padding:10%;position:fixed;top:0;transition:opacity .3s ease-in-out;width:100%;z-index:-99}@media screen and (min-width: 768Px){.RdjW3CJx{display:flex}}@media screen and (min-width: 1025Px){.RdjW3CJx{display:none}}.IVcvxjA7 .VDSdbLi_{left:0;min-width:15rem}@media screen and (min-width: 768Px){.IVcvxjA7 .VDSdbLi_{min-width:inherit;padding-top:1.42857143rem}}.IVcvxjA7 .VDSdbLi_ .M0d9RD_O{overflow:auto}@media screen and (min-width: 768Px)and (orientation: landscape){.IVcvxjA7 .VDSdbLi_ .M0d9RD_O{margin-top:0}}.VDSdbLi_{background-color:var(--color-background-default);box-shadow:-0.07142857rem -0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3),0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3);font-size:0.85714286rem;height:calc(100vh - 4.57142857rem);position:relative;width:100%}@media screen and (min-width: 768Px){.VDSdbLi_{padding-top:2.85714286rem}}@media screen and (min-width: 1025Px){.VDSdbLi_{height:inherit;left:inherit;width:auto}}.H0ok03y4{align-items:flex-start;display:none;justify-content:flex-end;padding:0 0 1.07142857rem 1.07142857rem;position:absolute;right:0;top:0}@media screen and (min-width: 1025Px){.H0ok03y4{cursor:pointer;display:flex}}.HSFrgDkQ{align-items:center;background-color:var(--color-background-primary);border-radius:0 0 0 0.28571429rem;color:var(--color-white);display:flex;font-size:0.71428571rem;height:1.42857143rem;justify-content:center;padding:0.35714286rem;width:1.42857143rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `M0d9RD_O`,
	"nav": `aeb57kxL`,
	"overlay": `RdjW3CJx`,
	"open": `IVcvxjA7`,
	"panel": `VDSdbLi_`,
	"toggle": `H0ok03y4`,
	"toggle__icon": `HSFrgDkQ`
};
export default ___CSS_LOADER_EXPORT___;
