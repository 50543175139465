import React, {
	ChangeEvent,
	MouseEvent,
	useEffect,
	useState,
	useRef,
	useCallback,
	SetStateAction,
	Dispatch,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumInputSize,
} from '@enums/form.enum';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// MODULES
import dataUtils, {
	FilterMappingInterface,
} from '@modules/data-utils';
import debounce from '@modules/debounce';

// COMPONENTS
import Button from '@components/button';
import InputSelect, {
	HTMLSelectElementCustom,
} from '@components/form/input-select';
import InputSearch from '@components/form/input-search';
import {
	ColumnFilters,
} from '@components/table';

// STYLES
import styles from './filter.module.scss';
import {
	EnumSteps,
} from '@enums/steps.enum';

interface FilterProps {
	filterValueMappings: FilterMappingInterface[];
	globalFilter: string;
	setColumnFilters: Dispatch<SetStateAction<ColumnFilters>>;
	setGlobalFilter: Dispatch<SetStateAction<string>>;
	setIsOpenFilterModal?: Dispatch<SetStateAction<boolean>>;
	isDesktopResolution: boolean;
	getOptionsFilters: (commercializationStep: EnumSteps) => object[];
	commercializationStep: EnumSteps;
}

const Filter = ({
	filterValueMappings,
	globalFilter,
	setColumnFilters,
	setGlobalFilter,
	setIsOpenFilterModal,
	isDesktopResolution,
	getOptionsFilters,
	commercializationStep
}: FilterProps) => {
	const { t } = useTranslation();
	const [
		showInputFilter,
		setShowInputFilter
	] = useState(false);

	const handleSelectedFilters = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		const selectValues = (document.querySelector('.columnFilters select') as HTMLSelectElementCustom).values;
		const filterValues = dataUtils.getFiltersForSelectedValues(filterValueMappings, selectValues);

		setColumnFilters(filterValues);
	};

	const selectRef = useRef(null);

	const filtersElement = isDesktopResolution ? (
		<div
			className={styles.select_action}
			ref={selectRef}
		>
			<InputSelect
				className={'columnFilters'}
				hasShadow={false}
				isMultiSelect={true}
				options={getOptionsFilters(commercializationStep)}
				placeholder={t('format.capitalize', {
					text: t('general.action.filter')
				})}
				size={EnumInputSize.SMALL}
				style={{
					top: 'calc(100% + 6px)',
					right: '0'
				}}
				onClickOption={handleSelectedFilters}
			/>
		</div>
	) : (
		<div
			className={styles.select_action}
			ref={selectRef}
		>
			<Button
				hasBoxShadow={false}
				iconLeft='filters'
				iconStyle={EnumFontStyle.LIGHT}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
				onClick={(event: MouseEvent<HTMLElement>) => {
					event.preventDefault();
					setIsOpenFilterModal(true);
				}}
			/>
		</div>
	);

	const handleChangeGlobalFilter = (event: ChangeEvent<HTMLInputElement>) => {
		setGlobalFilter(event.target.value);
	};

	const debouncedChangeHandler = useCallback(
		debounce(handleChangeGlobalFilter,APP_CONF_VARS.timeout.debounce), [
		]
	);

	const toggleFilter = () => {
		setShowInputFilter(!showInputFilter);
	};

	const handleClickOutside = () => {
		if (isDesktopResolution) {
			if (globalFilter.length === 0) {
				setTimeout(() => {
					setShowInputFilter(false);
				}, 300);
			}
		} else {
			if (globalFilter.length === 0) {
				setShowInputFilter(false);
			}
		}
	};

	useEffect(() => {
		setColumnFilters([
		]);
		setShowInputFilter(false);
		setGlobalFilter('');
	}, [
		commercializationStep
	]);

	const iconGlobalFilter = (
		<Button
			className={styles.icon_search_action}
			hasBoxShadow={false}
			iconLeft='search'
			iconStyle={EnumFontStyle.LIGHT}
			size={EnumButtonSize.SMALL}
			theme={EnumTheme.NAKED}
			onClick={toggleFilter}
		/>
	);

	const inputGlobalFilter = (
		<div
			className={styles.search_action}
		>
			<InputSearch
				autoFocus={true}
				className={`${isDesktopResolution ? showInputFilter ? 'animation__rtl' : 'animation__rtl__reverse' : ''}`}
				defaultValue={globalFilter}
				name='globalFilter'
				size={EnumInputSize.SMALL}
				onBlur={handleClickOutside}
				onChange={debouncedChangeHandler}
			/>
		</div>
	);

	return (
		<div className={styles.filter}>
			{showInputFilter ? inputGlobalFilter : iconGlobalFilter}
			{filtersElement}
		</div>
	);
};

export default Filter;
