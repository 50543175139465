// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RQGAzF3R{display:flex}.cPWCUPBQ{left:0;margin-right:0.57142857rem;padding-right:0.57142857rem;position:absolute;width:max-content}@media screen and (min-width: 768Px)and (orientation: landscape){.cPWCUPBQ{border:none;left:inherit;position:relative}}.Tgs3cRnV{left:0;overflow:hidden;pointer-events:auto;position:absolute;width:calc(100% - 1rem);z-index:2}@media screen and (min-width: 768Px)and (orientation: landscape){.Tgs3cRnV{left:inherit;margin-right:1.14285714rem;padding-left:0.35714286rem;position:relative;width:max-content}}.Zb5_aE35{border-left:0.07142857rem solid var(--color-border-default);border-right:0.07142857rem solid var(--color-border-default);padding:0 0.57142857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.Zb5_aE35{border:none;padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `RQGAzF3R`,
	"icon_search_action": `cPWCUPBQ`,
	"search_action": `Tgs3cRnV`,
	"select_action": `Zb5_aE35`
};
export default ___CSS_LOADER_EXPORT___;
