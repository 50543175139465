import * as React from 'react';
import {
	Dispatch,
	SetStateAction,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// TYPES
import {
	Media,
} from '@@types/Media';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
} from '@stores/_slices/toast_msgs';

// EXECPTIONS
import DeleteOfferMediaError from '@exceptions/DeleteOfferMediaError';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumMediaType,
} from '@enums/media.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// LAYOUTS
import {
	MediaOfferProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	deleteOfferMedia,
	getOfferMedia,
} from '@layouts/Pages/PageOfferEntry/config/fetch';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';

// STYLES
import styles from './modal-delete-media.module.scss';

interface ModalDeleteMediaProps {
	mediaOfferState: MediaOfferProps;
	mediaState: Media;
	mediaType: EnumMediaType;
	offerId: number;
	selectedMediaIds: number[];
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setMediaOfferState?: Dispatch<SetStateAction<MediaOfferProps>>;
	setSelectedMediaIds?: Dispatch<SetStateAction<number[]>>;
}

const ModalDeleteMedia = ({
	mediaOfferState,
	mediaState,
	mediaType,
	offerId,
	selectedMediaIds,
	setIsOpenModal,
	setMediaOfferState,
	setSelectedMediaIds,
}: ModalDeleteMediaProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	let mediaIds = [
	] as number[
	];

	if (selectedMediaIds.length > 0) {
		mediaIds = selectedMediaIds;
	} else if (mediaState?.id) {
		mediaIds = [
			mediaState?.id
		];
	}

	const handleOnErrorCallback = () => {
		dispatch(addStatusMsg({
			message: t('format.capitalize', {
				text: t(`modals.delete_media.error_message${mediaIds.length > 1 ? '_other' : ''}`, {
					'%type%': t(`type_file.${mediaType}${mediaIds.length > 1 ? '_other' : ''}`),
					context: mediaType === EnumMediaType.PLAN ? 'male' : 'female',
				})
			}),
			isClosable: true,
			buttons: [
				{
					label: t('format.capitalize', {
						text: t('general.action.contact'),
					}),
					href: 'serviceclient',
				},
			],
			theme: EnumStatusTheme.ERROR,
		}));
	};

	return (
		<Form
			onError={(responseParsed) => {
				// Manage error message
				if (responseParsed.statusText) {
					dispatch(addStatusMsg({
						message: t([
							`status.${responseParsed.statusText}`,
							'status.default'
						]),
						isClosable: true,
						buttons: [
							{
								label: t('format.capitalize', {
									text: t('general.action.contact'),
								}),
								href: 'serviceclient',
							},
						],
						theme: EnumStatusTheme.ERROR,
					}));
				}
			}}
			onSuccess={async () => {
				const deleteOfferMediaResponse = await deleteOfferMedia(offerId, mediaIds);
				handleErrorResponse(deleteOfferMediaResponse, DeleteOfferMediaError, handleOnErrorCallback);
				if (deleteOfferMediaResponse.status === 200) {
					const getOfferMediaResponse = await getOfferMedia(offerId);
					if (getOfferMediaResponse.status === 200) {
						setMediaOfferState(
							{
								...mediaOfferState,
								is_loaded: true,
								media: getOfferMediaResponse.payload,
							}
						);
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t(`status.deleted_element_success_message${mediaIds.length > 1 ? '_other' : ''}`, {
									'%type%': t(`type_file.${mediaType}${mediaIds.length > 1 ? '_other' : ''}`),
									context: mediaType === EnumMediaType.PLAN ? 'male' : 'female',
								})
							}),
							theme: EnumStatusTheme.SUCCESS,
						}));
					}
					setSelectedMediaIds([
					]);
					setIsOpenModal(false);
				}
			}}
		>
			<div className={styles.delete_media}>
				<h2 className={styles.title}>
					{t('format.capitalize', {
						text: t(`modals.delete_media.title${mediaIds.length > 1 ? '_other' : ''}`, {
							'%type%': t(`type_file.${mediaType}${mediaIds.length > 1 ? '_other' : ''}`),
							context: mediaType === EnumMediaType.PLAN ? 'male' : 'female',
						})
					})}
				</h2>
				<div className={styles.introduction}>
					{t('format.capitalize', {
						text: t(`modals.delete_media.introduction${mediaIds.length > 1 ? '_other' : ''}`, {
							'%type%': t(`type_file.${mediaType}${mediaIds.length > 1 ? '_other' : ''}`),
							context: mediaType === EnumMediaType.PLAN ? 'male' : 'female',
						})
					})}
				</div>
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={() => {
							setIsOpenModal(false);
						}}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</Form>
	);
};

export default withTranslation()(ModalDeleteMedia);
