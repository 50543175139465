import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';

// TYPE
import {
	Demand,
	DemandJsonPrivate,
} from '@@types/index';

// ENUMS
import {
	EnumInputTheme,
} from '@enums/form.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// MODULES
import * as utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// COMPONENTS
import Button from '@components/button';
import InputCheckbox, {
	InputCheckboxProps,
} from '@components/form/input-checkbox';
import LabelStatus from '@components/label-status';
import Paragraph from '@components/paragraph';

// STYLES
import styles from './internal-information.module.scss';

interface InternalInformationReportProps {
	demandGlobalState: DemandJsonPrivate;
	canEdit: boolean;
	setIsOpenModal: Dispatch<SetStateAction<boolean>>;
}

const InternalInformationReport = ({
	demandGlobalState,
	canEdit,
	setIsOpenModal
}: InternalInformationReportProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const demand = new Demand(demandGlobalState);
	const titleInternalInformation = t('format.capitalize', {
		text: t('general.internal_information.title')
	});

	const canEditMatchDemand = demand.state === 'active';
	const toogleElement = (
		<InputCheckbox
			checked={demand?.settings?.notifications?.enable_new_matching}
			className={styles.toggle}
			disabled={canEditMatchDemand ? false : true}
			id='alert_boker'
			label={t('format.capitalize', {
				text: t('general.internal_information.toggle_notifier_label')
			})}
			name='alert_boker'
			theme={EnumInputTheme.TOGGLE}
			onChange={(event, state: InputCheckboxProps) => {
				dispatch(clearToastMsgs());
				fetch(utils.getURL(FETCHES.private.demands.alert_broker, {
					':demandid': `${demand.id}`,
				}), {
					...APP_CONF_VARS.request.default,
					method: state.checked ? 'PUT' : 'DELETE',
				}).then((resp) => {
					if (resp.status === 200) {
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${state.checked ? 'notifications_enabled' : 'notifications_disabled'}`
								]),
							}),
						}));
						return resp.json();
					} else {
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${resp.statusText}`,
									'status.default'
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
					}
				}).catch(err => {
					console.warn('ERROR : ', err);
				});
			}}
		/>
	);

	const internalInformationElement = (
		<ul className={styles.text_list}>
			{demand?.state ? (
				<li className={styles.element}>
					<span className={styles.text_list__label}>{t('format.capitalize', {
						text: t('general.internal_information.demand_state')
					})} :</span>
					<span className={styles.text_list__value}>
						<LabelStatus
							text={demand.state}
						/>
						{canEdit ? (
							<Button
								className={styles.edit}
								hasBoxShadow={false}
								iconLeft='edit'
								theme={EnumTheme.NAKED}
								onClick={(event: MouseEvent<HTMLElement>) => {
									event.preventDefault();
									setIsOpenModal(true);
								}}
							/>
						) : null}
					</span>
				</li>
			) : null}
			{demand.created_infos ? (
				<li className={styles.element}>
					<span className={styles.text_list__label}>{t('format.capitalize', {
						text: t('general.internal_information.created_infos')
					})} :</span>
					<span className={styles.text_list__value}>
						{t('format.capitalize', {
							text: demand.created_infos.date + ' ' + t('general.by', {
								'%name%': demand.created_infos.user?.full_name
							})
						})}
					</span>
				</li>
			) : null}
			{demand?.updated_infos ? (
				<li className={styles.element}>
					<span className={styles.text_list__label}>{t('format.capitalize', {
						text: t('general.internal_information.updated_infos')
					})} :</span>
					<span className={styles.text_list__value}>
						{t('format.capitalize', {
							text: demand.updated_infos.date + ' ' + t('general.by', {
								'%name%': demand.updated_infos.user?.full_name
							})
						})}
					</span>
				</li>
			) : null}

			{demand?.sources?.length ? (
				<li className={styles.element}>
					<span className={styles.text_list__label}>{t('format.capitalize', {
						text: t('general.internal_information.sources_demand')
					})} :</span>
					<span className={styles.text_list__value}>{
						demand.sources.map((source) => {
							return t('format.capitalize', {
								text: t(`general.sources.${source}`)
							});
						}).join(' - ')}</span>
				</li>
			) : null}
		</ul>
	);

	const paragrapheElement = (
		<Paragraph
			className={styles.internal_information}
			hasBorder={true}
			title={titleInternalInformation}
			titleLevel={2}
		>
			{internalInformationElement}
			{toogleElement}
		</Paragraph>
	);

	return paragrapheElement;
};

export default InternalInformationReport;
