// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h_FXXDyh{font-size:2rem;margin-right:0.71428571rem}.cRyR4u1I{display:inline-flex;flex-direction:column}.dxUbJS7W{color:var(--color-boulder);font-size:0.78571429rem}.aRmCF_sM{background-color:var(--color-background-default);border-radius:0 0 0.28571429rem 0.28571429rem;border-top:0;font-size:0.78571429rem;padding:1rem 0}@media screen and (min-width: 768Px)and (orientation: landscape){.aRmCF_sM{font-size:1rem}}.IApOnwz8 .AEqrbxBU{align-items:center;display:flex;flex-wrap:nowrap;padding:0.57142857rem 1.14285714rem}.IApOnwz8 .AEqrbxBU:hover{background-color:var(--color-background-quaternary)}.aRmCF_sM.lCWlusxg{border:1px solid var(--color-border-default)}.aRmCF_sM.lCWlusxg:after{background-color:var(--color-border-autosuggest);content:"";height:1Px;left:50%;position:absolute;top:0;transform:translateX(-50%);width:calc(100% - 1.14285714rem);z-index:4}.aRmCF_sM.DxkvanSo{box-shadow:-0.07142857rem -0.57142857rem 0.71428571rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.3),0.07142857rem 0.57142857rem 0.71428571rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3)}.I9gs8TTE{align-items:flex-start;display:flex;font-size:1rem;padding:1.07142857rem 1.57142857rem}.SwQzse8Y{-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:1;line-height:1.1em;min-height:1em;overflow:hidden;text-overflow:ellipsis}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemIcon": `h_FXXDyh`,
	"itemText": `cRyR4u1I`,
	"itemType": `dxUbJS7W`,
	"suggestion_list": `aRmCF_sM`,
	"suggestion_list__item": `IApOnwz8`,
	"link": `AEqrbxBU`,
	"border": `lCWlusxg`,
	"shadow": `DxkvanSo`,
	"paragraph": `I9gs8TTE`,
	"ellipsis": `SwQzse8Y`
};
export default ___CSS_LOADER_EXPORT___;
