import React, {
	ChangeEvent,
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// COMPONENTS
import InputText, {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import Icon from '@components/icon';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import styles from './input-search.module.scss';

export interface InputSearchProps extends InputTextProps {
	defaultValue?: string;
}

const InputSearch = ({
	'data-testid': dataTestid,
	className,
	defaultValue,
	disabled = false,
	hasBorder = true,
	hasShadow = false,
	onChange,
	size,
	...otherProps
}: InputSearchProps): JSX.Element => {
	const classes = [
		styles.search
	];
	if (className) classes.push(className);

	const initialState: InputSearchProps = {
		...otherProps,
		disabled,
		value: defaultValue as string,
		isIconRightToggled: true,
	};

	initialState.isIconRightToggled = defaultValue ? defaultValue.length > 0 : false;

	const [
		state,
		setState,
	] = useState(initialState);

	// Manage click on icon to clear input value
	const handleOnClickClearSearch = (event: MouseEvent<HTMLInputElement>) => {
		const icon = event.currentTarget;
		const input = icon.closest('div').parentElement.querySelector('input');
		input.value = null;
		const newState: InputSearchProps = {
			...state,
			iconRight: null,
			value: null,
		};
		setState(newState);
		if (onChange) onChange({
			target:input
		} as ChangeEvent<HTMLInputElement>, newState);
	};

	const iconLeftElement = (
		<Icon
			fontStyle={EnumFontStyle.LIGHT}
			name={'search'}
		/>
	);

	const iconRightElement = (
		<Icon
			className={styles.icon}
			data-testid={`${dataTestid}-icon-right`}
			fontStyle={EnumFontStyle.LIGHT}
			name={'times'}
			onClick={handleOnClickClearSearch}
		/>
	);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, actualState: InputSearchProps) => {
		const target = event.currentTarget;
		const newState: InputSearchProps = {
			...state,
			...actualState,
			iconRight: target.value.length ? iconRightElement : null,
		};
		setState(newState);
		if (onChange) onChange(event, newState);
	};

	return (
		<InputText
			{...otherProps}
			className={classes.join(' ')}
			data-testid={`${dataTestid}-search`}
			defaultValue={defaultValue as string}
			disabled={disabled}
			hasBorder={hasBorder}
			hasShadow={hasShadow}
			iconLeft={iconLeftElement}
			iconRight={state.value?.length ? iconRightElement : undefined}
			size={size}
			type={EnumInputType.TEXT}
			onChange={handleOnChange}
		/>
	);
};

InputSearch.displayName = EnumComponentType.INPUT_SEARCH;

export default InputSearch;

