import {
} from 'react';

// TYPES
import {
	Building,
} from '@@types/Building';
import {
	Collection,
	DocumentTemplatesProps,
} from '@@types/Collection';
import {
	Contact, ContactJson,
} from '@@types/Contact';
import {
	DateVisit,
} from '@@types/Offer';
import {
	Image, ImageJson,
} from '@@types/Image';

export interface OnClickActionProps {
	name?: string;
	ids?: number[];
	state?: string;
	states?: string[];
	contacts?: Collection<ContactJson, Contact>;
	building?: Building;
	images?: Collection<ImageJson, Image>;
	visit?: DateVisit;
}
export interface BodyRequest {
	address?: string;
	building?: Building;
	brokers?: ContactJson[];
	commercialization_step?: string;
	contacts?: Collection<ContactJson, Contact>;
	demandId?: number;
	document_templates?: DocumentTemplatesProps[];
	ids?: number[];
	images?: string;
	name?: string;
	state?: string;
	states?: string[];
	visit?: DateVisit;
}

export const getDatesOffer = [
	{
		dates: [
			'created_at',
			'updated_at'
		],
		name: 'matched',
	},
	// PROPOSED
	{
		dates: [
			'proposed_at',
			'updated_at',
		],
		name: 'proposed',
	},
	// STEP CANDIDATE
	{
		dates: [
			'applied_at',
		],
		name: 'applied',
	},
	{
		dates: [
			'proposed_at',
			'refused_at'
		],
		name: 'refused',
	},
	{
		dates: [
			'ignored_at',
			'updated_at'
		],
		name: 'ignored',
	},
];
