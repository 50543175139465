const defaultRights = [
	'create',
	'read',
	'update',
	'delete',
];

const commercializationRights = [
	'create_my_offer',
	'mls_packages',
	'commercialization_module',
];

const mlsRights = [
	'mls_packages',
];

const commercialisationModuleRights = [
	'commercialization_module',
];

const managementRights = [
	'read_lease',
	'create_asset',
	'read_asset',
	'read_site'
];

const businessToolsRights = [
	...commercializationRights,
	...managementRights,
];

const allRights = [
	...defaultRights,
	...managementRights,
	...commercializationRights,
];

export {
	allRights,
	businessToolsRights,
	commercializationRights,
	commercialisationModuleRights,
	mlsRights,
	defaultRights,
	managementRights,
};
