import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	matchRoutes,
	useLocation,
	useOutletContext,
	useParams,
} from 'react-router-dom';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// CONFIG
import {
	routesMapping,
} from '@layouts/Pages/PageOfferEntry/config/offer_entry.config';

// MODULES
import {
	getURL,
} from '@modules/utils';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// TYPES
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';

// ROUTES
import {
	OFFER_ENTRY_LOCATION_CREATE_ROUTES,
} from '@routes/lpdipro/private/offer-entry';
import PATHS from '@routes/paths';

// LAYOUTS
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Icon from '@components/icon';
import Link from '@components/link';
import Sticky from '@components/sticky';
import TabList from '@components/tab-list';
import Tab from '@components/tab-list/tab';
import Text from '@components/text';

// STYLES
import styles from './header.module.scss';

interface HeaderProps {
	isValid: boolean;
}

const Header = ({isValid}: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const navIsOpen = useSelector((state: ReducerInstance) => state.app.instance.navIsOpen);

	const location = useLocation();

	const { offerid } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : undefined;

	const {
		contentEntryOfferRef,
		dirtySteps,
		isDesktopResolution,
		offerState,
	} : PageOfferContextProps = useOutletContext();

	const titleElement = (
		<div className={styles.title}>
			<h1>
				{t('format.capitalize', {
					text: offerState?.is_published ? t('page.offers_create_edit.title') : t('page.offers_create_edit.title_default')
				})}
			</h1>
			{offerState?.id && (
				<Text
					className={styles.ref}
					theme={EnumStatusTheme.SUCCESS}
				>
					{t('format.capitalize', {
						text: t('general.ref', {
							'%ref%': offerState?.id
						})
					})}
				</Text>
			)}
		</div>
	);

	const tabListElement = (
		<TabList>
			{routesMapping.map((mapping, key) => {
				const stepKey = key - 1 >= 0 ? key - 1 : 0;

				const isSuccessfulStep = offerState?.steps && offerState.steps[mapping.step] && !dirtySteps[mapping.step];

				const urlRoute = matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length ? PATHS.OFFERS.NEW : PATHS.OFFERS.EDIT[`${mapping.step}`.toUpperCase()];
				const isLocationStep = mapping.step === 'location' && matchRoutes(OFFER_ENTRY_LOCATION_CREATE_ROUTES, location.pathname)?.length;

				return (
					<Tab
						className={styles.step}
						disabled={offerState?.is_published || isLocationStep || dirtySteps[routesMapping[stepKey].step] || offerState?.steps && offerState.steps[routesMapping[stepKey].step] ? false : true}
						isActive={matchRoutes(mapping.route, location.pathname)?.length || isLocationStep ? true : false}
						isSuccessful={isSuccessfulStep ? true : false}
						key={key}
					>
						<Link
							href={getURL(urlRoute, {
								':offerid': `${currentOfferId}`,
								':mediatype': 'photos',
							})}
							theme={EnumThemeLink.TAB}
						>
							{t('format.capitalize', {
								text: t(`page.offers_create_edit.steps.${mapping.step}`)
							})}
							<Icon
								fontStyle={isSuccessfulStep ? EnumFontStyle.SOLID : EnumFontStyle.REGULAR}
								name={isSuccessfulStep ? 'check-circle' : 'circle'}
							/>
						</Link>
					</Tab>
				);
			})}
		</TabList>
	);

	const [
		headerCssClasses,
		setHeaderCssClasses
	] = useState([
		styles.header
	]);

	const [
		isSticky,
		setIsSticky
	] = useState(false);

	const [
		stickyCssClasses,
		setStickyCssClasses
	] = useState([
		styles.filter__sticky
	]);

	useEffect(() => {
		const baseFilterClasses = [
			styles.filter__sticky
		];
		const baseHeaderClasses = [
			styles.header
		];
		if (navIsOpen && isDesktopResolution) {
			baseHeaderClasses.push(styles.header__navisopen);
		}
		if (isSticky && navIsOpen && isDesktopResolution) {
			baseFilterClasses.push(styles.filter__sticky__navisopen);
		}
		setHeaderCssClasses(baseHeaderClasses);
		setStickyCssClasses(baseFilterClasses);
	}, [
		navIsOpen,
		isSticky
	]);

	const headerStickyElement = (
		<>
			{tabListElement}
			<ValidationForm
				isHeaderValidationForm={true}
				isValid={isValid}
			/>
		</>
	);

	const stickyContainerRef = useRef(null);

	return (
		<>
			<div className={headerCssClasses.join(' ')}>
				{titleElement}
				<div
					className={styles.filter}
					ref={stickyContainerRef}
				>
					{headerStickyElement}
				</div>
				<Sticky
					className={stickyCssClasses.join(' ')}
					isDesktopResolution={true}
					isSticky={isSticky}
					scrollContainerRef={isDesktopResolution ? contentEntryOfferRef : undefined}
					setIsSticky={setIsSticky}
					stickyContainerRef={stickyContainerRef}
				>
					{headerStickyElement}
				</Sticky>
			</div>
		</>
	);
};

export default withTranslation()(Header);
