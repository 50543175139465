// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SDc7R1tu{margin-bottom:2.28571429rem}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu{margin-bottom:2.85714286rem}}.SDc7R1tu .lLcr6JW5{display:flex;flex-wrap:wrap;justify-content:space-between}.SDc7R1tu .lLcr6JW5>li{display:flex;flex-direction:column;margin-bottom:0.71428571rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu .lLcr6JW5>li{align-items:flex-start;flex-direction:row;line-height:2rem;margin-bottom:0}}.SDc7R1tu .B3e4UQ5z{font-weight:600}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu .B3e4UQ5z{margin-right:1.42857143rem;width:14.28571429rem}}.SDc7R1tu .oTvZETjE:first-letter{text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu .oTvZETjE{width:calc(100% - 14.28571429rem - 1.42857143rem)}}.SDc7R1tu .oTvZETjE .lLcr6JW5>li{align-items:flex-start;display:flex;flex-direction:row;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu .oTvZETjE .oTvZETjE{width:auto}}.SDc7R1tu .oTvZETjE .B3e4UQ5z{font-weight:inherit}@media screen and (min-width: 768Px)and (orientation: landscape){.SDc7R1tu .oTvZETjE .B3e4UQ5z{margin-right:0;width:auto}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_criterias": `SDc7R1tu`,
	"text_list": `lLcr6JW5`,
	"text_list__label": `B3e4UQ5z`,
	"text_list__value": `oTvZETjE`
};
export default ___CSS_LOADER_EXPORT___;
