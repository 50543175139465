// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ehI35jbv{align-items:center;background-color:var(--color-background-default);flex:1;height:100%;justify-content:center;opacity:.5;width:100%}.foik3r0f{font-size:5.71428571rem}.foik3r0f i{color:var(--color-primary);opacity:.6}.foik3r0f i:before{border:8px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.3);border-radius:50%;content:"";display:block;height:5.71428571rem;position:absolute;width:5.71428571rem}.BRaRAGCB{margin-top:1.42857143rem}.Du688qEc{align-items:center;display:flex;flex-direction:column;font-weight:400;height:100%;justify-content:center;opacity:.8;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loader": `ehI35jbv`,
	"icon": `foik3r0f`,
	"message": `BRaRAGCB`,
	"wrapper": `Du688qEc`
};
export default ___CSS_LOADER_EXPORT___;
