// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p5fWpKOn{font-size:2rem;margin-right:0.71428571rem}.LMWZMDFE{display:inline-flex;flex-direction:column}.kTgDP97V{color:var(--color-boulder);font-size:0.78571429rem}._dOfDAx7{background-color:var(--color-background-default);border-radius:0 0 0.28571429rem 0.28571429rem;border-top:0;font-size:0.78571429rem;padding:1rem 0}@media screen and (min-width: 768Px)and (orientation: landscape){._dOfDAx7{font-size:1rem}}.pPMytPBk .PcclM49t{align-items:center;display:flex;flex-wrap:nowrap;padding:0.57142857rem 1.14285714rem}.pPMytPBk .PcclM49t:hover{background-color:var(--color-background-quaternary)}._dOfDAx7.Pt4uXWJi{border:1px solid var(--color-border-default)}._dOfDAx7.Pt4uXWJi:after{background-color:var(--color-border-autosuggest);content:"";height:1Px;left:50%;position:absolute;top:0;transform:translateX(-50%);width:calc(100% - 1.14285714rem);z-index:4}._dOfDAx7.RhVRT9UC{box-shadow:-0.07142857rem -0.57142857rem 0.71428571rem 0 hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), 0.3),0.07142857rem 0.57142857rem 0.71428571rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.3)}.zkglhwEj{align-items:flex-start;display:flex;font-size:1rem;padding:1.07142857rem 1.57142857rem}.pwWSxbYa{-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:1;line-height:1.1em;min-height:1em;overflow:hidden;text-overflow:ellipsis}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemIcon": `p5fWpKOn`,
	"itemText": `LMWZMDFE`,
	"itemType": `kTgDP97V`,
	"suggestion_list": `_dOfDAx7`,
	"suggestion_list__item": `pPMytPBk`,
	"link": `PcclM49t`,
	"border": `Pt4uXWJi`,
	"shadow": `RhVRT9UC`,
	"paragraph": `zkglhwEj`,
	"ellipsis": `pwWSxbYa`
};
export default ___CSS_LOADER_EXPORT___;
