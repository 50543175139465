import React from 'react';
import {
	useOutletContext,
} from 'react-router-dom';

// UTILS
import {
	handleOnChange,
	handleOnChangeToggle,
} from '../config/utils';

// LIB
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import InputText from '@components/form/input-text';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import InputToggleButton from '@components/form/input-toggle-button';
import Textarea from '@components/form/textarea';
import Icon from '@components/icon';
import ToggleButton from '@components/toggle-button-list/toggle-button';

// ENUMS
import {
	EnumToggleButton,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// LAYOUTS
import {
	OfferEnumsStateProps,
	OfferStateLotsProps,
	OfferStateProps,
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';

// STYLES
import styles from './lots.module.scss';

interface LotsInputsTotalProps {
	offerState: OfferStateProps;
	offerStateLots: OfferStateLotsProps;
	offerEnumsState: OfferEnumsStateProps;
}

const LotsInputsParking = ({
	offerState,
	offerEnumsState
}: LotsInputsTotalProps) => {
	const { t } = useTranslation();
	const {
		setDirtySteps,
		setOfferState,
	}: PageOfferContextProps = useOutletContext();

	const handleToggleDivisibleChange = (selectedIds: string[]) => {
		handleOnChangeToggle('is_divisible', selectedIds, 'lots', setDirtySteps, setOfferState);

		if (selectedIds[0] === 'false') {
			setDirtySteps(prevState => ({
				...prevState,
				lots: false,
			}));
			setOfferState(prevState => ({
				...prevState,
				divisible_area_min: null
			}));
		}
	};

	return (
		<div className={styles.section__left}>
			{/* --------- Is the lot divisible? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.lots.divisible_area_min.title')
				})}
			</div>
			<InputToggleButton
				activeButtonValues={[
					offerState?.is_divisible?.toString()
				]}
				className={styles.toggle}
				isLockedSelection={true}
				isMultiselect={false}
				name="is_divisible"
				theme={EnumToggleButton.DEFAULT}
				onChange={handleToggleDivisibleChange}
			>
				{offerEnumsState?.is_divisible?.map((value, index) => (
					<ToggleButton
						className={styles.toggle__button}
						id={`${value}`}
						key={index}
					>
						{offerState.is_divisible === value ? (
							<Icon
								className={styles.toggle__icon}
								name='check'
							/>
						) : undefined}
						{t('format.capitalize', {
							text: t(`general.${value ? 'yes' : 'no'}`)
						})}
					</ToggleButton>
				))}
			</InputToggleButton>
			{offerState?.is_divisible && (
				<InputTextWithUnit
					className={styles.input_single}
					defaultValue={offerState?.divisible_area_min}
					label={t('page.offers_create_edit.lots.divisible_area_min.label')}
					name="divisible_area_min"
					type={EnumInputType.NUMBER}
					unit={t('page.offers_create_edit.lots.divisible_area_min.unit')}
					onChange={(event) => {
						handleOnChange(event, 'lots', setDirtySteps, setOfferState);
					}}
				/>
			)}
			{/* --------- Is the lot parking available? --------- */}
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.lots.parking.parking_available.title')
				})}
			</div>
			<InputToggleButton
				activeButtonValues={[
					offerState?.parking_available?.toString()
				]}
				className={styles.toggle}
				isLockedSelection={true}
				isMultiselect={false}
				name="parking_available"
				theme={EnumToggleButton.DEFAULT}
				onChange={(selectedIds) => {
					handleOnChangeToggle('parking_available', selectedIds, 'lots', setDirtySteps, setOfferState);
					if (selectedIds[0] === 'false') {
						setDirtySteps(prevState => ({
							...prevState,
							lots: false,
						}));
						setOfferState(prevState => ({
							...prevState,
							has_indoor_parking: false,
							has_outdoor_parking: false,
							parking_indoor_place_nb: null,
							parking_outdoor_place_nb: null,
							parking_indoor_rental: null,
							parking_outdoor_rental_price: null,
							parking_indoor_sale_price: null,
							parking_outdoor_sale: null,
						}));
					}
				}}
			>
				{offerEnumsState?.parking_available?.map((value, index) => (
					<ToggleButton
						className={styles.toggle__button}

						id={`${value}`}
						key={index}
					>
						{offerState.parking_available === value ? (
							<Icon
								className={styles.toggle__icon}
								name='check'
							/>
						) : undefined}
						{t('format.capitalize', {
							text: t(`general.${value ? 'yes' : 'no'}`)
						})}
					</ToggleButton>
				))}
			</InputToggleButton>
			{/* --------- Is the lot has indoor parking? --------- */}
			{offerState?.parking_available && (
				<>
					<div className={styles.title}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.parking.has_indoor_parking.title')
						})}
					</div>
					<InputToggleButton
						activeButtonValues={[
							offerState?.has_indoor_parking?.toString()
						]}
						className={styles.toggle}
						isLockedSelection={true}
						isMultiselect={false}
						name="has_indoor_parking"
						theme={EnumToggleButton.DEFAULT}
						onChange={(selectedIds) => {
							handleOnChangeToggle('has_indoor_parking', selectedIds, 'lots', setDirtySteps, setOfferState);
							if (selectedIds[0] === 'false') {
								setDirtySteps(prevState => ({
									...prevState,
									lots: false,
								}));
								setOfferState(prevState => ({
									...prevState,
									parking_indoor_place_nb: null,
									parking_indoor_rental: null,
									parking_indoor_sale_price: null,
								}));
							}
						}}
					>
						{offerEnumsState?.has_indoor_parking?.map((value, index) => (
							<ToggleButton
								className={styles.toggle__button}
								id={`${value}`}
								key={index}
							>
								{offerState.has_indoor_parking === value ? (
									<Icon
										className={styles.toggle__icon}
										name='check'
									/>
								) : undefined}
								{t('format.capitalize', {
									text: t(`general.${value ? 'yes' : 'no'}`)
								})}
							</ToggleButton>
						))}
					</InputToggleButton>
					{offerState?.has_indoor_parking && (
						<>
							<div className={styles.form_row_split}>
								<InputText
									className={styles.input}
									defaultValue={offerState?.parking_indoor_place_nb}
									label={t('page.offers_create_edit.lots.parking.has_indoor_parking.place_nb.label')}
									name="parking_indoor_place_nb"
									type={EnumInputType.NUMBER}
									onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
								/>
							</div>
							<div className={styles.form_row_split}>
								<InputTextWithUnit
									className={styles.input}
									defaultValue={offerState?.parking_indoor_rental}
									label={t('page.offers_create_edit.lots.parking.has_indoor_parking.rental.label')}
									name="parking_indoor_rental"
									type={EnumInputType.NUMBER}
									unit={t('page.offers_create_edit.lots.parking.has_indoor_parking.rental.unit')}
									onChange={(event) => {
										handleOnChange(event, 'lots', setDirtySteps, setOfferState);
									}}
								/>
								<InputTextWithUnit
									className={styles.input}
									defaultValue={offerState?.parking_indoor_sale_price}
									label={t('page.offers_create_edit.lots.parking.has_indoor_parking.sale_price.label')}
									name="parking_indoor_sale_price"
									type={EnumInputType.NUMBER}
									unit={t('page.offers_create_edit.lots.parking.has_indoor_parking.sale_price.unit')}
									onChange={(event) => {
										handleOnChange(event, 'lots', setDirtySteps, setOfferState);
									}}
								/>
							</div>
						</>
					)}
					{/* --------- Is the lot has outdoor parking? --------- */}
					<div className={styles.title}>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.lots.parking.has_outdoor_parking.title')
						})}
					</div>
					<InputToggleButton
						activeButtonValues={[
							offerState?.has_outdoor_parking?.toString()
						]}
						className={styles.toggle}
						isLockedSelection={true}
						isMultiselect={false}
						name="has_outdoor_parking"
						theme={EnumToggleButton.DEFAULT}
						onChange={(selectedIds) => {
							handleOnChangeToggle('has_outdoor_parking', selectedIds, 'lots', setDirtySteps, setOfferState);
							if (selectedIds[0] === 'false') {
								setDirtySteps(prevState => ({
									...prevState,
									lots: false,
								}));
								setOfferState(prevState => ({
									...prevState,
									parking_outdoor_place_nb: null,
									parking_outdoor_rental_price: null,
									parking_outdoor_sale: null,
								}));
							}
						}}
					>
						{offerEnumsState?.has_outdoor_parking?.map((value, index) => (
							<ToggleButton
								className={styles.toggle__button}
								id={`${value}`}
								key={index}
							>
								{offerState.has_outdoor_parking === value ? (
									<Icon
										className={styles.toggle__icon}
										name='check'
									/>
								) : undefined}
								{t('format.capitalize', {
									text: t(`general.${value ? 'yes' : 'no'}`)
								})}
							</ToggleButton>
						))}
					</InputToggleButton>
					{offerState?.has_outdoor_parking && (
						<>
							<div className={styles.form_row_split}>
								<InputText
									defaultValue={offerState?.parking_outdoor_place_nb}
									label={t('page.offers_create_edit.lots.parking.has_outdoor_parking.place_nb.label')}
									name="parking_outdoor_place_nb"
									type={EnumInputType.NUMBER}
									onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
								/>
							</div>
							<div className={styles.form_row_split}>
								<InputTextWithUnit
									defaultValue={offerState?.parking_outdoor_rental_price}
									label={t('page.offers_create_edit.lots.parking.has_outdoor_parking.rental_price.label')}
									name="parking_outdoor_rental_price"
									type={EnumInputType.NUMBER}
									unit={t('page.offers_create_edit.lots.parking.has_outdoor_parking.rental_price.unit')}
									onChange={(event) => {
										handleOnChange(event, 'lots', setDirtySteps, setOfferState);
									}}
								/>
								<InputTextWithUnit
									defaultValue={offerState?.parking_outdoor_sale}
									label={t('page.offers_create_edit.lots.parking.has_outdoor_parking.sale.label')}
									name="parking_outdoor_sale"
									type={EnumInputType.NUMBER}
									unit={t('page.offers_create_edit.lots.parking.has_outdoor_parking.sale.unit')}
									onChange={(event) => {
										handleOnChange(event, 'lots', setDirtySteps, setOfferState);
									}}
								/>
							</div>
							<div className={styles.form_row}>
								<Textarea
									className={styles.value}
									defaultValue={offerState?.parking_comment}
									label={t('page.offers_create_edit.lots.parking.has_outdoor_parking.comment.label')}
									name="parking_comment"
									placeholder={t('page.offers_create_edit.lots.parking.has_outdoor_parking.comment.placeholder')}
									onChange={(event) => handleOnChange(event, 'lots', setDirtySteps, setOfferState)}
								/>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default LotsInputsParking;
