import {
	Children,
	cloneElement,
	FC,
	isValidElement,
	ReactNode,
} from 'react';
import {
	EnumComponentType,
} from '@enums/component.enum';

export const componentUtils = {
	cloneChildrenElement(children: ReactNode, type: EnumComponentType | EnumComponentType[], cssChild?: string, customProps?: Record<string, unknown>) {
		return Children.map(children, (child) => {
			if (isValidElement(child) && typeof child.type !== 'string') {
				const displayName = ((child.type as FC)?.displayName || undefined) as EnumComponentType;
				if ((Array.isArray(type) && type.includes(displayName)) || (displayName && displayName === type)) {
					const cssClasses = [
					];
					if (cssChild) cssClasses.push(cssChild);
					if (child.props.className) cssClasses.push(child.props.className);

					return cloneElement(child, {
						...child.props,
						...customProps,
						className: cssClasses.join(' '),
					});
				}
			}
		});
	},
};
