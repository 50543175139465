import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// EXCEPTIONS
import PutOfferContactError from '@exceptions/PutOfferContactError';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	putOfferContact,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	handleOnClickFocus,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	ElementProps,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputDatePicker from '@components/form/input-date-picker';
import InputText from '@components/form/input-text';
import Icon from '@components/icon';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../contact-row.module.scss';

function DateElement({
	activeElement,
	currentOfferId,
	isDesktopResolution,
	onClickRow,
	offerContact,
	offerStateContacts,
	rowId,
	selectedRowId,
	setActiveElement,
	setOfferStateContacts,
}: ElementProps) {
	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const inputRef = useRef<HTMLTextAreaElement>(null);
	const formRef = useRef(null);

	const handleOnErrorCallback = () => {
		dispatch(addStatusMsg({
			message: t('format.capitalize', {
				text: t('status.updated_element_error_message', {
					'%type%': 'contact',
				}),
			}),
			isClosable: true,
			buttons: [
				{
					label: t('format.capitalize', {
						text: t('general.action.contact'),
					}),
					href: 'serviceclient',
				},
			],
			theme: EnumStatusTheme.ERROR,
		}));
	};

	const formatTimeForInput = (dateString: string): string => {
		if (!dateString) return '';
		const date = new Date(dateString);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	};

	const inputDateElement = (
		<>
			<InputDatePicker
				className={styles.input_date_picker}
				defaultValue={offerContact?.original_mail_date ? new Date(offerContact.original_mail_date) : undefined}
				id={`date_${rowId}`}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.date.date.label')
				})}
				name={`date_${rowId}`}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.date.date.placeholder')
				})}
				value={offerContact?.original_mail_date}
				onChange={async (date: ChangeEvent<HTMLInputElement>) => {
					const newDate = new Date(date.target.value);
					if (offerContact.original_mail_date) {
						const actualDate = new Date(offerContact.original_mail_date);
						const hours = actualDate.getHours();
						const minutes = actualDate.getMinutes();
						newDate.setHours(hours, minutes, 0, 0);
					}
					const formattedDate = newDate.toLocaleString('en-us');
					const putOfferContactResponse = await putOfferContact(currentOfferId, offerContact.id, {
						original_mail_date: formattedDate,
						contact_id: offerContact.contact_id,
					});
					handleErrorResponse(putOfferContactResponse, PutOfferContactError, handleOnErrorCallback);
					if (putOfferContactResponse.status === 200) {
						setOfferStateContacts({
							...offerStateContacts,
							contacts: {
								...offerStateContacts?.contacts,
								collection: offerStateContacts?.contacts?.collection.map(contact =>
									contact.id === offerContact.id
										? {
											...contact,
											original_mail_date: formattedDate
										}
										: contact
								),
							} as Collection<ContactOfferJson, ContactOffer>,
						});
					}
				}
				}
			/>
			<InputText
				defaultValue={offerContact?.original_mail_date ? formatTimeForInput(offerContact.original_mail_date) : undefined}
				iconRight={(
					<Icon
						fontStyle={EnumFontStyle.LIGHT}
						name='clock'
					/>
				)}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.date.time.label')
				})}
				name={`time_${rowId}`}
				required={false}
				type={EnumInputType.TIME}
				onChange={async (time: ChangeEvent<HTMLInputElement>) => {
					const newTime = time.target.value;
					const [
						hours,
						minutes
					] = newTime.split(':').map(Number);
					const currentDateTime = offerContact.original_mail_date ? new Date(offerContact.original_mail_date) : new Date();
					currentDateTime.setHours(hours, minutes, 0, 0);
					const formattedDate = currentDateTime.toLocaleString('en-us');
					const putOfferContactsResponse = await putOfferContact(currentOfferId, offerContact.id, {
						original_mail_date: formattedDate,
						contact_id: offerContact.contact_id,
					});
					handleErrorResponse(putOfferContactsResponse, PutOfferContactError, handleOnErrorCallback);
					if (putOfferContactsResponse.status === 200) {
						setOfferStateContacts({
							...offerStateContacts,
							contacts: {
								...offerStateContacts?.contacts,
								collection: offerStateContacts?.contacts?.collection.map(contact =>
									contact.id === offerContact.id
										? {
											...contact,
											original_mail_date: formattedDate
										}	: contact
								),
							} as Collection<ContactOfferJson, ContactOffer>,
						});
					}
				}}
			/>
		</>
	);

	const modalCommentMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.header_table.date')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputDateElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const formatDate = (dateString: string): string => {
		if (!dateString) return '';
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear().toString().slice(-2);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${day}/${month}/${year}\nà ${hours}:${minutes}`;
	};

	const cssClasses = [
		styles.cell_date
	];

	if (activeElement === 'date' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);
	if (!offerContact?.id) cssClasses.push(styles.not_allowed);

	const dateRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`date_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, offerContact, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_date}
						clickable={true}
						disabled={!offerContact?.id}
						htmlElement={inputDateElement}
						id={`tooltip_date_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_date_content}>
							{offerContact?.original_mail_date ? formatDate(offerContact.original_mail_date) : (
								<span className={styles.placeholder}>{t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.date.placeholder')
								})}
								</span>
							)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.header_table.date')
						})}</span>
						<span className={styles.mobile_value}>{offerContact?.original_mail_date ? formatDate(offerContact.original_mail_date) : undefined}</span>
					</>
				)}
			</div>
			{modalCommentMobile}
		</>
	);
	return dateRow;
}

export default DateElement;
