import * as React from 'react';
import {
	ReactNode,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';

//COMPONENT
import Icon from '@components/icon';

//STYLES
import styles from './loader.module.scss';

interface LoaderProps {
	className?: string;
	message?: ReactNode;
	'data-testid'?: string;
	'data-theme-mode'?: string;
}

const Loader = ({
	className,
	message,
	'data-testid': dataTestid,
	'data-theme-mode': dataThemeMode,
}: LoaderProps): JSX.Element => {
	const { t } = useTranslation();
	const cssClasses = [
		styles.Loader
	];
	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			data-theme-mode={dataThemeMode}
		>
			<div className={styles.wrapper}>
				<div className={styles.icon}><Icon
					animation="spin"
					fontStyle={EnumFontStyle.LIGHT}
					name="spinner-third"
				/></div>
				<div className={styles.message}>
					<>{message ? message : t('format.capitalize', {
						text: t('general.loading')
					})}</>
				</div>
			</div>
		</div >
	);
};

export {
	Loader as default,
	LoaderProps,
};
