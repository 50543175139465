import i18n from '@modules/i18n';

const validationRules = [
	{
		'label': i18n.t('general.form.input.type.password.validation_rules.at_least_eight_characters.label'),
		'rule': i18n.t('general.form.input.type.password.validation_rules.at_least_eight_characters.rule')
	},
	{
		'label': i18n.t('general.form.input.type.password.validation_rules.at_least_one_upper_and_one_lower_case.label'),
		'rule': i18n.t('general.form.input.type.password.validation_rules.at_least_one_upper_and_one_lower_case.rule')
	},
	{
		'label': i18n.t('general.form.input.type.password.validation_rules.at_least_one_digit.label'),
		'rule': i18n.t('general.form.input.type.password.validation_rules.at_least_one_digit.rule')
	},
	{
		'label': i18n.t('general.form.input.type.password.validation_rules.at_least_one_special_character.label'),
		'rule': i18n.t('general.form.input.type.password.validation_rules.at_least_one_special_character.rule')
	}
];

export default validationRules;
