import * as React from 'react';
import {
	lazy,
} from 'react';
import PATHS from '@routes/paths';

// LAZY LOADING
const PageTestApi = lazy(() => import('@layouts/Pages/PageTests/api'));

const TEST_ROUTES = [
	{
		element: <PageTestApi />,
		path: PATHS.TESTS.API,
	},
];

export default TEST_ROUTES;
