import React from 'react';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import InputText, {
	InputTextProps,
} from '@components/form/input-text';

// STYLING
import styles from './input-text-with-unit.module.scss';

interface InputTextWithUnitProps extends Omit<InputTextProps, 'iconRight'> {
	unit: string;
}

const InputTextWithUnit = ({
	className,
	'data-testid': dataTestId,
	disabled,
	unit,
	...otherProps
}: InputTextWithUnitProps): JSX.Element => {

	const classes = [
		styles.input_with_unit,
	];

	if (disabled) classes.push(styles.disabled);
	if (className) classes.push(className);

	const unitElement = (
		<span className={styles.unit}>{
			unit
		}</span>
	);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestId}
		>
			<InputText
				{...otherProps}
				disabled={disabled}
			/>
			{unitElement}
		</div>
	);
};

InputTextWithUnit.displayName = EnumComponentType.INPUT_TEXT_WITH_UNIT;

export {
	InputTextWithUnit as default,
	InputTextWithUnitProps,
};
