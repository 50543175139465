// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v3Z7I2id{display:flex;flex-direction:column;font-family:raleway,montserrat,verdana,sans-serif;line-height:1;margin-left:0.35714286rem;-webkit-user-select:none;user-select:none;white-space:nowrap}.o_nDcVm5{font-size:1.42857143rem;font-weight:600;letter-spacing:0.03642857rem}@media screen and (min-width: 768Px)and (orientation: landscape){.o_nDcVm5{font-size:1.71428571rem;letter-spacing:0.04357143rem}}.epvH4Z9_{font-size:0.71428571rem;font-weight:400;letter-spacing:0.14rem}@media screen and (min-width: 768Px)and (orientation: landscape){.epvH4Z9_{font-size:0.85714286rem;letter-spacing:0.16785714rem}}.TPhg5mez{position:relative}.l7TohiVN{align-items:center;display:inline-flex;position:relative}.l7TohiVN svg{max-width:2.57142857rem;width:36Px}.VfXfzKvI{height:auto;left:0;position:absolute;top:-0.07142857rem;transform:rotate(-30deg) translateX(-50%);width:1.14285714rem}@media screen and (min-width: 768Px){.VfXfzKvI{top:-0.14285714rem;width:2rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandname": `v3Z7I2id`,
	"brandname__top": `o_nDcVm5`,
	"brandname__bottom": `epvH4Z9_`,
	"letter": `TPhg5mez`,
	"logo": `l7TohiVN`,
	"santaHat": `VfXfzKvI`
};
export default ___CSS_LOADER_EXPORT___;
