// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pbP8Ntbm{display:flex;position:relative}.pbP8Ntbm .RJtMwZWL{opacity:0;pointer-events:none;position:absolute}.pbP8Ntbm.YIM2K7SA{border:1Px solid var(--color-status-error);border-radius:7.14285714rem;padding:0.14285714rem;width:max-content}.pbP8Ntbm .jPNQjfyP{color:var(--color-status-error);font-size:0.85714286rem;position:absolute;text-align:left;top:100%;width:max-content}.pbP8Ntbm .jPNQjfyP input,.pbP8Ntbm .jPNQjfyP i{color:var(--color-status-error)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_toggle_button": `pbP8Ntbm`,
	"checkbox": `RJtMwZWL`,
	"invalid": `YIM2K7SA`,
	"error_message": `jPNQjfyP`
};
export default ___CSS_LOADER_EXPORT___;
