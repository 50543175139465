import React, {
	Children,
	cloneElement,
	ReactElement,
} from 'react';
import {
	ToggleButtonProps,
} from '@components/toggle-button-list/toggle-button';

import styles from './toggle-button-list.module.scss';
import {
	EnumToggleButton,
} from '@enums/button.enum';

interface ToggleButtonListProps extends ToggleButtonProps {
	activeButtonValues?: string[];
	children?: ReactElement<ToggleButtonProps> | ReactElement<ToggleButtonProps>[];
}

const ToggleButtonList = ({
	activeButtonValues = [
	],
	children,
	'data-testid': dataTestid,
	onClick,
	readOnly,
	theme = EnumToggleButton.DEFAULT,
}: ToggleButtonListProps): JSX.Element => {

	const cssClasses = [
		styles.toggle_button_list
	];

	const clonedChildren = Children.map(children, (child) => {
		return cloneElement(child, {
			isActive: activeButtonValues.includes(child.props.id),
			onClick: () => onClick(child.props.id),
			theme: theme,
			readOnly: readOnly
		});
	});

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{clonedChildren}
		</div>
	);
};

export { ToggleButtonList as default, ToggleButtonListProps, };
