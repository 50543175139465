import * as React from 'react';
import {
	useRef,
	Ref,
} from 'react';

// Import the Input component and other necessary types/enums
import Input, {
	InputProps,
} from '@components/form/input';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

const InputHidden = ({
	className,
	'data-testid': dataTestid,
	defaultValue,
	innerRef,
	name,
	methods,
	...otherProps
}: InputProps): JSX.Element => {
	const hookRef = useRef<HTMLInputElement>(null);
	const localRef = innerRef || hookRef;

	if (methods && defaultValue && name) {
		methods.setValue(name, defaultValue);
	}

	return (
		<Input
			{...otherProps}
			className={className}
			data-testid={dataTestid}
			innerRef={localRef as Ref<HTMLInputElement>}
			methods={methods}
			name={name}
			type={EnumInputType.HIDDEN}
		/>
	);
};

InputHidden.displayName = EnumComponentType.INPUT_HIDDEN;

export default InputHidden;
