import * as React from 'react';
import {
	Dispatch,
	FormEvent,
	Fragment,
	MouseEvent,
	SetStateAction,
	useState,
	useEffect,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// MODULES
import dataUtils, {
	FilterMappingInterface,
} from '@modules/data-utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';

// COMPONENTS
import Button from '@components/button';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import InputCheckbox from '@components/form/input-checkbox';

// LAYOUTS
import {
	filterValueMappings,
	getOptionsFilters,
} from '@layouts/Pages/PageDemand/manage/config/filterValueMappings';

// STYLES
import styles from './ModalDemandFiltersMobile.module.scss';

interface ModalDemandFiltersMobileProps {
    columnFilters?: Array<{
		id: string;
		value: string;
	}>;
    commercializationStep?: EnumSteps;
    setColumnFilters?: Dispatch<SetStateAction<FilterMappingInterface[]>>;
    setIsOpenFilterModal? : Dispatch<SetStateAction<boolean>>;
}

function ModalDemandFiltersMobile({
	columnFilters,
	commercializationStep,
	setColumnFilters,
	setIsOpenFilterModal,
}: ModalDemandFiltersMobileProps) {
	const { t } = useTranslation();
	const [
		selectedValues,
		setSelectedValues
	] = useState<string[]>([
	]);

	useEffect(() => {
		const updatedValues = columnFilters.flatMap(filter => filter.value.split(','));
		setSelectedValues(updatedValues);
	}, [
		columnFilters
	]);

	const handleOnClickResetFilters = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setColumnFilters([
		]);
		setSelectedValues([
		]);
	};

	const handleOnSubmitFilters = (event: FormEvent<HTMLFormElement>) => {
		event.stopPropagation();
		const target = event.target as HTMLElement;

		const selectValues = Array.from(target.closest('div').querySelectorAll('input')).filter((input: HTMLInputElement) => input.checked).map((input: HTMLInputElement) => input.value);

		const filterValues = dataUtils.getFiltersForSelectedValues(filterValueMappings, selectValues);

		setColumnFilters(filterValues);
		setIsOpenFilterModal(false);
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		setSelectedValues(prev =>
			checked ? [
				...prev,
				value
			] : prev.filter(val => val !== value)
		);
	};

	return (
		<div className={styles.demand_filter}>
			<form
				onSubmit={handleOnSubmitFilters}
			>
				{getOptionsFilters(commercializationStep).map((optionfilter: DropdownItemProps, key) => {
					const checked = selectedValues.includes(optionfilter.value);

					return optionfilter.isDisplayed === false ? <Fragment key={key}></Fragment> : (
						<InputCheckbox
							checked={checked}
							className={optionfilter.separator ? styles.separator : styles.checkbox }
							id={`filter_id_${key}`}
							key={key}
							label={t('format.capitalize', {
								text: t(`${optionfilter.label}`)
							})}
							name={`filter_name_${key}`}
							value={optionfilter.value}
							onChange={handleCheckboxChange}
						/>
					);
				})}

				<div className={styles.footer_buttons}>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.reset')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickResetFilters}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</form>
		</div>
	);
}

export default withTranslation()(ModalDemandFiltersMobile);
