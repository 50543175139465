import React, {
	FC,
	MouseEvent,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';
import Link from '@components/link';
import Text from '@components/text';

import styles from './suggestion.module.scss';

export interface SuggestionProps {
	'data-testid'?: string;
	className?: string;
    id?: string;
	link?: string;
	onClick?: (event: MouseEvent<HTMLElement>, suggestion: SuggestionProps) => void;
    icon?: string;
    text?: string;
    type?: string;
}

const Suggestion: FC<SuggestionProps> = ({
	'data-testid': dataTestid,
	className,
	icon,
	link,
	onClick,
	text,
	type,
}) => {
	const { t } = useTranslation();

	const classes = [
		styles.suggestion_list__item
	];

	if (className) classes.push(className);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			<Link
				className={styles.link}
				data-testid={`${dataTestid}-link`}
				fontWeight={EnumFontStyle.REGULAR}
				href={link}
				target={EnumTargetLink.BLANK}
				theme={EnumThemeLink.NAKED}
				onClick={(event) => {
					if (onClick) {
						onClick(event, {
							icon: icon,
							link: link,
							text: text,
							type: type
						});
					}
				}}
			>
				<Icon
					className={styles.itemIcon}
					fontStyle={EnumFontStyle.LIGHT}
					name={icon}
				/>
				<Text className={styles.itemText}>
					<span className={styles.itemType}>{t(`general.form.input.type.search.${type}`)}</span>
					<span
						className={styles.ellipsis}
						dangerouslySetInnerHTML={{
							__html: text
						}}
					/>
				</Text>
			</Link>
		</div>
	);
};

Suggestion.displayName = 'Suggestion';

export default Suggestion;
