// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g8KXaao_{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.g8KXaao_{padding:1.42857143rem}}.g4kYQKjF{display:flex;flex-direction:column;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.g4kYQKjF{flex-shrink:0;width:40.71428571rem}}.YYkEPIaE{display:flex;justify-content:center;width:100%}.DyDyVKLB{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.eLYe44sz{margin-bottom:1.42857143rem;font-size:0.85714286rem}.OUOGnysP{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.OUOGnysP:first-letter{display:block;text-transform:uppercase}.PRuJZbUc{margin-bottom:1.42857143rem;font-size:1.28571429rem;font-weight:600;line-height:1.6em}.PRuJZbUc:first-letter{display:block;text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.PRuJZbUc{font-size:1.42857143rem}}.yNUh6ho7{padding-top:2.14285714rem;width:auto}.LdmsHEOb{width:14.28571429rem}._VysiM8T{position:relative}.Mpm7ql7V{left:1.07142857rem;position:absolute}.FFtW8jd9{width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.FFtW8jd9{width:50%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `g8KXaao_`,
	"section__left": `g4kYQKjF`,
	"section__right": `YYkEPIaE`,
	"step_progress": `DyDyVKLB`,
	"legend": `eLYe44sz`,
	"title": `OUOGnysP`,
	"title__section": `PRuJZbUc`,
	"image": `yNUh6ho7`,
	"toggle": `LdmsHEOb`,
	"toggle__button": `_VysiM8T`,
	"toggle__icon": `Mpm7ql7V`,
	"input": `FFtW8jd9`
};
export default ___CSS_LOADER_EXPORT___;
