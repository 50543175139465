import * as React from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';
import {
	updateImageUrls,
} from '@modules/images';

// COMPONENTS
import Paragraph from '@components/paragraph';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// TYPES
import {
	Contact,
	Recipient,
} from '@@types/index';

interface CompanyReportProps {
	recipient: Recipient;
	establishmentId: number;
	editUrl: string;
}

// STYLES
import styles from './Company.module.scss';

const CompanyReport = ({
	recipient,
	establishmentId,
	editUrl
}: CompanyReportProps) => {

	const { t } = useTranslation();

	const titleCompany = t('format.capitalize', {
		text: t('page.demand.private.tabs.report.company_identity')
	});

	const lease = recipient?.company?.lease?.end_date ? t('format.capitalize', {
		text: t('general.internal_information.lease_expiring', {
			text: recipient?.company?.lease?.end_date
		})
	}) : null;

	let price = null;

	if (recipient?.company?.lease?.rental_price?.amount) {
		price = t('format.price', {
			val: recipient?.company?.lease?.rental_price?.amount
		});
	}

	const rentalPriceFormated = recipient?.company?.lease?.rental_price?.unit ? t(`format.per.${recipient?.company?.lease?.rental_price?.unit}`, {
		text: price
	}) : price;

	const rent = recipient?.company?.lease?.rental_price?.amount ? t('format.capitalize', {
		text: t('general.internal_information.rent', {
			text: rentalPriceFormated
		})
	}) : null;

	const dataCompany = {
		title: recipient?.company?.name,
		image_url : updateImageUrls(recipient?.company?.logo_squared?.url, EnumImageFormatQuery.SQUARE) as string || undefined,
		descriptions: [
			{
				value: recipient?.company?.activity || null,
				type: 'string'
			},
			{
				value: recipient?.company?.address?.full || null,
				type: 'string'
			},
			{
				value: lease || null,
				type: 'string'
			},
			{
				value: rent || null,
				type: 'string'
			},
			{
				value: t('format.lowercase', {
					text: recipient?.company?.website_url?.replace(/^http(s)?:\/\//i, '') || '',
				}),
				url: recipient?.company?.website_url || null,
				icon: 'globe',
				type: 'link'
			},
			{
				value: recipient?.company?.phone || null,
				type: 'tel'
			},
			{
				value: establishmentId ? t('format.capitalize', {
					text: t('general.internal_information.establishment_sheet')
				}) : null,
				url: `${APP_CONF_VARS.sfLegacyPath}/establishment/detail/?id=${establishmentId}`,
				icon: 'file',
				type: 'link'
			},
		],
	};

	const itemsContact = [
	] as JSX.Element[];
	recipient.contacts.collection.forEach((contact: Contact, index: number) => {
		const dataContact = {
			title: contact?.full_name,
			descriptions: [
				{
					value: contact?.job || null,
					type: 'string'
				},
				{
					value: contact?.phone || contact?.mobile || null,
					type: 'tel'
				},
				{
					value: contact?.email || null,
					type: 'email',
				}
			],
		};
		const itemContact = (
			<UserCard
				className={styles.user_card_contact}
				data={dataContact}
				key={index}
				theme={ThemeUserCard.ROUND}
			/>
		);
		itemsContact.push(itemContact);
	});

	const companyElement = (
		<Paragraph
			className={styles.company}
			editUrl={editUrl}
			hasBorder={true}
			title={titleCompany}
			titleLevel={2}
		>
			<UserCard
				data={dataCompany}
				theme={ThemeUserCard.SQUARE}
			/>
			{recipient?.contacts?.collection?.length > 0 ? itemsContact : null}
		</Paragraph>
	);

	return companyElement;
};

export default CompanyReport;
