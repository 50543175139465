// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BTpHNDb7{align-items:flex-start;display:flex;flex-direction:column;width:100%}.aJNEt84c{display:flex;flex:1;flex-direction:column;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.aJNEt84c{display:grid;grid-template-columns:repeat(2, 1fr)}}.BEL8TEjd{padding:0.85714286rem 0}.BEL8TEjd i{font-size:1.42857143rem;margin-left:0;margin-right:0.71428571rem}.BTpHNDb7 .ilnuYi5X{padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module_card": `BTpHNDb7`,
	"module_card_row": `aJNEt84c`,
	"module_card_link": `BEL8TEjd`,
	"title": `ilnuYi5X`
};
export default ___CSS_LOADER_EXPORT___;
