
import React, {
	Suspense,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	Outlet,
} from 'react-router-dom';
import PATHS from '@routes/paths';
import eventEmitter from '@modules/eventEmitter';
import EVENTS from '@static_data/events.data';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import Button, {
} from '@components/button';
import Header from '@components/header';
import Link from '@components/link';
import Loader from '@components/loader';
import Logo from '@components/logo';

// STYLES
import styles from './PageAppPublic.module.scss';

function PageAppPublic() {
	return (
		<Suspense fallback={<Loader />}>
			<div className={styles.PageAppPublic}>
				<Header className={styles.header}>
					<div className={`${styles.region}`}>
						<span className={`${styles.item} ${styles.logo}`}>
							<Link
								href={PATHS._ROOT}
								theme={EnumThemeLink.NAKED}
							>
								<Logo
									full={true}
								/>
							</Link>
						</span>
					</div>
					<div className={`${styles.region} ${styles.region__right}`}>
						<span className={`${styles.item}`}>
							<Button
								hasBoxShadow={false}
								iconLeft='question-circle'
								iconStyle={EnumFontStyle.LIGHT}
								theme={EnumTheme.NAKED}
								onClick={/* istanbul ignore next */() => {
									// Event custom dispatched to be used with GTM
									eventEmitter.dispatch(EVENTS.USER_HELP_OPEN_CLICK);
								}}
							/>
						</span>
					</div>
				</Header>
				<Outlet />
			</div>
		</Suspense>
	);
}
export default withTranslation()(PageAppPublic);
