// Source : https://stackoverflow.com/a/61127960/6995445
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	DependencyList,
	useRef,
	useEffect,
	useCallback,
} from 'react';

export const getRemainingTime = (lastTriggeredTime: number, delay: number) => {
	const elapsedTime = Date.now() - lastTriggeredTime;
	const remainingTime = delay - elapsedTime;

	return (remainingTime < 0) ? 0 : remainingTime;
};

const useThrottledEffect = (
	effect: () => void,
	deps: DependencyList,
	delay: number
) => {
	const lastTriggered = useRef(Date.now());
	const timeoutRef = useRef(null);
	const delayFormated = delay < 0 ? APP_CONF_VARS.timeout.debounce : delay;

	const cancel = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
	}, [
	]);

	useEffect(() => {
		let remainingTime = getRemainingTime(lastTriggered.current, delayFormated);
		if (!timeoutRef.current) {
			timeoutRef.current = setTimeout(() => {
				remainingTime = getRemainingTime(lastTriggered.current, delayFormated);
				lastTriggered.current = Date.now();
				effect();
				cancel();
			}, remainingTime);
		}
	}, [
		...deps,
		delayFormated
	]);

	useEffect(() => cancel, [
		cancel
	]);
};

export default useThrottledEffect;

// USAGE
// useThrottledEffect(() => {
// 	myCallback(value);
// }, [
// 	value
// ], delay);
