// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PMmEVyjw{opacity:.3;width:100%}.PMmEVyjw rect{width:100%}.PYlC7WGD{display:flex;flex-direction:column}@media screen and (min-width: 768Px)and (orientation: landscape){.PYlC7WGD{flex-direction:row}}.PYlC7WGD button{flex:1;margin-bottom:0 1.07142857rem 0 0}@media screen and (min-width: 768Px)and (orientation: landscape){.PYlC7WGD button{margin:0}.PYlC7WGD button:first-child{margin-right:0.35714286rem}.PYlC7WGD button:last-child{margin-left:0.35714286rem}}.X3Bo55PT{color:currentcolor;text-align:center}.X3Bo55PT a{display:block;text-align:center}.X3Bo55PT form{margin-bottom:2.71428571rem}.zJ6hho3h{margin-top:2.85714286rem;padding:0 1.14285714rem;text-align:center}@media screen and (min-width: 768Px)and (orientation: landscape){.zJ6hho3h{padding:0}}.MT8vacUM{margin-bottom:2.85714286rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.MT8vacUM{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `PMmEVyjw`,
	"button_group": `PYlC7WGD`,
	"step": `X3Bo55PT`,
	"main": `zJ6hho3h`,
	"statusMsgWrapper": `MT8vacUM`
};
export default ___CSS_LOADER_EXPORT___;
