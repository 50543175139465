import * as React from 'react';
import {
	useEffect,
	useRef,
} from 'react';
import {
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import {
	useTranslation,
} from 'react-i18next';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	addToastMsg,
} from '@stores/_slices/toast_msgs';

// SLICES
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// DATA
import {
	commercialisationModuleRights,
} from '@static_data/permissions-rights.data';

// ROUTES
import PATHS from '@routes/paths';

// MODULES
import Permissions from '@modules/permissions';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import WebViewer, {
	WebViewerInstance,
} from '@pdftron/webviewer';

// STYLES
import styles from './export_pdf.module.scss';

function PageExportPDF() {
	const userData = useSelector((state: ReducerInstance) => state.user.instance);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const viewer = useRef(null);
	const [
		searchParams,
	] = useSearchParams();

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;

			const modulePermissions = new Permissions({
				payload: userData?.modules
			});

			if (modulePermissions.can([
				...commercialisationModuleRights
			], 'commercialization')) {

				const callView = async () => {
					return await WebViewer(
						{
							path: APP_CONF_VARS.sfPathApryse,
							initialDoc: `${searchParams.get('url')}`,
							licenseKey:  process.env.APRYSE_LICENCE_KEY,
							fullAPI: true
						},
						viewer.current
					);
				};
				callView()
					.then((instance: WebViewerInstance) => {
						const { UI } = instance;
						const {
							enableFeatures,
							Feature,
							setToolbarGroup,
							Events: UIEvents,
							addEventListener: UIaddEventListener,
						} = UI;
						enableFeatures([
							Feature.ContentEdit
						]);
						setToolbarGroup(instance.UI.ToolbarGroup.EDIT);
						UIaddEventListener(UIEvents.LOAD_ERROR, () => {
							dispatch(addToastMsg({
								message: t('format.capitalize', {
									text: t('status.loading_pdf_error'),
								}),
								theme: EnumStatusTheme.ERROR,
							}));
							navigate(PATHS.HOMEPAGE);
						});
					}).catch((error) => {
						throw error;
					});
			} else {
				dispatch(addToastMsg({
					message: t('format.capitalize', {
						text: t('status.unauthorized_access'),
					}),
					theme: EnumStatusTheme.ERROR,
				}));
				navigate(PATHS.HOMEPAGE);
			}
		}
	}, [
	]);

	return (
		<div
			className={styles.page_export}
			ref={viewer}
		></div>
	);
}

export default PageExportPDF;
