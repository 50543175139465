// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RHM9a30U{display:flex;flex-direction:column;height:100%;margin-left:auto;margin-right:auto;padding:1.42857143rem 1.14285714rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.RHM9a30U{flex-direction:row;padding:0}}@media screen and (min-width: 768Px)and (orientation: landscape){.F4BJpWK3{flex-direction:row;padding:0 0 1.14285714rem}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manage": `RHM9a30U`,
	"manage__empty": `F4BJpWK3`
};
export default ___CSS_LOADER_EXPORT___;
