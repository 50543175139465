import * as React from 'react';
import {
	lazy,
} from 'react';
import {
	Navigate,
} from 'react-router-dom';
import PATHS from '@routes/paths';
import LOGIN_ROUTES from '@routes/lpdipro/public/login';

// LAZY LOADING
const PageAppPublic = lazy(() => import('@layouts/Pages/PageAppPublic'));
const PageLogin = lazy(() => import('@layouts/Pages/PageLogin'));
const PageError = lazy(() => import('@layouts/Pages/PageError'));

const PUBLIC_ROUTES = [
	{
		element: <PageLogin />,
		path: PATHS.LOGIN._ROOT,
		children: LOGIN_ROUTES,
	},
];

const PUBLIC_ROUTES_REDIRECT = [
	{
		element: <Navigate to={PATHS.LOGIN._ROOT} />,
		path: '*',
	},
	{
		element: <PageError />,
		path: PATHS.ERROR._ROOT,
	},
];

const PUBLIC_ROUTES_GLOBAL = [
	{
		element: <PageAppPublic />,
		children: [
			...PUBLIC_ROUTES,
			...PUBLIC_ROUTES_REDIRECT,
		],
	},
];

export {
	PUBLIC_ROUTES_GLOBAL as default,
	PUBLIC_ROUTES,
};
