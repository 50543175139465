import React, {
	ChangeEvent,
	memo,
	MouseEvent,
	useEffect,
	useState,
} from 'react';

// TYPES
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import {
	Media,
} from '@@types/Media';
import LabelStatus, {
	LabelStatusProps,
} from '@components/label-status';
import {
	EnumMediaType,
} from '@enums/media.enum';

// COMPONENTS
import Button from '@components/button';
import ButtonWithDropdown from '@components/button-with-dropdown';
import Icon from '@components/icon';
import Image from '@components/image';
import InputCheckbox from '@components/form/input-checkbox';

// STYLES
import styles from './media-card.module.scss';

enum EnumThemeMediaCard {
	NOTSELECTED = 'notselected'
}

type TypeThemeMediaCard = EnumThemeMediaCard | EnumTheme;

interface MediaCardProps {
	'data-testid'?: string;
	category?: EnumMediaType;
	className?: string;
	isChecked?: boolean;
	labels?: LabelStatusProps[];
	mediaData?: Media;
	menuItems?: DropdownItemProps[];
	onChange?: undefined | ((event: ChangeEvent<HTMLInputElement>, newState?: object) => void | object);
	onClickEdit?: undefined | ((event: MouseEvent<HTMLDivElement>, newState?: object) => void | object);
	placeHolder?: JSX.Element;
	theme?: TypeThemeMediaCard;
}

const MediaCard = memo(({
	'data-testid': dataTestid,
	className,
	isChecked = false,
	theme,
	labels = [
	],
	mediaData,
	menuItems = [
	],
	placeHolder,
	onChange,
	onClickEdit,
}: MediaCardProps): JSX.Element | undefined => {
	const cssClasses = [
		styles.mediaCard,
	];

	if (className) cssClasses.push(className);
	if (theme) cssClasses.push(styles[`theme__${theme}`]);

	const [
		isMouseOver,
		setIsMouseOver
	] = useState<boolean>(false);

	const [
		cssClassesState,
		setCssClassesState
	] = useState<string[]>(cssClasses);

	useEffect(() => {
		if (isChecked) {
			setCssClassesState([
				...cssClassesState,
				styles.checked
			]);
		} else {
			setCssClassesState([
				...cssClassesState.filter((cssClass) => cssClass !== styles.checked),
			]);
		}
	}, [
		isChecked
	]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
		if (onChange) {
			onChange(event, mediaData);
		}
	};

	const handleOnClickEdit = (event: MouseEvent<HTMLDivElement>): void => {
		onClickEdit(event, mediaData);
	};

	const handleOnMouseEnter = (): void => {
		setIsMouseOver(true);
	};

	const handleOnMouseLeave = (): void => {
		setIsMouseOver(false);
	};

	const mediaPlaceholder = placeHolder ? placeHolder : (
		<div
			className={styles.placeholder}
			data-testid={`${dataTestid}-placeholder`}
		>
			<Icon name='photo' />
		</div>
	);

	const mediaNameToDisplay = mediaData?.title || mediaData?.filename || mediaData?.url && mediaData?.url.split('/').pop() || undefined;

	let mediaElement = undefined;
	switch (mediaData?.type) {
		default:
			mediaElement = (
				<Image
					alt={mediaNameToDisplay}
					className={styles.image}
					data-testid={`${dataTestid}-media`}
					src={mediaData?.url}
				/>
			);
	}

	const labelElements = labels.length ? (
		<div
			className={styles.labels}
			data-testid={`${dataTestid}-labels`}
		>
			{labels.map((label, index) => (
				<LabelStatus
					key={index}
					{...label}
				/>
			))}
		</div>
	) : undefined;

	const menuElement = (
		<ButtonWithDropdown
			className={styles.menu}
			classNameTrigger={styles.menu_button}
			corners={EnumButtonCorners.Square}
			data-testid={`${dataTestid}-menu`}
			dropdownTheme={EnumTheme.SECONDARY}
			hasBoxShadow={false}
			iconLeft='ellipsis-stroke'
			items={menuItems}
			size={EnumButtonSize.SMALL}
			theme={EnumTheme.NAKED}
		/>
	);

	return (
		<div
			className={cssClassesState.join(' ')}
			data-testid={dataTestid}
			title={mediaNameToDisplay}
		>
			<div className={styles.block}>
				<div className={styles.header}>
					{menuItems?.length ? menuElement : <div className={styles.menu}></div>}
					<InputCheckbox
						checked={isChecked}
						className={styles.checkbox}
						data-testid={`${dataTestid}-checkbox`}
						disabled={false}
						id={`media_card_${mediaData?.id || Math.random()}`}
						onChange={handleOnChange}
					/>
				</div>
				<div
					className={styles.body}
					data-testid={`${dataTestid}-body`}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				>
					{isMouseOver && onClickEdit ? (
						<div
							className={styles.overlay}
							data-testid={`${dataTestid}-edit`}
							onClick={handleOnClickEdit}
						>
							<Button
								className={styles.editButton}
								corners={EnumButtonCorners.Round}
								iconLeft='edit'
								theme={EnumTheme.SECONDARY}
							></Button>
						</div>
					) : undefined}
					{labelElements}
					{mediaData?.url ? mediaElement : mediaPlaceholder}
				</div>
			</div>
			<div
				className={styles.name}
				data-testid={`${dataTestid}-name`}
			>
				{mediaNameToDisplay}
			</div>
		</div>
	);
});

export {
	MediaCard,
	MediaCardProps,
	EnumThemeMediaCard,
	TypeThemeMediaCard,
};
