import React, {
	Children,
	cloneElement,
	FC,
	isValidElement,
	MouseEvent,
	ReactNode,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import Loader from '@components/loader';

import styles from './suggestion-list.module.scss';

interface SuggestionListProps {
	className?: string;
	hasBorder?: boolean;
	hasShadow?: boolean;
	children?: ReactNode;
	'data-testid'?: string;
	isLoading?: boolean;
	suggestionsHTML?: ReactNode;
	onClickResult?: (event: MouseEvent<HTMLElement>, suggestion: Record<string, unknown>) => void;
}

const SuggestionList: FC<SuggestionListProps> = ({
	className,
	children,
	'data-testid': dataTestid,
	hasBorder = false,
	hasShadow = false,
	isLoading,
	suggestionsHTML,
}) => {
	const { t } = useTranslation();

	const classes = [
		styles.suggestion_list,
	];

	if (className) classes.push(className);
	if (hasShadow) classes.push(styles.shadow);
	if (hasBorder) classes.push(styles.border);

	const loaderElement = (
		<div
			className={styles.paragraph}
			data-testid={`${dataTestid}-loader`}
			key='loader'
		>
			<Loader message={t('component.input.autosuggest.searching')} />
		</div>
	);

	return isLoading || suggestionsHTML || children ? (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{isLoading ? (
				loaderElement
			) : suggestionsHTML ? (
				suggestionsHTML
			) : (
				Children.map(children, (child) => {
					if (isValidElement(child)) {
						return cloneElement(child, {
							...child.props,
							'data-testid': `${dataTestid}-suggestion`,
						});
					}
				})
			)}
		</div>
	) : undefined;
};

export default SuggestionList;
