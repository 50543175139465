import * as React from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// ASSETS
import congratulationImage from './images/congratulation.jpg';

// COMPONENTS
import Image from '@components/image';

// STYLES
import styles from './ModalAppCongrat.module.scss';

function ModalAppCongrat() {
	const { t } = useTranslation();

	return (
		<div className={styles.app_congrat}>
			<h2 className={styles.title}>
				{t('format.capitalize', {
					text: t('modals.application.confirmation.title')
				})}
			</h2>
			<div className={styles.introduction}>
				{t('format.capitalize', {
					text: t('modals.application.confirmation.introduction')
				})}
			</div>
			<Image
				className={styles.image}
				src={congratulationImage}
			/>
		</div>
	);
}

export default withTranslation()(ModalAppCongrat);
