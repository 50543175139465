// Source : https://stackoverflow.com/a/61127960/6995445
import {
	DependencyList,
	useEffect,
} from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

const useDebouncedEffect = (
	effect: () => void,
	deps: DependencyList = [
	],
	delay: number = APP_CONF_VARS.timeout.debounce
) => {
	useEffect(() => {
		const handler = setTimeout(() => effect(), delay);

		return () => {
			clearTimeout(handler);
		};
	}, [
		...deps || [
		],
		delay
	]);
};

// USAGE
// useDebouncedEffect(() => {
// 	myCallback(value);
// }, [
// 	value
// ], APP_CONF_VARS.timeout.debounce);

export default useDebouncedEffect;
