// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TKTcHDGe .GY4tOgRt{display:inline-flex;margin-bottom:2.14285714rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.TKTcHDGe .GY4tOgRt{margin-bottom:2.42857143rem}}.XBX54gAH{margin:0 0 2.14285714rem}.xFWYHUNZ{color:currentcolor}.xFWYHUNZ input{border-color:var(--color-status-error)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `TKTcHDGe`,
	"button": `GY4tOgRt`,
	"input": `XBX54gAH`,
	"action_failed": `xFWYHUNZ`
};
export default ___CSS_LOADER_EXPORT___;
