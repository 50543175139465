import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';
import {
	useOutletContext,
} from 'react-router-dom';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	handleOnClickFocus,
	updateOfferLots,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	LotRowProps,
} from '@layouts/Pages/PageOfferEntry/lots/lot-row/index';

// ENUMS
import {
	EnumButtonCorners, EnumButtonType,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../lot-row.module.scss';

function WorkstationRentalElement({
	activeElement,
	currentOfferId,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
}: LotRowProps) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const {
		isDesktopResolution,
		offerStateLots,
		setOfferStateLots,
	}: PageOfferContextProps = useOutletContext();

	const [
		inputValueWorkstationsRental,
		setInputValueWorkstationsRental
	] = useState<string>(undefined);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const handleChangeWorkstationsRental = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueWorkstationsRental(value);
	};

	useDebouncedEffect(() => {
		const updateWorkstationsRental = async () => {
			await updateOfferLots(currentOfferId, currentLot, inputValueWorkstationsRental, 'workstation_rental', offerStateLots, rowId, dispatch, setOfferStateLots);
		};

		if (inputValueWorkstationsRental !== undefined) {
			updateWorkstationsRental();
		}
	}, [
		inputValueWorkstationsRental
	], APP_CONF_VARS.timeout.debounce);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputWorkstationsRentalElement = (
		<div className={styles.form_row}>
			<InputTextWithUnit
				className={styles.form_row_main}
				defaultValue={currentLot?.workstation_rental}
				innerRef={inputRef}
				name={`workstation_rental_${rowId}`}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.lots.workstation_rental.placeholder')
				})}
				type={EnumInputType.NUMBER}
				unit={t('format.capitalize', {
					text: t('page.offers_create_edit.lots.workstation_rental.unit')
				})}
				onChange={handleChangeWorkstationsRental}
			/>
		</div>
	);

	const modalWorkstationRentalMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.header.workstation_rental')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
			>
				{inputWorkstationsRentalElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_workstation_rental
	];

	if (activeElement === 'workstation_rental' && selectedRowId === currentLot?.id) cssClasses.push(styles.active);

	const WorkstationsRentalRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`workstation_rental_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, currentLot, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_workstation_rental}
						clickable={true}
						htmlElement={inputWorkstationsRentalElement}
						id={`tooltip_workstation_rental_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_workstation_rental_content}>
							{currentLot?.workstation_rental
								?
								t('format.price', {
									val: currentLot.workstation_rental
								})
								: (
									<span className={styles.placeholder}>
										{t('format.capitalize', {
											text: t('page.offers_create_edit.lots.header.workstation_rental')
										})}
									</span>
								)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.lots.header.workstation_rental')
						})}</span>
						<span className={styles.mobile_value}>{currentLot?.workstation_rental ? currentLot.workstation_rental : undefined}</span>
					</>
				)}
			</div>
			{modalWorkstationRentalMobile}
		</>
	);

	return WorkstationsRentalRow;
}

export default WorkstationRentalElement;
