// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OhY4Oz4l{opacity:.3;width:100%}.OhY4Oz4l rect{width:100%}.txAtZWLt{margin-bottom:3.57142857rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.txAtZWLt{margin-bottom:4.28571429rem;padding:0}}.DTlFwd5u{color:currentcolor}.DTlFwd5u a{display:block;text-align:center}.DTlFwd5u form{margin-bottom:2.71428571rem}.DTlFwd5u .V2iBGstU{margin-bottom:0}.DTlFwd5u h1{text-align:center}.DTlFwd5u [data-status-msg-wrapper]{margin-left:auto;margin-right:auto;width:100%;max-width:460Px}.P3vH0P9H{text-align:center}.cNHpH9QV{margin-bottom:2.85714286rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.cNHpH9QV{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `OhY4Oz4l`,
	"main": `txAtZWLt`,
	"step": `DTlFwd5u`,
	"button": `V2iBGstU`,
	"wrapper": `P3vH0P9H`,
	"statusMsgWrapper": `cNHpH9QV`
};
export default ___CSS_LOADER_EXPORT___;
