import * as React from 'react';
import {
	Outlet,
} from 'react-router-dom';

function PageTestIframe() {
	return (
		<Outlet/>
	);
}

export default PageTestIframe;
