import i18n from '@modules/i18n';
import styles from '../table-view/offer-row/offer-row.module.scss';
import {
	FilterFnOption,
} from '@tanstack/react-table';

export const columnTableConf = (canSelect: boolean) => {
	return [
		{
			header: undefined as string | undefined,
			accessorKey: 'mandate_is_owner',
			enableColumnFilter: true,
			filterFn: 'customfilterFns' as FilterFnOption<unknown>,
			className: `${styles.cell} ${canSelect ? styles.pin_can_select : styles.pin}`,
		},
		{
			accessorKey: 'id',
			header: null,
			className: `${styles.cell} ${styles.image}`,
			enableColumnFilter: true,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.offer_informations')
			}),
			accessorKey: 'building_address_street_raw',
			enableColumnFilter: true,
			className: `${styles.cell} ${styles.informations}`,
		},
		{
			header: null,
			accessorKey: 'iconsList',
			className: `${styles.cell} ${styles.iconsList}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.offer_price')
			}),
			accessorKey: 'price',
			className: `${styles.cell} ${styles.price}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.area')
			}),
			accessorKey: 'area',
			className: `${styles.cell} ${styles.area}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.availability')
			}),
			accessorKey: 'availability',
			className: `${styles.cell} ${styles.availability}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.events')
			}),
			accessorKey: 'states',
			enableColumnFilter: true,
			filterFn: 'customfilterFns' as FilterFnOption<unknown>,
			className: `${styles.cell} ${styles.labels}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.more_info')
			}),
			accessorKey: 'info',
			className: `${styles.cell} ${styles.more_info}`,
		},
		{
			header: i18n.t('format.capitalize', {
				text: i18n.t('general.actions')
			}),
			accessorKey: 'actions',
			className: `${styles.cell} ${styles.actions}`,
		},
	];
};
