import * as React from 'react';
import {
	Dispatch,
	MouseEvent,
	ReactNode,
	SetStateAction,
	useState,
} from 'react';
import {
	useDispatch,
} from 'react-redux';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	useTranslation,
} from 'react-i18next';

// TYPES
import {
	Demand,
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumInputTheme,
} from '@enums/form.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ROUTES
import PATHS from '@routes/paths';
import FETCHES from '@routes/fetches';

// MODULES
import * as utils from '@modules/utils';

// COMPONENTS
import ButtonWithDropdown from '@components/button-with-dropdown';
import Icon from '@components/icon';
import InputCheckbox, {
	InputCheckboxProps,
} from '@components/form/input-checkbox';
import LabelStatus from '@components/label-status';
import Link from '@components/link';
import Text from '@components/text';
import Tooltip from '@components/tooltip';

// STYLES
import styles from './demand-row.module.scss';

interface DemandsRowProps {
	canSelect?: boolean;
	className?: string;
	checkboxElement?: ReactNode;
	currentUserId: number;
	demandJson: DemandJsonPrivate;
	index: number;
	isDesktopResolution: boolean;
	isSelected?: boolean;
	setDemandIds: Dispatch<SetStateAction<number[]>>;
	setDemandModalState: Dispatch<SetStateAction<DemandJsonPrivate>>;
	setIsOpenDeleteDemandModal: Dispatch<SetStateAction<boolean>>;
	setIsOpenUpdateDemandStateModal: Dispatch<SetStateAction<boolean>>;
}

const DemandRow = ({
	canSelect,
	className,
	checkboxElement,
	currentUserId,
	demandJson,
	index,
	isDesktopResolution,
	isSelected,
	setDemandIds,
	setDemandModalState,
	setIsOpenDeleteDemandModal,
	setIsOpenUpdateDemandStateModal,
}: DemandsRowProps): JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const demand = new Demand(demandJson);

	const demandUrl = utils.getURL(`${APP_CONF_VARS.publicPath}${PATHS.DEMANDS.PRIVATE.MANAGE.STEP._ROOT}`, {
		':demandid': `${demand.id}`,
		':step': 'matched',
	});

	const cssClasses = [
		styles.demand,
	];

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.demand__can_select);
	if (isSelected) cssClasses.push(styles.demand__selected);

	const newsElement = demand.new_offers_count > 0 ? (
		<LabelStatus
			text={t('page.demands.new_offers', {
				'%count%': demand.new_offers_count
			})}
			theme={EnumStatusTheme.INFO}
		/>
	) : null;

	const notSpecified = t('format.capitalize', {
		text: t('general.not_specified')
	});

	const companyElement = (
		<div className={styles.cell}>
			<Text
				className={`${styles.mb8} ${styles.company__name}`}
				ellipsis={true}
			>
				{demand.recipient.company.name ? t('format.capitalize', {
					text: demand.recipient.company.name
				}) : notSpecified}
			</Text>
			<Link
				className={styles.mb8}
				href={demandUrl}
				target={EnumTargetLink.BLANK}
			>
				{t('format.capitalize', {
					text: t('general.ref', {
						'%ref%': demand.id
					})
				})}
			</Link>
			<Text>
				{t('format.capitalize', {
					text: t('general.created_at', {
						'%date%': demand.created_infos.date
					})
				})}
			</Text>
			{isDesktopResolution ? null : (
				<div className={styles.mt8}>
					{newsElement}
				</div>
			)}
		</div>
	);

	const contact = demand.recipient.contacts.collection.length ? demand.recipient.contacts.collection[0] : null;

	const contactElement = isDesktopResolution ? (
		<div
			className={styles.cell}
		>
			<Text
				className={`${styles.mb8} ${styles.contact__name}`}
				ellipsis={true}
			>
				{contact?.full_name || notSpecified}
			</Text>
			<Text
				className={styles.mb8}
				ellipsis={true}
			>
				<Link
					fontWeight={EnumFontStyle.REGULAR}
					href={`tel:${contact?.phone}`}
					target={EnumTargetLink.BLANK}
				>
					<Icon
						className={styles.icon}
						fontStyle={EnumFontStyle.LIGHT}
						name='phone-alt'
					/>
					{contact?.phone ? t('format.tel', {
						'val': contact.phone,
					}) : notSpecified}
				</Link>
			</Text>
			<Text
				ellipsis={true}
			>
				<Link
					fontWeight={EnumFontStyle.REGULAR}
					href={`mailto:${contact?.email}`}
					target={EnumTargetLink.BLANK}
				>
					<Icon
						className={styles.icon}
						fontStyle={EnumFontStyle.LIGHT}
						name='envelope'
					/>
					{contact?.email || notSpecified}
				</Link>
			</Text>
		</div>
	) : null;

	const transactionTypes = demand.search_criterias.demand_types.map(demand_type => t('format.capitalize', {
		text: t(`general.${demand_type}`).charAt(0)
	})).join('/');

	const natures = demand.search_criterias?.natures?.length ? (
		demand.search_criterias?.natures.map((nature: string) => {
			return t('format.capitalize', {
				text: t(`references.natures.${nature}`)
			});
		}).join(' - ')
	) : null;

	let areaDiplayed = null;
	if (demand.search_criterias?.area?.min?.value && demand.search_criterias?.area?.max?.value) {
		areaDiplayed = t('format.to', {
			from: t('format.number', {
				val: demand.search_criterias.area.min.value
			}),
			to: t('format.area', {
				val: demand.search_criterias.area.max.value
			})
		});
	} else if (demand.search_criterias?.area?.max?.value) {
		areaDiplayed = t('format.max', {
			text: t('format.area', {
				val: demand.search_criterias.area.max.value
			})
		});
	} else if (demand.search_criterias?.area?.min?.value) {
		areaDiplayed = t('format.min', {
			text: t('format.area', {
				val: demand.search_criterias.area.min.value
			})
		});
	}

	const locations = demand.search_criterias?.locations?.length ? (
		demand.search_criterias?.locations.map((location: string) => {
			return t('format.capitalize', {
				text: location
			});
		}).join(' - ')
	) : null;

	const searchCriteriasElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<Text
				className={styles.mb8}
				ellipsis={true}
			>{natures ? `${natures} ` : null}{transactionTypes ? `(${transactionTypes})` : null}</Text>
			{locations ? (
				<Text
					className={styles.mb8}
					ellipsis={true}
				>{locations}</Text>
			) : null}
			{locations ? (
				<Text
					ellipsis={true}
				>{areaDiplayed}</Text>
			) : null}
		</div>
	) : null;

	const brokerElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<Text ellipsis={true}>{demand.created_infos.user.full_name}</Text>
		</div>
	) : null;

	const demandStateElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<LabelStatus
				text={demand.state}
			/>
		</div>
	) : null;

	const newsElementDesktop = isDesktopResolution ? (
		<div className={styles.cell}>
			{newsElement}
		</div>
	) : null;

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		window.open(demandUrl);
	};

	const [
		enableMatching,
		setEnableMatching
	] = useState(demand?.settings?.notifications?.enable_new_matching);

	const canEditMatchDemand = demand.state === 'active';
	const toogleElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<Tooltip
				content={t('format.capitalize', {
					text: t(`page.demands.${enableMatching ? 'toogle_off' : 'toogle_on'}`)
				})}
				id="toogle"
			>
				<InputCheckbox
					checked={demand?.settings?.notifications?.enable_new_matching}
					disabled={canEditMatchDemand ? false : true}
					id={`alert_broker_${index}`}
					name={`alert_broker_${index}`}
					theme={EnumInputTheme.TOGGLE}
					onChange={(event, state: InputCheckboxProps) => {
						dispatch(clearToastMsgs());
						fetch(utils.getURL(FETCHES.private.demands.alert_broker, {
							':demandid': `${demand.id}`,
						}), {
							...APP_CONF_VARS.request.default,
							method: state.checked ? 'PUT' : 'DELETE',
						}).then((resp) => {
							if (resp.status === 200) {
								setEnableMatching(state.checked);
								dispatch(addToastMsg({
									message: t('format.capitalize', {
										text: t([
											`status.${state.checked ? 'notifications_enabled' : 'notifications_disabled'}`
										]),
									}),
								}));
								return resp.json();
							} else {
								dispatch(addToastMsg({
									message: t('format.capitalize', {
										text: t([
											`status.${resp.statusText}`,
											'status.default'
										]),
									}),
									theme: EnumStatusTheme.ERROR,
								}));
							}
						}).catch(err => {
							console.warn('ERROR : ', err);
						});
					}}
				/>
			</Tooltip>
		</div>
	) : null;

	const handleOnClickUpdateDemandState = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setDemandModalState(demandJson);
		setIsOpenUpdateDemandStateModal(true);
	};

	const handleOnClickDeleteDemand = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setDemandIds([
			demandJson.id
		]);
		setIsOpenDeleteDemandModal(true);
	};

	const disabledDemand = [
		'abandoned',
		'failed',
		'successful',
	];

	const actionsElement = isDesktopResolution ? (
		<div className={styles.cell}>
			{isSelected === null ? (
				<ButtonWithDropdown
					corners={EnumButtonCorners.Square}
					dropdownTheme={EnumTheme.SECONDARY}
					hasBoxShadow={false}
					iconLeft={'ellipsis-stroke'}
					items={[
						{
							hasAccess: true,
							iconLeft: 'edit',
							id: 'update_demand_state',
							label: t('format.capitalize', {
								text: t('page.demands.update_demand_state')
							}),
							disabled: disabledDemand.includes(demand.state),
							onClick: handleOnClickUpdateDemandState,
						},
						{
							hasAccess: true,
							disabled: currentUserId === demand.created_infos.user.id ? false : true,
							iconLeft: 'trash-alt',
							id: 'delete_demand',
							label: t('format.capitalize', {
								text: t('page.demands.delete_demand')
							}),
							onClick: handleOnClickDeleteDemand,
						},
					]}
					name={'actions'}
					size={EnumButtonSize.SMALL}
					style={{
						bottom: 'calc(100% + 6px)',
						right: '0'
					}}
					theme={EnumTheme.SECONDARY}
				/>
			) : null}
		</div>
	) : null;

	return (
		<div
			className={cssClasses.join(' ')}
			onClick={handleOnClick}
		>
			{canSelect ? <div className={styles.selection}>{checkboxElement}</div> : null}
			{companyElement}
			{contactElement}
			{searchCriteriasElement}
			{brokerElement}
			{demandStateElement}
			{newsElementDesktop}
			{toogleElement}
			{actionsElement}
		</div>
	);
};

export { DemandRow as default, };
