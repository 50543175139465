import * as utils from '@modules/utils';
import FETCHES from '@routes/fetches';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import DeleteDemandsError from '@exceptions/DeleteDemandsError';

export const deleteDemands = async (demandIds: number[]) => {
	const urlencoded = new URLSearchParams();
	demandIds.forEach((demandId) => {
		urlencoded.append('demandIds[]', `${demandId}`);
	});

	return await fetch(utils.getURL(FETCHES.private.demands.delete), {
		...APP_CONF_VARS.request.default,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		method: 'POST',
		body: urlencoded,
	}).catch(error => {
		throw new DeleteDemandsError(error);
	});
};
