import React from 'react';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from '../card-view.module.scss';

const Loader = (props: object) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader}
			height="100%"
			width="100%"
		>
			<rect
				height={500}
				id="0"
				rx={device.display === 'mobile' ? 0 : 4}
				ry={device.display === 'mobile' ? 0 : 4}
				width="100%"
				x={0}
				y={0}
			/>
		</ContentLoader>
	);
};

export default Loader;
