import * as React from 'react';

// STYLES
import styles from './radio-button.module.scss';

interface RadioButtonProps {
	checked?: boolean;
	className?: string;
	'data-testid'?: string;
	disabled?: boolean;
	onChange?: () => void;
}

const RadioButton = ({
	checked,
	className,
	'data-testid': dataTestid,
	disabled,
}: RadioButtonProps): JSX.Element => {
	const cssClasses = [
		styles.radio_button
	];
	if (className) cssClasses.push(className);
	if (disabled) cssClasses.push(styles.radio_button__disabled);
	if (checked) cssClasses.push(styles.radio_button__checked);

	return (
		<span
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		></span>
	);
};

export {
	RadioButton as default,
	RadioButtonProps,
};

