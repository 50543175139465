// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hdtty2tD{color:var(--color-status-error);font-weight:600}.IOMt_pyw{font-size:1rem;margin-bottom:1.42857143rem}.IOMt_pyw>label{font-weight:600;font-size:1rem;font-size:1.14285714rem}.D3kd_Daw{display:flex;flex-direction:row;overflow:auto;width:100%}.AMJsptuO{padding:3.57142857rem;width:100%}.Vn8ecdCd{margin-bottom:1.42857143rem}.Vn8ecdCd.xLSH0_xt{align-items:center;display:flex}.Vn8ecdCd.xLSH0_xt .SDbz0_4D{margin-left:1.07142857rem}.Vn8ecdCd .fF7yD6rP{font-weight:600;font-size:1rem;font-size:1.14285714rem}.Vn8ecdCd .SDbz0_4D{background-color:var(--color-background-image-placeholder);border:1px solid;border-radius:0.42857143rem;max-height:70vh;overflow:auto;padding:0.71428571rem}.Vn8ecdCd .rJIKvPDz{min-height:21.42857143rem}.PezZS8Fb{align-self:flex-start;padding:0.71428571rem}.PezZS8Fb.lhrPQXmD{width:30%}.PezZS8Fb.jnM8kl9T{width:70%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `Hdtty2tD`,
	"field": `IOMt_pyw`,
	"main": `D3kd_Daw`,
	"PageTestApi": `AMJsptuO`,
	"result": `Vn8ecdCd`,
	"top": `xLSH0_xt`,
	"value": `SDbz0_4D`,
	"title": `fF7yD6rP`,
	"value__text": `rJIKvPDz`,
	"section": `PezZS8Fb`,
	"left": `lhrPQXmD`,
	"right": `jnM8kl9T`
};
export default ___CSS_LOADER_EXPORT___;
