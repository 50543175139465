import {
	Modules,
	Module,
} from '@@types/Module';

interface PermissionsProps {
	payload: object | Modules;
	whiteList?: Module | Module[];
}

class Permissions {
	payload: Modules;
	whiteList?: Module | Module[];
	constructor({
		payload,
		whiteList,
	} : PermissionsProps
	) {
		this.payload = {
			...payload
		};
		this.whiteList = whiteList || [
		];
		this.can = this.can.bind(this);
		this.canWithSections = this.canWithSections.bind(this);
		this.canWithPermissions = this.canWithPermissions.bind(this);
	}

	can(permissionName: string | string[], sectionName: string | string[]) {
		let result = false;
		const permissionNameIsAnArray = Array.isArray(permissionName) && permissionName.length;
		const sectionNameIsAnArray = Array.isArray(sectionName) && sectionName.length;
		const permissionNameIsAString = typeof permissionName === 'string';
		const sectionNameIsString = typeof sectionName === 'string';

		if (permissionNameIsAnArray && sectionNameIsAnArray) {
			const permResults = [
			];
			permissionName.filter(permissionNameItem => {
				if (this.canWithSections(permissionNameItem, sectionName)) {
					permResults.push(permissionNameItem);
				}
			});
			result = permResults.length ? true : false;
		} else if (!permissionNameIsAnArray && sectionNameIsAnArray) {
			result = this.canWithSections(permissionName as string, sectionName);
		} else if (permissionNameIsAnArray && !sectionNameIsAnArray) {
			result = this.canWithPermissions(permissionName, sectionName as string);
		} else if (!permissionNameIsAnArray && !sectionNameIsAnArray && permissionNameIsAString && sectionNameIsString) {
			result = this.payload[sectionName]?.permissions && this.payload[sectionName].permissions[permissionName] ? true : false;
		}
		return result;
	}

	canWithPermissions(permissionNames: string[], targetedSectionName: string) {
		const result = [
		];
		permissionNames.forEach(permissionName => {
			if (this.can(permissionName, targetedSectionName)) result.push(targetedSectionName);
		});

		return result.length ? true : false;
	}

	canWithSections(permissionName: string, targetedSectionsNames: string[]) {
		const result = [
		];
		targetedSectionsNames.forEach(targetedSectionName => {

			if (this.can(permissionName, targetedSectionName)) result.push(targetedSectionName);
		});

		return result.length ? true : false;
	}
}

export {
	Permissions as default,
	PermissionsProps,
};
