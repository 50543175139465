import React, {
	MouseEvent,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// TYPES
import {
	ObservatoryReport,
} from '@@types/User';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumInputSize,
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// STORES
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	clearDemands,
} from '@stores/_slices/demands';
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	userLogout,
	userUpdateModuleActivity,
} from '@stores/_slices/user';

// MODULES
import eventEmitter from '@modules/eventEmitter';
import Permissions from '@modules/permissions';
import * as utils from '@modules/utils';

// LAYOUTS
import ModalDefault from '@layouts/Modals/ModalDefault';
import Page from '@layouts/Pages/Page';
import ModalProductDemo, {
	ModalProductDemoProps,
} from '@layouts/Modals/ModalProductDemo';

// COMPONENTS
import ButtonList, {
	ButtonListProps,
	ButtonWithDropdownProps,
} from '@components/button-list';
import {
	ButtonProps,
} from '@components/button';
import Footer from '@components/footer';
import Image from '@components/image';
import InputSearchWithDropdow, {
	dataToShowProps,
} from '@components/form/input-search-with-dropdown';
import Link from '@components/link';
import Paragraph from '@components/paragraph';
import Modal from '@components/modal';
import ModuleCard from '@layouts/Pages/PageHome/module-card';
import Icon from '@components/icon';

// DATA
import EVENTS from '@static_data/events.data';
import {
	searchEngines,
	studies,
} from '@static_data/permissions-groups.data';

// ROUTES
import FETCHES from '@routes/fetches';
import PATHS from '@routes/paths';

// IMAGES
import check_validated from '@assets/images/lpdipro/icon_check_validated.png';
import locker from '@assets/images/lpdipro/icon_locker.png';

// STYLES
import styles from './PageHome.module.scss';

function PageHome() {
	const { t } = useTranslation();

	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const dispatch = useDispatch();
	const [
		isOpenState,
		setIsOpenState
	] = useState(false);

	const [
		activeModulePropsState,
		setActiveModulePropsState
	] = useState({
	});

	// State when the demo request has just been requested
	const [
		demoRequestHasJustBeenRequested,
		setDemoRequestHasJustBeenRequested
	] = useState(false);

	const [
		modalValidateButtonDisabledState,
		setModalValidateButtonDisabledState
	] = useState(false);

	// Get user state
	const userState = useSelector((state: ReducerInstance) => state.user.instance);

	const lastDemoRequestedProps = {
		illustration: check_validated,
		title: t('modals.product_demo.validated_title'),
		children: <div
			dangerouslySetInnerHTML={{
				__html: t('modals.product_demo.validated_content')
			}}
		></div>
	};

	const actualPermissions = new Permissions({
		payload: userState.modules
	});

	const modalCallback = (event: MouseEvent<HTMLElement>, args: ModalProductDemoProps) => {
		if (!args.hasAccess) {
			setActiveModulePropsState(args);
			setIsOpenState(true);
		}
		return args.hasAccess;
	};

	// START ON CLICK REQUEST DEMO BUTTON
	const handleOnClickAskRequestDemo = (event: MouseEvent<HTMLElement>, args: { itemName?: string; permissionName?: string }) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		const moduleName = args.itemName;
		setModalValidateButtonDisabledState(true);
		fetch(utils.getURL(FETCHES.private.create_request), {
			...APP_CONF_VARS.request.default,
			method: 'POST',
			body: JSON.stringify({
				moduleName: args?.permissionName || moduleName
			})
		}).then((response) => {
			return response.json();
		}).then((responseParsed) => {
			if (responseParsed.status === 200) {
				setDemoRequestHasJustBeenRequested(true);
				dispatch(userUpdateModuleActivity({
					module: moduleName,
					activity: args?.permissionName ? `${args.permissionName}_demo_requested` : 'demo_requested',
					value: true
				}));
			} else if (responseParsed.error === 'access_denied') {
				// STORING THE PREVIOUS ROUTE BEFORE AUTH
				dispatch(appSetRoute({
					name: 'from',
					url: location.pathname
				}));

				dispatch(userLogout());
				dispatch(clearDemands());
				dispatch(addStatusMsg({
					message: t('status.error_session'),
					theme: EnumStatusTheme.ERROR,
				}));
			}
			setModalValidateButtonDisabledState(false);
		}).catch((error) => {
			console.warn(error);
		});
	};
	// END ON CLICK REQUEST DEMO BUTTON

	// START GET BUTTONS FROM LIST FUNCTION
	// allow us to retrieve a list of Button elements from an array of string
	const getButtonItems = (itemNameList: string[], icon: string) => {
		const result = itemNameList.map((itemName: string) => {
			const canAccess = actualPermissions.can('read', itemName);
			return {
				label: t('format.capitalize', {
					text: t(`module.${itemName}.name_other`)
				}),
				hasAccess: canAccess,
				corners: EnumButtonCorners.Square,
				size: EnumButtonSize.SMALL,
				href: PATHS.SEARCH[itemName.toUpperCase()]?.LEGACY || null,
				theme: EnumTheme.SECONDARY,
				onClick: (event: MouseEvent<HTMLElement>) => {
					if (!canAccess) {
						const hasBeenAlreadyRequestedDemo = userState.modules[itemName]?.activity?.demo_requested || false;
						const isStudiesItem = studies.includes(itemName);
						const isSearchEnginesItem = searchEngines.includes(itemName);
						const itemType = isStudiesItem ? null : 'search_engine';
						modalCallback(event, {
							hasAccess: canAccess,
							content: <>{t(`module.${itemName}.description`)}</>,
							buttons: {
								more: {
									href: t(`module.${itemName}.zendesk_url`),
								},
								validate: {
									disabled: hasBeenAlreadyRequestedDemo || modalValidateButtonDisabledState,
									label: hasBeenAlreadyRequestedDemo ? t('modals.product_demo.modal_button.request_validated') : t('modals.product_demo.modal_button.request_demo'),
									onClick: (event: MouseEvent<HTMLElement>) => {
										handleOnClickAskRequestDemo(event, {
											itemName
										});
									}
								}
							},
							icon: icon,
							itemName: itemName,
							title: t(`modals.product_demo.${isStudiesItem ? 'title_other' : 'title'}`, {
								'%type%': t(`modals.product_demo.${itemType ? 'item_and_type' : 'item_without_type'}`,
									{
										'%item%': t('format.capitalize', {
											text: t(`module.${itemName}.name`, {
												count: isStudiesItem || isSearchEnginesItem ? 2 : 1
											})
										}),
										'%type%': t(`page.home.${itemType}`),
									}
								)
							})
						});
					}
					return canAccess;
				}
			};
		});
		return result;
	};
	// END GET BUTTONS FROM LIST FUNCTION

	const getObservatoryReportsButton = (itemNameList = [
	] as ObservatoryReport[], isPrivate = false) => {
		const result = itemNameList.map((observatoryReport: ObservatoryReport) => {
			const canAccess = actualPermissions.can(observatoryReport.permission_name, 'observatories');
			const validateLabel = isPrivate ? t('modals.product_demo.modal_button.request_access') : t('modals.product_demo.modal_button.request_demo');

			return {
				label: t('format.capitalize', {
					text: observatoryReport.name
				}),
				hasAccess: canAccess,
				corners: EnumButtonCorners.Square,
				theme: EnumTheme.SECONDARY,
				size: EnumButtonSize.SMALL,
				to: PATHS.OBSERVATORY.REPORT.replace(':reportid', `${observatoryReport.id}`),
				onClick: (event: MouseEvent<HTMLElement>) => {
					if (!canAccess) {
						const userActivity = userState?.modules['observatories']?.activity;
						const hasBeenAlreadyRequestedDemo = userActivity && Object.keys(userActivity)?.includes(`${observatoryReport.permission_name}_demo_requested`);
						modalCallback(event, {
							hasAccess: canAccess,
							content: <>{observatoryReport.demo.description}</>,
							buttons: {
								more: {
									href: observatoryReport.demo.url,
								},
								validate: {
									disabled: hasBeenAlreadyRequestedDemo || modalValidateButtonDisabledState,
									label: hasBeenAlreadyRequestedDemo ? t('modals.product_demo.modal_button.request_validated') : validateLabel,
									onClick: (event: MouseEvent<HTMLElement>) => {
										handleOnClickAskRequestDemo(event, {
											itemName: 'observatories',
											permissionName: observatoryReport.permission_name,
										});
									}
								}
							},
							icon: 'analytics',
							itemName: observatoryReport.permission_name,
							title: t('format.capitalize', {
								text: observatoryReport.demo.title
							})
						});
					}
					return canAccess;
				}
			};
		});
		return result;
	};

	// START DEFINING SEARCH ENGINES BLOCK ELEMENT CONTENT
	const buttonItems = getButtonItems(searchEngines, 'sliders-h-square-search');
	const searchEnginesBlock = (
		<Paragraph
			className={styles.cell}
			data-testid="homepage_search_engines_button_list"
			icon='sliders-h-square-search'
			title={t('format.capitalize', {
				text: t('page.home.search_engine', {
					count: buttonItems.length
				})
			})}
			titleLevel={2}
		>
			<ButtonList
				className={styles.button_list}
				items={buttonItems as ButtonProps[]}
			/>
		</Paragraph>
	);
	// END DEFINING SEARCH ENGINES BLOCK ELEMENT CONTENT

	// START DEFINING STUDIES BLOCK ELEMENT CONTENT
	const studiesBlock = (
		<Paragraph
			className={styles.cell}
			icon='chart-pie'
			title={t('format.capitalize', {
				text: t('page.home.study_other')
			})}
			titleLevel={2}
		>
			<ButtonList
				className={styles.button_list}
				items={[
					...getButtonItems(studies, 'chart-pie'),
					...getObservatoryReportsButton(userState?.observatory_reports?.filter(report => !report.is_private) as ObservatoryReport[])
				] as ButtonListProps['items']}
			/>
		</Paragraph>
	);
	// END DEFINING STUDIES BLOCK ELEMENT CONTENT

	// START DEFINING PRIVATE OBSERVATORIES BLOCK ELEMENT CONTENT
	const privateObservatories = userState?.observatory_reports?.filter(report => report.is_private);
	const privateObservatoriesBlock = privateObservatories?.length ? (
		<Paragraph
			className={styles.cell}
			icon='analytics'
			title={t('format.capitalize', {
				text: t('page.home.observatory_other')
			})}
			titleLevel={2}
		>
			<ButtonList
				className={styles.button_list}
				items={[
					...getObservatoryReportsButton(privateObservatories as ObservatoryReport[], true)
				] as ButtonProps[] | ButtonWithDropdownProps[]}
			/>
		</Paragraph>
	) : null;
	// END DEFINING STUDIES BLOCK ELEMENT CONTENT

	// START COMERCIALIZATION BLOCK BEHAVIOUR
	const onClickLinkCommercializationElement = (event: MouseEvent<HTMLElement>, hasAccess: boolean) => {
		if (!hasAccess) {
			event.preventDefault();
			const itemName = 'commercialization';
			const itemType = 'module';
			const hasBeenAlreadyRequestedDemo = userState.modules?.commercialization?.activity?.demo_requested || false;
			modalCallback(event, {
				hasAccess: hasAccess,
				content: <>{t(`module.${itemName}.description`)}</>,
				buttons: {
					more: {
						href: t(`module.${itemName}.zendesk_url`),
					},
					validate: {
						disabled: hasBeenAlreadyRequestedDemo || modalValidateButtonDisabledState,
						label: hasBeenAlreadyRequestedDemo ? t('modals.product_demo.modal_button.request_validated') : t('modals.product_demo.modal_button.request_demo'),
						onClick: (event: MouseEvent<HTMLElement>) => {
							handleOnClickAskRequestDemo(event, {
								itemName
							});
						}
					}
				},
				icon: 'sale-second',
				itemName: itemName,
				title: t('modals.product_demo.title', {
					'%type%': t('modals.product_demo.item_and_type',
						{
							'%item%': t('format.capitalize', {
								text: t(`module.${itemName}.name`)
							}),
							'%type%': t(`page.home.${itemType}`),
						}
					)
				})
			});
		}
		return hasAccess;
	};

	const modulePermissions = new Permissions({
		payload: userState?.modules || {
		}
	});

	const hasAccessAtLeastOneCommercializationModule = modulePermissions.can([
		'commercialization_module',
		'mls_packages',
		'create_my_offer'
	], 'commercialization');

	const hasAccessCommercializationModules = modulePermissions.can([
		'commercialization_module',
		'mls_packages',
	], 'commercialization');

	const commercializationElement = (
		<Paragraph
			className={styles.cell}
			hasBorder={true}
			icon='sale-second'
			title={t('format.capitalize', {
				text: t('page.home.block_module', {
					'%modulename%': t('module.commercialization.name')
				})
			})}
			titleLevel={2}
		>
			<ModuleCard>
				{(hasAccessAtLeastOneCommercializationModule && hasAccessCommercializationModules) || !hasAccessAtLeastOneCommercializationModule ? (
					<Link
						href={modulePermissions.can('commercialization_module', 'commercialization') ? PATHS.DEMANDS._ROOT : PATHS.SEARCH.MY_DEMAND.LEGACY}
						target={modulePermissions.can('commercialization_module', 'commercialization') ? EnumTargetLink.SELF : EnumTargetLink.BLANK}
						onClick={(event: MouseEvent<HTMLElement>) => onClickLinkCommercializationElement(event, hasAccessCommercializationModules)}

					>
						<Icon
							name='chevron-right'
						/>
						{t('general.action.view_my_other', {
							item: t('module.my_demand.name_other'),
						})}{userState.modules?.commercialization?.activity?.count_my_demand > 0 ? ` (${userState.modules.commercialization.activity.count_my_demand})` : ''}
					</Link>
				) : null}
				{(hasAccessAtLeastOneCommercializationModule && hasAccessCommercializationModules) || !hasAccessAtLeastOneCommercializationModule ? (
					<Link
						href={PATHS.SEARCH.MY_OFFER.LEGACY}
						target={EnumTargetLink.BLANK}
						onClick={(event: MouseEvent<HTMLElement>) => onClickLinkCommercializationElement(event, hasAccessCommercializationModules)}
					>
						<Icon
							name='chevron-right'
						/>
						{t('general.action.view_my_other', {
							item: t('module.my_offer.name_other'),
						})}{userState.modules?.commercialization?.activity?.count_my_offer > 0 ? ` (${userState.modules.commercialization.activity.count_my_offer})` : ''}
					</Link>
				) : null}
				{(hasAccessAtLeastOneCommercializationModule && hasAccessCommercializationModules) || !hasAccessAtLeastOneCommercializationModule ? (
					<Link
						href={PATHS.SEARCH.DEMAND_ADD.LEGACY}
						target={EnumTargetLink.BLANK}
						onClick={(event: MouseEvent<HTMLElement>) => onClickLinkCommercializationElement(event, hasAccessCommercializationModules)}
					>
						<Icon
							name='plus'
						/>
						{t('general.action.add_one_female', {
							item: t('module.my_demand.name')
						})}
					</Link>
				) : null}
				{(hasAccessAtLeastOneCommercializationModule && modulePermissions.can('create_my_offer', 'commercialization')) || !hasAccessAtLeastOneCommercializationModule ? (
					<Link
						href={modulePermissions.can('offer_form_v2', 'offer') ? PATHS.OFFERS.NEW : PATHS.SEARCH.OFFER_ADD.LEGACY}
						target={modulePermissions.can('offer_form_v2', 'offer') ? EnumTargetLink.SELF : EnumTargetLink.BLANK}
						onClick={(event: MouseEvent<HTMLElement>) => onClickLinkCommercializationElement(event, modulePermissions.can('create_my_offer', 'commercialization'))}
					>
						<Icon
							name='plus'
						/>
						{t('general.action.add_one_female', {
							'item': t('module.my_offer.name')
						})}
					</Link>
				) : null}
			</ModuleCard>
		</Paragraph>
	);
	// END COMERCIALIZATION BLOCK BEHAVIOUR

	// START MANAGEMENT BLOCK BEHAVIOUR
	const onClickLinkManagementElement = (event: MouseEvent<HTMLElement>, hasAccess: boolean) => {
		if (!hasAccess) {
			event.preventDefault();
			const itemName = 'management';
			const itemType = 'module';
			const hasBeenAlreadyRequestedDemo = userState.modules?.management?.activity?.demo_requested || false;
			modalCallback(event, {
				hasAccess: hasAccess,
				content: <>{t(`module.${itemName}.description`)}</>,
				buttons: {
					more: {
						href: t(`module.${itemName}.zendesk_url`),
					},
					validate: {
						disabled: hasBeenAlreadyRequestedDemo || modalValidateButtonDisabledState,
						label: hasBeenAlreadyRequestedDemo ? t('modals.product_demo.modal_button.request_validated') : t('modals.product_demo.modal_button.request_demo'),
						onClick: (event: MouseEvent<HTMLElement>) => {
							handleOnClickAskRequestDemo(event, {
								itemName
							});
						}
					}
				},
				icon: 'steering-wheel',
				itemName: itemName,
				title: t('modals.product_demo.title', {
					'%type%': t('modals.product_demo.item_and_type',
						{
							'%item%': t('format.capitalize', {
								text: t(`module.${itemName}.name`)
							}),
							'%type%': t(`page.home.${itemType}`),
						}
					)
				})
			});
		}
		return hasAccess;
	};

	const hasAccessAtLeastOneManagementModule = modulePermissions.can([
		'read_asset',
		'create_asset',
		'read_lease',
		'read_site'
	], 'management');

	const hasAccessAtLeastOneManagementInverstorModule = modulePermissions.can([
		'read_asset',
		'create_asset',
		'read_lease',
	], 'management');

	// START MANAGEMENT INVESTOR BLOCK BEHAVIOUR
	const managementInvestorElement = hasAccessAtLeastOneManagementInverstorModule || !hasAccessAtLeastOneManagementModule ? (
		<ModuleCard
			className={styles.management_module_card}
			title={t('page.home.management.investor')}
			titleLevel={3}
		>
			{(hasAccessAtLeastOneManagementModule && modulePermissions.can('read_asset', 'management')) || !hasAccessAtLeastOneManagementModule ? (
				<Link
					href={PATHS.SEARCH.ASSET_LIST.LEGACY}
					target={EnumTargetLink.BLANK}
					onClick={(event: MouseEvent<HTMLElement>) => onClickLinkManagementElement(event, modulePermissions.can('read_asset', 'management'))}
				>
					<Icon
						name='chevron-right'
					/>
					{t('general.action.view_my_other', {
						item: t('module.asset.name_other'),
					})}
				</Link>
			) : null}
			{(hasAccessAtLeastOneManagementModule && modulePermissions.can('read_lease', 'management')) || !hasAccessAtLeastOneManagementModule ? (
				<Link
					href={PATHS.SEARCH.LEASE_LIST._ROOT}
					target={EnumTargetLink.BLANK}
					onClick={(event: MouseEvent<HTMLElement>) => onClickLinkManagementElement(event, modulePermissions.can('read_lease', 'management'))}
				>
					<Icon
						name='chevron-right'
					/>
					{t('general.action.view_my_other', {
						item: t('module.lease.name_other'),
					})}
				</Link>
			) : null}
			{(hasAccessAtLeastOneManagementModule && modulePermissions.can('create_asset', 'management')) || !hasAccessAtLeastOneManagementModule ? (
				<Link
					href={PATHS.SEARCH.ASSET_ADD.LEGACY}
					target={EnumTargetLink.BLANK}
					onClick={(event: MouseEvent<HTMLElement>) => onClickLinkManagementElement(event, modulePermissions.can('create_asset', 'management'))}
				>
					<Icon
						name='plus'
					/>
					{t('general.action.add_one_male', {
						item: t('module.asset.name')
					})}
				</Link>
			) : null}
		</ModuleCard>
	) : null;
	// END MANAGEMENT INVESTOR

	// START MANAGEMENT USER BLOCK BEHAVIOUR
	const managementUserElement = modulePermissions.can('read_site', 'management') || !hasAccessAtLeastOneManagementModule ? (
		<ModuleCard
			title={t('page.home.management.user')}
			titleLevel={3}
		>
			<Link
				href={PATHS.SEARCH.SITE_LIST.LEGACY}
				target={EnumTargetLink.BLANK}
				onClick={(event: MouseEvent<HTMLElement>) => onClickLinkManagementElement(event, modulePermissions.can('read_site', 'management'))}
			>
				<Icon
					name='chevron-right'
				/>
				{t('general.action.view_my_other', {
					item: t('module.site.name_other'),
				})}
			</Link>
		</ModuleCard>
	) : null;
	// END MANAGEMENT USER BLOCK BEHAVIOUR

	// START MANAGEMENT ELEMENTS DISPLAY
	const managementBlock = (
		<Paragraph
			className={styles.cell}
			hasBorder={true}
			icon={'steering-wheel'}
			title={t('format.capitalize', {
				text: t('page.home.block_module', {
					'%modulename%': t('module.management.name')
				})
			})}
			titleLevel={2}
		>
			{managementInvestorElement}
			{managementUserElement}
		</Paragraph>
	);
	// END MANAGEMENT ELEMENTS DISPLAY

	// START MODAL TEMPLATES USED
	const lastRequesttModalTemplate = <ModalDefault {...lastDemoRequestedProps} />;
	const ModalProductDemoTemplate = (
		<ModalProductDemo
			{...activeModulePropsState}
		/>
	);

	const usedModalTemplate = demoRequestHasJustBeenRequested ? lastRequesttModalTemplate : ModalProductDemoTemplate;
	// END MODAL TEMPLATES USED

	const restrictedElement = (
		<div className={styles.restricted}>
			<Image
				className={styles.image}
				src={locker}
			/>
			<span className={styles.title}>{t('component.input.restricted.title')}</span>
			<span className={styles.text}>
				{t('component.input.restricted.text')}
			</span>
			<span className={styles.text}>
				{userState.modules?.simple_search_engine?.activity?.demo_requested ? t('component.input.restricted.contact_text') : (
					<>
						{t('component.input.restricted.ask_contact_text')}
						<Link
							className={styles.link}
							href={null}
							theme={EnumThemeLink.NAKED}
							onClick={(event: MouseEvent<HTMLInputElement>) => {
								handleOnClickAskRequestDemo(event, {
									itemName: 'simple_search_engine'
								});
							}}
						>
							{t('component.input.restricted.ask_contact_link')}
						</Link>
					</>
				)}
			</span>
		</div>
	);

	return (
		<Page className={styles.PageHome}>
			<div className={styles.PageHome__content}>
				<div className={styles.PageHome__banner}>
					<h1>
						{
							t('page.home.title', {
								'%username%': userState.firstname !== '' ? userState.firstname : userState.username
							})
						}
					</h1>
					<div className={styles.search}>
						<div className={styles.search__wrapper}>
							<form
								autoComplete="off"
								onSubmit={(event) => {
									event.preventDefault(); // this form can't be submit yet
								}}
							>
								<InputSearchWithDropdow
									api={{
										url: `${APP_CONF_VARS.privatePath}/search`,
									}}
									autoFocus={true}
									className={styles.search__field}
									hasShadow={true}
									id='autosuggest_home'
									name='autosuggest_home'
									placeholder={t('component.input.autosuggest.placeholder')}
									restrictedElement={userState.modules?.simple_search_engine?.permissions?.read ? null : restrictedElement}
									size={EnumInputSize.BIG}
									type={EnumInputType.TEXT}
									onClickResult={(event: MouseEvent<HTMLElement>, result: dataToShowProps) => {
										eventEmitter.dispatch(EVENTS.USER_SIMPLE_SEARCH_CLICK_RESULT, {
											simple_search: {
												item: result
											}
										});
									}}
									onDisplayResults={(results) => {
										const eventName = results.length ? EVENTS.USER_SIMPLE_SEARCH_GET_RESULTS : EVENTS.USER_SIMPLE_SEARCH_GET_NO_RESULT;
										eventEmitter.dispatch(eventName, {
											simple_search: {
												results: results
											}
										});
									}}
								/>
							</form>
						</div>
					</div>
				</div>
				<div className={styles.wrapper}>
					<div className={styles.col}>
						{searchEnginesBlock}
						{studiesBlock}
						{privateObservatoriesBlock}
					</div>
					<div className={styles.col}>
						{commercializationElement}
						{managementBlock}
					</div>
				</div>
			</div>
			<Modal
				hasHeader={false}
				isDesktopResolution={isDesktopResolution}
				isOpen={isOpenState}
				onClose={() => {
					setIsOpenState(false);
					setDemoRequestHasJustBeenRequested(false); // set the current request demo state to false when a demo request has just been sent then displayed before closing the modal window
				}}
			>
				{usedModalTemplate}
			</Modal>
			<Footer />
		</Page>
	);
}

export default withTranslation()(PageHome);
