import {
	createSlice,
} from '@reduxjs/toolkit';
import {
	CollectionJson,
} from '@@types/index';

export interface MatchingOfferDemands {
	offer_id: number;
	demand_ids: number[];
}

export interface StoreMatchingOffersDemands {
	instance: CollectionJson<MatchingOfferDemands>;
}

// Define the initial state using that type
const initialState: StoreMatchingOffersDemands = {
	// État initial de notre reducer qui stock les formulaires
	instance: {
		collection: [
		],
		count: 0,
	}
};

export const matchingOffersDemandsSlice = createSlice({
	name: 'matching_offers_demands',
	initialState,
	reducers: {
		clearMatchingOffersDemands: (state) => {
			state.instance = {
				collection: [
				],
				count: 0,
			};
		},
		matchingOffersDemandsUpdateDemands: (state, action) => {
			const findMatchingOffer = state.instance.collection.filter((matchingOfferDemands: MatchingOfferDemands) => {
				if (matchingOfferDemands.offer_id && action.payload.offer_id && matchingOfferDemands.offer_id === action.payload.offer_id) {
					return matchingOfferDemands;
				}
			});
			if (findMatchingOffer.length) {
				const matchingOffersDemands = state.instance.collection.map((matchingOfferDemands: MatchingOfferDemands) => {
					if (matchingOfferDemands.offer_id && action.payload.offer_id && matchingOfferDemands.offer_id === action.payload.offer_id) {
						return action.payload as MatchingOfferDemands;
					} else {
						return matchingOfferDemands;
					}
				});
				state.instance.collection = matchingOffersDemands;
			} else {
				state.instance.count = state.instance.count + 1;
				state.instance.collection = [
					action.payload,
					...state.instance.collection,
				];
			}
		},
		matchingOffersDemandsRemoveDemands: (state, action) => {
			const demandIdsToRemove = action.payload?.demand_ids?.length ? action.payload.demand_ids : [
			];

			const matchingOfferDemands = state.instance.collection.map((matchingOfferDemand: MatchingOfferDemands) => {
				if (matchingOfferDemand.offer_id === action.payload.offer_id) {
					const demandTargetedOffersFiltered = matchingOfferDemand?.demand_ids?.length ? matchingOfferDemand?.demand_ids.filter((demandId: number) => {
						return !demandIdsToRemove.includes(demandId);
					}) : [
					];

					return {
						...matchingOfferDemand,
						demand_ids: demandTargetedOffersFiltered,
					} as MatchingOfferDemands;
				} else {
					return matchingOfferDemand;
				}
			});

			state.instance.collection = matchingOfferDemands;
		},
	}
});

export const {
	clearMatchingOffersDemands,
	matchingOffersDemandsUpdateDemands,
	matchingOffersDemandsRemoveDemands,
} = matchingOffersDemandsSlice.actions;

export default matchingOffersDemandsSlice.reducer;
