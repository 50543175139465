// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RIxH83_i{color:currentcolor;text-align:center}.RIxH83_i>h1{padding:0}.AemeJglZ{display:flex;justify-content:center;margin-top:2.85714286rem;padding:0 1.14285714rem;text-align:center}@media screen and (min-width: 768Px)and (orientation: landscape){.AemeJglZ{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `RIxH83_i`,
	"main": `AemeJglZ`
};
export default ___CSS_LOADER_EXPORT___;
