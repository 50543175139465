
import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// EXCEPTIONS
import PutOfferContactError from '@exceptions/PutOfferContactError';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	putOfferContact,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	handleOnClickFocus,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	ElementProps,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputRadioGroup from '@components/form/input-radio-group';
import InputRadio from '@components/form/input-radio-group/input-radio';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../contact-row.module.scss';

function MandateElement({
	activeElement,
	currentOfferId,
	isDesktopResolution,
	onClickRow,
	offerContact,
	offerStateContacts,
	rowId,
	selectedRowId,
	setActiveElement,
	setOfferStateContacts,
}: ElementProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const mandateOptions = [
		{
			label: t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate.with')
			}),
			value: 'true',
			name: `width${rowId}`,
			id: `width${rowId}`,
		},
		{
			label: t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate.without')
			}),
			value: 'false',
			name: `without${rowId}`,
			id: `without${rowId}`,
		}
	];

	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef(null);

	const inputMandateElement = (
		<InputRadioGroup
			label={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.with_mandate')
			})}
			name={`mandate_${rowId}`}
			selectedIndex={offerContact?.is_mandated ? 0 : 1}
			onChange={async (event: ChangeEvent<HTMLInputElement>) => {
				const target = event.target as HTMLInputElement;
				const value = target.value === 'true' ? true : false;
				setOfferStateContacts({
					...offerStateContacts,
					contacts: {
						...offerStateContacts?.contacts,
						collection: offerStateContacts?.contacts?.collection.map(contact =>
							contact.id === offerContact.id
								? {
									...contact,
									is_mandated: value
								}
								: contact
						),
					} as Collection<ContactOfferJson, ContactOffer>,
				});
				const putOfferContactResponse = await putOfferContact(currentOfferId, offerContact.id, {
					comment: offerContact?.comment,
					contact_id: offerContact.contact_id,
					is_mandated: value,
					mandate_number: offerContact?.mandate_number,
					original_mail_date: offerContact?.original_mail_date,
					reference: offerContact?.reference,
					web_reference: offerContact?.web_reference,
				});
				const handleOnErrorCallback = () => {
					dispatch(addStatusMsg({
						message: t('format.capitalize', {
							text: t('status.updated_element_error_message', {
								'%type%': 'contact',
							}),
						}),
						isClosable: true,
						buttons: [
							{
								label: t('format.capitalize', {
									text: t('general.action.contact'),
								}),
								href: 'serviceclient',
							},
						],
						theme: EnumStatusTheme.ERROR,
					}));
				};

				handleErrorResponse(putOfferContactResponse, PutOfferContactError, handleOnErrorCallback);
				if (putOfferContactResponse.status !== 200) {
					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: offerStateContacts?.contacts?.collection.map(contact =>
								contact.id === offerContact.id
									? {
										...contact,
										is_mandated: !value
									}	: contact
							),
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}
			}}
		>
			{mandateOptions.map((option, key) => {
				return (
					<InputRadio
						{...option}
						key={key}
					/>
				);
			})}
		</InputRadioGroup>
	);

	const modalMandateMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.header_table.ref')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputMandateElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_mandate
	];

	if (activeElement === 'mandate' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);
	if (!offerContact?.id || offerContact?.role !== 'broker') cssClasses.push(styles.not_allowed);

	const mandateRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`mandate_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(
					activeElement,
					event,
					inputRef,
					isDesktopResolution,
					offerContact,
					onClickRow,
					setActiveElement,
					setIsOpenModal
				)}
			>
				{offerContact?.id && isDesktopResolution ? (
					offerContact.role !== 'broker' ? (
						<span className={styles.tooltip_modal_date_content}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.mandate.without')
							})}
						</span>
					) : (
						<TooltipModal
							className={styles.tooltip_modal_mandate}
							clickable={true}
							disabled={!offerContact?.id}
							htmlElement={inputMandateElement}
							id={`tooltip_mandate_${rowId}`}
							offset={1}
							openOnClick={true}
							place={EnumTooltipPosition.BOTTOM_START}
							onCloseHover={false}
						>
							<span className={styles.tooltip_modal_mandate_content}>
								{offerContact.is_mandated ? t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.mandate.with')
								}) : t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.mandate.without')
								})}
							</span>
						</TooltipModal>
					)
				) : undefined}

				{
					isDesktopResolution && !offerContact?.id ? (
						<span className={styles.tooltip_modal_mandate_content}>
							<span className={styles.placeholder}>
								{t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.mandate.placeholder')
								})}
							</span>
						</span>
					) : undefined
				}
				{!isDesktopResolution && (
					<>
						<span className={styles.mobile_label}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.header_table.mandate')
							})}
						</span>
						<span className={styles.mobile_value}>
							{offerContact?.id ? (
								offerContact.role !== 'broker' ? (
									t('format.capitalize', {
										text: t('page.offers_create_edit.contacts.mandate.without')
									})
								) : (
									offerContact.is_mandated
										? t('format.capitalize', {
											text: t('page.offers_create_edit.contacts.mandate.with')
										})
										: t('format.capitalize', {
											text: t('page.offers_create_edit.contacts.mandate.without')
										})
								)
							) : undefined}
						</span>
					</>
				)}
			</div>

			{modalMandateMobile}
		</>
	);
	return mandateRow;
}

export default MandateElement;
