import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
	func: F,
	waitFor: number = APP_CONF_VARS.timeout.debounce
) => {
	let timeout: NodeJS.Timeout;

	const debounced = (...args: Parameters<F>) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), waitFor);
	};

	return debounced;
};

export default debounce;
