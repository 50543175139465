import React from 'react';
import {
	Trans,
	useTranslation,
	withTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// ROUTES
import PATHS from '@routes/paths';

// MODULES
import eventEmitter from '@modules/eventEmitter';

// DATA
import EVENTS from '@static_data/events.data';

// LAYOUTS
import Page from '@layouts/Pages/Page';

// COMPONENTS
import Button from '@components/button';
import Link from '@components/link';

// STYLES
import styles from './step-unknown-ip-address.module.scss';

function PageUnknownIpAddress() {

	const { t } = useTranslation();

	return (
		<Page
			className={`${styles.step} wrapper__medium`}
			pageName='login'
			step='unknown_ip_address'
		>
			<div className={'wrapper__small'}>
				<Trans
					components={{
						Link: <Link
							fontWeight={EnumFontStyle.REGULAR}
							onClick={(e) => {
								// Event custom dispatched to be used with GTM
								e.preventDefault();
								eventEmitter.dispatch(EVENTS.USER_HELP_OPEN_CLICK);
							}}
						/>,
					}}
					i18nKey="page.login.step.unknown_ip_address.content"
				/>
			</div>
			<Button
				corners={EnumButtonCorners.Square}
				label={t('general.action.back_to_login')}
				to={PATHS.LOGIN._ROOT}
			/>
		</Page>
	);
}

export default withTranslation()(PageUnknownIpAddress);
