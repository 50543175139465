import {
	MigrationManifest,
} from 'redux-persist';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	FormJson,
} from '@stores/_slices/forms';

const migrations = {
	2: (previousVersionState: ReducerInstance)=> {
		// migration with app new data scheme
		return {
			...previousVersionState,
			app: {
				instance: {
					navIsOpen: false,
					desktop: {
						navIsOpen: false
					},
					mobile: {
						navIsOpen: false
					}
				}
			}
		};
	},
	3: (previousVersionState: ReducerInstance) => {
		// migration with app new data scheme
		return {
			...previousVersionState,
			forms: {
				...previousVersionState.forms,
				instances: Object.values(previousVersionState.forms.instances).map((instance: FormJson) => {
					return {
						...instance,
						isValid: true
					};
				})
			}
		};
	},
	4: (previousVersionState: ReducerInstance) => {
		return {
			...previousVersionState,
			user: {
				instance: {
					authenticated: false,
					otherUsers: [
					] as string[],
					permissions: {
					}
				}
			}
		};
	},
	5: (previousVersionState: ReducerInstance) => {
		// login form inputs labels fix
		return {
			...previousVersionState,
			forms: {
				...previousVersionState.forms,
				instances: [
				] as string[]
			}
		};
	},
	6: (previousVersionState: ReducerInstance) => {
		// auth sms form input code label fix
		return {
			...previousVersionState,
			forms: {
				...previousVersionState.forms,
				instances: [
				] as string[]
			}
		};
	},
	7: (previousVersionState: ReducerInstance) => {
		// auth sms form input code label fix
		return {
			...previousVersionState,
			app: {
				...previousVersionState.app as object,
				routes: {
					from: null
				} as null,
			}
		};
	},
	8: (previousVersionState: ReducerInstance) => {
		// auth sms form input code label fix
		return {
			...previousVersionState,
			toast_msgs: [
			] as string[]
		};
	},
	9: (previousVersionState: ReducerInstance) => {
		// login form input label fix
		return {
			...previousVersionState,
			app: {
				instance: {
					routes: {
						from: null
					} as null,
					dataLayer: [
						{
						}
					],
					desktop: {
						navIsOpen: false
					},
					isLoading: true,
					localization: {
						defaultLanguage: 'fr-FR',
						actualLanguage: 'fr-FR',
					},
					mobile: {
						navIsOpen: false
					},
					navIsOpen: false,
					tablet: {
						navIsOpen: false
					}
				}
			},
			forms: {
				instances: {
				}
			},
			toast_msgs: {
				instances: [
				] as string[]
			},
		};
	},
	10: (previousVersionState: ReducerInstance) => {
		// auth sms form input code label fix
		return {
			...previousVersionState,
			forms: {
				instances: [
				] as string[]
			}
		};
	},
	11: (previousVersionState: ReducerInstance) => {
		// login form inputs labels fix
		return {
			...previousVersionState,
			forms: {
				...previousVersionState.forms,
				instances: [
				] as string[]
			}
		};
	},
	12: (previousVersionState: ReducerInstance) => {
		// auth sms form input code label fix
		return {
			...previousVersionState,
			demands: {
				instances: [
				] as string[]
			}
		};
	},
} as MigrationManifest;

export default migrations;
