// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v5dNwmwp .GodSTnYe{font-size:1rem;line-height:1.25em;margin-bottom:0.28571429rem}.v5dNwmwp .cf1hSTSh{font-weight:600;font-size:1rem}.v5dNwmwp .trmyL6OY{align-items:center;display:flex}.v5dNwmwp .trmyL6OY .TRwFeVft:last-child{margin-left:1.14285714rem;position:relative}.v5dNwmwp .vBysopf7{width:21.42857143rem}.v5dNwmwp .WpXA4RAQ{margin-left:0.57142857rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offer_private": `v5dNwmwp`,
	"price": `GodSTnYe`,
	"price__amount": `cf1hSTSh`,
	"list_icons": `trmyL6OY`,
	"button": `TRwFeVft`,
	"dropdown_mobile": `vBysopf7`,
	"icon_element": `WpXA4RAQ`
};
export default ___CSS_LOADER_EXPORT___;
