// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oPMex42q{margin:0 auto;padding:2.85714286rem 0}.oPMex42q button{margin-left:auto;margin-right:auto}.oPMex42q h1{text-align:center}.UBJoxYWu{margin-bottom:1.71428571rem}.KfF5QJzh{justify-content:center;margin-top:2.85714286rem;padding:0 1.14285714rem;text-align:center}@media screen and (min-width: 768Px)and (orientation: landscape){.KfF5QJzh{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `oPMex42q`,
	"description": `UBJoxYWu`,
	"main": `KfF5QJzh`
};
export default ___CSS_LOADER_EXPORT___;
