import * as React from 'react';
import PATHS from '@routes/paths';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';

// MODULES
import eventEmitter from '@modules/eventEmitter';

// COMPONENTS
import Link from '@components/link';
import Text from '@components/text';

// DATA
import EVENTS from '@static_data/events.data';

// STYLES
import styles from './footer.module.scss';

interface FooterProps {
	'data-testid'?: string;
}

const Footer = ({ 'data-testid': dataTestid }: FooterProps): JSX.Element => {

	const { t } = useTranslation();

	return (
		<div
			className={styles.footer}
			data-
			data-testid={dataTestid}
		>
			<Text>
				<>
					{t('website.copyright')} -
					<Link
						fontWeight={EnumFontStyle.REGULAR}
						href={PATHS.USER.TERMS.LEGACY}
						target={EnumTargetLink.BLANK}
						theme={EnumThemeLink.SECONDARY}
					>
						&nbsp;{t('general.terms')}
					</Link> -
					<Link
						fontWeight={EnumFontStyle.REGULAR}
						theme={EnumThemeLink.SECONDARY}
						onClick={/* istanbul ignore next */() => {
							// Event custom dispatched to be used with GTM
							eventEmitter.dispatch(EVENTS.USER_MANAGE_COOKIES_CLICK);
						}}
					>
						&nbsp;{t('general.action.manage_cookies')}
					</Link>
				</>
			</Text>
		</div >
	);
};

export {
	Footer as default,
	FooterProps,
};
