import React from 'react';

import styles from './dropzone-child.module.scss';

type DropzoneChildProps = {
	'data-testid'?: string;
	children?: React.ReactNode;
	className?: string;
};

const DropzoneChild = ({
	'data-testid': dataTestId,
	children,
	className
}: DropzoneChildProps) => {

	const classList: string[] = [
		styles.dropzonechild
	];

	if (className) classList.push(className);

	return (
		<div
			className={classList.filter(n => n).join(' ')}
			data-testid={dataTestId}
		>
			{children}
		</div>
	);
};

// The default child element when the dropzone is empty
const DropzoneChildDefault = ({
	children,
	...otherProps
}: DropzoneChildProps) => {
	return (
		<DropzoneChild {...otherProps}>
			{children}
		</DropzoneChild>
	);
};
// Child element when the user is dragging a file over the dropzone or when the file dialog is open
const DropzoneChildOver = ({
	children,
	...otherProps
}: DropzoneChildProps) => {
	return (
		<DropzoneChild {...otherProps}>
			{children}
		</DropzoneChild>
	);
};

// Child element when files are being processed
const DropzoneChildProcessing = ({
	children,
	...otherProps
}: DropzoneChildProps) => {
	return (
		<DropzoneChild {...otherProps}>
			<div className={styles.progress_dots}></div>
			{children}
		</DropzoneChild>
	);
};

// Child element when the user is dragging a file over the dropzone or when the file dialog is open
const DropzoneChildError = ({
	children,
	...otherProps
}: DropzoneChildProps) => {
	return (
		<DropzoneChild {...otherProps}>
			{children}
		</DropzoneChild>
	);
};

export {
	DropzoneChild,
	DropzoneChildDefault,
	DropzoneChildError,
	DropzoneChildOver,
	DropzoneChildProcessing,
	DropzoneChildProps,
};
