import * as React from 'react';

import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import Image from '@components/image';
// STYLES
import styles from './logo.module.scss';
//IMAGES
import LogoImg from './images/logo.svg';
import santaHat from './images/santa-hat.png';

interface LogoProps {
	className?: string;
	'data-testid'?: string;
	full?: boolean;
	season?: string;
}

const Logo = ({
	className,
	'data-testid': dataTestid,
	full = false,
	season,
}: LogoProps): JSX.Element => {
	const { t } = useTranslation();
	const classes = [
		styles.logo
	];
	if (className) classes.push(className);

	const currentDate = new Date();
	const isChristmasSeason = currentDate.getMonth() === 11 && currentDate.getDate() >= 1 && currentDate.getDate() <= 25;

	const christmasHat = (
		<Image
			className={styles.santaHat}
			data-testid={`${dataTestid}-christmas`}
			src={santaHat}
		/>
	);

	const brandName = (
		<div className={styles.brandname}>
			<span className={styles.brandname__top}>
				<>
					{t('website.logobrand.part_1').split('').map((letter: string, index: number) => {
						let hat: JSX.Element | null = null;
						if (full && (isChristmasSeason || season === 'christmas') && letter.toLowerCase() === 'p') {
							hat = christmasHat;
						}
						return (
							<span
								className={styles.letter}
								data-letter={index}
								key={index}
							>
								{hat}{letter}
							</span>
						);
					})}
				</>
			</span>
			<span className={styles.brandname__bottom}>
				<>{t('website.logobrand.part_2')}</>
			</span>
		</div>
	);

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{full ? <><LogoImg alt={t('website.description')} /> {brandName}</> : <LogoImg alt={t('website.description')} />}
		</div>
	);
};

export {
	Logo as default,
	LogoProps,
};
