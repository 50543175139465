// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TJiCH0j3{position:relative}.TJiCH0j3 .QL8Rwx0z{color:var(--color-mine-shaft);font-size:1rem;position:absolute;top:50%;transform:translateY(-50%);z-index:2;right:1.42857143rem}.AS1CAfbX .QL8Rwx0z{background:var(--color-wild-sand);border-radius:0.57142857rem;color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5);cursor:not-allowed}.AS1CAfbX .QL8Rwx0z *{color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.AS1CAfbX .QL8Rwx0z:hover{background-color:var(--color-wild-sand) !important;border-color:var(--color-border-default) !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_with_unit": `TJiCH0j3`,
	"unit": `QL8Rwx0z`,
	"disabled": `AS1CAfbX`
};
export default ___CSS_LOADER_EXPORT___;
