import React from 'react';

// ENUMS
import {
	EnumToggleButton,
} from '@enums/button.enum';

// STYLES
import styles from './toggle-button.module.scss';

interface ToggleButtonProps {
    className?: string;
    children?: React.ReactNode;
    'data-testid'?: string;
	id?: string;
    isActive?: boolean;
    onClick?: (id: string) => void;
	readOnly?: boolean;
	theme?: EnumToggleButton;
}

const ToggleButton = ({
	className,
	children,
	'data-testid': dataTestid,
	id,
	isActive = false,
	onClick,
	readOnly = false,
	theme = EnumToggleButton.DEFAULT,
}: ToggleButtonProps): JSX.Element => {

	let cssClasses = [
		styles.toggle_button,
	];

	if (className) cssClasses.push(className);
	if (readOnly) cssClasses.push(styles.readOnly);
	cssClasses.push(styles[`${'theme__' + theme}`]);

	cssClasses = isActive
		? [
			...cssClasses,
			styles.active
		]
		: cssClasses.filter(cssClass => cssClass !== styles.active);

	const handleOnClick = () => {
		(!isActive);
		if (!readOnly && onClick) onClick(id);
	};

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			id={id}
			onClick={handleOnClick}
		>
			{children}
		</div>
	);
};

export {
	ToggleButton as default,
	ToggleButtonProps,
};
