import PRIVATE_ROUTES_GLOBAL, {
	PRIVATE_ROUTES,
} from '@routes/lpdipro/private/index';
import PUBLIC_ROUTES_GLOBAL, {
	PUBLIC_ROUTES,
} from '@routes/lpdipro/public/index';
import LOGIN_ROUTES from '@routes/lpdipro/public/login';

export {
	LOGIN_ROUTES,
	PRIVATE_ROUTES,
	PRIVATE_ROUTES_GLOBAL,
	PUBLIC_ROUTES,
	PUBLIC_ROUTES_GLOBAL,
};
