import {
	useEffect,
	useState,
} from 'react';
import {
	MouseEvent,
} from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// MODULES
import * as utils from '@modules/utils';
import i18n from '@modules/i18n';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	demandOfferAddContacts,
	demandUpdate,
} from '@stores/_slices/demands';

// ROUTES
import FETCHES from '@routes/fetches';

// TYPES
import {
	Collection,
	CollectionJson,
	DocumentTemplatesProps,
} from '@@types/Collection';
import {
	DemandJsonPrivate,
} from '@@types/Demand';
import {
	Offer,
	OfferJson,
} from '@@types/Offer';
import {
	Contact,
	ContactJson,
} from '@@types/Contact';

// ENUMS
import {
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import {
	ButtonListProps,
} from '@components/button-list';
import {
	ButtonWithDropdownProps,
} from '@components/button-with-dropdown';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import {
	ThemeUserCard,
} from '@components/user-card';

// LAYOUTS
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';

// DATA
import {
	getItemsContactData,
} from '@static_data/button-with-dropdown-contact.data';
import {
	getItemsOfferData,
} from '@static_data/button-with-dropdown-offer.data';

export const GetCardsConfiguration = (data: Offer, isDesktopResolution: boolean, demandId: number, commercialization_step: string, onClickModal?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void, onClickCommercializationStep?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void) => {
	const dispatch = useDispatch();
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const demands = useSelector((state: ReducerInstance) => state.demands?.instance);
	const brokers = useSelector((state: ReducerInstance) => state.user.instance.company.brokers);
	const documentsTemplates = useSelector((state: ReducerInstance) => state.user.instance.company.document_templates);
	const demandWithOffersGlobalState = demands.collection.filter((item: DemandJsonPrivate) => demandId == item?.id) as DemandJsonPrivate[];
	const demandGlobalState = demandWithOffersGlobalState && demandWithOffersGlobalState[0] ? demandWithOffersGlobalState[0] : null;
	const [
		isLoading,
		setIsLoading
	] = useState(false);
	const [
		isBookmarked,
		setIsBookmarked
	] = useState(false);
	const [
		isPinned,
		setIsPinned
	] = useState(false);
	const [
		itemsContact,
		setItemsContact
	] = useState([
	]);

	useEffect(() => {
		const initialBookmarked = data?.states?.includes('bookmarked') ? true : false;
		const initialpinned = data?.states?.includes('pinned') ? true : false;
		setIsPinned(initialpinned);
		setIsBookmarked(initialBookmarked);
	}, [
		data.states
	]);

	const getStates = async (bodyRequest: BodyRequest) => {
		const response = await fetch(utils.getURL(FETCHES.private.demands.offers, {
			':demandid': `${demandId}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify(bodyRequest),
		}).then((resp) => {
			return resp.json();
		}).catch(err => {
			console.warn('ERROR : ', err);
		});

		return await response;
	};

	const getContacts = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();

		setIsLoading(true);
		const response = fetch(utils.getURL(FETCHES.private.demands.offer.contacts, {
			':demandid': `${demandId}`,
			':offerid': `${data.id}`,
		}), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			setIsLoading(false);
			switch (responseParsed.status) {
				case 200:
					setItemsContact(responseParsed.payload.collection);
					dispatch(demandOfferAddContacts({
						demand_id: demandId,
						offer_id: data.id,
						contacts: responseParsed.payload,
					}));
					break;
				case 403: {
					console.log('error');
					break;
				}
				default:
			}

		}).catch(err => {
			console.warn('ERROR : ', err);
		});

		return response;
	};

	const setDemandGlobalState = (states: string[]) => {
		const updatedOffers: OfferJson[] = [
		];
		let offerUpdated = null;

		demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
			if (offer.id === data.id) {
				offerUpdated = {
					...offer,
					states: states
				};
				updatedOffers.push(offerUpdated);
			} else {
				updatedOffers.push(offer);
			}
		});
		dispatch(demandUpdate({
			demand: {
				...demandGlobalState,
				offers: {
					...demandGlobalState.offers,
					collection: [
						...updatedOffers,
					]
				}
			},
			demand_id: demandId,
		}));
	};

	const handleOnClickThumbtack = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		let stateUpdate: string[] = [
		];

		if (data?.states?.length) stateUpdate.push(...data.states);

		if (isPinned) {
			stateUpdate = data.states.filter(state => state !== 'pinned');
		} else {
			stateUpdate.push('pinned');
		}

		const newBodyRequest = {
			ids: [
				data.id
			],
			commercialization_step,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setIsPinned(!isPinned);
					setDemandGlobalState(stateUpdate);
					break;
			}
		});
	};

	const handleOnClickStar = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		let stateUpdate: string[] = [
		];
		if (data?.states?.length) stateUpdate.push(...data.states);

		if (isBookmarked) {
			stateUpdate = data.states.filter(state => state !== 'bookmarked');
		} else {
			stateUpdate.unshift('bookmarked');
		}

		const newBodyRequest = {
			ids: [
				data.id
			],
			commercialization_step,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setIsBookmarked(!isBookmarked);
					setDemandGlobalState(stateUpdate);
					break;
			}
		});
	};

	const elementsDesktop: ButtonListProps['items'] = [
		{
			disabled: isLoading,
			hasBoxShadow: false,
			iconLeft: 'address-book',
			items: getItemsContactData(data?.contacts?.collection?.length ? data.contacts.collection : itemsContact, ThemeUserCard.SQUARE),
			isOpenAfterClick: true,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.mandated_agents'),
			}),
			loader: isLoading,
			name: `mandated_agents_${data.id}`,
			shortDropdown: true,
			size: EnumButtonSize.SMALL,
			style: {
				bottom: isDesktopResolution ? '-70%' : null,
				left: isDesktopResolution ? '100%' : '0%',
				top: isDesktopResolution ? null : '150%',
			},
			title: {
				label: i18n.t('format.capitalize', {
					text: i18n.t('general.contact_title')
				})
			},
			theme: 'naked',
			onClick: (event) => getContacts(event),

		} as ButtonWithDropdownProps,
		{
			iconLeft: 'star',
			iconStyle: isBookmarked ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isBookmarked ? i18n.t('general.remove_bookmark') : i18n.t('general.bookmark_customer'),
			}),
			name: 'bookmark',
			theme: EnumTheme.NAKED,
			onClick: handleOnClickStar
		},
		{
			hasBoxShadow: false,
			theme: EnumTheme.NAKED,
			style: {
				bottom: isDesktopResolution ? '-70%' : null,
				left: isDesktopResolution ? '100%' : '0%',
				top: isDesktopResolution ? null : '150%',
			},
			name: `more_actions_${data.id}`,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.more_actions')
			}),
			dropdownTheme: 'secondary',
			iconLeft: 'ellipsis-stroke',
			items: getItemsOfferData(brokers as CollectionJson<ContactJson>, device, documentsTemplates as CollectionJson<DocumentTemplatesProps>, data.id, onClickModal),
		} as ButtonWithDropdownProps,
		{
			iconLeft: 'thumbtack',
			className: 'separator',
			iconStyle: isPinned ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isPinned ? i18n.t('general.unpin') : i18n.t('general.pin'),
			}),
			name: 'pin',
			size: EnumButtonSize.SMALL,
			theme: EnumTheme.NAKED,
			onClick: handleOnClickThumbtack
		},
	];

	const elementsMobile: DropdownItemProps[] = [
		{
			iconLeft: 'star',
			iconStyle: isBookmarked ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isBookmarked ? i18n.t('general.remove_bookmark') : i18n.t('general.bookmark_customer'),
			}),
			onClick: handleOnClickStar
		},
		...getItemsOfferData(brokers as CollectionJson<ContactJson>, device, documentsTemplates as CollectionJson<DocumentTemplatesProps>, data.id, onClickModal) as DropdownItemProps[],
		{
			iconLeft: 'thumbtack',
			iconStyle: isPinned ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isPinned ? i18n.t('general.unpin') : i18n.t('general.pin'),
			}),
			onClick: handleOnClickThumbtack
		},
	];

	if (commercialization_step === 'matched') {
		elementsMobile.unshift({
			iconLeft: 'trash-alt',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.ignored'),
			}),
			id: 'ignored',
			separator: true,
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'ignored',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'share-arrow',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.proposed'),
			}),
			id: 'proposed',
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'proposed',
					states: data.states
				});
			}
		});
	}
	if (commercialization_step === 'proposed') {
		elementsMobile.unshift({
			iconLeft: 'trash-alt',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.ignored'),
			}),
			id: 'removed',
			separator: true,
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'removed',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'times',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.refused'),
			}),
			id: 'refused',
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'refused',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'folder',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.applied'),
			}),
			id: 'applied',
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'applied',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'calendar-plus',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.plan_my_visit'),
			}),
			id: 'plan_my_visit',
			onClick: (event) => {
				getContacts(event);
				onClickModal(event, {
					ids: [
						data.id
					],
					address: data.building?.address?.full,
					name: 'plan_my_visit',
					contacts: new Collection({
						collection: itemsContact
					}, Contact),
					images: data?.images?.collection[0]?.url,
					demandId: demandGlobalState.id,
					states: data.states,
					visit: data.visit
				});
			}
		});

	}
	if (commercialization_step === 'applied') {
		elementsMobile.unshift({
			iconLeft: 'arrow-back',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.cancel_apply'),
			}),
			id: 'proposed',
			separator: true,
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'proposed',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'folder-grid',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.apply_progress'),
			}),
			id: 'apply_progress',
			onClick: (event) => {
				onClickModal(event, {
					ids: [
						data.id
					],
					name: 'apply_progress',
					states: data.states,
				});
			}
		});
		elementsMobile.unshift({
			iconLeft: 'calendar-plus',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.plan_my_visit'),
			}),
			id: 'plan_my_visit',
			onClick: (event) => {
				getContacts(event);
				onClickModal(event, {
					ids: [
						data.id
					],
					address: data.building?.address?.full,
					name: 'plan_my_visit',
					contacts: new Collection({
						collection: itemsContact
					}, Contact),
					images: data?.images?.collection[0]?.url,
					demandId: demandGlobalState.id,
					states: data.states,
					visit: data.visit
				});
			}
		});
	}
	if (commercialization_step === 'refused' || commercialization_step === 'ignored') {
		elementsMobile.unshift({
			iconLeft: 'share-arrow',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.proposed'),
			}),
			id: 'proposed',
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'proposed',
					states: data.states
				});
			},
		});
	}

	return isDesktopResolution ? elementsDesktop : elementsMobile;
};
