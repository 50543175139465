import React, {
	ChangeEvent,
	Children,
	cloneElement,
	isValidElement,
	memo,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import {
	InputProps,
} from '@components/form/input';
import InputRadio from '@components/form/input-radio-group/input-radio';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumInputPosition,
} from '@enums/form.enum';

// STYLING
import styles from './input-radio-group.module.scss';

export interface InputRadioGroupProps extends Omit<InputProps, 'hasBorder' | 'hasShadow'> {
	selectedIndex?: number;
	children: ReactNode;
}

const InputRadioGroup = memo(({
	className,
	customError,
	'data-testid': dataTestid,
	disabled = false,
	inputPosition = EnumInputPosition.LEFT,
	invalid,
	onChange,
	methods, // May not exist
	name,
	required,
	selectedIndex = 0, // Initial value
	validationType,
	children,
}: InputRadioGroupProps): JSX.Element => {
	const { t } = useTranslation();
	const isControlled = !!methods; // Check if the form is controlled
	const registerRules: InputProps['registerRules'] = {
	};

	// Register with React Hook Form if the component is controlled
	if (isControlled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.radio'),
			};
		}
		methods?.register(name, registerRules);
	}

	const error = invalid ? {
		message: customError || methods?.formState?.errors[name]
	} : {
	};

	const classes = [
		styles.input_radio_group,
		styles[`position__${inputPosition}`]
	];

	if (className) classes.push(className);
	if (error?.message || invalid) classes.push('invalid');

	// Use local state if the form is uncontrolled
	const [
		localSelectedIndex,
		setLocalSelectedIndex
	] = useState(selectedIndex);

	// Ensure children is an array of ReactElement
	const childrenArray = Children.toArray(children) as ReactElement[];

	useEffect(() => {
		// When the component mounts or selectedIndex changes, update the value in the form
		if (methods && childrenArray[selectedIndex]?.props?.value) {
			methods.setValue(name, childrenArray[selectedIndex]?.props?.value);
		}

		setLocalSelectedIndex(selectedIndex);
	}, [
		selectedIndex,
		childrenArray
	]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
		// If the form is controlled
		if (isControlled) {
			methods?.setValue(name, childrenArray[index]?.props?.value);
			methods?.clearErrors(name);
		}
		setLocalSelectedIndex(index);

		// Trigger onChange if passed
		if (onChange) onChange(event, {
			selectedIndex: index
		});
	};

	const errorMsgElement = error?.message ? (
		<div
			className={styles.error}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{error.message}
		</div>
	) : null;

	const clonedChildren = Children.map(children, (child, index) => {
		if (isValidElement(child) && child.type === InputRadio) {
			const inputClasses = [
				styles.input_radio_group__input,
			];

			if (child.props.className) inputClasses.push(child.props.className);
			return cloneElement(child, {
				...child.props,
				className: inputClasses.join(' '),
				checked: localSelectedIndex === index, // Compare with the current value
				disabled: disabled || child.props.disabled,
				onChange: (event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, index),
				'data-testid': `${dataTestid}-radio-group`,
			});
		}
	});

	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{clonedChildren}
			{errorMsgElement}
		</div>
	);
});

InputRadioGroup.displayName = EnumComponentType.INPUT_RADIO_GROUP;

export default InputRadioGroup;

