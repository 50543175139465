import React from 'react';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from './PageDemands.module.scss';

const Loader = (props: object) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const heightTop = device.display === 'mobile' ? 50 : 50;
	const heightRow = device.display === 'mobile' ? 140 : 140;
	const spacing = device.display === 'mobile' ? 12: 12;

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader}
			height={'100%'}
			width={'100%'}
		>
			<rect
				height={heightTop}
				id="0"
				rx="4"
				ry="4"
				width="10%"
				x="0"
				y="0"
			/>

			<rect
				height={heightTop}
				id="1"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={heightTop + spacing}
			/>
			<rect
				height={heightRow}
				id="2"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2}
			/>
			<rect
				height={heightRow}
				id="3"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2 + heightRow + spacing}
			/>

			<rect
				height={heightRow}
				id="4"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2 + (heightRow + spacing) * 2}
			/>

			<rect
				height={heightRow}
				id="5"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2 + (heightRow + spacing) * 3}
			/>

			<rect
				height={heightRow}
				id="6"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2 + (heightRow + spacing) * 4}
			/>

			<rect
				height={heightRow}
				id="6"
				rx="4"
				ry="4"
				width="100%"
				x="0"
				y={(heightTop + spacing) * 2 + (heightRow + spacing) * 5}
			/>
		</ContentLoader>
	);
};

export default Loader;
