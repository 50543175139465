import React, {
	memo,
} from 'react';
import {
	useRoutes,
} from 'react-router-dom';
import {
	withTranslation,
} from 'react-i18next';

// ROUTES
import {
	PUBLIC_ROUTES_GLOBAL,
} from '@routes/index';

function AppPublic() {
	const contentRoutes = useRoutes(PUBLIC_ROUTES_GLOBAL);
	return (
		<>{contentRoutes}</>
	);
}
export default memo(withTranslation()(AppPublic));
