import React, {
	MouseEvent,
	useState,
} from 'react';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// COMPONENTS
import InputText, {
	InputTextProps,
} from '@components/form/input-text';
import Icon from '@components/icon';

// STYLING
import styles from './input-password.module.scss';

interface InputPasswordProps extends InputTextProps {
	type?: EnumInputType.PASSWORD | EnumInputType.TEXT;
}

const InputPassword = ({
	className,
	type = EnumInputType.PASSWORD,
	...otherProps
}: InputPasswordProps): JSX.Element => {
	const initialState: InputPasswordProps = {
		isIconRightToggled: type === EnumInputType.PASSWORD ? false : true,
		type,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const handleClickIcon = (event: MouseEvent<HTMLElement>, isToggled: boolean) => {
		const newState = {
			...state,
			type: isToggled ? EnumInputType.TEXT : EnumInputType.PASSWORD,
			isIconRightToggled: isToggled,
		};

		setState(newState as InputPasswordProps);
	};

	const classes = [
		styles.input_password,
	];

	if (className) classes.push(className);

	return (
		<InputText
			{...otherProps}
			className={classes.join(' ')}
			iconRight={state.isIconRightToggled ? (
				<Icon
					data-testid={`${otherProps['data-testid']}-icon-right`}
					fontStyle={EnumFontStyle.LIGHT}
					name="eye-slash"
					onClick={(event) => handleClickIcon(event, !state.isIconRightToggled)}
				/>
			) : (
				<Icon
					data-testid={`${otherProps['data-testid']}-icon-right`}
					fontStyle={EnumFontStyle.LIGHT}
					name="eye"
					onClick={(event) => handleClickIcon(event, !state.isIconRightToggled)}
				/>
			)}
			type={state.type}
		/>
	);
};

InputPassword.displayName = EnumComponentType.INPUT_PASSWORD;

export default InputPassword;
