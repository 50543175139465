import {
	OFFER_ENTRY_CONTACTS_ROUTES,
	OFFER_ENTRY_INFOS_ROUTES,
	OFFER_ENTRY_LOCATION_EDIT_ROUTES,
	OFFER_ENTRY_LOTS_ROUTES,
	OFFER_ENTRY_MEDIA_ROUTES,
	OFFER_ENTRY_SERVICES_ROUTES,
	OFFER_ENTRY_SUMMARY_ROUTES,
	OFFER_ENTRY_TERMS_ROUTES,
} from '@routes/lpdipro/private/offer-entry';

export const routesMapping = [
	{
		step: 'location',
		route: OFFER_ENTRY_LOCATION_EDIT_ROUTES,
	},
	{
		step: 'infos',
		route: OFFER_ENTRY_INFOS_ROUTES,
	},
	{
		step: 'contacts',
		route: OFFER_ENTRY_CONTACTS_ROUTES,
	},
	{
		step: 'lots',
		route: OFFER_ENTRY_LOTS_ROUTES,
	},
	{
		step: 'media',
		route: OFFER_ENTRY_MEDIA_ROUTES,
	},
	{
		step: 'services',
		route: OFFER_ENTRY_SERVICES_ROUTES,
	},
	{
		step: 'terms',
		route: OFFER_ENTRY_TERMS_ROUTES,
	},
	{
		step: 'summary',
		route: OFFER_ENTRY_SUMMARY_ROUTES,
	},
];
