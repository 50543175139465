import * as React from 'react';
import {
	Dispatch,
	FormEvent,
	MouseEvent,
	SetStateAction,
	useState,
} from 'react';
import {
	Trans,
	useTranslation,
	withTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button from '@components/button';
import InputDatePicker from '@components/form/input-date-picker';
import InputSelect, {
	DropdownSize,
} from '@components/form/input-select';
import LabelStatus from '@components/label-status';

// STYLES
import styles from './ModalUpdateDemandApply.module.scss';

interface ModalUpdateDemandApplyProps {
	demandId?: number;
	isDesktopResolution?: boolean;
	offerId?: number;
	states?: string[];
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setApplicationModalConfirmation?: Dispatch<SetStateAction<boolean>>;
	onClickApplication?: (event: FormEvent<HTMLFormElement>, args: object) => void;
}

function ModalUpdateDemandApply({
	isDesktopResolution,
	offerId,
	states,
	setIsOpenModal,
	setApplicationModalConfirmation,
	onClickApplication,
}: ModalUpdateDemandApplyProps) {
	const { i18n, t } = useTranslation();

	const [
		invalid,
		setInvalid
	] = useState(undefined);

	const handleOnClickConfirm = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault(); // Prevent default submission

		const target = event.target as HTMLElement;
		const inputsValues = {
		} as { [key: string]: string };
		const inputsForm = [
			...target.closest('div').querySelectorAll('input'),
		];
		inputsForm.forEach((input: HTMLInputElement) => {
			switch (true) {
				case input.name === 'appointment_date': {
					inputsValues[input.name] = input.value;
					break;
				}
			}
		});

		const bodyRequest = {
			date: inputsValues['appointment_date'],
			ids: [
				offerId
			],
			state: informationState,
		};

		if (((informationState === 'application_accepted') && (inputsValues['appointment_date'])) ||
		(informationState === 'application_submitted') || (informationState === 'application_refused') ||
		(informationState === 'application_to_submit')) {
			setIsOpenModal(false);
			if (onClickApplication) onClickApplication(event as FormEvent<HTMLFormElement>, bodyRequest);
		} else if ((informationState === 'application_signed') && (inputsValues['appointment_date']) ) {
			setApplicationModalConfirmation(true);
			if (onClickApplication) onClickApplication(event as FormEvent<HTMLFormElement>, bodyRequest);
		} else {
			setInvalid({
				message: t('general.form.input.error.date_picker')
			});
		}
	};

	const [
		selectedIndexState,
		setSelectedIndexState
	] = useState(undefined);

	const optionsElement = [
		{
			value: 'application_to_submit',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.application_to_submit')
			}),
			theme: EnumStatusTheme.WARNING,
		},
		{
			value: 'application_submitted',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.application_submitted')
			}),
			theme: EnumStatusTheme.INFO,
		},
		{
			value: 'application_accepted',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.application_accepted')
			}),
			theme: EnumStatusTheme.SUCCESS,
		},
		{
			value: 'application_signed',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.application_signed')
			}),
			theme: EnumStatusTheme.SUCCESS,
		},
		{
			value: 'application_refused',
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.application_refused')
			}),
			theme: EnumStatusTheme.ERROR,
		},
	];

	const initialIndex = optionsElement.findIndex((option) => {
		return states.includes(option.value);
	});

	const state = initialIndex === -1 ? optionsElement[0].value : optionsElement[initialIndex].value;

	const [
		informationState,
		setInformationState
	] = useState(state);

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModal(false);
	});

	const handleOnClickOption = (event: MouseEvent<HTMLElement> | FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const target = event.target as HTMLElement;
		const selectElement = target.closest('form').querySelector('select');
		const optionIndexSelected = optionsElement.findIndex((option) => option.value === selectElement.value);

		setSelectedIndexState(optionIndexSelected);
		let informationText = null;

		switch (selectElement.value) {
			case 'application_to_submit':
				informationText = 'application_to_submit';
				break;
			case 'application_submitted':
				informationText = 'application_submitted';
				break;
			case 'application_accepted':
				informationText = 'application_accepted';
				break;
			case 'application_signed':
				informationText = 'application_signed';
				break;
			case 'application_refused':
				informationText = 'application_refused';
				break;
		}
		setInformationState(informationText);
	};

	return (
		<div className={styles.update_demand_apply}>
			<form
				onSubmit={handleOnClickConfirm}
			>
				<h2 className={styles.title}>
					{t('format.capitalize', {
						text: t('modals.update_apply.title')
					})}
				</h2>
				<InputSelect
					className={styles.select}
					dropdownSize={DropdownSize.FULL}
					hasEllipsis={true}
					name="update_demand_state"
					options={optionsElement}
					placeholder={i18n.t('format.capitalize', {
						text: i18n.t(`general.action.${informationState}`)
					})}
					selectedIndexes={[
						selectedIndexState !== undefined ? selectedIndexState : initialIndex
					]}
					style={isDesktopResolution ? {
						top: 'calc(100% + 6px)',
						left: '0'
					} : {
						bottom: 'calc(100% + 6px)',
						left: '0'
					}}
					onClickOption={handleOnClickOption}
				/>
				{informationState === 'application_accepted' || informationState === 'application_signed' ? (
					<>
						<div className={styles.information}>
							<div>
								<Trans
									components={{
										LabelStatus: <LabelStatus
											context='female'
											text={informationState}
										/>,
									}}
									i18nKey={'modals.update_apply.new_state'}
								/>
							</div>
							<div>
								<Trans
									components={{
										br: <br />,
										span: <span />,
									}}
									i18nKey={`modals.update_apply.information.${informationState}`}
								/>
							</div>
						</div>
						<div className={styles.app_choice_date}>
							<InputDatePicker
								className={styles.date_picker}
								hasPlaceholder={true}
								invalid={invalid}
								label={t('format.capitalize', {
									text: i18n.exists(`modals.application.${state}.date_picker_label`) ? t(`modals.application.${state}.date_picker_label`) : t('general.form.input.type.date_picker.label')
								})}
								maxDate={new Date()}
								name="appointment_date"
								required={true}
							/>
						</div>
					</>
				) : null}

				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>

				</div>
			</form>
		</div>
	);
}

export default withTranslation()(ModalUpdateDemandApply);
