import React, {
	ChangeEvent,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	findDefaultOption,
	generateOptions,
	handleOnChange,
	handleOptionChange,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import Header from '@layouts/Pages/PageOfferEntry/header';

// ENUMS
import {
	EnumInputType,
} from '@enums/form.enum';

// COMPONENTS
import Form from '@components/form';
import InputCheckbox from '@components/form/input-checkbox';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputRadioGroup from '@components/form/input-radio-group';
import InputRadio from '@components/form/input-radio-group/input-radio';
import InputText from '@components/form/input-text';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Textarea from '@components/form/textarea';
import Image from '@components/image';

// ASSETS
import infosImage from '@assets/images/lpdipro/illu_man_working.png';

// STYLES
import ValidationForm from '../validation-form';
import styles from './infos.module.scss';

const Infos = () => {
	const { t } = useTranslation();
	const {
		dirtySteps,
		formRef,
		isDesktopResolution,
		offerEnumsState,
		offerState,
		setDirtySteps,
		setOfferState,
	} : PageOfferContextProps = useOutletContext();

	const defaultLease = offerEnumsState?.rental_ref_lease_types.find(item => item.value === offerState?.leases[0]?.rental_ref_lease_type_id)?.key;

	const [
		selectedLease,
		setSelectedLease
	] = useState(defaultLease);

	const onLeaseChange = (selectedValue: number) => {
		const foundLease = offerEnumsState?.rental_ref_lease_types.find(item => item.value === selectedValue);
		const key = foundLease ? foundLease.key : null;
		setSelectedLease(key);
	};

	const handleOnChangeLease = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = String(target.value);
		const name = target.name;

		const leases = [
		] as {
			details: {
				[key: string]: string | number;
			};
		}[];

		offerState.leases.forEach((lease) => {
			let details = undefined;
			details = {
				...lease,
				details: {
					...lease.details,
					[name]: value,
				}
			};

			leases.push(details);

			if (!dirtySteps.infos) {
				setDirtySteps({
					...dirtySteps,
					infos: true,
				});
			}

			setOfferState({
				...offerState,
				leases: leases,
			});
		});
	};

	const inputsInfo = () => {
		switch (selectedLease) {
			case 'commercial': {
				return (
					<>
						{Array.from({
							length: 3
						}, (_, index) => (
							<InputTextWithUnit
								defaultValue={
									offerState?.leases[0]?.details?.[`nb_years_${index + 1}`]
										? (offerState.leases[0].details[`nb_years_${index + 1}`] as string)
										: undefined
								}
								key={index}
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.lease.commercial.nb_years.label')
								})}
								name={`nb_years_${index + 1}`}
								type={EnumInputType.NUMBER}
								unit={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.lease.commercial.nb_years.unit')
								})}
								onChange={handleOnChangeLease}
							/>
						))}
						<InputText
							defaultValue={offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity as string : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.label')
							})}
							name="additional_maturity"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.placeholder')
							})}
							onChange={handleOnChangeLease}
						/>
					</>
				);
			}
			case 'lease_to_be_specified':
			case 'professional':
			case 'tenancy_overriding':
			case 'subletting':
			case 'service_agreement': {
				return (
					<>
						<InputText
							defaultValue={offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity as string : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.label')
							})}
							name="additional_maturity"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.commercial.additional_maturity.placeholder')
							})}
							onChange={handleOnChangeLease}
						/>
					</>
				);
			}
			default:
				return null;
		}
	};

	const privatePublicOptions = offerEnumsState?.visibilities.map(({ key, value }) => {
		return {
			id: key,
			label: t('format.capitalize', {
				text: t(`page.offers_create_edit.infos.visibilities.${key}`)
			}),
			name: 'is_private',
			value: value,
		};
	});

	const mandateType = offerEnumsState?.mandate_type.map((value: string) => {
		return {
			id: value,
			label: t('format.capitalize', {
				text: t(`general.mandate.${value}`)
			}),
			name: 'mandate_type',
			value: value,
		};
	});

	return (
		<>
			<Header
				isValid={offerState.types?.length ? true : false}
			/>
			<div className={styles.section}>
				<div className={styles.section__left}>
					<div className={styles.step_progress}>
						{t('format.capitalize', {
							text: `${t('page.offers_create_edit.step')} 2/8`
						})}
					</div>
					<div className={styles.legend}>{t('general.form.input.required')}</div>
					<Form innerRef={formRef}>
						{/* --------- Is this offer private or public? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.visibilities.title')
							})}
						</div>
						<InputRadioGroup
							name="is_private"
							selectedIndex={offerState.is_private ? 1 : 0}
							onChange={(event) => handleOnChange(event, 'infos', setDirtySteps, setOfferState)}
						>
							{privatePublicOptions.map((option, key) => {
								return (
									<InputRadio
										{...option}
										key={key}
									/>
								);
							})}
						</InputRadioGroup>

						{/* --------- What is the main nature of your offer? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.nature.title')
							})}
						</div>
						<InputMultiSelect
							className={styles.full_select}
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_property_nature_announcements, 'natures'), offerState?.ref_property_nature_main_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.nature.label')
							})}
							name="ref_property_nature_main_id"
							options={generateOptions(offerEnumsState?.ref_property_nature_announcements, 'natures')}
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.nature.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_property_nature_main_id', selected, 'infos', setDirtySteps, setOfferState);
							}}
						/>

						{/* --------- What is the name of the building? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building.title')
							})}
						</div>
						<InputText
							defaultValue={offerState.building_name ? offerState.building_name : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building.label')
							})}
							name="building_name"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building.placeholder')
							})}
							onChange={(event) => handleOnChange(event, 'infos', setDirtySteps, setOfferState)}
						/>

						{/* --------- Information related to the building --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building_info.title')
							})}
						</div>
						<div className={styles.form_row}>
							<InputText
								defaultValue={offerState.program_name ? offerState.program_name : undefined}
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.building_info.program_name.label')
								})}
								name="program_name"
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.building_info.program_name.placeholder')
								})}
								onChange={(event) => handleOnChange(event, 'infos', setDirtySteps, setOfferState)}
							/>
							<InputText
								defaultValue={offerState.caz_name ? offerState.caz_name : undefined}
								label={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.building_info.caz_name.label')
								})}
								name="caz_name"
								placeholder={t('format.capitalize', {
									text: t('page.offers_create_edit.infos.building_info.caz_name.placeholder')
								})}
								onChange={(event) => handleOnChange(event, 'infos', setDirtySteps, setOfferState)}
							/>
						</div>
						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_building_commercial_zones), offerState?.ref_building_commercial_zone_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building_info.ref_building_commercial_zones.label')
							})}
							maxMenuHeight={150}
							name="ref_building_commercial_zones"
							options={generateOptions(offerEnumsState?.ref_building_commercial_zones)}
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.building_info.ref_building_commercial_zones.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_building_commercial_zone_id', selected, 'infos', setDirtySteps, setOfferState);
							}}
						/>

						{/* --------- What are the types of transport around your offer? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.transport_access.title')
							})}
						</div>
						<Textarea
							defaultValue={offerState.transport_access ? offerState.transport_access : undefined}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.transport_access.label')
							})}
							name="transport_access"
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.transport_access.placeholder')
							})}
							rows={5}
							onChange={(event) => handleOnChange(event, 'infos', setDirtySteps, setOfferState)}
						></Textarea>

						{/* --------- What is your type of mandate? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.mandate_type.title')
							})}
						</div>
						<InputRadioGroup
							name="mandate_type"
							selectedIndex={offerEnumsState?.mandate_type.findIndex((option: string) => option === offerState.mandate?.type) || 0}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								const target = event.target as HTMLInputElement;
								const value = target.value;

								if (!dirtySteps.infos) {
									setDirtySteps(prevState => ({
										...prevState,
										infos: true,
									}));
								}
								setOfferState({
									...offerState,
									mandate: {
										type: value,
									},
								});
							}}
						>
							{mandateType.map((option, key) => {
								return (
									<InputRadio
										{...option}
										key={key}
									/>
								);
							})}
						</InputRadioGroup>

						{/* --------- What is your type of offer? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.types.title')
							})} {<span>*</span>}
						</div>
						<div className={styles.checkbox}>
							{offerEnumsState?.types.map((type, key) => {
								return (
									<InputCheckbox
										checked={offerState.types?.includes(type)}
										id={`offer_type_${type}`}
										key={key}
										label={t('format.capitalize', {
											text: t(`page.offers_create_edit.infos.types.${type}`)
										})}
										name={`offerType_${type}`}
										required={true}
										value={type}
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											const target = event.target as HTMLInputElement;
											const value = target.value;
											const isChecked = target.checked;

											let types = offerState.types || [
											] as string[];
											if (isChecked) {
												types.push(value);
											} else {
												types = types.filter((type) => type !== value);
											}

											if (!dirtySteps.infos) {
												setDirtySteps(prevState => ({
													...prevState,
													infos: true,
												}));
											}

											setOfferState({
												...offerState,
												steps: {
													...offerState?.steps,
													infos: false,
												},
												types: types,
											});
										}}
									/>
								);
							})}
						</div>

						{/* --------- What is your type of lease? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.title')
							})}
						</div>
						<InputMultiSelect
							className={styles.full_select}
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.rental_ref_lease_types), offerState?.leases[0]?.rental_ref_lease_type_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.label')
							})}
							maxMenuHeight={150}
							name="lease"
							options={generateOptions(offerEnumsState?.rental_ref_lease_types)}
							placeholder={t('format.capitalize', {
								text: t('page.offers_create_edit.infos.lease.placeholder')
							})}
							onChange={(selected: OptionType) => {
								onLeaseChange(Number(selected.value));
								if (!dirtySteps.infos) {
									setDirtySteps(prevState => ({
										...prevState,
										infos: true,
									}));
								}
								setOfferState({
									...offerState,
									leases: [
										{
											rental_ref_lease_type_id: Number(selected.value),
											details: {
												additional_maturity: offerState?.leases[0]?.details?.additional_maturity ? offerState.leases[0].details.additional_maturity : undefined,
											}
										}
									],
									steps: {
										...offerState?.steps,
										infos: false,
									},
								});
							}}
						/>
						<div className={styles.form_column}>
							{inputsInfo()}
						</div>
					</Form>
					<ValidationForm
						isValid={offerState.types?.length ? true : false}
					/>
				</div>
				{isDesktopResolution && (
					<div className={styles.section__right}>
						<Image
							alt='placeholder'
							className={styles.image}
							src={infosImage}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default withTranslation()(Infos);
