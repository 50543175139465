// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HHXBxfsZ{display:flex;flex-direction:row;overflow:hidden}@media screen and (min-width: 768Px)and (orientation: landscape){.HHXBxfsZ{height:100vh}}.fZ2vIXuR{display:block;left:0;margin:0 1.14285714rem;position:fixed;top:0.57142857rem;width:calc(100% - 2.28571429rem);z-index:9}@media screen and (min-width: 768Px)and (orientation: landscape){.fZ2vIXuR{left:inherit;margin:auto;position:absolute;right:2.5rem;top:6.78571429rem;width:max-content}}.fZ2vIXuR .GSAbbEo4{margin-bottom:1.42857143rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `HHXBxfsZ`,
	"toastMsgWrapper": `fZ2vIXuR`,
	"toastMsgs": `GSAbbEo4`
};
export default ___CSS_LOADER_EXPORT___;
