// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uRdq5sxS{display:flex;flex-direction:column;margin-left:auto;margin-right:auto;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.uRdq5sxS{flex-direction:row}}.uRdq5sxS .sCPj2y7O{display:flex;flex-direction:column}.uRdq5sxS .yfmntqt5{display:flex;flex-direction:column}.uRdq5sxS .LVcI9PHN{padding:1.42857143rem 1.14285714rem 0}@media screen and (min-width: 768Px)and (orientation: landscape){.uRdq5sxS .LVcI9PHN{height:calc(100vh - 4.57142857rem - 8.14285714rem);min-width:30rem;overflow-y:auto;padding:1.42857143rem 0.71428571rem 1.42857143rem 1.42857143rem;position:relative;width:30%}}.uRdq5sxS .Ea5V8rlC{padding:0 1.14285714rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.uRdq5sxS .Ea5V8rlC{height:calc(100vh - 4.57142857rem - 8.14285714rem);overflow-y:auto;padding:1.42857143rem 1.42857143rem 1.42857143rem 0.71428571rem;position:relative;width:70%;z-index:1}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"report": `uRdq5sxS`,
	"heading": `sCPj2y7O`,
	"section": `yfmntqt5`,
	"section__left": `LVcI9PHN`,
	"section__right": `Ea5V8rlC`
};
export default ___CSS_LOADER_EXPORT___;
