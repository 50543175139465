import React from 'react';
import ContentLoader from 'react-content-loader';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from '../card-view.module.scss';

const Loader = (props: object) => {

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader_search}
		>
			<rect
				height="100%"
				id="0"
				rx={4}
				ry={4}
				width="100%"
				x={0}
				y={0}
			/>
		</ContentLoader>
	);
};

export default Loader;
