import React from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// ENUMS
import {
	EnumToggleButton,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	findDefaultOption,
	generateOptions,
	handleOnChange,
	handleOnChangeToggle,
	handleOptionChange,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import Header from '@layouts/Pages/PageOfferEntry/header';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import Form from '@components/form';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import InputRadioGroup from '@components/form/input-radio-group';
import InputRadio from '@components/form/input-radio-group/input-radio';
import InputText from '@components/form/input-text';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import InputToggleButton from '@components/form/input-toggle-button';
import Textarea from '@components/form/textarea';
import Icon from '@components/icon';
import Image from '@components/image';
import ToggleButton from '@components/toggle-button-list/toggle-button';

// ASSETS				formRef={formRef}

import servicesImage from '@assets/images/lpdipro/illu_man_services.png';

// STYLES
import styles from './services.module.scss';

const Services = () => {
	const { t } = useTranslation();

	const {
		isDesktopResolution,
		offerEnumsState,
		offerState,
		setDirtySteps,
		setOfferState,
	}: PageOfferContextProps = useOutletContext();

	return (
		<>
			<Header
				isValid={true}
			/>
			<div className={styles.section}>
				<div className={styles.section__left}>
					<div className={styles.step_progress}>
						{t('format.capitalize', {
							text: `${t('page.offers_create_edit.step')} 6/8`
						})}
					</div>

					<Form>
						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.description')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.private_offer_title.title')
							})}
						</div>

						<InputText
							defaultValue={offerState.private_offer_title ? offerState.private_offer_title : undefined}
							name="private_offer_title"
							placeholder={t('page.offers_create_edit.services.private_offer_title.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.building_description.title')
							})}
						</div>

						<Textarea
							defaultValue={offerState?.building_description}
							name="building_description"
							placeholder={t('page.offers_create_edit.services.building_description.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.building_services')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.is_independent_building.title')
							})}
						</div>

						<InputToggleButton
							activeButtonValues={[
								offerState.is_independent_building.toString()
							]}
							className={styles.toggle}
							isLockedSelection={true}
							isMultiselect={false}
							name="is_independent_building"
							theme={EnumToggleButton.DEFAULT}
							onChange={(selectedIds) => {
								handleOnChangeToggle('is_independent_building', selectedIds, 'services', setDirtySteps, setOfferState);
							}}
						>
							{offerEnumsState?.is_independent_building?.map((value, index) => (
								<ToggleButton
									className={styles.toggle__button}
									id={`${value}`}
									key={index}
								>
									{offerState.is_independent_building === value ? (
										<Icon
											className={styles.toggle__icon}
											name='check'
										/>
									) : undefined}
									{t('format.capitalize', {
										text: t(`general.${value ? 'yes' : 'no'}`)
									})}
								</ToggleButton>
							))}
						</InputToggleButton>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_property_state_id.title')
							})}
						</div>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_property_states), offerState?.ref_property_state_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_property_state_id.label')
							})}
							maxMenuHeight={150}
							name='ref_property_state_id'
							options={generateOptions(offerEnumsState?.ref_property_states)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_property_state_id', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_offer_certification_classement_ids.title')
							})}
						</div>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_offer_certification_classements), offerState?.ref_offer_certification_classement_ids)}
							hasShadowMenu={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_offer_certification_classement_ids.label')
							})}
							maxMenuHeight={150}
							name='ref_offer_certification_classement_ids'
							options={generateOptions(offerEnumsState?.ref_offer_certification_classements)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_offer_certification_classement_ids', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_offer_certification_labels), offerState?.ref_offer_certification_label_ids)}
							hasShadowMenu={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_offer_certification_label_ids.label')
							})}
							maxMenuHeight={150}
							name='ref_offer_certification_label_ids'
							options={generateOptions(offerEnumsState?.ref_offer_certification_labels)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_offer_certification_label_ids', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<Textarea
							defaultValue={offerState?.certification_comment}
							name="certification_comment"
							placeholder={t('page.offers_create_edit.services.certification_comment.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.construction_comment.title')
							})}
						</div>

						<Textarea
							defaultValue={offerState?.construction_comment}
							name="construction_comment"
							placeholder={t('page.offers_create_edit.services.construction_comment.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.building_services.title')
							})}
						</div>

						<Textarea
							defaultValue={offerState?.building_services}
							name="building_services"
							placeholder={t('page.offers_create_edit.services.building_services.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.services_activities')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_building_warehouse_classification_id.title')
							})}
						</div>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_building_warehouse_classifications), offerState?.ref_building_warehouse_classification_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_building_warehouse_classification_id.label')
							})}
							maxMenuHeight={150}
							name='ref_building_warehouse_classification_id'
							options={generateOptions(offerEnumsState?.ref_building_warehouse_classifications)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_building_warehouse_classification_id', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_building_warehouse_typologies), offerState?.ref_building_warehouse_typology_id)}
							hasShadowMenu={true}
							isMulti={false}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_building_warehouse_typology_id.label')
							})}
							maxMenuHeight={150}
							name='ref_building_warehouse_typology_id'
							options={generateOptions(offerEnumsState?.ref_building_warehouse_typologies)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_building_warehouse_typology_id', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<InputMultiSelect
							defaultValue={findDefaultOption(generateOptions(offerEnumsState?.ref_icpe_codes, null), offerState?.ref_icpe_code_ids)}
							hasShadowMenu={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.services.ref_icpe_code_ids.label')
							})}
							maxMenuHeight={150}
							name='ref_icpe_code_ids'
							options={generateOptions(offerEnumsState?.ref_icpe_codes, null)}
							placeholder={t('format.capitalize', {
								text: t('general.form.input.type.select.placeholder')
							})}
							onChange={(selected: OptionType) => {
								handleOptionChange('ref_icpe_code_ids', selected, 'services', setDirtySteps, setOfferState);
							}}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.is_single_storey_building.title')
							})}
						</div>

						<InputToggleButton
							activeButtonValues={[
								offerState?.is_single_storey_building?.toString()
							]}
							className={styles.toggle}
							isLockedSelection={true}
							isMultiselect={false}
							name="is_single_storey_building"
							theme={EnumToggleButton.DEFAULT}
							onChange={(selectedIds) => {
								handleOnChangeToggle('is_single_storey_building', selectedIds, 'services', setDirtySteps, setOfferState);
							}}
						>
							{offerEnumsState?.is_single_storey_building?.map((value, index) => (
								<ToggleButton
									className={styles.toggle__button}
									id={`${value}`}
									key={index}
								>
									{offerState.is_single_storey_building === value ? (
										<Icon
											className={styles.toggle__icon}
											name='check'
										/>
									) : undefined}
									{t('format.capitalize', {
										text: t(`general.${value ? 'yes' : 'no'}`)
									})}
								</ToggleButton>
							))}
						</InputToggleButton>
						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.logistic_hub_height}` || '0'}
							label={t('page.offers_create_edit.services.logistic_hub_height.label')}
							name="logistic_hub_height"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.logistic_hub_height.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>
						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.loading_dock}` || '0'}
							label={t('page.offers_create_edit.services.loading_dock.label')}
							name="loading_dock"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.loading_dock.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>
						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.grade_level_door}` || '0'}
							label={t('page.offers_create_edit.services.grade_level_door.label')}
							name="grade_level_door"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.grade_level_door.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.is_single_storey_building.title')
							})}
						</div>

						<InputToggleButton
							activeButtonValues={[
								offerState.has_branch_line_ite.toString()
							]}
							className={styles.toggle}
							isLockedSelection={true}
							isMultiselect={false}
							name="has_branch_line_ite"
							theme={EnumToggleButton.DEFAULT}
							onChange={(selectedIds) => {
								handleOnChangeToggle('has_branch_line_ite', selectedIds, 'services', setDirtySteps, setOfferState);
							}}
						>
							{offerEnumsState?.has_branch_line_ite?.map((value, index) => (
								<ToggleButton
									className={styles.toggle__button}
									id={`${value}`}
									key={index}
								>
									{offerState.has_branch_line_ite === value ? (
										<Icon
											className={styles.toggle__icon}
											name='check'
										/>
									) : undefined}
									{t('format.capitalize', {
										text: t(`general.${value ? 'yes' : 'no'}`)
									})}
								</ToggleButton>
							))}
						</InputToggleButton>

						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.commercial_services')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.showcase.title')
							})}
						</div>

						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.showcase}` || '0'}
							label={t('page.offers_create_edit.services.showcase.label')}
							name="showcase"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.showcase.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.meter_linear_showcase}` || '0'}
							label={t('page.offers_create_edit.services.meter_linear_showcase.label')}
							name="meter_linear_showcase"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.meter_linear_showcase.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.dpe')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.is_dpe_applicable.title')
							})}
						</div>
						<InputRadioGroup
							name="is_dpe_applicable"
							selectedIndex={offerEnumsState?.is_dpe_applicable?.findIndex((value) => value === offerState.is_dpe_applicable)}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						>
							{offerEnumsState?.is_dpe_applicable?.map((value, key) => {
								return (
									<InputRadio
										id={`is_dpe_applicable_${value}`}
										key={key}
										label={t('format.capitalize', {
											text: t(`page.offers_create_edit.services.is_dpe_applicable.${value}`)
										})}
										name={`is_dpe_applicable[${value}]`}
										value={value.toString()}
									/>
								);
							})}
						</InputRadioGroup>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.dpe_energy_consumption.title')
							})}
						</div>

						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.dpe_energy_consumption}` || '0'}
							label={t('page.offers_create_edit.services.dpe_energy_consumption.label')}
							name="dpe_energy_consumption"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.dpe_energy_consumption.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<InputToggleButton
							activeButtonValues={[
								offerState.ref_dpe_energy_consumption_id
							]}
							isLockedSelection={true}
							isMultiselect={false}
							name="ref_dpe_energy_consumption_id"
							theme={EnumToggleButton.SECONDARY}
							onChange={(selectedIds) => {
								handleOnChangeToggle('ref_dpe_energy_consumption_id', selectedIds, 'services', setDirtySteps, setOfferState);
							}}
						>
							{offerEnumsState?.ref_dpe_energy_consumption_ids?.map((value, index) => (
								<ToggleButton
									className={styles.toggle__button}
									id={value}
									key={index}
								>
									{t('format.capitalize', {
										text: value
									})}
								</ToggleButton>
							))}
						</InputToggleButton>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.dpe_greenhouse_gas_emission.title')
							})}
						</div>

						<InputTextWithUnit
							className={styles.input}
							defaultValue={`${offerState?.dpe_greenhouse_gas_emission}` || '0'}
							label={t('page.offers_create_edit.services.dpe_greenhouse_gas_emission.label')}
							name="dpe_greenhouse_gas_emission"
							type={EnumInputType.NUMBER}
							unit={t('page.offers_create_edit.services.dpe_greenhouse_gas_emission.unit')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>

						<InputToggleButton
							activeButtonValues={[
								offerState.ref_dpe_greenhouse_gas_emission_id
							]}
							isLockedSelection={true}
							isMultiselect={false}
							name="ref_dpe_greenhouse_gas_emission_id"
							theme={EnumToggleButton.TERTIARY}
							onChange={(selectedIds) => {
								handleOnChangeToggle('ref_dpe_greenhouse_gas_emission_id', selectedIds, 'services', setDirtySteps, setOfferState);
							}}
						>
							{offerEnumsState?.ref_dpe_greenhouse_gas_emission_ids?.map((value, index) => (
								<ToggleButton
									className={styles.toggle__button}
									id={value}
									key={index}
								>
									{t('format.capitalize', {
										text: value
									})}
								</ToggleButton>
							))}
						</InputToggleButton>

						<div className={styles.title__section}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.section.observations')
							})}
						</div>

						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.services.observations.title')
							})}
						</div>

						<Textarea
							defaultValue={offerState?.observations}
							name="observations"
							placeholder={t('page.offers_create_edit.services.observations.placeholder')}
							onChange={(event) => handleOnChange(event, 'services', setDirtySteps, setOfferState)}
						/>
					</Form>
					<ValidationForm
						isValid={true}
					/>
				</div>
				{isDesktopResolution && (
					<div className={styles.section__right}>
						<Image
							alt='placeholder'
							className={styles.image}
							src={servicesImage}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default withTranslation()(Services);
