// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fx75TfRW{align-items:center;display:flex;font-size:0.85714286rem;height:3.57142857rem;justify-content:center;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `fx75TfRW`
};
export default ___CSS_LOADER_EXPORT___;
