import * as React from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	Dispatch,
	MouseEvent,
	SetStateAction,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useDispatch, useSelector,
} from 'react-redux';

// STORES
import {
	demandOffersUpdate,
} from '@stores/_slices/demands';
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// ROUTES
import FETCHES from '@routes/fetches';

// MODULES
import * as utils from '@modules/utils';

// TYPES
import {
	OfferJson,
} from '@@types/Offer';
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button, {
} from '@components/button';

// STYLES
import styles from './ModalVisitCanceled.module.scss';

interface ModalVisitCanceledProps {
	demandId?: number;
	offerId?: number;
	setModalCanceled?: Dispatch<SetStateAction<{
		enabled: boolean;
		action?: string;
	}>>;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	onClickCommercializationStep?: (event: MouseEvent<HTMLElement>, args: object) => void;
}

function ModalVisitCanceled({
	demandId,
	offerId,
	setModalCanceled,
	setIsOpenModal,
	onClickCommercializationStep,
}: ModalVisitCanceledProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const demandWithOffersGlobalState = useSelector((state: ReducerInstance) => state.demands.instance).collection.filter((item: DemandJsonPrivate) => demandId == item?.id) as DemandJsonPrivate[];
	const demandGlobalState = demandWithOffersGlobalState && demandWithOffersGlobalState[0] ? demandWithOffersGlobalState[0] : null;

	const handleOnClicCanceled = ((event: MouseEvent<HTMLElement>, action: string) => {
		event.preventDefault();
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		setIsOpenModal(false);
		const updatedOffers: OfferJson[] = [
		];
		const hiddenLabel = [
			'visit_planned',
			'visit_expired'
		];
		const bodyRequestDeleteVisit = {
			reason: action,
		};
		fetch(utils.getURL(FETCHES.private.demands.offer.visit, {
			':demandid': `${demandId}`,
			':offerid': `${offerId}`,
		}), {
			...APP_CONF_VARS.request.default,
			method: 'DELETE',
			body: JSON.stringify(bodyRequestDeleteVisit),
		}).then((resp) => {
			setModalCanceled({
				enabled: false,
				action: action,
			});
			switch (resp.status) {
				case 204:
					demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
						if (offer.id === offerId) {
							const offerUpdated = {
								...offer,
								states: [
									...offer.states.filter(state => !hiddenLabel.includes(state)),
								]
							};
							updatedOffers.push(offerUpdated);
						} else {
							updatedOffers.push(offer);
						}
					});
					dispatch(demandOffersUpdate({
						demand_id: demandId,
						offers: {
							...demandGlobalState.offers,
							collection: [
								...updatedOffers
							]
						}
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${resp.statusText}`,
								'status.default',
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(err => {
			setModalCanceled({
				enabled: false,
				action: null,
			});
			console.warn('ERROR : ', err);
		});
	});

	return (
		<div className={`${styles.visit_canceled}`}>
			<h2 className={styles.title}>{t('format.capitalize', {
				text: t('modals.visit.canceled.introduction')
			})}</h2>

			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				label={t('format.capitalize', {
					text: t('general.action.visit_customer_refusal')
				})}
				theme={EnumTheme.PRIMARY}
				onClick={event => {
					handleOnClicCanceled(event, 'customer_refusal');
					onClickCommercializationStep(event, {
						ids: [
							offerId,
						],
						commercialization_step: 'refused',
					});
				}}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				label={t('format.capitalize', {
					text: t('general.action.visit_commercialized_announcement')
				})}
				theme={EnumTheme.PRIMARY}
				onClick={event => handleOnClicCanceled(event, 'commercialized_announcement')}
			/>
			<Button
				className={styles.button}
				corners={EnumButtonCorners.Square}
				label={t('format.capitalize', {
					text: t('general.action.visit_other')
				})}
				theme={EnumTheme.PRIMARY}
				onClick={event => handleOnClicCanceled(event, 'other')}
			/>
		</div>
	);
}

export default withTranslation()(ModalVisitCanceled);
