import {
	EnumSteps,
} from '@enums/steps.enum';
import i18n from '@modules/i18n';

export const filterValueMappings = [
	{
		id: 'states',
		value: 'bookmarked'
	},
	{
		id: 'states',
		value: 'pinned'
	},
	{
		id: 'states',
		value: 'new'
	},
	{
		id: 'states',
		value: 'updated'
	},
	{
		id: 'mandate_is_owner',
		value: 'true'
	},
	{
		id: 'states',
		value: 'visit_to_plan'
	},
	{
		id: 'states',
		value: 'visit_planned'
	},
	{
		id: 'states',
		value: 'visit_expired'
	},
	{
		id: 'states',
		value: 'visit_visited'
	},
];

export const getOptionsFilters = (commercializationStep: EnumSteps) => {
	return [
		{
			iconLeft: 'star',
			iconStyle: 'solid',
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.bookmarked'),
			}),
			value: 'bookmarked'
		},
		{
			iconLeft: 'thumbtack',
			iconStyle: 'solid',
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.pinned'),
			}),
			separator: true,
			value: 'pinned'
		},
		{
			isDisplayed: commercializationStep === EnumSteps.MATCHED ? true : false,
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.new_female'),
			}),
			value: 'new',
		},
		{
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.updated'),
			}),
			value: 'updated'
		},
		{
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.mandate.internal'),
			}),
			separator: commercializationStep === EnumSteps.MATCHED ? false : true,
			value: 'true'
		},
		{
			isDisplayed: commercializationStep === EnumSteps.MATCHED || commercializationStep === EnumSteps.REFUSED || commercializationStep === EnumSteps.IGNORED ? false : true,
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.visit_to_plan'),
			}),
			value: 'visit_to_plan'
		},
		{
			isDisplayed: commercializationStep === EnumSteps.MATCHED || commercializationStep === EnumSteps.REFUSED || commercializationStep === EnumSteps.IGNORED ? false : true,
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.visit_planned'),
			}),
			value: 'visit_planned'
		},
		{
			isDisplayed: commercializationStep === EnumSteps.MATCHED || commercializationStep === EnumSteps.REFUSED || commercializationStep === EnumSteps.IGNORED  ? false : true,
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.visit_expired'),
			}),
			value: 'visit_expired'
		},
		{
			isDisplayed: commercializationStep === EnumSteps.MATCHED ? false : true,
			label: i18n.t('format.capitalize', {
				text: i18n.t('status.visit_visited'),
			}),
			value: 'visit_visited'
		},
	] as object[];
};

