import React, {
	ReactNode,
} from 'react';
import PATHS from '@routes/paths';
import i18n from '@modules/i18n';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

// COMPONENTS
import LinkToLogout from '@components/link-to-logout';
import Link from '@components/link';

// MODULES
import eventEmitter from '@modules/eventEmitter';
import Permissions from '@modules/permissions';

// DATA
import EVENTS from './events.data';

// TYPES
import {
	UserJson,
} from '@@types/User';

const getProfileData = (userData: UserJson) => {

	const modulePermissions = new Permissions({
		payload: userData?.modules || {
		}
	});

	const modules = [
		{
			href: PATHS.USER.PROFILE.LEGACY,
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('profile_user.my_account')
			})}`,
			target: EnumTargetLink.BLANK,
		},
		{
			href: PATHS.USER.HISTORY.LEGACY,
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('profile_user.my_history')
			})}`,
			target: EnumTargetLink.BLANK,
		},
		{
			hasAccess: modulePermissions.can('read', 'my_alerts'),
			href: PATHS.USER.LISTS.DYNAMICS.LEGACY,
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('profile_user.my_alerts')
			})}`,
			target: EnumTargetLink.BLANK,
		},
		{
			hasAccess: modulePermissions.can('read', 'my_lists'),
			href: PATHS.USER.LISTS.STATICS.LEGACY,
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('profile_user.my_lists')
			})}`,
			target: EnumTargetLink.BLANK,
			separator: true
		},
		{
			href: PATHS.USER.TERMS.LEGACY,
			label: `${i18n.t('format.capitalize', {
				text: i18n.t('general.terms')
			})}`,
			target: EnumTargetLink.BLANK,
			visible: {
				desktop: true,
				mobile: false
			},
		},
		{
			content: <Link
				fontWeight={EnumFontStyle.REGULAR}
				theme={EnumThemeLink.NAKED}
				onClick={/* istanbul ignore next */() => {
					// Event custom dispatched to be used with GTM
					eventEmitter.dispatch(EVENTS.USER_MANAGE_COOKIES_CLICK);
				}}
			>
				{i18n.t('general.action.manage_cookies') as ReactNode}
			</Link>,
			separator: true,
			visible: {
				desktop: true,
				mobile: false
			}
		},
		{
			content: <LinkToLogout>{i18n.t('general.action.logout') as ReactNode}</LinkToLogout>,
		},
	];

	return modules;
};

export default getProfileData;
