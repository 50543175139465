const searchEngines = [
	'owner',
	'occupier',
	'offer',
	'transaction',
	'building',
	'land',
	'project',
	'permit'
];

const studies = [
	'analyze',
];

const publicData = [
	...searchEngines,
	...studies,
];

const profile = [
	'my_alerts',
	'my_lists',
];

const businessTools = [
	'commercialization',
	'management',
];

const all = [
	...profile,
	...publicData,
	...businessTools
];

export {
	all,
	businessTools,
	profile,
	publicData,
	searchEngines,
	studies,
};
