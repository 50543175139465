interface userDataProps {
	username?: string;
	company?: { id?: string | number; name?: string };
}

interface FeatureFlagsProps {
	isEnabled: boolean;
	name?: string;
	authorized_company_ids?: Array<number>;
	authorized_usernames?: Array<string>;
}
export interface featureFlagsAccessProps {
	[key: string]: boolean;
}

export function FeatureFlags(FeatureFlagsData: { [key: string]: FeatureFlagsProps }, user: userDataProps) {
	const featureFlagsAccess = {
	} as featureFlagsAccessProps;
	for (const flag in FeatureFlagsData) {
		let hasAccess = false;
		featureFlagsAccess[FeatureFlagsData[flag].name] = hasAccess;
		if (FeatureFlagsData[flag].isEnabled) {
			if (FeatureFlagsData[flag].authorized_company_ids?.length) {
				hasAccess = FeatureFlagsData[flag].authorized_company_ids?.includes(user?.company?.id as number);
				featureFlagsAccess[FeatureFlagsData[flag].name] = hasAccess;
			} else if (FeatureFlagsData[flag].authorized_usernames?.length) {
				hasAccess = FeatureFlagsData[flag].authorized_usernames.includes(user?.username as string);
				featureFlagsAccess[FeatureFlagsData[flag].name] = hasAccess;
			}
		}
	}
	return featureFlagsAccess;
}
