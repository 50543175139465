import React from 'react';
import {
	useTranslation,
} from 'react-i18next';

// LAYOUTS
import {
	ElementProps,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// STYLES
import styles from '../contact-row.module.scss';

function RoleElement({
	activeElement,
	isDesktopResolution,
	offerContact,
	selectedRowId,
	rowId,
}: ElementProps) {
	const {t} = useTranslation();

	let mobileContent = null;
	if (!isDesktopResolution) {
		mobileContent = (
			<>
				<span className={styles.mobile_label}>
					{t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.header_table.role')
					})}
				</span>
				<span className={styles.mobile_value}>
					{offerContact?.role &&
						t('format.capitalize', {
							text: t(`page.offers_create_edit.contacts.role.${offerContact.role}`)
						})
					}
				</span>
			</>
		);
	}

	let tooltipContent;
	if (!offerContact?.id) {
		tooltipContent = (
			<span className={styles.placeholder}>
				{t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.role.placeholder')
				})}
			</span>
		);
	} else if (offerContact.role) {
		tooltipContent = t('format.capitalize', {
			text: t(`page.offers_create_edit.contacts.role.${offerContact.role}`)
		});
	} else {
		tooltipContent = t('format.capitalize', {
			text: t('page.offers_create_edit.contacts.role.unknown')
		});
	}

	const cssClasses = [
		styles.cell_role,
		styles.not_allowed,
	];

	if (activeElement === 'role' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);

	const roleElement = (
		<div
			className={cssClasses.join(' ')}
			id={`role_${rowId}`}
		>
			{mobileContent}
			{isDesktopResolution && (
				<span className={styles.tooltip_modal_role_content}>
					{tooltipContent}
				</span>
			)}
		</div>
	);

	return roleElement;
}

export default RoleElement;
