// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wbyYZrzY{display:flex;flex-direction:row;padding:1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.wbyYZrzY{padding:1.42857143rem}}.hHUWfv8S{display:flex;flex-direction:column;width:100%;overflow-x:auto}.hHUWfv8S .KL8b2qSI{color:var(--color-primary);margin-bottom:1.42857143rem;font-weight:600;font-size:1rem}.hHUWfv8S .BvCa7RP7{margin-bottom:1.42857143rem;font-size:0.85714286rem}.hHUWfv8S .e4BSre7f{margin-bottom:1.42857143rem;font-size:1.14285714rem;font-weight:600;line-height:1.6em}.hHUWfv8S .e4BSre7f:first-letter{display:block;text-transform:uppercase}.hHUWfv8S .RV5SYYUy{bottom:1.42857143rem}.hHUWfv8S .iq1dSzr_{cursor:pointer;display:flex;flex-direction:column;margin-bottom:4.28571429rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.hHUWfv8S .iq1dSzr_{height:100%;width:100%}}.hHUWfv8S .iq1dSzr_ [data-type=selection]{background-color:var(--color-wild-sand);border:1Px solid var(--color-border-default);border-bottom:none}.dZDFp459{align-items:center;display:flex;flex-direction:column}.dZDFp459>*{flex:1}@media screen and (min-width: 768Px)and (orientation: landscape){.dZDFp459{flex-direction:row;gap:1.42857143rem}}.FwGCTslD{display:flex;flex-direction:column}.H9ktiKn6{margin-bottom:2.14285714rem}.H9ktiKn6>div{margin:0 0 1.07142857rem}.H9ktiKn6>div:last-child{margin:0}.BEqw162m{background-color:var(--color-wild-sand);border-left:1Px solid rgba(0,0,0,0);border-right:1Px solid var(--color-border-default);border-top:1Px solid var(--color-border-default)}.BEqw162m:nth-child(3){max-width:12%;min-width:12%}.BEqw162m:nth-child(4){max-width:8%;min-width:8%}.BEqw162m:nth-child(5){max-width:9%;min-width:9%}.BEqw162m:nth-child(6){max-width:9%;min-width:9%}.BEqw162m:nth-child(7){max-width:9%;min-width:9%}.BEqw162m:nth-child(8){max-width:9%;min-width:9%}.BEqw162m:nth-child(9){max-width:16%;min-width:16%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `wbyYZrzY`,
	"section__full": `hHUWfv8S`,
	"step_progress": `KL8b2qSI`,
	"legend": `BvCa7RP7`,
	"title": `e4BSre7f`,
	"action_bar": `RV5SYYUy`,
	"table": `iq1dSzr_`,
	"form_row": `dZDFp459`,
	"form_column": `FwGCTslD`,
	"checkbox": `H9ktiKn6`,
	"cell_header": `BEqw162m`
};
export default ___CSS_LOADER_EXPORT___;
