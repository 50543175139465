import React, {
	ReactNode,
} from 'react';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';

// MODULES
import {
	componentUtils,
} from '@modules/componentUtils';

// STYLES
import styles from './module-card.module.scss';

interface ModuleCardProps {
	children?: ReactNode;
	className?: string;
	title?: string;
	titleLevel?: number;
}

const ModuleCard = ({
	children,
	className,
	title,
	titleLevel = 3,
	...otherProps
}: ModuleCardProps): JSX.Element => {

	const cssClasses = [
		styles.module_card
	];
	if (className) {
		cssClasses.push(className);
	}

	const Tag = 'h' + titleLevel as keyof JSX.IntrinsicElements;
	const TagProps = { // make sure all required component's inputs/Props keys&types match
		children: title,
		className: styles.title,
	};
	const titleElement = title ? (
		<Tag {...TagProps} />
	) : null;

	return (
		<div
			{...otherProps}
			className={cssClasses.join(' ')}
		>
			{titleElement}
			<div className={styles.module_card_row}>
				{componentUtils.cloneChildrenElement(children, EnumComponentType.LINK, styles.module_card_link)}
			</div>
		</div>
	);
};

export {
	ModuleCard as default,
	ModuleCardProps,
};
