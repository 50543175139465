import * as React from 'react';
import {
	MouseEvent,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';

// COMPONENTS
import Icon from '@components/icon';
import NavigationItem, {
	ItemDataProps,
} from '@components/navigation/navigation-item';

//STYLES
import styles from './navigation.module.scss';

export interface NavigationProps {
	className?: string;
	'data-testid'?: string;
	deviceDisplay?: string;
	isOpen?: boolean;
	items: ItemDataProps[];
	onClickItem?: (event: MouseEvent<HTMLElement>) => void;
	onClickToggle?: (event: MouseEvent<HTMLElement>) => void;
}

function Navigation({
	className,
	'data-testid': dataTestid,
	deviceDisplay = 'mobile',
	isOpen = false,
	items,
	onClickItem,
	onClickToggle,
}: NavigationProps) {

	const handleOnClickItem = (event: MouseEvent<HTMLElement>) => {
		onClickItem ? onClickItem(event) : undefined;
	};

	const handleOnClickToggleNav = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		onClickToggle ? onClickToggle(event) : undefined;
	};

	const arrowIcon = (
		<span
			className={styles.toggle}
			data-testid={`${dataTestid}-toggle`}
			onClick={handleOnClickToggleNav}
		>
			<Icon
				className={styles.toggle__icon}
				data-testid={`${dataTestid}-toggleIcon`}
				name={isOpen ? 'chevron-left' : 'chevron-right'}
			/>
		</span>
	);

	const wrapperCssClasses = [
	];

	const panelCssClasses = [
		styles.panel,
	];
	if (className) panelCssClasses.push(className);

	const overlayCssClasses = [
		styles.overlay,
	];

	if (isOpen) {
		wrapperCssClasses.push(styles.open);
		overlayCssClasses.push('animation__appearing');
	}
	const navigation = (
		<div
			className={panelCssClasses.join(' ')}
			data-testid={dataTestid}
		>
			<nav className={styles.nav}>
				{arrowIcon}
				<ol className={styles.list}>
					{items.map((itemData: ItemDataProps, index: number) => {
						let showItem = true;
						const result = (
							<NavigationItem
								data-testid={`${dataTestid}-items`}
								deviceDisplay={deviceDisplay}
								itemData={itemData as ItemDataProps}
								key={index}
								navIsOpen={isOpen}
								onClick={handleOnClickItem}
							/>
						);
						if (itemData.visible) {
							showItem = itemData.visible[deviceDisplay] !== false;
						}
						return showItem ? result : null;
					})}
				</ol>
			</nav>
		</div>
	);

	return (
		<div className={wrapperCssClasses.join(' ')}>
			{navigation}
			<div
				className={overlayCssClasses.join(' ')}
				onClick={handleOnClickToggleNav}
			></div>
		</div>
	);
}

export default withTranslation()(Navigation);
