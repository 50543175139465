import React, {
	memo,
	useEffect,
	useRef,
} from 'react';
import {
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useLocation,
	useNavigate,
	useRoutes,
} from 'react-router-dom';
import {
	initializedLogger,
} from '@modules/logger';

// STORE
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// ROUTES
import {
	PRIVATE_ROUTES_GLOBAL,
} from '@routes/index';

function AppPrivate() {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const app = useSelector((state: ReducerInstance) => state.app.instance);
	const user = useSelector((state: ReducerInstance) => state.user.instance);

	const contentRoutes = useRoutes(PRIVATE_ROUTES_GLOBAL);
	const didLogRef = useRef(false);

	initializedLogger.setUser(user);

	useEffect(() => {
		// check if a route has been store from previous url before auth and is already the same as the current one
		if (didLogRef.current === false && app.routes.from && app.routes.from !== location.pathname) {
			didLogRef.current = true;
			navigate(app.routes.from);
			dispatch(appSetRoute({
				name: 'from',
				url: null // remove the value if the current one is the same to avoid recursive redirects
			}));
		}
	}, [
	]);

	return (
		<>
			{contentRoutes}
		</>
	);
}

export default memo(withTranslation()(AppPrivate));
