import React from 'react';
import {
	MouseEvent,
	FormEvent,
	useEffect,
	useState,
} from 'react';
import {
	useNavigate,
	useOutletContext,
	useParams,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';

// STORES
import {
	demandGeoJsonUpdate,
	demandOfferAddContacts,
	demandOfferAddDetail,
	demandOfferAddHistory,
	demandOfferAddViewed,
	demandOffersRemove,
	demandOffersUpdate,
	demandUpdate,
} from '@stores/_slices/demands';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// CONFIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import featuresFlagsConf from '@appConf/featuresFlags.conf';
import {
	addViewedOnAnnouncement,
	getContacts,
	getEventHistory,
	getPrivateAnnouncement,
	getPrivateAnnouncements,
	getPrivateDemandGeojsonDots,
	setApplicationAnnouncement,
	setCommercializationStep,
} from './config/fetch';

// DATA
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';

// TYPE
import {
	Contact,
	ContactJson,
	Offer,
	OfferJson,
} from '@@types/index';
import {
	PageDemandOutletContextType,
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumSwitchView,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';

// EXECPTIONS
import GetContactsByOfferError from '@exceptions/GetContactsByOfferError';

// LAYOUTS
import ModalAppCongrat from '@layouts/Modals/ModalAppCongrat';
import ModalDocumentsTemplates from '@layouts/Modals/ModalDocumentsTemplates';
import ModalVisit from '@layouts/Modals/ModalVisit';
import ModalVisitConfirmation from '@layouts/Modals/ModalVisitConfirmation';
import ModalVisitExpired from '@layouts/Modals/ModalVisitExpired';
import ModalVisitCanceled from '@layouts/Modals/ModalVisitCanceled';
import ModalUpdateDemandApply from '@layouts/Modals/ModalUpdateDemandApply';

// COMPONENTS
import Modal from '@components/modal';
import CardView from './card-view';
import TableView from './table-view';

// MODULES
import {
	FeatureFlags,
} from '@modules/featureFlags';
import * as utils from '@modules/utils';

// ROUTES
import PATHS from '@routes/paths';

// STYLES
import styles from './manage.module.scss';

export interface isContentLoadedProps {
	canSelect: boolean;
	demand: boolean;
	map: boolean;
	offer: boolean;
	offers: boolean;
}

const Manage = (): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { demandid, step, offerid } = useParams();
	const currentOfferId = !isNaN(Number(offerid)) ? Number(offerid) : null;
	const currentDemandId = !isNaN(Number(demandid)) ? Number(demandid) : null;

	const commercializationStep = step as EnumSteps || EnumSteps.MATCHED;
	const navigate = useNavigate();
	const {
		demandGlobalState,
		demandIsLoaded,
		isDesktopResolution,
	}: PageDemandOutletContextType = useOutletContext();

	const actualLanguage = useSelector((state: ReducerInstance) => state.app.instance.localization.actualLanguage);

	const user = useSelector((state: ReducerInstance) => state.user.instance);
	const flags = FeatureFlags(featuresFlagsConf, user);

	const handleGetContacts = async () => {
		await getContacts(currentDemandId, currentOfferId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddContacts({
						demand_id: currentDemandId,
						offer_id: currentOfferId,
						contacts: responseParsed.payload,
					}));
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					navigate(PATHS.HOMEPAGE);
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					throw new GetContactsByOfferError(responseParsed.message);
			}
		}).catch(error => {
			throw new GetContactsByOfferError(error);
		});
	};

	const hideStateActions = [
		'abandoned',
		'failed',
		'successful',
	];

	const showActions = demandIsLoaded ? !hideStateActions.includes(demandGlobalState.state) : true;

	const [
		isContentLoaded,
		setIsContentLoaded
	] = useState<isContentLoadedProps>({
		canSelect: showActions === false ? false : true,
		demand: demandIsLoaded,
		map: false,
		offer: false,
		offers: false,
	});

	const [
		isEventHistoryLoaded,
		setIsEventHistoryLoaded
	] = useState<boolean>(false);

	useEffect(() => {
		setIsContentLoaded({
			...isContentLoaded,
			demand: demandIsLoaded
		});
	}, [
		demandIsLoaded
	]);

	const handleOnClosePanelDetail = () => {
		// the user is redirected to the listing
		navigate(`${PATHS.DEMANDS._ROOT}/${currentDemandId}/manage/${commercializationStep}`);
	};

	const handleGetPrivateAnnouncements = async () => {
		await getPrivateAnnouncements(currentDemandId, commercializationStep).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOffersUpdate({
						demand_id: currentDemandId,
						offers: responseParsed.payload,
					}));
					setIsContentLoaded({
						...isContentLoaded,
						map: true,
						offers: true,
						canSelect: showActions === false ? false : true
					});
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					navigate(PATHS.HOMEPAGE);
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).catch(() => {
			setIsContentLoaded({
				...isContentLoaded,
				map: true,
				offers: true,
				canSelect: showActions === false ? false : true
			});
		});
	};

	const handleGetPrivateDemandGeojsonDots = async () => {
		await getPrivateDemandGeojsonDots(currentDemandId, commercializationStep).then((responseGetDotMap) => {
			switch (responseGetDotMap.status) {
				case 200:
					dispatch(demandGeoJsonUpdate({
						demand_id: currentDemandId,
						geo_json: responseGetDotMap.payload.geo_json,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseGetDotMap.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		});
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsContentLoaded({
				...isContentLoaded,
				map: false,
				offers: false,
			});
			await handleGetPrivateDemandGeojsonDots();
			await handleGetPrivateAnnouncements();
		};

		if (isContentLoaded.demand) {
			fetchData();
		}
	}, [
		commercializationStep,
		isContentLoaded.demand
	]);

	const handleOnClickOffer = (event: MouseEvent<HTMLElement>, offerId: number) => {
		event.stopPropagation();
		event.preventDefault();

		navigate(`${PATHS.DEMANDS._ROOT}/${currentDemandId}/manage/${commercializationStep}/offer/${offerId}`);
	};

	const handleAddViewedOnAnnouncement = async () => {
		await addViewedOnAnnouncement(currentDemandId, currentOfferId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddViewed({
						demand_id: currentDemandId,
						offer_id: currentOfferId
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		});
	};

	const handleGetAnnouncement = async () => {
		await getPrivateAnnouncement(currentDemandId, currentOfferId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddDetail({
						demand_id: currentDemandId,
						offer_id: currentOfferId,
						offer: responseParsed.payload,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).finally(() => {
			setIsContentLoaded({
				...isContentLoaded,
				offer: true,
			});
		});
	};

	const handleGetEventHistoryAnnouncement = async () => {
		await getEventHistory(currentDemandId, currentOfferId).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(demandOfferAddHistory({
						demand_id: currentDemandId,
						offer_id: currentOfferId,
						event_history: responseParsed.payload
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		}).finally(() => {
			setIsEventHistoryLoaded(true);
		});
	};

	const handleSelectedOfferFromList = (offerId: number) => {
		const selectedOffers = demandGlobalState.offers.collection.filter((offer: OfferJson) => offer.id == offerId) as OfferJson[];
		return selectedOffers?.length ? new Offer(selectedOffers[0]) : undefined;
	};

	const handleOnDisplayOfferDetails = async () => {
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		await handleGetAnnouncement();
		await handleAddViewedOnAnnouncement();
		await handleGetEventHistoryAnnouncement();
		await handleGetContacts();
	};
	const handleOnClickCommercializationStep = (event: MouseEvent<HTMLElement>, args: BodyRequest) => {
		event.stopPropagation();
		dispatch(clearStatusMsgs());
		dispatch(clearToastMsgs());
		setCommercializationStep(currentDemandId, args).then((responseParsed) => {
			if (responseParsed.status === 200) {
				const firstSend = demandGlobalState.counters.steps.accepted === 0 && demandGlobalState.counters.steps.applied === 0 && demandGlobalState.counters.steps.ignored === 0 && demandGlobalState.counters.steps.proposed === 0 && demandGlobalState.counters.steps.refused === 0;
				const recipientContactsEmails: string[] = [
				];
				if (demandGlobalState.recipient?.contacts?.collection?.length) demandGlobalState.recipient.contacts.collection.forEach((contact: ContactJson) => recipientContactsEmails.push(contact.email));
				if ((commercializationStep === EnumSteps.MATCHED || commercializationStep === EnumSteps.IGNORED) && args.commercialization_step === EnumSteps.PROPOSED) {
					const sharedDemandToken = demandGlobalState.shared_demand_token || responseParsed.payload.token;
					const segmentUrlCompany = demandGlobalState.broker_company?.segment_url;
					const publicUrlDemand = utils.getURL(`${process.env.DOMAIN_MESOFFRES}${APP_CONF_VARS.sfPathMesOffres}${PATHS.DEMANDS.PUBLIC.STEP._ROOT}`, {
						':segmenturl': segmentUrlCompany,
						':step': EnumSteps.PROPOSED,
						':token': sharedDemandToken,
					});

					const buttons = flags?.hasCRM ? null : [
						{
							corners: 'square',
							href: t('page.demand.private.email.mailto', {
								'%subject%': t('page.demand.private.email.inform_customer.subject'),
								'%body%': t('page.demand.private.email.inform_customer.body', {
									'%url%': publicUrlDemand
								}),
								'%email%': recipientContactsEmails.join(',')
							}),
							label: t('format.capitalize', {
								text: t('page.demand.private.email.inform_customer.button')
							}),
							theme: 'success'
						}
					];
					dispatch(demandUpdate({
						demand: {
							...demandGlobalState,
							shared_demand_token: responseParsed.payload.token
						},
						demand_id: currentDemandId,
					}));
					firstSend ? dispatch(addStatusMsg({
						buttons: buttons,
						isClosable: true,
						message: t('page.demand.private.send_offer_notifications.first_send'),
						theme: EnumStatusTheme.SUCCESS,
					})) : null;
				}
				dispatch(addToastMsg({
					message: t('format.capitalize', {
						text: t(`page.demand.private.tabs.manage.moved_to${args.ids.length > 1 ? '_other' : ''}`, {
							'%step%': t('format.capitalize', {
								text: t(`page.demand.private.tabs.manage.steps.${args.commercialization_step}.title`)
							})
						}),
					}),
				}));
				dispatch(demandOffersRemove({
					demand_id: demandGlobalState.id,
					current_commercialization_step: commercializationStep,
					new_commercialization_step: args.commercialization_step,
					offer_ids: args.ids
				}));
			} else {
				dispatch(addToastMsg({
					message: t('format.capitalize', {
						text: t([
							`status.${responseParsed.statusText}`,
							'status.default'
						]),
					}),
					theme: EnumStatusTheme.ERROR,
				}));
			}
		});
	};

	const [
		dataModal,
		setDataModal
	] = useState(null);

	const [
		modalCanceled,
		setModalCanceled
	] = useState({
		enabled: false,
	});

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const [
		dataModalConfirmation,
		setDataModalConfirmation
	] = useState(null);

	const [
		applicationModalConfirmation,
		setApplicationModalConfirmation
	] = useState(false);

	const handleOnClickModal = (event: MouseEvent<HTMLElement>, args: BodyRequest) => {
		event.stopPropagation();
		if (args?.visit && args?.states?.includes('visit_planned')) {
			setDataModalConfirmation({
				...args.visit,
				is_modify: true,
			});
		}
		setDataModal(args);
		setIsOpenModal(true);
	};

	const hiddenLabel = [
		'application_to_submit',
		'application_submitted',
		'application_accepted',
		'application_signed',
		'application_refused',
	];

	const offersUpdated: OfferJson[] = [
	];

	const handleOnClickApplication = (event: MouseEvent<HTMLElement> | FormEvent<HTMLFormElement>, args: BodyRequest) => {
		dispatch(clearToastMsgs());
		dispatch(clearStatusMsgs());
		const offerId = args.ids[0];
		setApplicationAnnouncement(currentDemandId, args).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
						let result = offer;
						if (offer.id === offerId) {
							const labelsStatus = [
								...offer.states.filter(state => !hiddenLabel.includes(state))
							];

							labelsStatus.push(responseParsed.payload.state);
							labelsStatus.sort();

							result = {
								...offer,
								states: [
									...labelsStatus,
								]
							};
						}
						offersUpdated.push(result);
					});
					dispatch(demandUpdate({
						demand: {
							...demandGlobalState,
							state: responseParsed.payload.state === 'application_signed' ? 'successful' : demandGlobalState.state,
							offers: {
								collection: [
									...offersUpdated
								],
								count: offersUpdated.length
							}
						},
						demand_id: currentDemandId,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
			}
		});
	};

	const contactConfirmation = dataModalConfirmation?.contact_id ? dataModal?.contacts?.collection?.filter((item: ContactJson) => item.id === Number(dataModalConfirmation.contact_id))[0] : null;

	const recipientCompany = isContentLoaded.demand ? demandGlobalState.recipient?.company : null;

	let titleModal: string = null;
	let contentModal = null;
	switch (true) {
		case commercializationStep === EnumSteps.APPLIED && applicationModalConfirmation:
			contentModal = (
				<ModalAppCongrat />
			);
			break;
		case typeof dataModalConfirmation === 'object' && dataModalConfirmation !== null && dataModal?.name?.includes('plan_my_visit'):
			titleModal = dataModalConfirmation.is_modify ? t('format.capitalize', {
				text: t('modals.visit.confirmation.title_update')
			}) : t('format.capitalize', {
				text: t('modals.visit.confirmation.title_planned')
			});

			contentModal = (
				<ModalVisitConfirmation
					address={dataModal?.address}
					comment={dataModalConfirmation?.comment}
					contact={new Contact(contactConfirmation)}
					date={dataModalConfirmation?.date}
					hasAction={dataModalConfirmation.is_modify}
					image={dataModal?.images}
					language={actualLanguage}
					setDataModalConfirmation={setDataModalConfirmation}
					setModalCanceled={setModalCanceled}
					subject={dataModalConfirmation?.subject}
					time={dataModalConfirmation?.time}
					time_end={dataModalConfirmation?.time_end}
				/>
			);
			break;
		case (dataModal?.states?.includes('visit_expired') || dataModal?.states?.includes('visit_planned')) && modalCanceled.enabled && dataModal?.name?.includes('plan_my_visit'):
			contentModal = (
				<ModalVisitCanceled
					demandId={currentDemandId}
					offerId={dataModal?.ids[0]}
					setIsOpenModal={setIsOpenModal}
					setModalCanceled={setModalCanceled}
					onClickCommercializationStep={handleOnClickCommercializationStep}
				/>
			);
			break;
		case dataModal?.states?.includes('visit_expired') && dataModal?.name?.includes('plan_my_visit'):
			titleModal = t('format.capitalize', {
				text: t('modals.visit.expired.title')
			});
			contentModal = (
				<ModalVisitExpired
					address={dataModal?.address}
					date={dataModal?.visit?.date}
					demandId={currentDemandId}
					image={dataModal?.images}
					offerId={dataModal?.ids[0]}
					setIsOpenModal={setIsOpenModal}
					setModalCanceled={setModalCanceled}
					time={dataModal?.visit?.time}
				/>
			);
			break;
		case dataModal?.name?.includes('apply_progress'):
			contentModal = (
				<ModalUpdateDemandApply
					offerId={dataModal?.ids[0]}
					setApplicationModalConfirmation={setApplicationModalConfirmation}
					setIsOpenModal={setIsOpenModal}
					states={dataModal?.states}
					onClickApplication={handleOnClickApplication}
				/>
			);
			break;
		case dataModal?.document_templates?.length > 0:
			titleModal = t('format.capitalize', {
				text: t('modals.document_templates.title')
			});
			contentModal = (
				<ModalDocumentsTemplates
					brokers={dataModal?.brokers}
					documentTemplates={dataModal?.document_templates}
					offerId={dataModal?.offerId}
				/>
			);
			break;
		default:
			titleModal = t('format.capitalize', {
				text: t('modals.visit.plan.title')
			});
			contentModal = (
				<ModalVisit
					address={dataModal?.address}
					contacts={dataModal?.contacts}
					demandId={currentDemandId}
					images={dataModal?.images}
					language={actualLanguage}
					offerId={dataModal?.ids[0]}
					recipientCompany={recipientCompany}
					setDataModalConfirmation={setDataModalConfirmation}
					setIsOpenModal={setIsOpenModal}
					time={dataModal?.visit?.time}
				/>
			);
	}

	const modalElement = (
		<Modal
			hasHeader={titleModal ? true : false}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenModal}
			size={EnumModalSize.DEFAULT}
			title={titleModal}
			onClose={() => {
				setIsOpenModal(false);
				setModalCanceled({
					enabled: false,
				});
				setDataModalConfirmation(null);
				setApplicationModalConfirmation(false);
			}}
		>
			{contentModal}
		</Modal>
	);

	const cardView = (
		<CardView
			actualLanguage={actualLanguage}
			commercializationStep={commercializationStep}
			currentOfferId={currentOfferId}
			demandGlobalState={demandGlobalState as DemandJsonPrivate}
			isContentLoaded={isContentLoaded}
			isDesktopResolution={isDesktopResolution}
			isEventHistoryLoaded={isEventHistoryLoaded}
			selectedOfferFromList={handleSelectedOfferFromList}
			showActions={showActions}
			onClickCommercializationStep={handleOnClickCommercializationStep}
			onClickModal={handleOnClickModal}
			onClickOffer={handleOnClickOffer}
			onClosePanelDetail={handleOnClosePanelDetail}
			onDisplayOfferDetail={handleOnDisplayOfferDetails}
		/>
	);

	const tableView = (
		<TableView
			actualLanguage={actualLanguage}
			commercializationStep={commercializationStep}
			currentOfferId={currentOfferId}
			demandGlobalState={demandGlobalState as DemandJsonPrivate}
			isContentLoaded={isContentLoaded}
			isDesktopResolution={isDesktopResolution}
			isEventHistoryLoaded={isEventHistoryLoaded}
			selectedOfferFromList={handleSelectedOfferFromList}
			showActions={showActions}
			onClickCommercializationStep={handleOnClickCommercializationStep}
			onClickModal={handleOnClickModal}
			onClickOffer={handleOnClickOffer}
			onClosePanelDetail={handleOnClosePanelDetail}
			onDisplayOfferDetail={handleOnDisplayOfferDetails}
		/>
	);

	const cssClasses = [
		styles.manage,
	];

	if (isContentLoaded.demand && demandGlobalState?.counters?.steps[`${commercializationStep}`] === 0) {
		cssClasses.push(styles.manage__empty);
	}

	return (
		isContentLoaded.demand ? (
			<div className={cssClasses.join(' ')}>
				{demandGlobalState?.settings?.display?.view_mode === EnumSwitchView.TABLE && isDesktopResolution ? tableView : cardView}
				{modalElement}
			</div>
		) : null
	);
};

export default withTranslation()(Manage);
