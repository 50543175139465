import i18n from '@modules/i18n';
import styles from  '../PageMatchingOfferDemands.module.scss';
import {
	FilterFnOption,
} from '@tanstack/react-table';

export const columnTableConf = [
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.company')
		}),
		id: 'company',
		accessorKey: 'recipient_company_name',
		className: styles.cell_header,
		enableColumnFilter: true,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.contact')
		}),
		id: 'contact',
		accessorKey: 'recipient_contacts',
		className: styles.cell_header,
		enableColumnFilter: true,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.search_criterias.title')
		}),
		id: 'search_criterias',
		accessorKey: 'id',
		className: styles.cell_header,
		enableColumnFilter: true,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.broker')
		}),
		id: 'negotiator',
		accessorKey: 'negotiator',
		className: styles.cell_header,
		enableColumnFilter: true,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.demand_state')
		}),
		id: 'state',
		accessorKey: 'state',
		className: styles.cell_header,
		enableColumnFilter: true,
		filterFn: 'customfilterFns' as FilterFnOption<unknown>,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.matching_offer_demands.events')
		}),
		id: 'offer_states',
		accessorKey: 'offer_states',
		className: styles.cell_header,
		enableColumnFilter: true,
		filterFn: 'customfilterFns' as FilterFnOption<unknown>,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.actions')
		}),
		id: 'actions',
		accessorKey: 'actions',
		className: styles.cell_header,
		enableColumnFilter: false,
	}
];
