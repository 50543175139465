import i18n from '@modules/i18n';
import styles from '../lots.module.scss';

const columnTableConf = [
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.building')
		}),
		id: 'building',
		accessorKey: 'building',
		className: styles.cell_header_building,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.lot_number')
		}),
		id: 'cell',
		accessorKey: 'cell',
		className: styles.cell_header_cell,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.level')
		}),
		id: 'level',
		accessorKey: 'level',
		className: styles.cell_header_level,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.nature')
		}) + ' *',
		id: 'nature',
		accessorKey: 'nature',
		className: styles.cell_header_nature,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.surface')
		}) + ' *',
		id: 'surface',
		accessorKey: 'surface',
		className: styles.cell_header_surface,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.workstations_number')
		}),
		id: 'workstations_number',
		accessorKey: 'workstations_number',
		className: styles.cell_header_workstations_number,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.workstation_rental')
		}),
		id: 'workstation_rental',
		accessorKey: 'workstation_rental',
		className: styles.cell_header_workstation_rental,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.rental_price_m2')
		}),
		id: 'rental_price_m2',
		accessorKey: 'rental_price_m2',
		className: styles.cell_header_rental_price_m2,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.rental_price')
		}),
		id: 'rental_price',
		accessorKey: 'rental_price',
		className: styles.cell_header_rental_price,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.sale_price_m2')
		}),
		id: 'sale_price_m2',
		accessorKey: 'sale_price_m2',
		className: styles.cell_header_sale_price_m2,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.sale_price')
		}),
		id: 'sale_price',
		accessorKey: 'sale_price',
		className: styles.cell_header_sale_price,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.charges_m2')
		}),
		id: 'charges_m2',
		accessorKey: 'charges_m2',
		className: styles.cell_header_charges_m2,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.charges_yearly')
		}),
		id: 'charges_yearly',
		accessorKey: 'charges_yearly',
		className: styles.cell_header_charges_yearly,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.availability')
		}),
		id: 'availability',
		accessorKey: 'availability',
		className: styles.cell_header_availability,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.status')
		}),
		id: 'status',
		accessorKey: 'status',
		className: styles.cell_header_status,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.lots.header.observation')
		}),
		id: 'observation',
		accessorKey: 'observation',
		className: styles.cell_header_observation,
		enableColumnFilter: false,
	},
];

export default columnTableConf;
