import React, {
	ChangeEvent,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useOutletContext,
} from 'react-router-dom';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import Header from '@layouts/Pages/PageOfferEntry/header';
import ValidationForm from '@layouts/Pages/PageOfferEntry/validation-form';

// COMPONENTS
import InputDatePicker from '@components/form/input-date-picker';
import Image from '@components/image';

// ASSETS
import infosImage from '@assets/images/lpdipro/illu_recap.png';

// STYLES
import Form from '@components/form';
import styles from './summary.module.scss';

const Summary = () => {
	const { t } = useTranslation();
	const {
		currentLanguage,
		isDesktopResolution,
		offerState,
		formRef,
		setOfferState,
	} : PageOfferContextProps = useOutletContext();

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		const formatedDate = value ? new Date(value).toLocaleDateString(currentLanguage) : undefined;
		setOfferState(prevState => ({
			...prevState,
			[name]: formatedDate,
		}));
	};

	return (
		<>
			<Header
				isValid={true}
			/>
			<div className={styles.section}>
				<div className={styles.section__left}>
					<div className={styles.step_progress}>
						{t('format.capitalize', {
							text: `${t('page.offers_create_edit.step')} 8/8`
						})}
					</div>
					<div className={styles.intro}>
						{t('format.capitalize', {
							text: `${t('page.offers_create_edit.summary.introduction')} 🚀`
						})}
					</div>
					<div className={styles.legend}>{t('general.form.input.required')}</div>
					<Form innerRef={formRef}>
						{/* --------- What is the creation date displayed on the offer?  --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.summary.created_at.title')
							})}
						</div>
						<InputDatePicker
							className={styles.input}
							defaultValue={offerState?.created_at ? new Date(offerState.created_at) : undefined}
							hasPlaceholder={true}
							label={`${t('format.capitalize', {
								text: t('page.offers_create_edit.summary.created_at.label')
							})} *`}
							name={'created_at'}
							required={true}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'created_at')}
						/>

						{/* --------- What is the status date displayed on the offer? --------- */}
						<div className={styles.title}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.summary.under_promise_at.title')
							})}
						</div>
						<InputDatePicker
							className={styles.input}
							defaultValue={offerState?.under_promise_at ? new Date(offerState.under_promise_at) : undefined}
							hasPlaceholder={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.summary.under_promise_at.label')
							})}
							name={'under_promise_at'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'under_promise_at')}
						/>
						<InputDatePicker
							className={styles.input}
							defaultValue={offerState?.under_option_at ? new Date(offerState.under_option_at) : undefined}
							hasPlaceholder={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.summary.under_option_at.label')
							})}
							name={'under_option_at'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'under_option_at')}
						/>
						<InputDatePicker
							className={styles.input}
							defaultValue={offerState?.suspended_at ? new Date(offerState.suspended_at) : undefined}
							hasPlaceholder={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.summary.suspended_at.label')
							})}
							name={'suspended_at'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'suspended_at')}
						/>
						<InputDatePicker
							className={styles.input}
							defaultValue={offerState?.commercialized_at ? new Date(offerState.commercialized_at) : undefined}
							hasPlaceholder={true}
							label={t('format.capitalize', {
								text: t('page.offers_create_edit.summary.commercialized_at.label')
							})}
							name={'commercialized_at'}
							onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, 'commercialized_at')}
						/>
					</Form>
					<ValidationForm
						isValid={offerState?.created_at ? true : false}
					/>
				</div>
				{isDesktopResolution && (
					<div className={styles.section__right}>
						<Image
							alt='placeholder'
							className={styles.image}
							src={infosImage}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default withTranslation()(Summary);
