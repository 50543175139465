import * as React from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import Paragraph from '@components/paragraph';

// TYPE
import {
	SearchCriterias,
} from '@@types/index';

// Styles
import styles from './search-criterias.module.scss';

interface SearchCriteriasReportProps {
	searchCriterias: SearchCriterias;
	editUrl: string;
}

const SearchCriteriasReport = ({
	searchCriterias,
	editUrl
}: SearchCriteriasReportProps) => {
	const { t } = useTranslation();
	const searchCriteriasElement = searchCriterias ? (
		<Paragraph
			className={styles.search_criterias}
			editUrl={editUrl}
			hasBorder={true}
			title={t('format.capitalize', {
				text: t('general.search_criterias.title')
			})}
			titleLevel={2}
		>
			<ul className={styles.text_list}>
				{searchCriterias.locations ? (
					<li>
						<span className={styles.text_list__label}>{t('format.capitalize', {
							text: t('general.search_criterias.locations')
						})} :</span>
						<span className={styles.text_list__value}>{searchCriterias.locations}</span>
					</li>
				) : null}

				{searchCriterias.demand_types?.length ? (
					<li>
						<span className={styles.text_list__label}>{t('format.capitalize', {
							text: t('general.search_criterias.demand_types')
						})} :</span>
						<span className={styles.text_list__value}>
							{
								searchCriterias.demand_types.map((type) => {
									return t('format.capitalize', {
										text: t(`general.${type}`)
									});
								}).join(' - ')
							}
						</span>
					</li>
				) : null}

				{searchCriterias.area?.max?.value || searchCriterias.area?.min?.value ? (
					<li>
						<span className={styles.text_list__label}>{t('format.capitalize', {
							text: t('general.search_criterias.area')
						})} :</span>

						<span className={styles.text_list__value}>
							{searchCriterias.area.min.value ?
								t('format.min', {
									'text': t('format.area', {
										'val': searchCriterias.area.min.value
									})
								}) : null}

							{(searchCriterias.area?.max?.value && searchCriterias.area?.min?.value) ? ' - ' : null}

							{searchCriterias.area.max.value ?
								t('format.max', {
									'text': t('format.area', {
										'val': searchCriterias.area.max.value
									})
								}) : null}
						</span>
					</li>
				) : null}

				{searchCriterias.natures?.length ? (
					<li>
						<span className={styles.text_list__label}>{t('format.capitalize', {
							text: t('general.search_criterias.natures')
						})} :</span>
						<span className={styles.text_list__value}>
							{
								searchCriterias.natures.map((nature) => {
									return t('format.capitalize', {
										text: t(`references.natures.${nature}`)
									});
								}).join(' - ')
							}
						</span>
					</li>
				) : null}

				{searchCriterias.budget?.min?.price?.amount || searchCriterias.budget?.max?.price?.amount ? (
					<li>
						<span className={styles.text_list__label}>
							{t('format.capitalize', {
								text: t('general.search_criterias.budget')
							})} :</span>

						<span className={styles.text_list__value}>
							{searchCriterias.budget.min.price.amount ?
								t('format.min', {
									'text': t('format.price', {
										'val': searchCriterias.budget.min.price.amount
									})
								}) : null}

							{(searchCriterias.budget?.min?.price?.amount && searchCriterias.budget?.max?.price?.amount) ? ' - ' : null}

							{searchCriterias.budget.max.price.amount ?
								t('format.max', {
									'text': t('format.price', {
										'val': searchCriterias.budget.max.price.amount
									})
								}) : null}

						</span>
					</li>
				) : null}

				{searchCriterias.budget_m2?.min?.price?.amount || searchCriterias.budget_m2?.max?.price?.amount ? (
					<li>
						<span className={styles.text_list__label}>
							{t('format.capitalize', {
								text: t('general.search_criterias.budget_sm')
							})} :</span>

						<span className={styles.text_list__value}>
							{searchCriterias.budget_m2.min.price.amount ?
								t('format.min', {
									'text': t('format.price', {
										'val': searchCriterias.budget_m2.min.price.amount
									})
								}) : null}

							{(searchCriterias.budget_m2?.min?.price?.amount && searchCriterias.budget_m2?.max?.price?.amount) ? ' - ' : null}

							{searchCriterias.budget_m2?.max?.price?.amount ?
								t('format.max', {
									'text': t('format.price', {
										'val': searchCriterias.budget_m2.max.price.amount
									})
								}) : null}

						</span>
					</li>
				) : null}
			</ul>
		</Paragraph >
	) : null;

	return searchCriteriasElement;
};

export default SearchCriteriasReport;
