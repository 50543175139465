import React, {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useState,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
} from '@stores/_slices/toast_msgs';

// TYPES
import {
	Media,
} from '@@types/Media';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumMediaType,
} from '@enums/media.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// EXECPTIONS
import GetOfferMediaError from '@exceptions/GetOfferMediaError';
import PostOfferMediaError from '@exceptions/PostOfferMediaError';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// CONFIG
import {
	getOfferMedia,
	patchOfferMedia,
} from '@layouts/Pages/PageOfferEntry/config/fetch';

// LAYOUTS
import {
	MediaOfferProps,
} from '@layouts/Pages/PageOfferEntry';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Image from '@components/image';

// STYLES
import styles from './modal-update-media.module.scss';

interface ModalUpdateMediaProps {
	mediaOfferState: MediaOfferProps;
	mediaState: Media;
	offerId: number;
	setMediaState?: Dispatch<SetStateAction<Media>>;
	setMediaOfferState?: Dispatch<SetStateAction<MediaOfferProps>>;
	setIsOpenModalUpdateMedia?: Dispatch<SetStateAction<boolean>>;
}

const ModalUpdateMedia = ({
	mediaOfferState,
	mediaState,
	offerId,
	setMediaState,
	setMediaOfferState,
	setIsOpenModalUpdateMedia,
}: ModalUpdateMediaProps) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [
		rotationState,
		setRotationState
	] = useState(0);

	const [
		rotationClass,
		setRotationClass
	] = useState('');

	const rotateImage = (rotationChange: number) => {
		let newRotation = (rotationState + rotationChange) % 360;
		if (newRotation < 0) {
			newRotation += 360;
		}
		setRotationState(newRotation);

		let newRotationClass = '';
		switch (newRotation) {
			case 90:
				newRotationClass = styles.image_rotation__90;
				break;
			case 180:
				newRotationClass = styles.image_rotation__180;
				break;
			case 270:
				newRotationClass = styles.image_rotation__270;
				break;
			default:
				newRotationClass = '';
		}

		setRotationClass(newRotationClass);
	};

	return (
		<Form
			onError={(responseParsed) => {
				// Manage error message
				if (responseParsed.statusText) {
					dispatch(addStatusMsg({
						message: t([
							`status.${responseParsed.statusText}`,
							'status.default'
						]),
						isClosable: true,
						buttons: [
							{
								label: t('format.capitalize', {
									text: t('general.action.contact'),
								}),
								href: 'serviceclient',
							},
						],
						theme: EnumStatusTheme.ERROR,
					}));
				}
			}}
			onSuccess={async () => {
				const patchOfferMediaResponse = await patchOfferMedia(offerId, [
					{
						...mediaState,
						orientation: rotationState,
					}
				]);

				const handleOnErrorCallback = () => {
					dispatch(addStatusMsg({
						message: t('format.capitalize', {
							text: t('updated_element_error_message', {
								'%type%': t(`type_file.${mediaState.type}`),
								context: mediaState.type === EnumMediaType.PLAN ? 'male' : 'female',
							})
						}),
						isClosable: true,
						buttons: [
							{
								label: t('format.capitalize', {
									text: t('general.action.contact'),
								}),
								href: 'serviceclient',
							},
						],
						theme: EnumStatusTheme.ERROR,
					}));
				};

				handleErrorResponse(patchOfferMediaResponse, PostOfferMediaError, handleOnErrorCallback);
				if (patchOfferMediaResponse.status === 200) {
					const getOfferMediaResponse = await getOfferMedia(offerId);
					handleErrorResponse(getOfferMediaResponse, GetOfferMediaError);
					if (getOfferMediaResponse.status === 200) {
						setMediaOfferState({
							...mediaOfferState,
							is_loaded: true,
							media: getOfferMediaResponse.payload,
						});
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t('status.updated_element_success_message', {
									'%type%': t(`type_file.${mediaState.type}`),
									context: mediaState.type === EnumMediaType.PLAN ? 'male' : 'female',
								}),
							}),
							theme: EnumStatusTheme.SUCCESS,
						}));
					}
				}
				setMediaState(undefined);
				setIsOpenModalUpdateMedia(false);
			}}
		>
			<div className={styles.update_media}>
				<InputText
					className={styles.input}
					defaultValue={mediaState?.title}
					label={t('format.capitalize', {
						text: t('modals.update_media.form.title')
					})}
					name="title"
					placeholder={t('modals.update_media.form.title_placeholder')}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setMediaState({
							...mediaState,
							title: event.target.value,
						});
					}}
				/>
				<InputText
					className={styles.input}
					defaultValue={mediaState?.copyright}
					label={t('format.capitalize', {
						text: t('modals.update_media.form.copyright')
					})}
					name="copyright"
					placeholder={t('modals.update_media.form.copyright_placeholder')}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						setMediaState({
							...mediaState,
							copyright: event.target.value,
						});
					}}
				/>

				<Image
					className={`${styles.image_rotation} ${rotationClass}`}
					src={mediaState?.url}
				/>

				<div className={styles.orientation}>
					<Button
						hasBoxShadow={false}
						iconLeft='rotate'
						theme={EnumTheme.NAKED}
						onClick={() => rotateImage(-90)}
					/>
					<Button
						hasBoxShadow={false}
						iconLeft='rotate'
						theme={EnumTheme.NAKED}
						onClick={() => rotateImage(90)}
					/>
				</div>
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={(event: MouseEvent<HTMLElement>) => {
							event.preventDefault();
							setMediaState(undefined);
							setIsOpenModalUpdateMedia(false);
						}}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.save')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</Form>
	);
};

export default withTranslation()(ModalUpdateMedia);
