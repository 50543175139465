import * as React from 'react';
import {
	MouseEvent,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// TYPES
import {
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Button from '@components/button';
import StatusBanner from '@components/status-banner';

// STYLES
import styles from './ModalShare.module.scss';

interface ModalShareProps {
	demand? : DemandJsonPrivate;
	publicUrlDemand? : string;
}

const ModalShare = ({
	demand,
	publicUrlDemand,
}: ModalShareProps) => {
	const { t } = useTranslation();

	const recipientContact = demand?.recipient?.contacts?.collection?.length ? demand.recipient.contacts.collection[0] : null;

	const hrefMailto = t('page.demand.private.email.mailto', {
		'%email%': recipientContact?.email,
		'%subject%': t('page.demand.private.email.contact_customer.subject', {
			'%companyName%': demand.recipient.company?.name,
		}),
		'%body%': t('page.demand.private.email.contact_customer.body', {
			'%name%': recipientContact?.full_name ? ` ${recipientContact.full_name}` : '',
			'%url%': publicUrlDemand
		})
	});

	const [
		isActive,
		setIsActive
	] = useState(false);

	const originLabel = t('format.capitalize', {
		text: t('general.copy_link')
	});

	const updateLabel = t('format.capitalize', {
		text: t('general.copied_link')
	});

	const handleOnClickCopyLink = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		navigator.clipboard.writeText(publicUrlDemand);
		setIsActive(true);
	};

	return (
		<div className={styles.modal_share}>
			<div className={styles.buttons}>
				<Button
					corners={EnumButtonCorners.Square}
					href={hrefMailto}
					iconLeft='envelope'
					label={t('format.capitalize', {
						text: t('modals.share.button_share')
					})}
					theme={EnumTheme.SECONDARY}
				/>
				<Button
					corners={EnumButtonCorners.Square}
					iconLeft={isActive ? 'check' : 'clone'}
					label={isActive ? updateLabel : originLabel}
					theme={EnumTheme.SECONDARY}
					onClick={handleOnClickCopyLink}
				/>
			</div>
			<StatusBanner
				className={styles.status_banner}
				hasIcon={true}
				message={t('format.capitalize', {
					text: t('modals.share.status_banner')
				})}
				theme={EnumStatusTheme.INFO}
			/>
		</div>
	);
};

export default ModalShare;
