// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._d2R6nvD{color:currentcolor;display:flex;flex-direction:column;padding:0;width:100%}._d2R6nvD iframe{border:0}.GtYE8hzl{height:calc(100vh - 4.57142857rem);width:100%}.report-style-class{height:100%;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageObservatoryReport": `_d2R6nvD`,
	"wrapper": `GtYE8hzl`
};
export default ___CSS_LOADER_EXPORT___;
