import React from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// ROUTES
import PATHS from '@routes/paths';

// ENUMS
import {
	EnumButtonCorners,
} from '@enums/button.enum';

// LAYOUT
import Page from '@layouts/Pages/Page';

// COMPONENT
import Button, {
} from '@components/button';

// STYLES
import styles from './step-password-success.module.scss';

function PagePasswordSuccess() {
	const { t } = useTranslation();

	return (
		<Page
			className={`${styles.step} wrapper__small`}
			pageName='login'
			step='password_success'
		>
			<div className={styles.main}>
				<Button
					corners={EnumButtonCorners.Square}
					label={t('general.action.back_to_login')}
					to={PATHS.LOGIN._ROOT}
				/>
			</div>
		</Page>
	);
}

export default withTranslation()(PagePasswordSuccess);
