// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MtBg6YsQ{background-color:var(--color-background-quinary);padding:0;position:relative}.MtBg6YsQ ._0l25HFA{padding-bottom:5.21428571rem}@media screen and (min-width: 768Px)and (orientation: landscape){.MtBg6YsQ ._0l25HFA{height:calc(100vh - 4.57142857rem);overflow-y:hidden;padding-bottom:0}}.H48dz5tD{opacity:.3;width:100%}.H48dz5tD rect{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageDemand": `MtBg6YsQ`,
	"content": `_0l25HFA`,
	"loader": `H48dz5tD`
};
export default ___CSS_LOADER_EXPORT___;
