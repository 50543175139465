// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yWcwEOMk{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;padding-bottom:2.28571429rem;padding-top:4.28571429rem;width:26.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.yWcwEOMk{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.yWcwEOMk{padding-bottom:2.85714286rem;padding-top:4.28571429rem}}.yWcwEOMk .M0pGmwUg{font-size:1.28571429rem;font-weight:600;line-height:1.6em;line-height:1.57142857rem;margin-bottom:1.78571429rem;text-align:center}.yWcwEOMk .M0pGmwUg:first-letter{display:block;text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.yWcwEOMk .M0pGmwUg{font-size:1.42857143rem}}.yWcwEOMk .mLuKo6S7{margin-bottom:0.57142857rem}.yWcwEOMk .mLuKo6S7:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visit_canceled": `yWcwEOMk`,
	"title": `M0pGmwUg`,
	"button": `mLuKo6S7`
};
export default ___CSS_LOADER_EXPORT___;
