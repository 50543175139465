import * as React from 'react';
import i18n from '@modules/i18n';

// TYPES
import {
	User, UserJson,
} from '@@types/User';

// COMPONENTS
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

const optionsState = [
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('status.active')
		}),
		value: 'active',
		name: 'state',
		id: 'active',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('status.pending')
		}),
		value: 'pending',
		name: 'state',
		id: 'pending',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('status.successful')
		}),
		value: 'successful',
		name: 'state',
		id: 'successful',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('status.abandoned_female')
		}),
		value: 'abandoned',
		name: 'state',
		id: 'abandoned',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('status.failed')
		}),
		value: 'failed',
		name: 'state',
		id: 'failed',
	},
];

const optionsActivitiesIds = [
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('page.demands.filter.activities.proposed'),
		}),
		value: 'proposed',
		name: 'activities',
		id: 'proposed',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('page.demands.filter.activities.visited'),
		}),
		value: 'visited',
		name: 'activities',
		id: 'visited',
	},
	{
		label: i18n.t('format.capitalize', {
			text: i18n.t('page.demands.filter.activities.applied'),
		}),
		value: 'application_submitted',
		name: 'activities',
		id: 'application_submitted',
	},
];

const optionsNegociator = (brokers: UserJson[]) => {
	return brokers.map((broker: UserJson, index: number) => {
		const user = new User(broker);
		const createdNegociator = {
			title: user.full_name,
			descriptions: [
				{
					value: user?.email || null,
					type: 'subtitle',
				},
			],
		};
		return {
			label: (
				<UserCard
					data={createdNegociator}
					theme={ThemeUserCard.ROUND}
				/>
			),
			value: broker.id.toString(),
			name: 'negociator',
			id: broker.id.toString(),
			key: index,
		};
	});
};

const optionsNegociatorDesktop = (brokers: UserJson[], currentUserId: number) => {
	return brokers.map((broker: UserJson) => {
		const user = new User(broker);
		const createdNegociator = {
			title: user.full_name,
			descriptions: [
				{
					value: user?.email || null,
					type: 'subtitle',
				},
			],
		};
		return {
			label: user.full_name,
			content: <UserCard
				data={createdNegociator}
				theme={ThemeUserCard.ROUND}
			/>,
			value: `${user.id}`,
			selected: currentUserId === user.id ? true : false,
		};
	});
};

export {
	optionsState,
	optionsActivitiesIds,
	optionsNegociator,
	optionsNegociatorDesktop,
};
