import React from 'react';
import {
	useDispatch,
} from 'react-redux';

// STORES
import {
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENT
import Button, {
	ButtonProps,
} from '@components/button';
import Icon from '@components/icon';

// STYLES
import styles from './status-banner.module.scss';
export interface StatusBannerProps {
	buttons?: Array<ButtonProps>;
	className?: string;
	isClosable?: boolean;
	hasIcon?: boolean;
	message: string;
	theme?: EnumStatusTheme;
	'data-testid'?: string;
}

const StatusBanner = ({
	buttons,
	isClosable = false,
	hasIcon = true,
	className,
	message,
	theme = EnumStatusTheme.INFO,
	'data-testid': dataTestid,
}: StatusBannerProps): JSX.Element | null => {
	const dispatch = useDispatch();

	const cssClasses = [
		styles.banner,
	];

	if (className) cssClasses.push(className);
	if (hasIcon) cssClasses.push(styles.icon);
	if (isClosable) cssClasses.push(styles.closable);
	cssClasses.push(styles[`${'theme__' + theme}`]);

	let iconElement = null;

	let iconName = 'info-circle';
	switch (theme) {
		case EnumStatusTheme.ERROR:
			iconName = 'times-circle';
			break;
		case EnumStatusTheme.SUCCESS:
			iconName = 'check-circle';
			break;
		case EnumStatusTheme.WAIT:
		case EnumStatusTheme.WARNING:
			iconName = 'exclamation-triangle';
			break;
		default:
	}

	iconElement = hasIcon ? (
		<Icon
			className={styles.icon}
			data-testid={`${dataTestid}-icon`}
			name={iconName}
		/>
	) : null;

	const messageElement = (
		<span
			className={styles.message}
			dangerouslySetInnerHTML={{
				__html: message
			}}
		></span>
	);

	const getButtonItems = (itemList: Array<ButtonProps>) => {
		const result = itemList.map((button, index) => {
			return (
				<Button
					corners={EnumButtonCorners.Square}
					href={button.href}
					key={index}
					label={button.label}
					theme={theme}
					to={button.to}
				/>
			);
		});
		return result;
	};
	const buttonElement = buttons ? (
		<div
			className={styles.buttons}
			data-testid={`${dataTestid}-button`}
		>
			{getButtonItems(buttons)}
		</div>
	) : null;

	const handleOnClose = () => {
		dispatch(clearStatusMsgs());
	};

	const closeElement = isClosable ? (
		<Button
			className={styles.closable_button}
			data-testid={`${dataTestid}-close`}
			hasBoxShadow={false}
			iconLeft="times"
			iconStyle={EnumFontStyle.REGULAR}
			size={EnumButtonSize.SMALL}
			theme={EnumTheme.NAKED}
			onClick={handleOnClose}
		/>
	) : null;

	return (
		<div
			aria-live='assertive'
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			role='alert'
		>
			{closeElement}
			<div className={styles.content}>
				{iconElement}
				{messageElement}
			</div>
			{buttonElement}
		</div>
	);
};

export default StatusBanner;

