import React from 'react';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from '../card-view.module.scss';

const Loader = (props: object) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const height_01 = device.display === 'mobile' ? 400 : 210;
	const height_02 = 156;
	const height_03 = 32;
	const spacing = 20;
	const margin_left = '5%';
	const margin_left_02 = '35%';
	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader}
			height="100%"
			width="100%"
		>
			<rect
				height={height_01}
				id="2"
				rx="4"
				ry="4"
				width={device.display === 'mobile' ? '100%' : '27%'}
				x={device.display === 'mobile' ? 0 : margin_left}
				y={spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_02}
				id="3"
				rx="4"
				ry="4"
				width="65%"
				x={device.display === 'mobile' ? 0 : margin_left_02}
				y={spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_03}
				id="4"
				rx="4"
				ry="4"
				width="65%"
				x={device.display === 'mobile' ? 0 : margin_left_02}
				y={spacing + height_02 + spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_01}
				id="5"
				rx="4"
				ry="4"
				width={device.display === 'mobile' ? '100%' : '27%'}
				x={margin_left}
				y={spacing + height_01 + spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_02}
				id="6"
				rx="4"
				ry="4"
				width="65%"
				x={margin_left_02}
				y={spacing + height_01 + spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_03}
				id="7"
				rx="4"
				ry="4"
				width="65%"
				x={margin_left_02}
				y={spacing + height_01 + spacing + height_02 + spacing}
			/>

			<rect
				height={height_01}
				id="2"
				rx="4"
				ry="4"
				width={device.display === 'mobile' ? '100%' : '27%'}
				x={device.display === 'mobile' ? 0 : margin_left}
				y={spacing + height_01 + spacing + height_01 + spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_02}
				id="3"
				rx="4"
				ry="4"
				width="65%"
				x={device.display === 'mobile' ? 0 : margin_left_02}
				y={spacing + height_01 + spacing + height_01 + spacing}
			/>

			<rect
				display={device.display === 'mobile' ? 'none' : 'true'}
				height={height_03}
				id="4"
				rx="4"
				ry="4"
				width="65%"
				x={device.display === 'mobile' ? 0 : margin_left_02}
				y={spacing + height_01 + spacing + height_02 + spacing + height_01 + spacing}
			/>
		</ContentLoader>
	);
};

export default Loader;
