import {
	APP_CONF_VARS as APP_CONF_VARS_GLOBAL,
	InterfaceAppConfVars,
} from './vars.conf';

const APP_CONF_VARS: InterfaceAppConfVars = {
	...APP_CONF_VARS_GLOBAL,
	tools: {
		...APP_CONF_VARS_GLOBAL.tools,
		google: {
			tag_manager: {
				key: process.env.GOOGLE_TAG_MANAGER_ID_LPDI
			}
		}
	}
};
export {
	APP_CONF_VARS,
	InterfaceAppConfVars,
};
