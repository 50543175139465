// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sGdTT_Lt{align-items:center;border-bottom:0.14285714rem solid rgba(0,0,0,0);cursor:pointer;display:flex;font-weight:600;line-height:1em;margin-right:1.14285714rem;padding-bottom:0.28571429rem;text-decoration:none;-webkit-user-select:none;user-select:none;width:max-content}.sGdTT_Lt:last-child{margin-right:0}.sGdTT_Lt .USevC1VR{align-items:center;color:var(--color-boulder);display:flex}.sGdTT_Lt .USevC1VR i{font-size:1.14285714rem;margin-left:0.28571429rem}.JfMruHVQ{color:var(--color-boulder);cursor:not-allowed}.JfMruHVQ a.vO_yXwnv{color:var(--color-boulder);cursor:not-allowed}.JfMruHVQ a.vO_yXwnv:hover{color:var(--color-boulder)}.JfMruHVQ:not(.E7WVeIrH) i.vO_yXwnv{color:var(--color-boulder)}.JfMruHVQ.E7WVeIrH i.vO_yXwnv{color:hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.5)}.W5A9cH4D{border-bottom:0.14285714rem solid var(--color-mine-shaft)}.W5A9cH4D>.USevC1VR{color:var(--color-mine-shaft)}.W5A9cH4D:not(.E7WVeIrH) i.MLsT4bCI{color:var(--color-mine-shaft)}.E7WVeIrH{color:var(--color-mine-shaft)}.E7WVeIrH i.BeknZwyN{color:var(--color-primary)}.E7WVeIrH:not(.W5A9cH4D,.JfMruHVQ){cursor:pointer}.E7WVeIrH:not(.W5A9cH4D,.JfMruHVQ) a.BeknZwyN{color:var(--color-boulder)}.E7WVeIrH:not(.W5A9cH4D,.JfMruHVQ) a.BeknZwyN:hover{color:var(--color-mine-shaft)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `sGdTT_Lt`,
	"child": `USevC1VR`,
	"tab__disabled": `JfMruHVQ`,
	"child__disabled": `vO_yXwnv`,
	"tab__successful": `E7WVeIrH`,
	"tab__active": `W5A9cH4D`,
	"child__active": `MLsT4bCI`,
	"child__successful": `BeknZwyN`
};
export default ___CSS_LOADER_EXPORT___;
