import * as React from 'react';
import {
	AddToCalendarButton as ReactAddToCalendarButton,
	AddToCalendarButtonType,
} from 'add-to-calendar-button-react';

interface AddToCalendarButtonProps extends AddToCalendarButtonType {
	className?: string;
	'data-testid'?: string;
	time?: string;
}

const AddToCalendarButton = ({
	className,
	'data-testid': dataTestid,
	description,
	endTime,
	location,
	name,
	startDate,
	startTime,
}: AddToCalendarButtonProps): JSX.Element => {

	const currentDate = new Date(startDate as string);
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth()+1 < 10 ? `0${currentDate.getMonth()+1}` : currentDate.getMonth()+1;
	const currentDay = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();
	const currentDateFormatted = `${currentYear}-${currentMonth}-${currentDay}`;

	return (
		<div
			className={className}
			data-testid={dataTestid}
		>
			<ReactAddToCalendarButton
				buttonsList
				buttonStyle="round"
				description={description}
				endTime={endTime}
				hideTextLabelButton
				lightMode="bodyScheme"
				location={location}
				name={name}
				options={[
					'Outlook.com',
					'Google',
					'Apple',
					'iCal',
				]}
				startDate={currentDateFormatted}
				startTime={startTime}
				timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
			></ReactAddToCalendarButton>
		</div>
	);
};

export {
	AddToCalendarButton as default,
	AddToCalendarButtonProps,
};

