import {
	createSlice,
} from '@reduxjs/toolkit';

export interface FormJsonVars {
	id: string;
	name: string;
	method?: 'POST' | 'GET';
	action?: string;
	novalidate?: string;
	autofocus?: string;
}

export interface FormJsonField {
	[key: string]: string | boolean;
	id: string;
	name: string;
	type: string;
	label?: string;
	required?: boolean | 'true' | 'false';
	placeholder?: string;
	value?: string;
}

export interface FormJson {
	vars: FormJsonVars;
	fields?: FormJsonField[];
	isValid?: boolean;
}

export interface FormsStateInstances {
	[key: string]: FormJson;
}

// Define a type for the slice state
export interface FormsState {
	instances: FormsStateInstances;
}

// Define the initial state using that type
const initialState: FormsState = {
	// État initial de notre reducer qui stock les formulaires
	instances: {
	}
};

// Partie du store qui permet de stocker, coté client, les informations des fomulaires récupérées auprès du backend
export const formsSlice = createSlice({
	name: 'forms',
	initialState: initialState,
	reducers: {
		// Ceux sont les fonctions qui permettent de manipuler les données afin de construire les formulaires
		addForm: (state, action) => {
			state.instances = {
				...state.instances,
				[action.payload.vars.id]: {
					...action.payload,
					isValid: true
				}
			};
		},
		clearForms: (state) => {
			state.instances = {
			};
		},
		resetAllFormsValidation: (state) => {
			Object.keys(state.instances as FormsStateInstances).forEach(instance => {
				state.instances[instance] = {
					...state.instances[instance],
					isValid: true,
				};
			});
		},
		resetFormValidation: (state, action) => {
			state.instances = {
				...state.instances,
				[action.payload.formId]: {
					...state.instances[action.payload.formId],
					isValid: true,
				}
			};
		},
		removeForm: (state, action) => {
			// payload = form unique id
			delete state.instances[action.payload];
		},
		updateFormValidation: (state, action) => {
			state.instances = {
				...state.instances,
				[action.payload.formId]: {
					...state.instances[action.payload.formId],
					isValid: action.payload.isValid,
				}
			};
		},
		updateFieldValue: (state, action) => {
			(state.instances as FormsStateInstances)[action.payload.formId].fields.forEach(field => {
				if (field.type !== 'password' && action.payload.input.name === field.name) {
					field.value = action.payload.input.value;
				}
				return field;
			});
		},
	}
});
// On exporte chaque fonction afin de les rendre disponibles dans l'application
export const {
	addForm,
	clearForms,
	removeForm,
	resetAllFormsValidation,
	resetFormValidation,
	updateFormValidation,
	updateFieldValue,
} = formsSlice.actions;

export default formsSlice.reducer;
