// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ABWLnk8m{text-align:center}.PXkS9jcR{opacity:.3;width:100%}.PXkS9jcR rect{width:100%}.juWX_sm_{margin-bottom:3.57142857rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.juWX_sm_{margin-bottom:4.28571429rem;padding:0}}.RpWuflA9{display:flex;flex-direction:column}@media screen and (min-width: 768Px)and (orientation: landscape){.RpWuflA9{flex-direction:row}}.RpWuflA9 button{flex:1;margin-bottom:0}@media screen and (min-width: 768Px)and (orientation: landscape){.RpWuflA9 button:first-child{margin-right:0.35714286rem}.RpWuflA9 button:last-child{margin-left:0.35714286rem}}.B1h1hosk{color:currentcolor}.B1h1hosk a{display:block;text-align:center}.B1h1hosk form{margin-bottom:2.71428571rem}.B1h1hosk h1{text-align:center}.B1h1hosk [data-status-msg-wrapper]{margin-left:auto;margin-right:auto;width:100%;max-width:460Px}.RX6sJQFv{margin-bottom:2.85714286rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.RX6sJQFv{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `ABWLnk8m`,
	"loader": `PXkS9jcR`,
	"main": `juWX_sm_`,
	"button_group": `RpWuflA9`,
	"step": `B1h1hosk`,
	"statusMsgWrapper": `RX6sJQFv`
};
export default ___CSS_LOADER_EXPORT___;
