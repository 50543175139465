import React from 'react';
import ContentLoader from 'react-content-loader';
import {
	useSelector,
} from 'react-redux';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import globalStyles from '@styles/styles.module.scss';
import styles from './header.module.scss';

const Loader = (props: object) => {
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const heightTop = device.display === 'mobile' ? 55 : 35;
	const heightBottom = device.display === 'mobile' ? 110 : 80;
	const spacing = device.display === 'mobile' ? 12: 12;
	const margin = device.display === 'mobile' ? '5%': 0;

	return (
		<ContentLoader
			backgroundColor={globalStyles['color-placeholder-background' as string]}
			foregroundColor={globalStyles['color-placeholder-flash' as string]}
			speed={2}
			{...props}
			className={styles.loader}
			height={device.display === 'mobile' ? 178 : 127}
			width="100%"
		>
			<rect
				height={heightTop}
				id="1"
				rx="4"
				ry="4"
				width={device.display === 'mobile' ? '90%' : '40%'}
				x={margin}
				y="0"
			/>
			<rect
				height={heightBottom}
				id="2"
				rx="4"
				ry="4"
				width={device.display === 'mobile' ? '90%': '60%'}
				x={margin}
				y={heightTop + spacing}
			/>
		</ContentLoader>
	);
};

export default Loader;
