import React, {
	Dispatch,
	memo,
	ReactNode,
	SetStateAction,
	useState,
} from 'react';

// TYPES
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// LAYOUTS
import {
	OfferStateContactsProps,
} from '@layouts/Pages/PageOfferEntry/index';

// ROW ELEMENTS
import CommentElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/comment';
import DateElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/date';
import IdentityElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/identity';
import LinkElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/link';
import MandateElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/mandate';
import MandateNumberElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/mandateNumber';
import RefElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/ref';
import RoleElement from '@layouts/Pages/PageOfferEntry/contacts/contact-row/row-elements/role';

// STYLES
import styles from './contact-row.module.scss';

interface ContactRowProps {
	canSelect?: boolean;
	checkboxElement?: ReactNode;
	className?: string;
	currentLanguage: string;
	currentOfferId: number;
	error: boolean;
	isDesktopResolution: boolean;
	isSelected?: boolean;
	offerContact: ContactOffer;
	offerStateContacts: OfferStateContactsProps;
	onClickRow?: (rowId: number) => void;
	rowId?: number;
	selectedRowId?: number;
	setError: Dispatch<SetStateAction<boolean>>;
	setIsOpenModal: Dispatch<SetStateAction<boolean>>;
	setOfferStateContacts: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

export interface IsOpenState {
  comment: boolean;
  identity: boolean;
  link: boolean;
  mandate: boolean;
  mandateNumber: boolean;
  receipt: boolean;
  ref: boolean;
  role: boolean;
}

export interface InputLinkState {
	value: string | undefined;
	type: 'web_reference' | 'web_title' | undefined;
}

export interface ElementProps {
	activeElement: string | null;
	currentOfferId?: number;
	error?: boolean;
	isDesktopResolution: boolean;
	onClickRow?: (id: number) => void;
	offerContact: ContactOfferJson;
	offerStateContacts?: OfferStateContactsProps;
	rowId: number;
	selectedRowId: number;
	setError?: Dispatch<SetStateAction<boolean>>;
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setActiveElement?: Dispatch<SetStateAction<string | null>>;
	setOfferStateContacts?: Dispatch<SetStateAction<OfferStateContactsProps>>;
}

const ContactRow = memo(({
	canSelect,
	checkboxElement,
	className,
	currentOfferId,
	error,
	isDesktopResolution,
	isSelected,
	offerContact,
	offerStateContacts,
	onClickRow,
	rowId,
	selectedRowId,
	setError,
	setIsOpenModal,
	setOfferStateContacts,
}: ContactRowProps): JSX.Element => {

	const cssClasses = [
		styles.contact_row
	];

	const [
		activeElement,
		setActiveElement
	] = useState<string | null>(null);

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.contact_row__can_select);
	if (isSelected) cssClasses.push(styles.contact_row__selected);
	if (error) cssClasses.push(styles.contact_row__error);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			{canSelect ? <div className={styles.selection}>{offerContact?.id ? checkboxElement : undefined}</div> : null}
			<IdentityElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				error={error}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setError={setError}
				setIsOpenModal={setIsOpenModal}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<RoleElement
				activeElement={activeElement}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				rowId={rowId}
				selectedRowId={selectedRowId}
			/>
			<MandateElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<MandateNumberElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<RefElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<DateElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<LinkElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
			<CommentElement
				activeElement={activeElement}
				currentOfferId={currentOfferId}
				isDesktopResolution={isDesktopResolution}
				offerContact={offerContact}
				offerStateContacts={offerStateContacts}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setOfferStateContacts={setOfferStateContacts}
				onClickRow={onClickRow}
			/>
		</div>
	);
});

export default ContactRow;
