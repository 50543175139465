import React, {
	Suspense,
} from 'react';
import {
	Outlet,
} from 'react-router-dom';

// STYLES
import styles from './PageLogin.module.scss';

// COMPONENTS
import Footer from '@components/footer';
import Loader from '@components/loader';

function PageLogin() {
	return (
		<Suspense fallback={<Loader />}>
			<div className={styles.PageLogin}>
				<Outlet />
				<Footer />
			</div>
		</Suspense>
	);
}

export default PageLogin;
