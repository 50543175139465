import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	Outlet,
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

// EXCEPTIONS
import GetBrokersByCompany from '@exceptions/GetBrokersByCompany';
import GetDocumentsTemplates from '@exceptions/GetDocumentsTemplates';

// STORES
import {
	DeviceDisplays,
	DeviceOrientations,
} from '@stores/_slices/device';

// TYPES
import {
	DemandJsonPrivate,
	PageDemandOutletContextType,
} from '@@types/Demand';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// DATA
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import PATHS from '@routes/paths';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// SLICES
import {
	appSetRoute,
} from '@stores/_slices/app';
import {
	clearDemands,
	demandUpdate,
} from '@stores/_slices/demands';
import {
	addStatusMsg,
	clearStatusMsgs,
} from '@stores/_slices/status_msgs';
import {
	addToastMsg,
	clearToastMsgs,
} from '@stores/_slices/toast_msgs';
import {
	userSetDocumentsTemplates,
	userSetCompanyBrokers,
	userLogout,
} from '@stores/_slices/user';

// LAYOUTS
import Page from '@layouts/Pages/Page';
import Header from '@layouts/Pages/PageDemand/header';
import ContentLoader from '@layouts/Pages/PageDemand/header/contentLoader';

// MODULES
import * as utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

// STYLES
import styles from './PageDemand.module.scss';

function PageDemand() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { demandid } = useParams();
	const currentDemandId = !isNaN(Number(demandid)) ? Number(demandid) : null;
	// Get user state
	const demandsGlobalState = useSelector((state: ReducerInstance) => state.demands.instance).collection.filter((item: DemandJsonPrivate) => item?.id === currentDemandId) as DemandJsonPrivate[];
	const demandGlobalState = demandsGlobalState && demandsGlobalState.length ? demandsGlobalState[0] : null;
	const actualLanguage = useSelector((state: ReducerInstance) => state.app.instance.localization.actualLanguage);

	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const isDesktopResolution = device.display === DeviceDisplays.DESKTOP || (device.display === DeviceDisplays.TABLET && device.orientation === DeviceOrientations.LANDSCAPE);

	const [
		isLoaded,
		setIsLoaded
	] = useState<boolean>(false);

	const cssContentClasses = [
		styles.content
	];

	const getBrokersCompany = async () => {
		await fetch(utils.getURL(FETCHES.private.brokers, {
		}), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(userSetCompanyBrokers({
						brokers: responseParsed.payload?.collection?.length ? responseParsed.payload : null,
					}));
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					throw new GetBrokersByCompany(responseParsed.statusText);
			}

		}).catch(err => {
			throw new GetBrokersByCompany(err);
		});
	};

	const getDocumetsTemplates = async () => {
		await fetch(utils.getURL(FETCHES.private.document_templates, {
		}), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			switch (responseParsed.status) {
				case 200:
					dispatch(userSetDocumentsTemplates({
						document_templates: responseParsed.payload?.collection ? responseParsed.payload : null,
					}));
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					break;
				default:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								`status.${responseParsed.statusText}`,
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					throw new GetDocumentsTemplates(responseParsed.statusText);
			}
		}).catch(err => {
			throw new GetDocumentsTemplates(err);
		});
	};

	const getDemand = async () => {
		await fetch(utils.getURL(FETCHES.private.demands.id, {
			':demandid': `${currentDemandId}`,
		}), {
			...APP_CONF_VARS.request.default,
		}).then((resp) => {
			return resp.json();
		}).then((responseParsed) => {
			//event triggered on page load
			if (responseParsed.payload?.redirect_url) {
				// REDIRECT ASKED BY BACKEND
				window.location.href = responseParsed.payload.redirect_url;
				return;
			}
			switch (responseParsed.status) {
				case 200:
					dispatch(demandUpdate({
						demand: responseParsed.payload.demand,
						demand_id: currentDemandId,
					}));
					setIsLoaded(true);
					break;
				case 401:
				case 403:
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t('status.unauthorized_access'),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
					navigate(PATHS.HOMEPAGE);
					break;
				default: {
					if (responseParsed.error === 'access_denied') {
						// STORING THE PREVIOUS ROUTE BEFORE AUTH
						dispatch(appSetRoute({
							name: 'from',
							url: location.pathname
						}));
						dispatch(userLogout());
						dispatch(clearDemands());
						dispatch(addStatusMsg({
							message: t('status.error_session'),
							theme: EnumStatusTheme.ERROR,
						}));
					} else {
						dispatch(addToastMsg({
							message: t('format.capitalize', {
								text: t([
									`status.${responseParsed.statusText}`,
									'status.default'
								]),
							}),
							theme: EnumStatusTheme.ERROR,
						}));
						navigate(PATHS.HOMEPAGE);
					}
				}
			}
		}).catch(err => {
			console.warn('ERROR : ', err);
		});
	};

	const didLogRef = useRef(false);

	useEffect(() => {
		if (didLogRef.current === false) {
			didLogRef.current = true;
			dispatch(clearStatusMsgs());
			dispatch(clearToastMsgs());

			getBrokersCompany();
			getDocumetsTemplates();
			getDemand();
		}
	}, [
	]);

	useEffect(() => {
		document.title = `${t('website.brand_name')} - ${t('page.demand.private.title', {
			'%companyName%': demandGlobalState?.recipient?.company.name
		})}`;
	}, [
		demandGlobalState?.recipient?.company.name
	]);

	const contentDemandRef = useRef<HTMLDivElement>(null);

	return (
		<Page className={styles.PageDemand} >
			<div
				className={cssContentClasses.join(' ')}
				ref={contentDemandRef}
			>
				{isLoaded && demandGlobalState ? (
					<Header
						actualLanguage={actualLanguage}
						contentDemandRef={contentDemandRef}
						demandGlobalState={demandGlobalState}
						isDesktopResolution={isDesktopResolution}
					/>
				) : <ContentLoader />}
				<Outlet
					context={{
						demandGlobalState: isLoaded ? demandGlobalState : null,
						demandIsLoaded: isLoaded,
						isDesktopResolution: isDesktopResolution,
					} as PageDemandOutletContextType}
				/>
			</div>
		</Page>
	);
}

export default withTranslation()(PageDemand);
