// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n7h4_y9L{margin-bottom:2.28571429rem}@media screen and (min-width: 768Px)and (orientation: landscape){.n7h4_y9L{margin-bottom:2.85714286rem}}.n7h4_y9L .mamJNXsf{border-top:0.07142857rem solid var(--color-background-separator);margin-top:1.07142857rem;padding-top:1.21428571rem}.n7h4_y9L .tkOS0a1K{margin-left:0.71428571rem}.n7h4_y9L .JIAT7NOy{display:flex;flex-wrap:wrap;justify-content:space-between}.n7h4_y9L .JIAT7NOy .he8RHa4x{display:flex;flex-direction:column;margin-bottom:0.71428571rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.n7h4_y9L .JIAT7NOy .he8RHa4x{align-items:center;flex-direction:row;line-height:2rem;margin-bottom:0}}.n7h4_y9L .GL63Ql1S{font-weight:600}@media screen and (min-width: 768Px)and (orientation: landscape){.n7h4_y9L .GL63Ql1S{margin-right:1.42857143rem;width:14.28571429rem}}.n7h4_y9L .uqLNK09t{align-items:center;display:flex}.n7h4_y9L .uqLNK09t .JIAT7NOy .he8RHa4x{align-items:flex-start;display:flex;flex-direction:row;width:100%}.n7h4_y9L .uqLNK09t .GL63Ql1S{font-weight:inherit}@media screen and (min-width: 768Px)and (orientation: landscape){.n7h4_y9L .uqLNK09t .GL63Ql1S{margin-right:0;width:auto}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"internal_information": `n7h4_y9L`,
	"toggle": `mamJNXsf`,
	"edit": `tkOS0a1K`,
	"text_list": `JIAT7NOy`,
	"element": `he8RHa4x`,
	"text_list__label": `GL63Ql1S`,
	"text_list__value": `uqLNK09t`
};
export default ___CSS_LOADER_EXPORT___;
