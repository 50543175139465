import * as React from 'react';
import {
	FocusEvent,
	useState,
} from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	EnumInputType,
} from '@enums/form.enum';
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';
import Input from '@components/form/input';

import styles from './Iframe.module.scss';

function PageTestIframe() {

	const [
		fieldUrl,
		setFieldUrl
	] = useState(`${window.location.host}/webapp/app/home`);

	const [
		newUrl,
		setNewUrl
	] = useState(null);

	const handleOnInput = (fieldUrl: string) => {
		setNewUrl(fieldUrl);
	};

	useDebouncedEffect(() => {
		handleOnInput(fieldUrl);
	}, [
		fieldUrl
	], APP_CONF_VARS.timeout.debounce);

	return (
		<div
			className={styles.PageTestIframe}
		>
			<h1>Test iframe</h1>
			<div>
				<Input
					placeholder='URL to load in the iframe'
					type={EnumInputType.TEXT}
					value={fieldUrl}
					onInput={(event: FocusEvent<HTMLInputElement>) => {
						setFieldUrl(event.currentTarget.value);
					}}
				/>
			</div>
			<iframe
				className={styles.iframe}
				height="500"
				id="inlineFrameExample"
				src={newUrl}
				title="Inline Frame Example"
			>
			</iframe>
		</div>
	);
}

export default PageTestIframe;
