import React, {
	ReactNode,
} from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';
import {
	useSelector,
} from 'react-redux';
import {
	useLocation,
} from 'react-router-dom';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';

// STYLES
import styles from './Page.module.scss';

interface PageProps {
	children?: ReactNode;
	className?: string;
	code?: string;
	pageName?: string;
	step?: string;
}

function Page({
	children,
	className,
	code,
	pageName,
	step,
}: PageProps) {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const user = useSelector((state: ReducerInstance) => state.user.instance);

	const tokenConf = {
		'%email%': t('website.contact.email'),
		'%username%': user.firstname
	};

	let title = t(`page.${pageName || location.pathname.replace('/', '')}.title`, tokenConf);
	let description = i18n.exists(`page.${pageName}.description`) ? t(`page.${pageName}.description`, tokenConf) : '';
	const brand = t('website.brand_name');

	if (code) {
		title = i18n.exists(`page.${pageName}.${code}.title`) ? t(`page.${pageName}.${code}.title`) : '';
		description = i18n.exists(`page.${pageName}.${code}.description`) ? t(`page.${pageName}.${code}.description`, tokenConf) : '';
	}
	if (step) {
		title = i18n.exists(`page.${pageName}.step.${step}.title`) ? t(`page.${pageName}.step.${step}.title`) : '';
		description = i18n.exists(`page.${pageName}.step.${step}.description`) ? t(`page.${pageName}.step.${step}.description`, tokenConf) : '';
	}

	const titleHTML = title ? <h1>{title}</h1> : '';
	const descriptionHTML = description ? (
		<p
			className={`${styles.description}`}
			dangerouslySetInnerHTML={
				{
					__html: description,
				}
			}
		></p>
	) : '';

	document.title = `${brand} - ${title}`;

	const cssClasses = [
		styles.page
	];
	if (className) cssClasses.push(className);

	return (
		<div className={cssClasses.join(' ')}>
			{pageName ? titleHTML : null}
			{descriptionHTML}
			<main>
				{children}
			</main>
		</div>
	);
}

export default withTranslation()(Page);
