import * as React from 'react';
import {
	Dispatch,
	FormEvent,
	MouseEvent,
	SetStateAction,
} from 'react';
import {
	Trans,
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// STORES
import {
	demandsDelete,
} from '@stores/_slices/demands';
import {
	addToastMsg,
} from '@stores/_slices/toast_msgs';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

// LAYOUTS
import {
	deleteDemands,
} from '@layouts/Pages/PageDemands/config/fetch';

// COMPONENTS
import Button, {
} from '@components/button';

// STYLES
import styles from './ModalDeleteDemand.module.scss';

interface ModalDeleteDemandProps {
	demandIds: number[];
	setIsOpenModal?: Dispatch<SetStateAction<boolean>>;
	setReinitSelection?: Dispatch<SetStateAction<boolean>>;
}

const ModalDeleteDemand = ({
	demandIds,
	setIsOpenModal,
	setReinitSelection
}: ModalDeleteDemandProps) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleOnClickCancel = ((event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		setIsOpenModal(false);
	});

	const handleOnClickConfirm = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		deleteDemands(demandIds).then((resp) => {
		//event triggered on page load
			switch (resp.status) {
				case 200:
					dispatch(demandsDelete({
						demand_ids: demandIds,
					}));
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t(`page.demands.deleted_demand${demandIds.length > 1 ? '_other' : ''}`),
						}),
					}));

					setReinitSelection(true);
					break;
				default: {
					dispatch(addToastMsg({
						message: t('format.capitalize', {
							text: t([
								'status.default'
							]),
						}),
						theme: EnumStatusTheme.ERROR,
					}));
				}
			}
		});

		setIsOpenModal(false);
	};

	return (
		<form
			onSubmit={event => {
				handleOnClickConfirm(event);
			}}
		>
			<div className={styles.delete_demand}>
				<h2 className={styles.title}>
					{t('format.capitalize', {
						text: t(`modals.delete_demand.title${demandIds.length > 1 ? '_other' : ''}`)
					})}
				</h2>
				<div className={styles.introduction}>
					<Trans
						components={{
							br: <br />,
							span: <span />,
						}}
						i18nKey={demandIds.length > 1 ? 'modals.delete_demand.introduction_other' :'modals.delete_demand.introduction'}
					/>
				</div>
				<div
					className={styles.footer_buttons}
				>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.cancel')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.SECONDARY}
						type={EnumButtonType.RESET}
						onClick={handleOnClickCancel}
					/>
					<Button
						corners={EnumButtonCorners.Square}
						label={t('format.capitalize', {
							text: t('general.action.confirm')
						})}
						size={EnumButtonSize.SMALL}
						theme={EnumTheme.PRIMARY}
						type={EnumButtonType.SUBMIT}
					/>
				</div>
			</div>
		</form>
	);
};

export default withTranslation()(ModalDeleteDemand);
