import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// TYPES

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	handleOnClickFocus,
	updateOfferContact,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	ElementProps,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../contact-row.module.scss';

function MandateNumberElement({
	activeElement,
	currentOfferId,
	isDesktopResolution,
	onClickRow,
	offerContact,
	offerStateContacts,
	rowId,
	selectedRowId,
	setActiveElement,
	setOfferStateContacts,
}: ElementProps) {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [
		inputValueMandateNumber,
		setInputValueMandateNumber
	] = useState<string>(undefined);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const handleChangeMandateNumber = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueMandateNumber(value);
	};

	useDebouncedEffect(() => {
		const updateMandateNumber = async () => {
			await updateOfferContact(currentOfferId, offerContact, inputValueMandateNumber, 'mandate_number', offerStateContacts, setOfferStateContacts, dispatch);
		};

		if (inputValueMandateNumber !== undefined) {
			updateMandateNumber();
		}
	}, [
		inputValueMandateNumber
	], APP_CONF_VARS.timeout.debounce);

	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef(null);

	const inputMandateNumberElement = (
		<InputText
			defaultValue={offerContact?.mandate_number}
			innerRef={inputRef}
			name={`mandate_number_${rowId}`}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.mandate_number.input_placeholder')
			})}
			value={offerContact?.mandate_number}
			onChange={handleChangeMandateNumber}
		/>
	);

	const modalMandateNumberMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.header_table.mandate_number')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputMandateNumberElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_mandate_number
	];

	if (activeElement === 'mandate_number' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);
	if (!offerContact?.id) cssClasses.push(styles.not_allowed);

	const mandateNumberRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`mandate_number_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, offerContact, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_mandate_number}
						clickable={true}
						disabled={!offerContact?.id}
						htmlElement={inputMandateNumberElement}
						id={`tooltip_mandate_number_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_mandate_number_content}>
							{offerContact?.mandate_number ? offerContact.mandate_number : (
								<span className={styles.placeholder}>{t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.mandate_number.placeholder')
								})}</span>
							)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.header_table.mandate_number')
						})}</span>
						<span className={styles.mobile_value}>{offerContact?.mandate_number ? offerContact.mandate_number : undefined}</span>
					</>
				)}
			</div>
			{modalMandateNumberMobile}
		</>
	);
	return mandateNumberRow;
}

export default MandateNumberElement;
