const dateUtils = {
	isItChristmasWeek: () => {
		const now = new Date();
		const christmas = {
			month: 12,
			day: 25
		};
		const isChristmasWeek = now.getDate() > (christmas.day - 7) && now.getDate() <= christmas.day;
		return (now.getMonth() + 1 === christmas.month && isChristmasWeek);
	},
	isBetweenTwoDates: (start: Date, end: Date) => {
		const now = new Date();
		return now > start && now < end ? true : false;
	},
	formatedDated: (date: Date, language: string) => new Date(date).toLocaleDateString(language),
};

export default dateUtils;
