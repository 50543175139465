const downloadCSVFile = (csv: Blob, filename: string) => {
	const exportedFilename = filename + '.csv';

	const csvFile = new Blob([
		csv
	], {
		type: 'text/csv'
	});
	const downloadLink = document.createElement('a');
	downloadLink.download = exportedFilename;
	downloadLink.href = window.URL.createObjectURL(csvFile);
	downloadLink.style.display = 'none';
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

export default downloadCSVFile ;
