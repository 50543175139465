import React, {
	ReactElement,
} from 'react';

// COMPONENTS
import {
	TabProps,
} from '@components/tab-list/tab';

// STYLES
import styles from './tab-list.module.scss';

interface TabListProps {
  className?: string;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  'data-testid'?: string;
}

const TabList = ({
	children,
	className,
	'data-testid': dataTestid
}: TabListProps): JSX.Element => {
	const cssClasses = [
		styles.tab_list
	];
	if (className) cssClasses.push(className);

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{children}
		</div>
	);
};

export default TabList;
