import i18n from '@modules/i18n';

import styles from '../offer-row/offer-row.module.scss';

export const columnLocationTable = [
	{
		accessorKey: 'photo',
		className: styles.cell__image,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.photo')
		}),
	},
	{
		accessorKey: 'ref',
		className: styles.cell__reference,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.ref')
		})
	},
	{
		accessorKey: 'natures',
		className: styles.cell__nature,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.nature')
		}),
	},
	{
		accessorKey: 'offer_status',
		className: styles.cell__status,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.offer_status')
		}),
	},
	{
		accessorKey: 'status_date',
		className: styles.cell__date,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.status_date')
		}),
	},
	{
		accessorKey: 'is_private',
		className: styles.cell__private,
		header: undefined,
	},
	{
		accessorKey: 'mandate_type',
		className: styles.cell__mandate_type,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.mandate_type')
		}),
	},
	{
		accessorKey: 'area',
		className: styles.cell__area,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.area')
		}),
	},
	{
		accessorKey: 'actions_lots',
		className: styles.cell__lots,
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.location.columns.lots')
		}),
	},
	{
		accessorKey: 'actions',
		className: styles.cell__actions,
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.actions')
		}),
	},
];
