// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PgA9GieL{align-items:center;display:flex;flex-direction:column;height:100%;max-width:100%}.PgA9GieL .TXaxyQVs{height:100%;width:100%}.PgA9GieL .TXaxyQVs>div{height:100%}.PgA9GieL .TXaxyQVs>div iframe{height:100%;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"full": `PgA9GieL`,
	"modal_content": `TXaxyQVs`
};
export default ___CSS_LOADER_EXPORT___;
