import React, {
	MouseEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// STORES
import {
	addStatusMsg,
} from '@stores/_slices/status_msgs';

// TYPES
import {
	Collection,
} from '@@types/Collection';
import {
	ContactOffer,
	ContactOfferJson,
} from '@@types/ContactOffer';

// EXCEPTIONS
import PostOfferContactError from '@exceptions/PostOfferContactError';
import PutOfferContactError from '@exceptions/PutOfferContactError';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// MODULES
import {
	handleErrorResponse,
} from '@modules/utils';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	getSearchContacts,
	postOfferContact,
	putOfferContact,
} from '@layouts/Pages/PageOfferEntry/config/fetch';
import {
	ElementProps,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputMultiSelect, {
	OptionType,
} from '@components/form/input-multi-select';
import Icon from '@components/icon';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from '../contact-row.module.scss';

function IdentityElement({
	activeElement,
	currentOfferId,
	error,
	isDesktopResolution,
	offerContact,
	offerStateContacts,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
	setError,
	setIsOpenModal,
	setOfferStateContacts,
}: ElementProps) {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [
		inputValue,
		setInputValue
	] = useState<string>(undefined);

	const [
		isOpenModalMobile,
		setIsOpenModalMobile
	] = useState(false);

	const [
		searchContacts,
		setSearchContacts
	] = useState([
	] as ContactOffer[]);

	const [
		query,
		setQuery
	] = useState(undefined);

	const [
		isTooltipOpen,
		setIsTooltipOpen
	] = useState(false);

	useEffect(() => {
		const fetchSearchContacts = async () => {
			return await getSearchContacts(query).then(responseParsed => {
				if (responseParsed.status === 200) {
					if (offerStateContacts?.contacts?.collection?.length > 0) {
						const existingContactIds = offerStateContacts.contacts.collection.map(contact => contact.contact_id);
						const filteredContacts = responseParsed.payload.collection.filter((contact: ContactOffer) => {
							return !existingContactIds.includes(contact.contact_id);
						});
						setSearchContacts(filteredContacts);
					} else {
						setSearchContacts(responseParsed.payload.collection);
					}
				}
			});
		};
		fetchSearchContacts();
	}, [
		query
	]);

	useDebouncedEffect(() => {
		if (inputValue?.length >= 3) {
			setQuery(inputValue);
		} else {
			setQuery(undefined);
		}
	}, [
		inputValue
	], APP_CONF_VARS.timeout.debounce);

	const formRef = useRef(null);

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (onClickRow && offerContact?.id) onClickRow(offerContact?.id);
		const id = event.currentTarget.id;
		setActiveElement(prev => (prev === id ? null : id));
	};

	const searchOptions = searchContacts.length > 0 ? searchContacts.map((searchContact) => {
		const newSearchContact = new ContactOffer(searchContact);
		const userCardDataSearch = {
			title: newSearchContact?.full_name,
			id: newSearchContact?.contact_id?.toString(),
			image_url: newSearchContact.logo?.url,
			descriptions: [
				{
					value: newSearchContact?.company_name,
					type: 'subtitle'
				},
			],
		};
		return {
			value: newSearchContact,
			inputValue: {
				label: newSearchContact.full_name
					? `${newSearchContact.full_name}${newSearchContact.company_name ? ` - ${newSearchContact.company_name}` : ''}`
					: newSearchContact.company_name,
				img: newSearchContact.logo,
			},
			label: newSearchContact.is_default ? (
				<div className={styles.mandat_pending}>
					<span>?</span>
					{t('format.capitalize', {
						text: t('general.awaiting_mandate')
					})}
				</div>
			) : (
				<UserCard
					data={userCardDataSearch}
					theme={ThemeUserCard.SQUARE}
				/>
			),
		};
	}) : [
	];

	const inputSearchElement = (
		<div className={styles.input_search_container}>
			<InputMultiSelect
				defaultValue={offerContact ? {
					label: offerContact.full_name,
					value: offerContact,
					inputValue: {
						label: offerContact.full_name,
						img: offerContact.logo,
					},
				} : undefined}
				isMulti={false}
				maxMenuHeight={isDesktopResolution ? 230 : undefined}
				menuIsOpen={true}
				name={'name'}
				noOptionsMessage={() => (
					<div className={styles.no_options_message}>
						<Icon
							className={styles.icon}
							fontStyle={EnumFontStyle.LIGHT}
							name='search-slash'
						/>
						{t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.no_results_message')
						})}
					</div>
				)}
				options={searchOptions}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.identity.add_contact')
				})}
				onChange={async (selected: OptionType | OptionType[]) => {
					setIsTooltipOpen(false);

					const newContact = Array.isArray(selected) ? selected[0]?.value as ContactOffer : selected?.value as ContactOffer;

					let newContactId: number;
					if (offerContact?.id) {
						const putOfferContactResponse = await putOfferContact(currentOfferId, offerContact.id, {
							contact_id: newContact?.contact_id,
						});
						const handleOnErrorCallback = () => {
							setError(true);
							dispatch(addStatusMsg({
								message: t('format.capitalize', {
									text: t('status.updated_element_error_message', {
										'%type%': 'contact',
									}),
								}),
								isClosable: true,
								buttons: [
									{
										label: t('format.capitalize', {
											text: t('general.action.contact'),
										}),
										href: 'serviceclient',
									},
								],
								theme: EnumStatusTheme.ERROR,
							}));
						};
						handleErrorResponse(putOfferContactResponse, PutOfferContactError, handleOnErrorCallback);
						if (putOfferContactResponse.status === 200) {
							newContactId = offerContact?.id;
							setError(false);
						}
					} else {
						const handleOnErrorCallback = () => {
							setError(true);
							dispatch(addStatusMsg({
								message: t('format.capitalize', {
									text: t('status.added_element_error_message', {
										'%type%': 'contact',
									}),
								}),
								isClosable: true,
								buttons: [
									{
										label: t('format.capitalize', {
											text: t('general.action.contact'),
										}),
										href: 'serviceclient',
									},
								],
								theme: EnumStatusTheme.ERROR,
							}));
						};
						const postOfferContactResponse = await postOfferContact(currentOfferId, {
							contact_id: newContact?.contact_id,
						});
						handleErrorResponse(postOfferContactResponse, PostOfferContactError, handleOnErrorCallback);
						if (postOfferContactResponse.status === 200) {
							newContactId = postOfferContactResponse.payload.id;
							setError(false);
						} else {
							setError(true);
						}
					}

					const createNewContactObject = () => ({
						company_name: newContact?.company_name,
						contact_id: newContact?.contact_id,
						firstname: newContact?.firstname,
						id: newContactId,
						is_default: newContact?.is_default,
						is_mandated: newContact?.is_mandated,
						lastname: newContact?.lastname,
						logo: newContact?.logo,
						mandate_number: newContact?.mandate_number,
						original_mail_date: newContact?.original_mail_date,
						reference: newContact?.reference,
						role: newContact?.role,
						web_reference: newContact?.web_reference,
					});

					const existingContacts = offerStateContacts?.contacts?.collection || [
					];

					let newContactList;

					if (existingContacts.length === 0) {
						newContactList = [
							createNewContactObject()
						];
					} else if (rowId === undefined || rowId >= existingContacts.length) {
						newContactList = [
							...existingContacts,
							createNewContactObject()
						];
					} else {
						newContactList = existingContacts.map((contact, index) =>
							index === rowId ? createNewContactObject() : contact
						);
					}

					setOfferStateContacts({
						...offerStateContacts,
						contacts: {
							...offerStateContacts?.contacts,
							collection: newContactList,
						} as Collection<ContactOfferJson, ContactOffer>,
					});
				}}
				onInputChange={(newValue: string) => {
					if (newValue) {
						setInputValue(newValue);
					}
				}}
			/>
			<div className={styles.button_container}>
				<Button
					corners={EnumButtonCorners.Square}
					hasBoxShadow={false}
					iconLeft={'plus'}
					label={t('format.capitalize', {
						text: t('page.offers_create_edit.contacts.identity.create_contact')
					})}
					theme={EnumTheme.NAKED}
					onClick={() => {
						setIsTooltipOpen(false);
						setIsOpenModalMobile(false);
						setIsOpenModal(true);
					}}
				/>
			</div>
		</div>
	);

	const modalIdentityMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModalMobile}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.header_table.identity')
			})}
			onClose={() => {
				setIsOpenModalMobile(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputSearchElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModalMobile(false)}
				/>
			</Form>
		</Modal>
	);

	const userCardData = {
		title: offerContact?.full_name,
		id: offerContact?.id?.toString(),
		image_url: offerContact?.logo?.url,
		descriptions: [
			{
				value: offerContact?.company_name,
				type: 'subtitle'
			},
		],
	};

	const contactCell = offerContact?.is_default ? (
		<div className={styles.mandat_pending}>
			<span>?</span>
			{t('format.capitalize', {
				text: t('general.awaiting_mandate')
			})}
		</div>
	) : (
		<UserCard
			data={userCardData}
			theme={ThemeUserCard.SQUARE}
		/>
	);

	const cssClasses = [
		styles.cell_identity
	];

	if (activeElement === 'identity' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);

	const identityRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`identity_${rowId}`}
				onClick={handleOnClick}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_contact_identity}
						clickable={true}
						htmlElement={inputSearchElement}
						id={`tooltip_identity_${rowId}`}
						isOpen={isTooltipOpen}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						setIsOpen={setIsTooltipOpen}
						onCloseHover={true}
					>
						<span className={styles.tooltip_modal_contact_identity_content}>
							{offerContact ? contactCell : error ?
								(
									<span className={styles.error_message}>
										<Icon
											className={styles.icon}
											fontStyle={EnumFontStyle.LIGHT}
											name="exclamation-triangle"
										/>
										{t('format.capitalize', {
											text: t('page.offers_create_edit.contacts.identity.error_message')
										})}
									</span>
								)
								: t('format.capitalize', {
									text: t('page.offers_create_edit.contacts.identity.add_contact')
								})}
						</span>
					</TooltipModal>
				) : (
					<div onClick={() => setIsOpenModalMobile(true)}>{offerContact ? (
						contactCell
					) : (
						<span className={styles.tooltip_modal_contact_identity_content}>
							{t('format.capitalize', {
								text: t('page.offers_create_edit.contacts.identity.add_contact')
							})}
						</span>
					)}
					</div>
				)}
			</div>
			{modalIdentityMobile}
		</>
	);
	return identityRow;
}

export default IdentityElement;
