import i18n from '@modules/i18n';
import styles from '../contacts.module.scss';

const columnTableConf = [
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.identity')
		}) + ' *',
		id: 'identity',
		accessorKey: 'identity',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.role')
		}),
		id: 'role',
		accessorKey: 'role',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.mandate')
		}),
		id: 'mandate',
		accessorKey: 'mandate',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.mandate_number')
		}),
		id: 'mandate_number',
		accessorKey: 'mandate_number',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.ref')
		}),
		id: 'ref',
		accessorKey: 'ref',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.date')
		}),
		id: 'date',
		accessorKey: 'date',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.link')
		}),
		id: 'link',
		accessorKey: 'link',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.offers_create_edit.contacts.header_table.comment')
		}),
		id: 'comment',
		accessorKey: 'comment',
		className: styles.cell_header,
		enableColumnFilter: false,
	}
];

export default columnTableConf;
