// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fTDyQV18{display:flex;flex-direction:column}.Bxw4kY1A{margin-top:2.14285714rem}.Bxw4kY1A .L58fmzOa{display:flex;flex-direction:column}.Bxw4kY1A .WdCjIwci{align-items:flex-start;display:flex;margin-bottom:0.28571429rem}.Bxw4kY1A .FnmFa4Mo{color:var(--color-status-error)}.Bxw4kY1A .wExWKbHd{color:var(--color-status-success)}.Bxw4kY1A .WdCjIwci .cGxeQqNR{font-size:1.14285714rem;height:1.14285714rem;margin-right:0.71428571rem;width:1.14285714rem}.Bxw4kY1A .WdCjIwci .k3nmQtje{font-size:0.85714286rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_password_validation": `fTDyQV18`,
	"panel": `Bxw4kY1A`,
	"list": `L58fmzOa`,
	"rule": `WdCjIwci`,
	"rule__error": `FnmFa4Mo`,
	"rule__validate": `wExWKbHd`,
	"icon": `cGxeQqNR`,
	"label": `k3nmQtje`
};
export default ___CSS_LOADER_EXPORT___;
