import React, {
	Dispatch,
	memo,
	ReactNode,
	SetStateAction,
	useState,
} from 'react';

// TYPES

// LAYOUTS
import {
	LotsStateProps,
} from '@layouts/Pages/PageOfferEntry/index';
import AvailabilityElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/availability';
import BuildingElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/building';
import ChargesM2Element from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/charges_m2';
import ChargesYearlyElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/charges_yearly';
import LevelElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/level';
import LotNumberElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/lot_number';
import NatureElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/nature';
import RentalPriceElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/rental_price';
import RentalPriceM2Element from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/rental_price_m2';
import SalePriceElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/sale_price';
import SalePriceM2Element from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/sale_price_m2';
import SurfaceElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/surface';
import WorkstationNumberElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/workstation_number';
import WorkstationRentalElement from '@layouts/Pages/PageOfferEntry/lots/lot-row/row-elements/workstation_rental';

// STYLES
import styles from './lot-row.module.scss';
import ObservationElement from './row-elements/observation';
import StatusElement from './row-elements/status';

export interface LotRowProps {
	activeElement?: string;
	canSelect?: boolean;
	checkboxElement?: ReactNode;
	className?: string;
	currentOfferId: number;
	error?: boolean;
	isSelected?: boolean;
	currentLot?: LotsStateProps;
	onClickRow?: (rowId: number) => void;
	rowId?: number;
	selectedRowId?: number;
	setActiveElement?: Dispatch<SetStateAction<string | null>>;
	setError?: Dispatch<SetStateAction<boolean>>;
}

const LotRow = memo(({
	canSelect,
	checkboxElement,
	className,
	currentOfferId,
	error,
	isSelected,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setError,
}: LotRowProps): JSX.Element => {

	const cssClasses = [
		styles.lot_row
	];

	const [
		activeElement,
		setActiveElement
	] = useState<string | null>(null);

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.lot_row__can_select);
	if (isSelected) cssClasses.push(styles.lot_row__selected);
	if (error) cssClasses.push(styles.lot_row__error);

	return (
		<div
			className={cssClasses.join(' ')}
		>
			<div className={styles.selection}>{currentLot.id ? checkboxElement : undefined}</div>
			<BuildingElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<LotNumberElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<LevelElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<NatureElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				error={error}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setError={setError}
				onClickRow={onClickRow}
			/>
			<SurfaceElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				error={error}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				setError={setError}
				onClickRow={onClickRow}
			/>
			<WorkstationNumberElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<WorkstationRentalElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<RentalPriceM2Element
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<RentalPriceElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<SalePriceM2Element
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<SalePriceElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<ChargesM2Element
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<ChargesYearlyElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<AvailabilityElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<StatusElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
			<ObservationElement
				activeElement={activeElement}
				currentLot={currentLot}
				currentOfferId={currentOfferId}
				rowId={rowId}
				selectedRowId={selectedRowId}
				setActiveElement={setActiveElement}
				onClickRow={onClickRow}
			/>
		</div>
	);
});

export default LotRow;
