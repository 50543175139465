import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';
import {
	useOutletContext,
} from 'react-router-dom';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// LAYOUTS
import {
	PageOfferContextProps,
} from '@layouts/Pages/PageOfferEntry';
import {
	handleOnClickFocus,
	updateOfferLots,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	LotRowProps,
} from '@layouts/Pages/PageOfferEntry/lots/lot-row/index';

// ENUMS
import {
	EnumButtonCorners, EnumButtonType,
} from '@enums/button.enum';
import {
	EnumInputType,
} from '@enums/form.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputTextWithUnit from '@components/form/input-text-with-unit';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../lot-row.module.scss';

function RentalPriceM2Element({
	activeElement,
	currentOfferId,
	currentLot,
	onClickRow,
	rowId,
	selectedRowId,
	setActiveElement,
}: LotRowProps) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const {
		isDesktopResolution,
		offerStateLots,
		setOfferStateLots,
	}: PageOfferContextProps = useOutletContext();

	const [
		inputValueRentalPriceM2,
		setInputValueRentalPriceM2
	] = useState<string>(undefined);

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const handleChangeRentalPriceM2 = (event: ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueRentalPriceM2(value);
	};

	useDebouncedEffect(() => {
		const updateRentalPriceM2 = async () => {
			await updateOfferLots(currentOfferId, currentLot, inputValueRentalPriceM2, 'rental_price_m2', offerStateLots, rowId, dispatch, setOfferStateLots);
		};

		if (inputValueRentalPriceM2 !== undefined) {
			updateRentalPriceM2();
		}
	}, [
		inputValueRentalPriceM2
	], APP_CONF_VARS.timeout.debounce);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputRentalPriceM2Element = (
		<InputTextWithUnit
			defaultValue={currentLot?.rental_price_m2}
			innerRef={inputRef}
			name={`rental_price_m2_${rowId}`}
			placeholder={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.rental_price_m2.placeholder')
			})}
			type={EnumInputType.NUMBER}
			unit={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.rental_price_m2.unit')
			})}
			onChange={handleChangeRentalPriceM2}
		/>
	);

	const modalWorkstationsNumberMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.lots.header.rental_price_m2')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
			>
				{inputRentalPriceM2Element}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_rental_price_m2
	];

	if (activeElement === 'rental_price_m2' && selectedRowId === currentLot?.id) cssClasses.push(styles.active);

	const rentalPriceM2Row = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`rental_price_m2_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, currentLot, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_rental_price_m2}
						clickable={true}
						htmlElement={inputRentalPriceM2Element}
						id={`tooltip_rental_price_m2_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_rental_price_m2_content}>
							{currentLot?.rental_price_m2 ? t('format.price', {
								val: currentLot.rental_price_m2
							}) : (
								<span className={styles.placeholder}>{t('format.capitalize', {
									text: t('page.offers_create_edit.lots.header.rental_price_m2')
								})}</span>
							)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.lots.header.rental_price_m2')
						})}</span>
						<span className={styles.mobile_value}>{currentLot?.rental_price_m2 ? currentLot.rental_price_m2 : undefined}</span>
					</>
				)}
			</div>
			{modalWorkstationsNumberMobile}
		</>
	);

	return rentalPriceM2Row;
}

export default RentalPriceM2Element;
