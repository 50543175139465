import React from 'react';

// ENUMS
import {
	EnumStatusTheme,
} from '@enums/theme.enum';

// COMPONENTS
import Icon from '@components/icon';

// STYLES
import styles from './toast.module.scss';

export interface ToastProps {
	className?: string;
	'data-testid'?: string;
	message: string;
	theme?: EnumStatusTheme;
	onAnimationEnd?: () => void;
}

const Toast = ({
	className,
	'data-testid': dataTestid,
	message,
	theme,
	onAnimationEnd,
}: ToastProps): JSX.Element => {
	const cssClasses = [
		styles.toast,
		'animation__disappearing_tooltip'
	];

	if (className) cssClasses.push(className);

	if (theme && styles[`theme__${theme}`]) {
		cssClasses.push(styles[`theme__${theme}`]);
	}

	let icon = null;
	let iconName = '';
	switch (theme) {
		case EnumStatusTheme.ERROR:
			iconName = 'times-circle';
			break;
		case EnumStatusTheme.ABANDONED:
		case EnumStatusTheme.INFO:
		case EnumStatusTheme.UPDATE:
			iconName = 'info-circle';
			break;
		case EnumStatusTheme.SUCCESS:
			iconName = 'check-circle';
			break;
		case EnumStatusTheme.WAIT:
		case EnumStatusTheme.WARNING:
			iconName = 'exclamation-triangle';
			break;
		default:
	}
	icon = iconName ? <Icon name={iconName} /> : null;
	if (icon) cssClasses.push(styles.icon__left);

	return (
		<div
			aria-live="assertive"
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			role="alert"
			onAnimationEnd={onAnimationEnd}
		>
			{icon}
			{message}
		</div>
	);
};

export default Toast;
