import i18n from '@modules/i18n';
import {
	EnumSortFn,
} from '@enums/sort.enum';
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

const getColumns = (offerState: OfferStateProps) => [
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.building')
		}),
		accessorKey: 'building',
		sortingFn: EnumSortFn.ALPHANUMERIC,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.lot')
		}),
		accessorKey: 'lot',
		sortingFn: EnumSortFn.ALPHANUMERIC,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.floor')
		}),
		accessorKey: 'floor',
		sortingFn: EnumSortFn.ALPHANUMERIC,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.nature')
		}),
		accessorKey: 'nature',
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.area')
		}),
		accessorKey: 'area',
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.sale_price')
		}),
		accessorKey: 'sale_price',
		dataType: 'currency',
		legend: i18n.t('format.tax_type.excluded_tax_or_excluded_registration_fees', {
			text: i18n.t('format.per.square_meter', {
				text: i18n.t('format.unit.price.iso_4217.symbol')
			})
		}),
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.rental_price')
		}),
		accessorKey: 'rental_price',
		dataType: 'currency',
		legend: offerState?.rental_ref_price_unit ? i18n.t(`format.tax_type.${offerState.rental_ref_price_unit}`, {
			text: i18n.t('format.per.square_meter_per_year', {
				text: i18n.t('format.unit.price.iso_4217.symbol')
			})
		}) : undefined,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.availability')
		}),
		accessorKey: 'availability',
	},
];

export default getColumns;
