// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oxSZZ3pA{align-items:center;background-color:var(--color-white);border:1Px solid var(--color-boulder);border-radius:50%;display:flex;height:1.14285714rem;justify-content:center;min-width:1.14285714rem;width:1.14285714rem}.oxSZZ3pA:hover{background-color:hsla(var(--color-background-primary-h), var(--color-background-primary-s), var(--color-background-primary-l), 0.05);border:1px solid var(--color-mine-shaft)}.NEfWf0gP{border:1Px solid var(--color-background-primary);position:relative}.NEfWf0gP:after{background-color:var(--color-background-primary);border-radius:50%;content:"";height:0.71428571rem;left:0.14285714rem;position:absolute;top:0.14285714rem;width:0.71428571rem}.mC6SFhtX{border:1Px solid hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio_button": `oxSZZ3pA`,
	"radio_button__checked": `NEfWf0gP`,
	"radio_button__disabled": `mC6SFhtX`
};
export default ___CSS_LOADER_EXPORT___;
