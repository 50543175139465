import i18n from '@modules/i18n';
import styles from  '../PageDemands.module.scss';

const columnTableConf = [
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.company')
		}),
		id: 'company',
		accessorKey: 'company',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.contact')
		}),
		id: 'contact',
		accessorKey: 'contact',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.search_criterias.title')
		}),
		id: 'search_criterias',
		accessorKey: 'search_criterias',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.broker')
		}),
		id: 'negotiator',
		accessorKey: 'negotiator',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.demand_state')
		}),
		id: 'state',
		accessorKey: 'state',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.demands.news')
		}),
		id: 'news',
		accessorKey: 'news',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('page.demands.notification')
		}),
		id: 'notification',
		accessorKey: 'notification',
		className: styles.cell_header,
		enableColumnFilter: false,
	},
	{
		header: i18n.t('format.capitalize', {
			text: i18n.t('general.actions')
		}),
		id: 'actions',
		accessorKey: 'actions',
		className: styles.cell_header,
		enableColumnFilter: false,
	}
];

export default columnTableConf;
