import appReducer from '@stores/_slices/app';
import demandsReducer from '@stores/_slices/demands';
import deviceReducer from '@stores/_slices/device';
import offersReducer from '@stores/_slices/offers';
import formsReducer from '@stores/_slices/forms';
import mapReducer from '@stores/_slices/map';
import matchingOffersDemandsReducer from '@stores/_slices/matching_offers_demands';
import statusMsgsReducer from '@stores/_slices/status_msgs';
import toastMsgsReducer from '@stores/_slices/toast_msgs';
import userReducer from '@stores/_slices/user';
import {
	AppState,
} from '@stores/_slices/app';
import {
	DeviceSliceInstance,
} from '@stores/_slices/device';
import {
	FormsState,
} from '@stores/_slices/forms';
import {
	MapSliceInstance,
} from '@stores/_slices/map';
import {
	GlobalUserInstance,
} from '@stores/_slices/user';
import {
	StoreStatusMsgsInstance,
} from '@stores/_slices/status_msgs';
import {
	StoreToastMsgsInstance,
} from '@stores/_slices/toast_msgs';
import {
	StoreDemands,
} from '@stores/_slices/demands';
import {
	StoreOffers,
} from '@stores/_slices/offers';
import {
	StoreMatchingOffersDemands,
} from '@stores/_slices/matching_offers_demands';
import {
	PersistedState,
} from 'redux-persist';

export interface ReducerInstance extends PersistedState {
	app?: AppState;
	demands?: StoreDemands;
	device?: DeviceSliceInstance;
	forms?: FormsState;
	map?: MapSliceInstance;
	matchings_offers_demands?: StoreMatchingOffersDemands;
	offers?: StoreOffers;
	status_msgs?: StoreStatusMsgsInstance;
	toast_msgs?: StoreToastMsgsInstance;
	user?: GlobalUserInstance;
}
const reducers = {
	app: appReducer,
	demands: demandsReducer,
	device: deviceReducer,
	forms: formsReducer,
	map: mapReducer,
	matchings_offers_demands: matchingOffersDemandsReducer,
	offers: offersReducer,
	status_msgs: statusMsgsReducer,
	toast_msgs: toastMsgsReducer,
	user: userReducer,
};

export default reducers;
