// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hgwpOb3b{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;padding-bottom:9.71428571rem;padding-top:4.28571429rem;width:37.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.hgwpOb3b{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.hgwpOb3b{padding-bottom:7.14285714rem;padding-top:4.28571429rem}}.hgwpOb3b ._BOUhuwa{font-size:1.28571429rem;font-weight:600;line-height:1.6em;line-height:1.57142857rem;margin-bottom:1.78571429rem;text-align:center}.hgwpOb3b ._BOUhuwa:first-letter{display:block;text-transform:uppercase}@media screen and (min-width: 768Px)and (orientation: landscape){.hgwpOb3b ._BOUhuwa{font-size:1.42857143rem}}.hgwpOb3b .qPkABP4f{margin-bottom:2.14285714rem;text-align:center}.hgwpOb3b .qPkABP4f span{font-weight:600}.hgwpOb3b .m67yGq6h{align-items:center;background-color:var(--color-white);border-radius:0;bottom:0;box-shadow:0 0 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);display:flex;flex-direction:column;justify-content:center;left:0;min-height:4.28571429rem;padding:1rem 1.14285714rem;position:absolute;width:100%;z-index:2}@media screen and (min-width: 768Px)and (orientation: landscape){.hgwpOb3b .m67yGq6h{border-radius:0 0 0.57142857rem 0.57142857rem;flex-direction:row;padding:0 2.85714286rem;justify-content:space-between}}.hgwpOb3b .m67yGq6h button{margin-bottom:0.57142857rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.hgwpOb3b .m67yGq6h button{margin-bottom:0;margin-left:0.64285714rem;width:100%}.hgwpOb3b .m67yGq6h button:first-child{margin-left:0}}.hgwpOb3b .m67yGq6h button:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete_demand": `hgwpOb3b`,
	"title": `_BOUhuwa`,
	"introduction": `qPkABP4f`,
	"footer_buttons": `m67yGq6h`
};
export default ___CSS_LOADER_EXPORT___;
