// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlQsR5aB{display:flex;flex-direction:column;max-width:100%;padding-left:1.14285714rem;padding-right:1.14285714rem;max-height:calc(100vh - 4.28571429rem - 0.85714286rem);overflow-y:scroll;padding-bottom:9.71428571rem;padding-top:1.42857143rem;width:37.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.BlQsR5aB{padding-left:2.85714286rem;padding-right:2.85714286rem}}@media screen and (min-width: 768Px)and (orientation: landscape){.BlQsR5aB{padding-bottom:7.14285714rem;padding-top:2.85714286rem}}.BlQsR5aB .qZ58l0M9{border-bottom:1Px solid var(--color-border-default);margin-bottom:1.42857143rem;padding-bottom:1.42857143rem}.BlQsR5aB .M_Fbgti3{font-size:1rem;margin-bottom:1.42857143rem}.BlQsR5aB .isWXiejI{align-items:center;background-color:var(--color-white);border-radius:0;bottom:0;box-shadow:0 0 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);display:flex;flex-direction:column;justify-content:center;left:0;min-height:4.28571429rem;padding:1rem 1.14285714rem;position:absolute;width:100%;z-index:2}@media screen and (min-width: 768Px)and (orientation: landscape){.BlQsR5aB .isWXiejI{border-radius:0 0 0.57142857rem 0.57142857rem;flex-direction:row;padding:0 2.85714286rem;justify-content:space-between}}.BlQsR5aB .isWXiejI button{margin-bottom:0.57142857rem;width:100%}@media screen and (min-width: 768Px)and (orientation: landscape){.BlQsR5aB .isWXiejI button{margin-bottom:0;margin-left:0.64285714rem;width:100%}.BlQsR5aB .isWXiejI button:first-child{margin-left:0}}.BlQsR5aB .isWXiejI button:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"demand_filter": `BlQsR5aB`,
	"separator": `qZ58l0M9`,
	"checkbox": `M_Fbgti3`,
	"footer_buttons": `isWXiejI`
};
export default ___CSS_LOADER_EXPORT___;
