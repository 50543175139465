import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import FETCHES from '@routes/fetches';

// MODULES
import {
	getURL,
} from '@modules/utils';

// TYPES
import {
	Media,
	MediaUpload,
} from '@@types/Media';

// ENUMS
import {
	EnumQueryMethods,
} from '@enums/query.enum';

// LAYOUTS
import {
	OfferStateProps,
} from '@layouts/Pages/PageOfferEntry';

// EXCEPTIONS
import DeleteOfferContactsError from '@exceptions/DeleteOfferContactsError';
import DeleteOfferLotsError from '@exceptions/DeleteOfferLotsError';
import DeleteOfferMediaError from '@exceptions/DeleteOfferMediaError';
import GetMarketersError from '@exceptions/GetMarketersError';
import GetOfferContactsError from '@exceptions/GetOfferContactsError';
import GetOfferDefaultValuesError from '@exceptions/GetOfferDefaultValuesError';
import GetOfferEnumsError from '@exceptions/GetOfferEnumsError';
import GetOfferError from '@exceptions/GetOfferError';
import GetOfferLotsEditError from '@exceptions/GetOfferLotsEditError';
import GetOfferLotsError from '@exceptions/GetOfferLotsError';
import GetOfferLotsTotalError from '@exceptions/GetOfferLotsTotalError';
import GetOfferMediaError from '@exceptions/GetOfferMediaError';
import GetSearchAddressByIdError from '@exceptions/GetSearchAddressByIdError';
import GetSearchAddressesByStreetCityError from '@exceptions/GetSearchAddressesByStreetCityError';
import GetSearchContactsError from '@exceptions/GetSearchContactsError';
import PatchOfferMediaError from '@exceptions/PatchOfferMediaError';
import PostMarketersError from '@exceptions/PostMarketersError';
import PostOfferContactsError from '@exceptions/PostOfferContactError';
import PostOfferError from '@exceptions/PostOfferError';
import PostOfferLotsError from '@exceptions/PostOfferLotsError';
import PostOfferMediaError from '@exceptions/PostOfferMediaError';
import PostOfferSearchError from '@exceptions/PostOfferSearchError';
import PutOfferContactError from '@exceptions/PutOfferContactError';
import PutOfferError from '@exceptions/PutOfferError';
import PutOfferLotsError from '@exceptions/PutOfferLotsError';

// ADDRESSES

export const getSearchAddressById = async (addressId: number) => {
	return await fetch(getURL(FETCHES.private.addresses.id, {
		':address_id': `${addressId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetSearchAddressByIdError(error);
	});
};

export const getSearchAddressesByStreetCity = async (street_raw: string, city_raw: string) => {
	return await fetch(getURL(FETCHES.private.addresses.street_city, {
		':street_raw': `${street_raw}`,
		':city_raw': `${city_raw}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetSearchAddressesByStreetCityError(error);
	});
};

// OFFER

export const postOfferSearch = async (addressId: number) => {
	return await fetch(getURL(FETCHES.private.offers.search), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify({
			address: {
				id: `${addressId}`,
			}
		}),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostOfferSearchError(error);
	});
};

export const postOffer = async (offerState: OfferStateProps) => {
	return await fetch(getURL(FETCHES.private.offers._ROOT), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(offerState),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostOfferError(error);
	});
};

export const putOffer = async (offerState: OfferStateProps) => {
	return await fetch(getURL(FETCHES.private.offers.id, {
		':offerid': `${offerState.id}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.PUT,
		body: JSON.stringify(offerState),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PutOfferError(error);
	});
};

export const getOffer = async (offerId: number) => {
	return await fetch(getURL(FETCHES.private.offers.edit, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferError(error);
	});
};

// LOTS

export const getOfferLots = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.lots, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferLotsError(error);
	});
};

export const getOfferLotsEdit = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.lots_edit.__ROOT, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferLotsEditError(error);
	});
};

export const getOfferLotsTotal = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.lots_edit.total, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferLotsTotalError(error);
	});
};

export const putOfferLots = async (offerid: number, lotid: number, lot: object) => {
	return await fetch(getURL(FETCHES.private.offers.lots_edit.lot_id, {
		':offerid': `${offerid}`,
		':lotid': `${lotid}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.PUT,
		body: JSON.stringify(lot),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PutOfferLotsError(error);
	});
};

export const postOfferLots = async (offerid: number, lot: object) => {
	return await fetch(getURL(FETCHES.private.offers.lots_edit.__ROOT, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(lot),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostOfferLotsError(error);
	});
};

export const deleteOfferLots = async (offerId: number, contactIds: number[]) => {
	return await fetch(getURL(FETCHES.private.offers.lots_edit.__ROOT, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.DELETE,
		body: JSON.stringify({
			ids: contactIds,
		}),
	}).catch(error => {
		throw new DeleteOfferLotsError(error);
	});
};

// CONTACTS

export const getOfferContacts = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.contacts.__ROOT, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferContactsError(error);
	});
};

export const postOfferContact = async (offerid: number, contact: object) => {
	return await fetch(getURL(FETCHES.private.offers.contacts.__ROOT, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(contact),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostOfferContactsError(error);
	});
};

export const putOfferContact = async (offerid: number, contactid: number, contact: object) => {
	return await fetch(getURL(FETCHES.private.offers.contacts.contact_id, {
		':offerid': `${offerid}`,
		':contactid': `${contactid}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.PUT,
		body: JSON.stringify(contact),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PutOfferContactError(error);
	});
};

export const deleteOfferContacts = async (offerId: number, contactids: number[]) => {
	return await fetch(getURL(FETCHES.private.offers.contacts.__ROOT, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.DELETE,
		body: JSON.stringify({
			ids: contactids,
		}),
	}).catch(error => {
		throw new DeleteOfferContactsError(error);
	});
};

// MARKETERS

export const getMarketers = async (company: string) => {
	return await fetch(getURL(FETCHES.private.marketers, {
		':company': `${company}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetMarketersError(error);
	});
};

export const postMarketers = async (marketers: object) => {
	return await fetch(getURL(FETCHES.private.marketers_create, {
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(marketers),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostMarketersError(error);
	});
};

export const getSearchContacts = async (query: string) => {
	return await fetch(getURL(FETCHES.private.contacts_search, {
		':query': `${query}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetSearchContactsError(error);
	});
};

// ENUMS

export const getOfferEnums = async () => {
	return await fetch(getURL(FETCHES.private.offers.enums), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferEnumsError(error);
	});
};

export const getOfferDefaultValues = async (offerid: number) => {
	return await fetch(getURL(FETCHES.private.offers.default_values, {
		':offerid': `${offerid}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferDefaultValuesError(error);
	});
};

// MEDIA

export const postOfferMedia = async (offerId: number,  media: MediaUpload[]) => {
	return await fetch(getURL(FETCHES.private.offers.media, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.POST,
		body: JSON.stringify(media),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PostOfferMediaError(error);
	});
};

export const patchOfferMedia = async (offerId: number, media: Media[]) => {
	return await fetch(getURL(FETCHES.private.offers.media, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.PATCH,
		body: JSON.stringify(media),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new PatchOfferMediaError(error);
	});
};

export const deleteOfferMedia = async (offerId: number, mediaIds: number[]) => {
	return await fetch(getURL(FETCHES.private.offers.media, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
		method: EnumQueryMethods.DELETE,
		body: JSON.stringify({
			ids: mediaIds,
		}),
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new DeleteOfferMediaError(error);
	});
};

export const getOfferMedia = async (offerId: number) => {
	return await fetch(getURL(FETCHES.private.offers.media, {
		':offerid': `${offerId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch(error => {
		throw new GetOfferMediaError(error);
	});
};
