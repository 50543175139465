// CONGIG
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// MODULES
import {
	getURL,
} from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

// EXCEPTIONS
import GetObservatoryReportError from '@exceptions/GetObservatoryReportError';
import GetUserAuthError from '@exceptions/GetUserAuthError';

export const getObservatoryReport = async (currentReportId: number) => {
	return await fetch(getURL(FETCHES.private.observatory.report, {
		':reportid': `${currentReportId}`,
	}), {
		...APP_CONF_VARS.request.default,
	}).then((resp) => {
		return resp.json();
	}).catch((error) => {
		throw new GetObservatoryReportError(error);
	});
};

export const getUserAuth = async () => {
	return await fetch(getURL(FETCHES.public.session), {
		...APP_CONF_VARS.request.default,
	}).then((response) => {
		return response.json();
	}).catch(error => {
		throw new GetUserAuthError(error);
	});
};
