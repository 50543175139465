import React, {
	MouseEvent,
	ReactElement,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useLocation,
} from 'react-router-dom';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumThemeLink,
} from '@enums/link.enum';

//COMPONENTS
import Icon from '@components/icon';
import LinkNav, {
	LinkNavProps,
} from '@components/link-nav';
import NavigationDropdown from '@components/navigation/navigation-dropdown';

//STYLES
import styles from './navigation-item.module.scss';

export interface ItemDataProps extends LinkNavProps {
	content?: string;
	icon?: string;
	items?: LinkNavProps[];
	label?: string;
	separator?: string;
	text_icon?: string;
	visible?: { [key: string]: boolean };
}

interface NavigationItemProps {
	className?: string;
	'data-testid'?: string;
	deviceDisplay?: string;
	itemData?: ItemDataProps;
	lvl?: number;
	navIsOpen?: boolean;
	// only navigation isOpen
	onClick?: (event: MouseEvent<HTMLElement>) => void;
}

function NavigationItem({
	className,
	'data-testid': dataTestid,
	deviceDisplay = 'mobile',
	itemData,
	lvl,
	navIsOpen = false,
	onClick,
}: NavigationItemProps) {
	const { t } = useTranslation();
	const location = useLocation();

	const menuLvl = lvl ? lvl + 1 : 1;
	let line = null;
	let title = null;
	let icon = null;

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		if (onClick) onClick(event);
	};

	function hasAtLeastOneChildActive(items: ItemDataProps[]) {
		let hasChildActive = 0;
		hasChildActive = items.filter(item => {
			return item.href === location.pathname;
		}).length;
		return hasChildActive;
	}

	function getActiveChildIndex(items: ItemDataProps[]) {
		const result: Array<number> = [
		];
		items.forEach((item: ItemDataProps, index: number) => {
			if (item.href === location.pathname) result.push(index);
		});
		return result.length ? result[0] : null;
	}

	let hasChildActive = 0;
	let childActiveIndex: number = null;
	if (itemData?.items) {
		hasChildActive = hasAtLeastOneChildActive(itemData.items);
		childActiveIndex = getActiveChildIndex(itemData.items);
	}
	if (itemData?.text_icon) {
		icon = <span className={styles.text_circle}><span className={styles.text_icon}>{itemData.text_icon}</span></span>;
	} else if (itemData?.icon) {
		icon = (
			<Icon
				className={styles.icon}
				fontStyle={EnumFontStyle.LIGHT}
				name={itemData.icon}
			/>
		);
	}

	function getHeadingByLvl(definedLvl: number, childrens: ReactElement) {
		let result = <div className={`${styles.line} ${styles.line__lvl3}`}>{childrens}</div>;
		switch (definedLvl) {
			case 1:
				result = (
					<div
						className={`${styles.line} ${styles.line__lvl1}`}
						role='heading'
					>{childrens}</div>
				);
				break;
			case 2: {
				const title = (
					<div
						className={`${styles.line} ${styles.line__lvl2}`}
						role='heading'
					>{childrens}</div>
				);

				let dropdownItems = [
				] as ItemDataProps[];

				dropdownItems = [
					...itemData.items
				];

				const linkWithDropdownElement = (
					<NavigationDropdown
						data-testid={`${dataTestid}-dropdown`}
						items={dropdownItems}
						selectedIndex={childActiveIndex ? childActiveIndex : null}
						titleDropdown={t(itemData.label)}
					>{title}</NavigationDropdown>

				);
				result = !navIsOpen && deviceDisplay === 'desktop' ? linkWithDropdownElement : title;
				break;
			}
			default:
		}
		return result;
	}

	if (itemData?.content) {
		title = (
			<span className={styles.itemContent}>
				{icon}
				<span className={styles.label}>{itemData.content}</span>
			</span >
		);
	} else if ((itemData?.href) && (navIsOpen)) {
		title = (
			<LinkNav
				className={styles.itemContent}
				classNameActive={styles.active__lvl3}
				data-testid={`${dataTestid}-link`}
				disabled={itemData.disabled}
				fontWeight={itemData.fontWeight || EnumFontStyle.REGULAR}
				href={itemData.href}
				target={itemData.target}
				theme={EnumThemeLink.NAKED}
				onClick={handleOnClick}
			>
				{icon}
				<span className={styles.label}>{itemData.label ? t(itemData.label) : ''}</span>
			</LinkNav>
		);
	} else {
		title = itemData?.label || icon ? <span className={styles.itemContent}>{icon}<span className={styles.label}>{t(itemData.label)}</span></span> : null;
	}
	line = title ? getHeadingByLvl(menuLvl, title) : undefined;

	let subitems = null;
	if (itemData?.items?.length) {
		subitems = (
			<ul>
				{itemData.items.map((subitem, index) => {
					return (
						<NavigationItem
							data-testid={`${dataTestid}-subitem`}
							deviceDisplay={deviceDisplay}
							itemData={subitem}
							key={index}
							lvl={menuLvl}
							navIsOpen={navIsOpen}
							onClick={handleOnClick}
						/>
					);
				})}
			</ul>
		);
	}

	const cssClasses = [
		styles.navigation_item,
		styles.lvl,
	];

	const allowedLvlClasses = [
		styles.lvl__1,
		styles.lvl__2,
	];

	if (allowedLvlClasses.includes(styles[`lvl__${menuLvl}`])) cssClasses.push(styles[`lvl__${menuLvl}`]);
	if (className) cssClasses.push(className);
	if (navIsOpen) cssClasses.push(styles.open);
	if (hasChildActive) cssClasses.push(styles.active);

	if (itemData?.separator) cssClasses.push(styles.separator);
	return (
		<li
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{line}
			{subitems}
		</li>
	);
}

export default withTranslation()(NavigationItem);
