// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhbMrp9I{opacity:.3;width:100%}.WhbMrp9I rect{width:100%}.H6eaoeHd{margin-bottom:3.57142857rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.H6eaoeHd{margin-bottom:4.28571429rem;padding:0}}.yokzWeet{color:currentcolor;text-align:center}.yokzWeet a{text-align:center}.yokzWeet form{margin-bottom:2.71428571rem}.yokzWeet .VI8agbO0{margin-bottom:0}.yokzWeet h1{text-align:center}.yokzWeet [data-status-msg-wrapper]{margin-left:auto;margin-right:auto;width:100%;max-width:460Px}.fuMU_Vjc{margin-bottom:2.85714286rem;padding:0 1.14285714rem}@media screen and (min-width: 768Px)and (orientation: landscape){.fuMU_Vjc{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `WhbMrp9I`,
	"main": `H6eaoeHd`,
	"step": `yokzWeet`,
	"button": `VI8agbO0`,
	"statusMsgWrapper": `fuMU_Vjc`
};
export default ___CSS_LOADER_EXPORT___;
