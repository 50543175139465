import React, {
	ChangeEvent,
	MouseEvent,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';
import {
	useDispatch,
} from 'react-redux';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// HOOKS
import useDebouncedEffect from '@hooks/useDebouncedEffect/hook.useDebouncedEffect';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonType,
} from '@enums/button.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';
import {
	EnumQueryMethods,
} from '@enums/query.enum';
import {
	EnumTooltipPosition,
} from '@enums/tooltip.enums';

// LAYOUTS
import {
	handleOnClickFocus,
	updateOfferContact,
} from '@layouts/Pages/PageOfferEntry/config/utils';
import {
	ElementProps,
	InputLinkState,
} from '@layouts/Pages/PageOfferEntry/contacts/contact-row';

// COMPONENTS
import Button from '@components/button';
import Form from '@components/form';
import InputText from '@components/form/input-text';
import Icon from '@components/icon';
import Link from '@components/link';
import Modal from '@components/modal';
import TooltipModal from '@components/tooltip-modal';

// STYLES
import styles from '../contact-row.module.scss';

function LinkElement({
	activeElement,
	currentOfferId,
	isDesktopResolution,
	onClickRow,
	offerContact,
	offerStateContacts,
	rowId,
	selectedRowId,
	setActiveElement,
	setOfferStateContacts,
}: ElementProps) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [
		inputValueLink,
		setInputValueLink
	] = useState<InputLinkState>({
		value: undefined,
		type: undefined
	});

	const [
		isOpenModal,
		setIsOpenModal
	] = useState(false);

	const handleChangeLink = async (event: ChangeEvent<HTMLInputElement>, type: 'web_title' | 'web_reference') => {
		const target = event.target as HTMLInputElement;
		const value = target.value;
		setInputValueLink({
			value,
			type
		});
	};

	useDebouncedEffect(() => {
		const updateLink = async () => {
			await updateOfferContact(currentOfferId, offerContact, inputValueLink.value, inputValueLink.type, offerStateContacts, setOfferStateContacts, dispatch);
		};

		if (inputValueLink !== undefined) {
			updateLink();
		}
	}, [
		inputValueLink
	], APP_CONF_VARS.timeout.debounce);

	const inputRef = useRef<HTMLInputElement>(null);
	const formRef = useRef(null);

	const inputLinkElement = (
		<>
			<InputText
				autoComplete='web_title'
				autoFocus={true}
				className={styles.input_link}
				defaultValue={offerContact?.web_title}
				innerRef={inputRef}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.link.web_title.label')
				})}
				name={`link_web_title_${rowId}`}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.link.web_title.input_placeholder')
				})}
				tabIndex={0}
				value={offerContact?.web_title}
				onChange={(event) => handleChangeLink(event, 'web_title')}
			/>
			<InputText
				defaultValue={offerContact?.web_reference}
				label={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.link.web_reference.label')
				})}
				name={`link_web_reference_${rowId}`}
				placeholder={t('format.capitalize', {
					text: t('page.offers_create_edit.contacts.link.web_reference.input_placeholder')
				})}
				value={offerContact?.web_reference}
				onChange={(event) => handleChangeLink(event, 'web_reference')}
			/>
		</>
	);

	const displayLink = (
		<div className={styles.display_link}>
			<Icon
				className={styles.icon}
				name={'edit'}
			/>
			<Link
				className={styles.link}
				href={offerContact?.web_reference}
				target={EnumTargetLink.BLANK}
			>
				{offerContact?.web_title || offerContact?.web_reference}
			</Link>
		</div>
	);

	const modalLinkMobile = (
		<Modal
			hasHeader={true}
			isDesktopResolution={false}
			isOpen={isOpenModal}
			size={EnumModalSize.FULL}
			title={t('format.capitalize', {
				text: t('page.offers_create_edit.contacts.header_table.link')
			})}
			onClose={() => {
				setIsOpenModal(false);
			}}
		>
			<Form
				className={styles.form__modal}
				innerRef={formRef}
				method={EnumQueryMethods.GET}
			>
				{inputLinkElement}
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					label={t('format.capitalize', {
						text: t('general.action.validate')
					})}
					type={EnumButtonType.SUBMIT}
					onClick={() => setIsOpenModal(false)}
				/>
			</Form>
		</Modal>
	);

	const cssClasses = [
		styles.cell_link
	];

	if (activeElement === 'link' && selectedRowId === offerContact?.id) cssClasses.push(styles.active);
	if (!offerContact?.id) cssClasses.push(styles.not_allowed);

	const linkRow = (
		<>
			<div
				className={cssClasses.join(' ')}
				id={`link_${rowId}`}
				onClick={(event: MouseEvent<HTMLElement>) => handleOnClickFocus(activeElement, event, inputRef, isDesktopResolution, offerContact, onClickRow, setActiveElement, setIsOpenModal)}
			>
				{isDesktopResolution ? (
					<TooltipModal
						className={styles.tooltip_modal_link}
						clickable={true}
						disabled={!offerContact?.id}
						htmlElement={inputLinkElement}
						id={`tooltip_link_${rowId}`}
						offset={1}
						openOnClick={true}
						place={EnumTooltipPosition.BOTTOM_START}
						onCloseHover={false}
					>
						<span className={styles.tooltip_modal_link_content}>
							{offerContact?.web_reference ? displayLink : (
								<span className={styles.placeholder}>
									{t('format.capitalize', {
										text: t('page.offers_create_edit.contacts.link.placeholder')
									})}
								</span>
							)}
						</span>
					</TooltipModal>
				) : (
					<>
						<span className={styles.mobile_label}> {t('format.capitalize', {
							text: t('page.offers_create_edit.contacts.header_table.link')
						})}</span>
						<span className={styles.mobile_value}>{offerContact?.web_reference ? offerContact?.web_reference : undefined}</span>
					</>
				)}
			</div>
			{modalLinkMobile}
		</>
	);
	return linkRow;
}

export default LinkElement;
