import * as React from 'react';
import {
	MouseEvent,
	ReactNode,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// TYPES
import {
	Demand,
	DemandJsonPrivate,
} from '@@types/Demand';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumSteps,
} from '@enums/steps.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// CONF
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';

// ROUTES
import PATHS from '@routes/paths';

// MODULES
import * as utils from '@modules/utils';

// COMPONENTS
import Button from '@components/button';
import ButtonWithDropdown from '@components/button-with-dropdown';
import Icon from '@components/icon';
import LabelStatus from '@components/label-status';
import Link from '@components/link';
import Text from '@components/text';

// LAYOUTS
import {
	BodyRequestMatching,
} from '@layouts/Pages/PageMatchingOfferDemands';

// STYLES
import styles from './demand-row.module.scss';

interface DemandsRowProps {
	actions?: ReactNode;
	canSelect?: boolean;
	className?: string;
	checkboxElement?: ReactNode;
	commercializationStep?: string;
	demandJson: DemandJsonPrivate;
	index: number;
	offerId: number;
	isDesktopResolution: boolean;
	isSelected?: boolean;
	onClickCommercializationStep: (event: MouseEvent<HTMLElement>, args: BodyRequestMatching) => void;
	onClickBookmarked: (event: MouseEvent<HTMLElement>, args: BodyRequestMatching) => void;
}

const DemandRow = ({
	canSelect,
	className,
	commercializationStep,
	checkboxElement,
	demandJson,
	isDesktopResolution,
	isSelected,
	offerId,
	onClickCommercializationStep,
	onClickBookmarked,
}: DemandsRowProps): JSX.Element => {
	const { t } = useTranslation();
	const demand = new Demand(demandJson);

	const demandUrl = utils.getURL(`${APP_CONF_VARS.publicPath}${PATHS.DEMANDS.PRIVATE.MANAGE.STEP.OFFER._ROOT}`, {
		':demandid': `${demand.id}`,
		':step': commercializationStep,
		':offerid': `${offerId}`,
	});

	const cssClasses = [
		styles.demand,
	];

	if (className) cssClasses.push(className);
	if (canSelect) cssClasses.push(styles.demand__can_select);
	if (isSelected) cssClasses.push(styles.demand__selected);

	const notSpecified = t('format.capitalize', {
		text: t('general.not_specified')
	});

	const demandStateElement = (
		<LabelStatus
			className={styles.status}
			context='female'
			text={demand.state}
		/>
	);

	const handleOnClickBookmarked = (event: MouseEvent<HTMLElement>) => {
		let states = [
		];

		if (demand?.offer_states?.length) {
			states = demand.offer_states.includes('bookmarked')
				? demand.offer_states.filter(state => state !== 'bookmarked')
				: [
					'bookmarked',
					...demand.offer_states
				];
		} else {
			states = [
				'bookmarked'
			];
		}

		const newBodyRequestMatching: BodyRequestMatching = {
			commercialization_step: commercializationStep,
			demands: [
				demand.id
			],
			states: states,
		};

		onClickBookmarked(event, newBodyRequestMatching);
	};

	let desktopActions = null;

	if (commercializationStep === EnumSteps.MATCHED) {
		desktopActions = (
			<>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='share-arrow'
					iconStyle={EnumFontStyle.LIGHT}
					name='proposed'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.proposed')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'proposed',
						});
					}}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='trash-alt'
					iconStyle={EnumFontStyle.LIGHT}
					name='ignored'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.ignored')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'ignored',
						});
					}}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='star'
					iconStyle={demand?.offer_states?.includes('bookmarked') ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT}
					name='bookmarked'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={demand?.offer_states?.includes('bookmarked')
						? t('format.capitalize', {
							text: t('general.remove_bookmark')
						})
						: t('format.capitalize', {
							text: t('general.bookmark_customer')
						})}
					onClick={handleOnClickBookmarked}
				/>
			</>
		);
	} else if (commercializationStep === EnumSteps.PROPOSED) {
		desktopActions = (
			<>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='trash-alt'
					iconStyle={EnumFontStyle.LIGHT}
					name='ignored'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.ignored')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'ignored',
						});
					}}
				/>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='times'
					iconStyle={EnumFontStyle.LIGHT}
					name='refused'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.refused')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'refused',
						});
					}}
				/>

				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='folder'
					iconStyle={EnumFontStyle.LIGHT}
					name='applied'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.applied')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'applied',
						});
					}}
				/>
			</>
		);
	} else if (commercializationStep === EnumSteps.ACCEPTED) {
		desktopActions = (
			<>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='share-arrow'
					iconStyle={EnumFontStyle.LIGHT}
					name='proposed'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.proposed')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'proposed',
						});
					}}
				/>
			</>
		);
	} else if (commercializationStep === EnumSteps.REFUSED || EnumSteps.IGNORED) {
		desktopActions = (
			<>
				<Button
					className={styles.button}
					corners={EnumButtonCorners.Square}
					hasBoxShadow={true}
					iconLeft='share-arrow'
					iconStyle={EnumFontStyle.LIGHT}
					name='proposed'
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					tooltipText={t('format.capitalize', {
						text: t('general.action.proposed')
					})}
					onClick={event => {
						onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'proposed',
						});
					}}
				/>
			</>
		);
	}

	let mobileActions = null;

	if (commercializationStep === EnumSteps.MATCHED) {
		mobileActions = (
			<ButtonWithDropdown
				className={styles.button}
				corners={EnumButtonCorners.Square}
				dropdownTheme={EnumTheme.SECONDARY}
				hasBoxShadow={false}
				iconLeft='ellipsis-stroke'
				iconStyle={EnumFontStyle.LIGHT}
				isOpenAfterClick={true}
				items={[
					{
						iconLeft: 'share-arrow',
						label: t('format.capitalize', {
							text: t('general.action.proposed')
						}),
						onClick: (event: MouseEvent<HTMLElement>) => onClickCommercializationStep(event, {
							demands: [
								demand.id
							],
							commercialization_step: 'proposed',
						}),
						iconStyle: EnumFontStyle.LIGHT,
					},
					{
						label: t('format.capitalize', {
							text: t('general.action.ignored')
						}),
						iconLeft: 'trash-alt',
						iconStyle: EnumFontStyle.LIGHT,
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'ignored',
							});
						},
						separator: true,
					},
					{
						iconLeft: 'star',
						iconStyle: demand?.offer_states?.includes('bookmarked') ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
						label: t('format.capitalize', {
							text: demand?.offer_states?.includes('bookmarked') ? t('general.remove_bookmark') : t('general.bookmark_customer')
						}),
						onClick: handleOnClickBookmarked,
					},
				]}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
			/>
		);
	} else if (commercializationStep === EnumSteps.PROPOSED) {
		mobileActions = (
			<ButtonWithDropdown
				className={styles.button}
				corners={EnumButtonCorners.Square}
				dropdownTheme={EnumTheme.SECONDARY}
				hasBoxShadow={false}
				iconLeft='ellipsis-stroke'
				iconStyle={EnumFontStyle.LIGHT}
				isOpenAfterClick={true}
				items={[
					{
						iconLeft: 'trash-alt',
						label: t('format.capitalize', {
							text: t('general.action.ignored')
						}),
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'ignored',
							});
						},
						iconStyle: EnumFontStyle.LIGHT,
					},
					{
						label: t('format.capitalize', {
							text: t('general.action.refused')
						}),
						iconLeft: 'times',
						iconStyle: EnumFontStyle.LIGHT,
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'refused',
							});
						},
						separator: true,
					},
					{
						label: t('format.capitalize', {
							text: t('general.action.applied')
						}),
						iconLeft: 'folder',
						iconStyle: EnumFontStyle.LIGHT,
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'applied',
							});
						},
						separator: true,
					},
				]}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
			/>
		);
	} else if (commercializationStep === EnumSteps.ACCEPTED) {
		mobileActions = (
			<ButtonWithDropdown
				className={styles.button}
				corners={EnumButtonCorners.Square}
				dropdownTheme={EnumTheme.SECONDARY}
				hasBoxShadow={false}
				iconLeft='ellipsis-stroke'
				iconStyle={EnumFontStyle.LIGHT}
				isOpenAfterClick={true}
				items={[
					{
						iconLeft: 'share-arrow',
						label: t('format.capitalize', {
							text: t('general.action.proposed')
						}),
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'proposed',
							});
						},
						iconStyle: EnumFontStyle.LIGHT,
					},
				]}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
			/>
		);
	} else if (commercializationStep === EnumSteps.REFUSED || EnumSteps.IGNORED) {
		mobileActions = (
			<ButtonWithDropdown
				className={styles.button}
				corners={EnumButtonCorners.Square}
				dropdownTheme={EnumTheme.SECONDARY}
				hasBoxShadow={false}
				iconLeft='ellipsis-stroke'
				iconStyle={EnumFontStyle.LIGHT}
				isOpenAfterClick={true}
				items={[
					{
						iconLeft: 'share-arrow',
						label: t('format.capitalize', {
							text: t('general.action.proposed')
						}),
						onClick: (event: MouseEvent<HTMLElement>) => {
							onClickCommercializationStep(event, {
								demands: [
									demand.id
								],
								commercialization_step: 'proposed',
							});
						},
						iconStyle: EnumFontStyle.LIGHT,
					},
				]}
				size={EnumButtonSize.SMALL}
				theme={EnumTheme.NAKED}
			/>
		);
	}

	const demandStateElementDesktop = isDesktopResolution ? (
		<div className={`${styles.cell} ${styles.labels}`}>
			{demandStateElement}
			{demand.state === 'successful' ? t('page.matching_offer_demands.with_my_offer') : undefined}
		</div>
	) : null;

	const statesOfferElement = demand.offer_states?.length > 0 ? (
		demand.offer_states.map((state, index) => {
			return (
				<LabelStatus
					className={styles.status}
					context='female'
					key={index}
					text={state}
				/>
			);
		})
	) : null;

	const statesOfferElementDesktop = isDesktopResolution ? (
		<div className={`${styles.cell} ${styles.labels}`}>
			{statesOfferElement}
		</div>
	) : null;

	const companyElement = (
		<div className={styles.cell}>
			{isDesktopResolution || isSelected ? undefined : commercializationStep === EnumSteps.MATCHED ? mobileActions : undefined}
			<Text
				className={`${styles.mb8} ${styles.company__name}`}
				ellipsis={true}
			>
				{demand.recipient.company.name ? t('format.capitalize', {
					text: demand.recipient.company.name
				}) : notSpecified}
			</Text>
			<Link
				className={styles.mb8}
				href={demandUrl}
				target={EnumTargetLink.BLANK}
			>
				{t('format.capitalize', {
					text: t('general.ref', {
						'%ref%': demand.id
					})
				})}
			</Link>
			<Text>
				{t('format.capitalize', {
					text: t('general.created_at', {
						'%date%': demand.created_infos.date
					})
				})}
			</Text>
			{isDesktopResolution ? undefined : (
				<>
					<div className={styles.mt8}>
						{demandStateElement}
					</div>
					{statesOfferElement ? (
						<div className={`${styles.mt8} ${styles.labels_mobile}`}>
							{statesOfferElement}
						</div>
					) : undefined}
				</>
			)}
		</div>
	);

	const contact = demand.recipient.contacts.collection.length ? demand.recipient.contacts.collection[0] : null;

	const contactElement = isDesktopResolution ? (
		<div
			className={styles.cell}
		>
			<Text
				className={`${styles.mb8} ${styles.contact__name}`}
				ellipsis={true}
			>
				{contact?.full_name || notSpecified}
			</Text>
			<Text
				className={styles.mb8}
				ellipsis={true}
			>
				<Link
					fontWeight={EnumFontStyle.REGULAR}
					href={`tel:${contact?.phone}`}
				>
					<Icon
						className={styles.icon}
						fontStyle={EnumFontStyle.LIGHT}
						name='phone-alt'
					/>
					{contact?.phone ? t('format.tel', {
						'val': contact.phone,
					}) : notSpecified}
				</Link>
			</Text>
			<Text
				ellipsis={true}
			>
				<Link
					fontWeight={EnumFontStyle.REGULAR}
					href={`mailto:${contact?.email}`}
				>
					<Icon
						className={styles.icon}
						fontStyle={EnumFontStyle.LIGHT}
						name='envelope'
					/>
					{contact?.email || notSpecified}
				</Link>
			</Text>
		</div>
	) : null;

	const transactionTypes = demand.search_criterias.demand_types.map(demand_type => t('format.capitalize', {
		text: t(`general.${demand_type}`).charAt(0)
	})).join('/');

	const natures = demand.search_criterias?.natures?.length ? (
		demand.search_criterias?.natures.map((nature: string) => {
			return t('format.capitalize', {
				text: t(`references.natures.${nature}`)
			});
		}).join(' - ')
	) : null;

	let areaDiplayed = null;
	if (demand.search_criterias?.area?.min?.value && demand.search_criterias?.area?.max?.value) {
		areaDiplayed = t('format.to', {
			from: t('format.number', {
				val: demand.search_criterias.area.min.value
			}),
			to: t('format.area', {
				val: demand.search_criterias.area.max.value
			})
		});
	} else if (demand.search_criterias?.area?.max?.value) {
		areaDiplayed = t('format.max', {
			text: t('format.area', {
				val: demand.search_criterias.area.max.value
			})
		});
	} else if (demand.search_criterias?.area?.min?.value) {
		areaDiplayed = t('format.min', {
			text: t('format.area', {
				val: demand.search_criterias.area.min.value
			})
		});
	}

	const locations = demand.search_criterias?.locations?.length ? (
		demand.search_criterias?.locations.map((location: string) => {
			return t('format.capitalize', {
				text: location
			});
		}).join(' - ')
	) : null;

	const searchCriteriasElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<Text
				className={styles.mb8}
				ellipsis={true}
			>{natures ? `${natures} ` : null}{transactionTypes ? `(${transactionTypes})` : null}</Text>
			{locations ? (
				<Text
					className={styles.mb8}
					ellipsis={true}
				>{locations}</Text>
			) : null}
			{locations ? (
				<Text
					ellipsis={true}
				>{areaDiplayed}</Text>
			) : null}
		</div>
	) : null;

	const brokerElement = isDesktopResolution ? (
		<div className={styles.cell}>
			<Text ellipsis={true}>{demand.created_infos.user.full_name}</Text>
		</div>
	) : undefined;

	const actionsElementDesktop = isDesktopResolution && commercializationStep === EnumSteps.MATCHED && isSelected === null ? (
		<div className={`${styles.cell} ${styles.action}`}>
			{desktopActions}
		</div>
	) : undefined;

	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.preventDefault();
		window.open(demandUrl);
	};

	return (
		<div
			className={cssClasses.join(' ')}
			onClick={handleOnClick}
		>
			{canSelect ? <div className={styles.selection}>{checkboxElement}</div> : null}
			{companyElement}
			{contactElement}
			{searchCriteriasElement}
			{brokerElement}
			{demandStateElementDesktop}
			{statesOfferElementDesktop}
			{actionsElementDesktop}
		</div>
	);
};

export { DemandRow as default, };
