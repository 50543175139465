// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vrRbWYCZ{padding:0;position:relative}.eOw9BcBp{display:flex;flex-direction:column;padding:0 0 4.28571429rem}@media screen and (min-width: 768Px)and (orientation: landscape){.eOw9BcBp{height:calc(100vh - 4.57142857rem);overflow-y:auto;padding:0}}.vrRbWYCZ main{height:100%}.vrRbWYCZ .jJJ9BHeR{color:var(--color-primary);font-weight:600}@media screen and (min-width: 768Px)and (orientation: landscape){.Goss1Rlu{display:flex;justify-content:center;position:absolute;top:1.42857143rem;width:100%;z-index:5}}.Goss1Rlu .t6jfjwSx{box-shadow:0.07142857rem 0.07142857rem 0.35714286rem 0 hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.2);position:fixed;top:6.14285714rem;width:calc(100% - 2.28571429rem);z-index:5}@media screen and (min-width: 768Px)and (orientation: landscape){.Goss1Rlu .t6jfjwSx{max-width:60%;top:unset}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageOfferEntry": `vrRbWYCZ`,
	"PageOfferEntry__content": `eOw9BcBp`,
	"step_progress": `jJJ9BHeR`,
	"status_banner_container": `Goss1Rlu`,
	"status_banner": `t6jfjwSx`
};
export default ___CSS_LOADER_EXPORT___;
