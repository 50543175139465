import React, {
	ReactNode,
	useState,
	useRef,
} from 'react';

// COMPONENTS
import Input from '@components/form/input';
import {
	InputProps,
} from '@components/form/input';
import LabelInput from '@components/form/input-label';
import RadioButton from '@components/radio-button';

// ENUMS
import {
	EnumInputPosition,
	EnumInputType,
} from '@enums/form.enum';

// STYLES
import styles from './input-radio.module.scss';

interface InputRadioProps extends Omit<InputProps, 'label'> {
	checked?: boolean;
	label?: ReactNode;
	tabindex?: number;
}

const InputRadio = ({
	checked,
	className,
	'data-testid': dataTestid,
	disabled = false,
	id,
	innerRef,
	inputPosition = EnumInputPosition.LEFT,
	invalid,
	label,
	methods,
	name,
	tabindex = 0,
	...otherProps
}: InputRadioProps): JSX.Element => {
	const registerRules: InputProps['registerRules'] = {
	};

	const hookRef = useRef();
	const localRef = innerRef || hookRef;

	const classes = [
		styles.input_radio,
		styles[`position__${inputPosition}`]
	];

	if (className) classes.push(className);
	if (disabled) classes.push(styles.disabled);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	const handleOnBlur = () => {
		const newCssClasses = cssClasses.filter(cssClass => cssClass !== styles.focused);
		setCssClasses(newCssClasses);
	};

	const handleOnFocus = () => {
		setCssClasses([
			...cssClasses,
			styles.focused
		]);
	};

	const input = (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			onBlur={handleOnBlur}
			onFocus={disabled ? null : handleOnFocus}
		>
			<Input
				{...otherProps}
				checked={checked}
				data-testid={`${dataTestid}-input`}
				id={id}
				innerRef={localRef}
				invalid={invalid}
				methods={methods}
				name={name}
				registerRules={registerRules}
				type={EnumInputType.RADIO}

			/>
			<LabelInput
				data-testid={`${dataTestid}-label`}
				id={id}
				invalid={invalid ? true : false}
				tabIndex={tabindex}
			>
				<RadioButton
					checked={checked}
					className={styles.radio_button}
					data-testid={`${dataTestid}-radio`}
					disabled={disabled}
				/>
				<div className={styles.input_radio__text}>{label}</div>
			</LabelInput>
		</div>
	);

	return input;
};

export {
	InputRadio as default,
	InputRadioProps,
};
