import i18n from '@modules/i18n';

export const filterValueMappings = [
	{
		id: 'offer_states',
		value: 'bookmarked'
	},
	{
		id: 'state',
		value: 'active'
	},
	{
		id: 'state',
		value: 'pending'
	},
];

export const getOptionsFilters = () => {
	return [
		{
			iconLeft: 'star',
			iconStyle: 'solid',
			label: i18n.t('format.capitalize', {
				text: i18n.t('page.matching_offer_demands.filter.bookmarked_offers'),
			}),
			value: 'bookmarked'
		},
		{
			label: i18n.t('format.capitalize', {
				text: i18n.t('page.matching_offer_demands.filter.active_demands'),
			}),
			value: 'active'
		},
		{
			label: i18n.t('format.capitalize', {
				text: i18n.t('page.matching_offer_demands.filter.pending_demands'),
			}),
			value: 'pending'
		},
	] as object[];
};

